import {
  WIDGET_DIALOG_OPEN,
  TODOBOARD_SHOW,
  UPDATE_TODOS,
  UPDATE_COLUMNS,
  UPDATE_TODO_WIDGETID,
  UPDATE_ACTIVE_TODOS,
  UPDATE_ARCHIVE_TODOS,
  TODO_PRIORITY_LIST,
  TODO_PANEL_LIST,
  TODO_LIST,
  TODO_MASTER_STATUS_DATA,
  WIDGETS_UPDATED,
} from "../constants";

export const clickWidgetBtn = (data: any) => {
  return {
    type: WIDGET_DIALOG_OPEN,
    data,
  };
};

export const todoBoardShow = (data: any) => {
  return {
    type: TODOBOARD_SHOW,
    data,
  };
};

export const widgetsUpdated = (data: any) => {
  return {
    type: WIDGETS_UPDATED,
    data,
  };
};

export const updateTodos = (data: any) => {
  return {
    type: UPDATE_TODOS,
    data,
  };
};
export const updateColumns = (data: any) => {
  return {
    type: UPDATE_COLUMNS,
    data,
  };
};
export const updateTodoWidgetId = (data: any) => {
  return {
    type: UPDATE_TODO_WIDGETID,
    data,
  };
};

export const updateActiveTodos = (data: any) => {
  return {
    type: UPDATE_ACTIVE_TODOS,
    data,
  };
};

export const updateArchiveTodos = (data: any) => {
  return {
    type: UPDATE_ARCHIVE_TODOS,
    data,
  };
};

export const priorityTodoList = (data: any) => {
  return {
    type: TODO_PRIORITY_LIST,
    data,
  };
};

export const panelTodoList = (data: any) => {
  return {
    type: TODO_PANEL_LIST,
    data,
  };
};

export const todoMasterStatusData = (data: any) => {
  return {
    type: TODO_MASTER_STATUS_DATA,
    data,
  };
};

export const toDoList = (data: any) => {
  return {
    type: TODO_LIST,
    data,
  };
};
