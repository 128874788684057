// const panelStorage = [{
//   children: [],
//   folder: 'Intel',
//   id: '012PH7AC7PTNUXHIJ6WNAZFSQTWXQ2TDC7',
//   parentRefId: '012PH7AC7PTNUXHIJ6WNAZFSQTWXQ2TDC7',
//   itemType: 'Folder',
// }];
const panelStorage = [] as any;
export type Actions = {
  type: string;
  payload: any;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const UpdateStorageBeforeDrag = (state = panelStorage, action: Actions) => {
  switch (action.type) {
    case "updateStorageBeforeDrag":
      state = action.payload.data;
      return state;
    default:
      return state;
  }
};

export default UpdateStorageBeforeDrag;
