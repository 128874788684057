/* eslint-disable import/prefer-default-export */
import { Dispatch } from "redux";

export const ADD_DATA_SOURCE = "addDataSource";
export const addDataSourceAction = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ADD_DATA_SOURCE,
      payload: data,
    });
  };
};
