// export const UPDATE_POSITION_AFTER_DRAG = 'UPDATE_POSITION_AFTER_DRAG';
// export const UPDATE_PANEL_DETAILS = 'UPDATE_PANEL_DETAILS';
// export const UPDATE_POSITION_AFTER_DRAG_SIDEBAR =
//   'UPDATE_POSITION_AFTER_DRAG_SIDEBAR';
// export const UPDATE_PANEL_NAME = 'UPDATE_PANEL_NAME';
// export const UPDATE_ARCHIVED_PANEL_DETAILS = 'UPDATE_ARCHIVED_PANEL_DETAILS';
// export const UPDATE_PANEL = 'UPDATE_PANEL';

const PanelActionTypes = {
  UPDATE_POSITION_AFTER_DRAG: "UPDATE_POSITION_AFTER_DRAG",
  UPDATE_PANEL_DETAILS: "UPDATE_PANEL_DETAILS",
  UPDATE_POSITION_AFTER_DRAG_SIDEBAR: "UPDATE_POSITION_AFTER_DRAG_SIDEBAR",
  UPDATE_PANEL_NAME: "UPDATE_PANEL_NAME",
  UPDATE_ARCHIVED_PANEL_DETAILS: "UPDATE_ARCHIVED_PANEL_DETAILS",
  UPDATE_PANEL: "UPDATE_PANEL",
  DELETE_PANEL: "DELETE_PANEL",
  UPDATE_PANEL_CHILDREN_SETTINGS_BY_ID: "UPDATE_PANEL_CHILDREN_SETTINGS_BY_ID",
  UPDATE_PANEL_SETTINGS_BY_ID: "UPDATE_PANEL_SETTINGS_BY_ID",
  UPDATE_ALL_PANEL_SETTINGS: "UPDATE_ALL_PANEL_SETTINGS",
};

export default PanelActionTypes;
