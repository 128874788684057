import { useTranslation } from "react-i18next";
import { FilterOutlined } from "@ant-design/icons";

export default function AddEditFilesHeader({
  addFolder,
  addFolderHandler,
  imageIcon,
  isDraggable,
  reorderedData,
  workSpaceName,
}: any) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="email-head-content">
        <div className="workspace-name">{workSpaceName}</div>
        {isDraggable ? (
          <div className="save-cancel-button-container">
            <button
              onClick={() => reorderedData(true)}
              type="button"
              className="sidebar-button-items returnToDefault"
            >
              {t<string>("globalSettings.save")}
            </button>
            <button
              onClick={() => reorderedData(false)}
              type="button"
              className="setting-cancle cancle-btn"
            >
              {t<string>("globalSettings.cancel")}
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="headerButtonItems headerButtonIconRed"
            onClick={addFolderHandler}
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <FilterOutlined
              style={{
                color: "#BA3A29",
                fontSize: 18,
                height: "fit-content",
              }}
              title={addFolder}
              alt={t<string>("addEditFilterButton")}
            />
          </button>
        )}

        <span className="p-10px">
          <img alt="icon" width={20} height={20} src={imageIcon} />
        </span>
      </div>
    </div>
  );
}
