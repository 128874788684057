/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import c from "classnames";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import ReactRouterPrompt from "react-router-prompt";
import { useNavigate } from "react-router-dom";
import DropdownPreview from "./dropdown-preview";
import useStorageSettings from "./use-storage-settings";
import ResetToDefaultModal from "./reset-to-default-modal";
import "./storage.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import { Child } from "../../interfaces/user-detail-interface";
import { Loader } from "../reusable-components/loader/loader";
import ConfirmLeavePageModal from "./confirm-leave-page-modal";
import { pathnameLocationAction } from "../../store/reducers/pathname-location/actions/pathname-location-action";
import {
  updatePanelChildrenById,
  updatePanelSettingsById,
} from "../../store/reducers/panels/actions/panel-actions";
import { RootReducer } from "../../store/reducers/root-reducer";
import { DefaultSettings } from "./use-email-settings";

type DropboxProps = {
  item?: Child;
};

function Dropbox({ item }: DropboxProps) {
  const { t } = useTranslation();
  const { maxFoldersDesktop, maxFoldersMobile, sortByDesktop, sortByMobile } =
    useStorageSettings();
  const [settings, setSettings] = useState<
    Record<string, Record<string, unknown>>
  >({});

  const [response, loading, error, axiosFetch] = useAxios();

  const { pathname } = useSelector(
    (state: RootReducer) => state.pathnameLocation
  );
  const navigate = useNavigate();

  const url = () => {
    if (item?.pId && item?.id) {
      return `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.pId}/subpanels/${item?.id}/settings-drive/DropBox`;
    }
    if (item?.id) {
      return `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.id}/settings-drive/DropBox`;
    }

    return `${SERVER_CONFIG.BASE_URL}${SERVER_CONFIG.API_URL.DRIVE_SETTINGS.DROPBOX}`;
  };
  const dispatch = useDispatch<any>();

  const [maxFoldersDesktopState, setMaxFoldersDesktopState] = useState<
    string | number
  >("");
  const [sortByDesktopState, setSortByDesktopState] = useState<string | number>(
    ""
  );
  const [maxFoldersMobileState, setMaxFoldersMobileState] = useState<
    string | number
  >("");
  const [sortByMobileState, setSortByMobileState] = useState<string | number>(
    ""
  );
  const [isResetModalOpened, setIsResetModalOpened] = useState(false);

  const loadSettings = async () => {
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: url(),
    });

    const userOptions = data?.userOptions || data;

    setSettings(data);
    setMaxFoldersDesktopState(userOptions?.desktopMaxFoldersDisplayed);
    setSortByDesktopState(userOptions?.desktopFolderSortBy);
    setMaxFoldersMobileState(userOptions?.mobileMaxFoldersDisplayed);
    setSortByMobileState(userOptions?.mobileFolderSortBy);
    maxFoldersDesktop.onChange(userOptions?.desktopMaxFoldersDisplayed);
    maxFoldersMobile.onChange(userOptions?.mobileMaxFoldersDisplayed);
    sortByDesktop.onChange(userOptions?.desktopFolderSortBy);
    sortByMobile.onChange(userOptions?.mobileFolderSortBy);
  };

  const handleSave = async () => {
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: url(),
      data: {
        desktopMaxFoldersDisplayed: maxFoldersDesktop.value,
        desktopFolderSortBy: sortByDesktop.value,
        mobileMaxFoldersDisplayed: maxFoldersMobile.value,
        mobileFolderSortBy: sortByMobile.value,
      },
    });

    const newData = {
      dropbox: {
        ...data,
      },
    };
    if (item && item.pId) {
      dispatch(
        updatePanelChildrenById({ id: item.id, pId: item.pId }, newData)
      );
    } else if (item) {
      dispatch(updatePanelSettingsById({ id: item.id }, newData));
    }

    if (!item) {
      loadSettings();
    }

    if (pathname) {
      const nextPath = pathname;
      dispatch(
        pathnameLocationAction({
          pathname: "",
        })
      );
      navigate(nextPath);
    }
  };

  const shouldSaveChanges =
    maxFoldersDesktopState !== maxFoldersDesktop.value ||
    sortByDesktopState !== sortByDesktop.value ||
    maxFoldersMobileState !== maxFoldersMobile.value ||
    sortByMobileState !== sortByMobile.value;

  const handleResetToDefault = async () => {
    const itemUrl =
      item && item.pId
        ? `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.pId}/subpanels/${item?.id}/settings-reset-to-global/dropbox`
        : `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.id}/settings-reset-to-global/dropbox`;
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: item ? itemUrl : url(),
      data: item
        ? {}
        : ({
            ...settings?.options,
          } as DefaultSettings),
    });

    const newData = {
      dropbox: {
        ...data,
      },
    };
    if (item && item.pId) {
      dispatch(
        updatePanelChildrenById({ id: item.id, pId: item.pId }, newData)
      );
    } else if (item) {
      dispatch(updatePanelSettingsById({ id: item.id }, newData));
    }

    if (!item) {
      const options = settings?.options;

      setMaxFoldersDesktopState(Number(options?.desktopMaxFoldersDisplayed));
      setSortByDesktopState(`${options?.desktopFolderSortBy}`);
      setMaxFoldersMobileState(Number(options?.mobileMaxFoldersDisplayed));
      setSortByMobileState(`${options?.mobileFolderSortBy}`);
      maxFoldersDesktop.onChange(Number(options?.desktopMaxFoldersDisplayed));
      maxFoldersMobile.onChange(Number(options?.mobileMaxFoldersDisplayed));
      sortByDesktop.onChange(`${options?.desktopFolderSortBy}`);
      sortByMobile.onChange(`${options?.mobileFolderSortBy}`);
    }

    setIsResetModalOpened(false);
  };

  const { activePanels } = useSelector((state: RootReducer) => state.panels);

  useEffect(() => {
    loadSettings();
  }, [activePanels]);

  const handleSaveClick = () => {
    dispatch(
      pathnameLocationAction({
        pathname: "",
      })
    );
    handleSave();
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <div className={c("storage-container", { "panel-setting": item?.id })}>
      <h2 className="heading">{t<string>("globalSettings.dropbox.title")}</h2>
      {item?.id ? (
        <p className="individual-note">
          {t<string>("individualSettingNote1")}
          <div className="gap" />
          {t<string>("individualSettingNote2")}
          <div className="gap" />
          {t<string>("individualSettingNote3")}
        </p>
      ) : null}
      <div className="settings-container">
        <h6 className="section-heading">
          {t<string>("globalSettings.gdrive.desktop")}
        </h6>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.gdrive.maxFolders")}
          </p>
          <DropdownPreview {...maxFoldersDesktop} />
        </div>
        <div className="dropdown-container-small">
          <p className="view-heading">
            {t<string>("globalSettings.gdrive.sortBy")}
          </p>
          <DropdownPreview {...sortByDesktop} />
        </div>

        <h6 className="section-heading">
          {t<string>("globalSettings.gdrive.mobile")}
        </h6>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.gdrive.maxFolders")}
          </p>
          <DropdownPreview {...maxFoldersMobile} />
        </div>
        <div className="dropdown-container-small">
          <p className="view-heading">
            {t<string>("globalSettings.gdrive.sortBy")}
          </p>
          <DropdownPreview {...sortByMobile} />
        </div>

        <ResetToDefaultModal
          open={isResetModalOpened}
          onSubmit={handleResetToDefault}
          onClose={() => {
            setIsResetModalOpened(false);
          }}
        />

        <ReactRouterPrompt
          when={({ nextLocation }) => {
            const data = {
              pathname: nextLocation.pathname,
            };
            if (shouldSaveChanges) {
              dispatch(pathnameLocationAction(data));
            }
            return shouldSaveChanges;
          }}
        >
          {({ isActive, onConfirm, onCancel }) => (
            <ConfirmLeavePageModal
              isActive={isActive}
              onCancel={onCancel}
              onConfirm={onConfirm}
              saveData={handleSave}
            />
          )}
        </ReactRouterPrompt>

        <div className="divider" />
        <div className="button-container">
          {item?.id ? (
            <Button
              type="text"
              className="reset-button"
              onClick={() => {
                setIsResetModalOpened(true);
              }}
            >
              {t<string>("adoptAll")}
            </Button>
          ) : (
            <Button
              type="text"
              className="reset-button"
              onClick={() => {
                setIsResetModalOpened(true);
              }}
            >
              {t<string>("globalSettings.gdrive.resetToDefault")}
            </Button>
          )}
          <Button danger className="save-button" onClick={handleSaveClick}>
            {t<string>("globalSettings.gdrive.save")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Dropbox;
