/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { RootReducer } from "../../../store/reducers/root-reducer";
import {
  updateChannelList,
  updateDMList,
  updateLoading,
  updateWorkspaceList,
} from "../../../store/reducers/loading-states/actions/loading-actions";
import {
  Child,
  Panel,
  Source,
} from "../../../interfaces/user-detail-interface";
import { updatePanelsDetails } from "../../../store/reducers/panels/actions/panel-actions";
import useAxios from "../../../services/axios";
import SERVER_CONFIG from "../../../utils/config";

type SelectedChannel = {
  channelID: string;
  channelName: string;
};

type SelectedDM = {
  channelID: string;
  userName: string;
};

type SlackSelectedWorkspaceList = {
  sourceId: number;
  emailId: string;
  workspaceId: string;
  tokenId: number;
  slackName: string;
};

type SlackWorkspaceFromServer = {
  accessToken: string;
  appId: string;
  authedUserId: string;
  authedUserScope: string;
  botAccessToken: string;
  botUserId: string;
  code: string;
  error: null;
  id: string;
  panelUserId: string;
  slackName: string;
  state: string;
  teamId: string;
  tokenId: number;
  tokenType: string;
  userName: string;
  workspaceId: string;
  workspaceName: string;
};

type TempDirectMessage = {
  channelID: string;
  color: string;
  firstName: string;
  image_24: string;
  image_32: string;
  image_48: string;
  image_72: string;
  image_192: string;
  image_512: string;
  image_1024: string;
  image_original: string;
  isIm: boolean;
  lastName: string;
  teamId: string;
  userID: string;
  userName: string;
};

type TempChannel = {
  channelID: string;
  channelName: string;
  channelType: string;
  isChannel: boolean;
  isGroup: boolean;
  isIm: boolean;
  isMpim: boolean;
  userID: string;
};

function AddChannel({
  item,
  workspace,
  updateData,
}: {
  item: any;
  workspace: SlackSelectedWorkspaceList;
  updateData: (panelId: string) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const { connection } = useSelector(
    (state: RootReducer) => state.signalRConnection
  );
  const { username } = useSelector((state: RootReducer) => state.user);
  const { activePanels } = useSelector((state: RootReducer) => state.panels);
  const { workspaceList, channelList, dmList, panelId } = useSelector(
    (state: RootReducer) => state.loading.addRemoveSlackChannel
  );
  const currentWorkspace = workspaceList.find(
    (workspaceItem: SlackWorkspaceFromServer) =>
      workspaceItem.slackName === workspace.slackName
  );
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>();
  const [selectedChannel, setSelectedChannel] = useState<SelectedChannel[]>([]);
  const [selectedDM, setSelectedDM] = useState<SelectedDM[]>([]);
  const [lastSearchedWorkspace, setLastSearchedWorkspace] = useState<string>();

  const [
    fetchChannelsResponse1,
    fetchChannelsLoading1,
    fetchChannelsError1,
    saveSelectedChannelsAndDirectMessages,
  ] = useAxios();
  const [wsList, setWorkSpaceList] = useState<[]>();
  const [chList, setChannelList] = useState<[]>();
  const [dmsgList, setDirectMessage] = useState<[]>();
  const [fetchWorspacesResponse, fetchWorspacesLoading, error, axiosFetch] =
    useAxios();
  const [
    fetchChannelsResponse,
    fetchChannelsLoading,
    fetchChannelsError,
    axiosFetchChannels,
  ] = useAxios();
  const [fetchDMResponse, fetchDMLoading, fetchDMError, axiosFetchDM] =
    useAxios();
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.SOURCE_SLACK_GET_WORKSPACES(panelId),
    });
    // if (connection) {
    //   connection.invoke('GetSlackWorkspacesData', username).catch((err:any) => console.error(err.toString()));
    // }
  }, [panelId]);

  useEffect(() => {
    if (
      selectedWorkspace?.tokenId &&
      selectedWorkspace?.teamId !== lastSearchedWorkspace
    ) {
      setLastSearchedWorkspace(selectedWorkspace.teamId);
      dispatch(updateChannelList([]));
      dispatch(updateDMList([]));
      // eslint-disable-next-line
      // @ts-ignore
      axiosFetchDM({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.SOURCE_SLACK_GET_WORKSPACE_DIRECTMESSAGES(
          item.id
        ),
        params: {
          slack_user_name: selectedWorkspace.userName,
          workspaceid: selectedWorkspace.teamId,
        },
      });
      // eslint-disable-next-line
      // @ts-ignore
      axiosFetchChannels({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.SOURCE_SLACK_GET_WORKSPACE_CHANNELS(item.id),
        params: {
          slack_user_name: selectedWorkspace.userName,
          workspaceid: selectedWorkspace.teamId,
        },
      });
      // connection.invoke('GetSlackChannelsData', selectedWorkspace.tokenId, username).catch((err:any) => console.error(err.toString()));
      // connection.invoke('GetSlackUsersData', selectedWorkspace.tokenId, username).catch((err:any) => console.error(err.toString()));
    }
  }, [selectedWorkspace]);
  useEffect(() => {
    console.log(fetchWorspacesResponse);
    if (fetchWorspacesResponse) {
      const res = fetchWorspacesResponse as any;
      if (res && res.data.success) {
        dispatch(updateWorkspaceList(res.data.data));
      }
    }
  }, [fetchWorspacesResponse]);
  useEffect(() => {
    console.log(fetchChannelsResponse);
    if (fetchChannelsResponse) {
      const res = fetchChannelsResponse as any;
      if (res && res.data.success) {
        dispatch(updateChannelList(res.data.data));
      }
    }
  }, [fetchChannelsResponse]);

  useEffect(() => {
    console.log(fetchDMResponse);
    if (fetchDMResponse) {
      const res = fetchDMResponse as any;
      if (res && res.data.success) {
        dispatch(updateDMList(res.data.data));
      }
    }
  }, [fetchDMResponse]);

  const selectChannel = useCallback(
    (channel: SelectedChannel) => {
      const channelPresent = selectedChannel.findIndex(
        (e: SelectedChannel) => e.channelID === channel.channelID
      );
      if (channelPresent > -1) {
        const temp = [...selectedChannel];
        temp.splice(channelPresent, 1);
        setSelectedChannel([...temp]);
      } else {
        setSelectedChannel([
          ...selectedChannel,
          { channelID: channel.channelID, channelName: channel.channelName },
        ]);
      }
    },
    [selectedChannel]
  );

  const selectDM = useCallback(
    (dm: SelectedDM) => {
      const dmPresent = selectedDM.findIndex(
        (e: SelectedDM) => e.channelID === dm.channelID
      );
      if (dmPresent > -1) {
        const temp = [...selectedDM];
        temp.splice(dmPresent, 1);
        setSelectedDM([...temp]);
      } else {
        setSelectedDM([
          ...selectedDM,
          { channelID: dm.channelID, userName: dm.userName },
        ]);
      }
    },
    [selectedDM]
  );
  const isChannelSelected = useCallback(
    (id: string) => {
      const tempIndex = selectedChannel.findIndex(
        (e: SelectedChannel) => e.channelID === id
      );
      if (tempIndex < 0) {
        return false;
      }
      return true;
    },
    [selectedChannel, selectChannel]
  );

  const isDMSelected = useCallback(
    (id: string) => {
      const tempIndex = selectedDM.findIndex(
        (e: SelectedDM) => e.channelID === id
      );
      if (tempIndex === -1) {
        return false;
      }
      return true;
    },
    [selectedDM, selectDM, dmList]
  );

  const workspaceListUi = useMemo(() => {
    const tempLayout = workspaceList.map(
      (workspaceTemp: SlackWorkspaceFromServer) => {
        return (
          <li
            className={classNames("channel", {
              isActive: selectedWorkspace?.teamId === workspaceTemp.teamId,
            })}
            key={`${workspaceTemp.teamId}${workspaceTemp.tokenId}`}
            onClick={() => {
              if (workspace.slackName === workspaceTemp.slackName) {
                setSelectedWorkspace(workspaceTemp);
                setSelectedChannel([]);
                setSelectedDM([]);
              }
            }}
          >
            <p>{workspaceTemp.slackName}</p>
          </li>
        );
      }
    );
    return tempLayout;
  }, [workspaceList, selectedWorkspace]);

  const channelListUi = useMemo(() => {
    const tempLayout = channelList?.map((channel: any) => {
      return (
        <li
          className={classNames("channel grab", {
            isActive: isChannelSelected(channel.channelID),
          })}
          key={channel.channelID}
          onClick={() => selectChannel(channel)}
        >
          <p>#{channel.channelName}</p>
        </li>
      );
    });
    return tempLayout;
  }, [channelList, selectChannel, selectedChannel]);

  const dmListUi = useMemo(() => {
    const tempLayout = dmList?.map((dm: any) => {
      return (
        <li
          className={classNames("channel grab", {
            isActive: isDMSelected(dm.channelID),
          })}
          key={dm.channelId}
          onClick={() => selectDM(dm)}
        >
          <p>#{dm.userName}</p>
        </li>
      );
    });
    return tempLayout;
  }, [dmList, selectDM, selectedDM, isDMSelected]);

  useEffect(() => {
    setSelectedWorkspace(currentWorkspace);
  }, [workspaceList]);

  const cancel = () => {
    const temp: any = {
      panelId: "",
      workspaceList: [],
      channelList: [],
      dmList: [],
    };
    dispatch(updateLoading("addRemoveSlackChannel", temp));
  };

  useEffect(() => {
    if (fetchChannelsResponse1) {
      updateData(item.id);
      cancel();
    }
  }, [fetchChannelsResponse1]);

  const save = () => {
    // For Child
    if (selectedWorkspace.teamId) {
      if (item.pId) {
        const tempPanels = [...activePanels];
        const parentIndex = tempPanels.findIndex(
          (e: Panel) => e.id === item.pId
        );
        const childIndex = tempPanels[parentIndex].children.findIndex(
          (e: Child) => e.id === item.id
        );
        const childDetails = tempPanels[parentIndex].children[childIndex];
        const sourceIndex = childDetails.sources.findIndex(
          (e: Source) =>
            e.sourceId === 3 && selectedWorkspace.workspaceId === e.workspaceId
        );
        const sourceDetails = childDetails.sources[sourceIndex];
        const mockData = {
          [selectedWorkspace.userName]: {
            [selectedWorkspace.teamId]: {
              slackName: selectedWorkspace.slackName,
              tokenId: selectedWorkspace.tokenId,
              channels: {},
              directMessages: {},
            },
          },
        };
        const { data = {} } = sourceDetails || {};
        const { slack = {} } = data;
        if (!slack[selectedWorkspace.userName]) {
          slack[selectedWorkspace.userName] =
            mockData[selectedWorkspace.userName];
        }
        if (!slack[selectedWorkspace.userName][selectedWorkspace.teamId]) {
          slack[selectedWorkspace.userName][selectedWorkspace.teamId] =
            mockData[selectedWorkspace.userName][selectedWorkspace.teamId];
        }
        slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels =
          {};
        selectedChannel.forEach((e: SelectedChannel) => {
          slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels[
            e.channelID
          ] = {
            channelName: e.channelName,
            channelId: e.channelID,
          };
        });
        slack[selectedWorkspace.userName][
          selectedWorkspace.teamId
        ].directMessages = {};
        selectedDM.forEach((e: SelectedDM) => {
          slack[selectedWorkspace.userName][
            selectedWorkspace.teamId
          ].directMessages[e.channelID] = {
            directMessagesName: e.userName,
            channelId: e.channelID,
          };
        });

        const channelKeysString = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels
        )?.toString();
        let tempChannels = [] as any;
        channelList.forEach((channel: any) => {
          if (channelKeysString.indexOf(channel.channelID) !== -1) {
            tempChannels = [...tempChannels, channel];
          }
        });

        const directMessagesKeysString = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId]
            .directMessages
        )?.toString();
        let tempDirectMessages = [] as any;
        dmList.forEach((directMessage: any) => {
          if (
            directMessagesKeysString.indexOf(directMessage.channelID) !== -1
          ) {
            tempDirectMessages = [...tempDirectMessages, directMessage];
          }
        });
        const updatedSourceDetails = { ...sourceDetails, data: { slack } };
        childDetails.sources.splice(sourceIndex, 1, updatedSourceDetails);
        const updatedParent = JSON.parse(
          JSON.stringify(tempPanels[parentIndex])
        );
        tempPanels.splice(parentIndex, 1, updatedParent);

        saveSelectedChannelsAndDirectMessages({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: SERVER_CONFIG.API_URL.SOURCE_SLACK_SAVE_SELECTED_WORKSPACE_DIRECTMESSAGES_CHNAELLS(
            item.id
          ),
          data: {
            slack: {
              slackEmail: selectedWorkspace.userName,
              workspaceId: selectedWorkspace.teamId,
            },
            Channels: tempChannels,
            DirectMessages: tempDirectMessages,
          },
        });
        dispatch(updatePanelsDetails([...tempPanels]));
      } else if (item.id) {
        const tempPanels = [...activePanels];
        const parentIndex = tempPanels.findIndex(
          (e: Panel) => e.id === item.id
        );
        const childDetails = tempPanels[parentIndex];
        const sourceIndex = childDetails.sources.findIndex(
          (e: Source) =>
            e.sourceId === 3 && selectedWorkspace.workspaceId === e.workspaceId
        );
        const sourceDetails = childDetails.sources[sourceIndex];
        const mockData = {
          [selectedWorkspace.userName]: {
            [selectedWorkspace.teamId]: {
              slackName: selectedWorkspace.slackName,
              tokenId: selectedWorkspace.tokenId,
              channels: {},
              directMessages: {},
            },
          },
        };
        const { data = {} } = sourceDetails || {};
        const { slack = {} } = data;
        if (!slack[selectedWorkspace.userName]) {
          slack[selectedWorkspace.userName] =
            mockData[selectedWorkspace.userName];
        }
        if (!slack[selectedWorkspace.userName][selectedWorkspace.teamId]) {
          slack[selectedWorkspace.userName][selectedWorkspace.teamId] =
            mockData[selectedWorkspace.userName][selectedWorkspace.teamId];
        }
        slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels =
          {};
        selectedChannel.forEach((e: SelectedChannel) => {
          slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels[
            e.channelID
          ] = {
            channelName: e.channelName,
            channelId: e.channelID,
          };
        });
        slack[selectedWorkspace.userName][
          selectedWorkspace.teamId
        ].directMessages = {};
        selectedDM.forEach((e: SelectedDM) => {
          slack[selectedWorkspace.userName][
            selectedWorkspace.teamId
          ].directMessages[e.channelID] = {
            directMessagesName: e.userName,
            channelId: e.channelID,
          };
        });

        const channelKeysString = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels
        )?.toString();
        const tempChannels: TempChannel[] = [];
        channelList.forEach((channel: TempChannel) => {
          if (channelKeysString.indexOf(channel.channelID) !== -1) {
            tempChannels.push(channel);
          }
        });
        const directMessagesKeysString = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId]
            .directMessages
        )?.toString();
        const tempDirectMessages: TempDirectMessage[] = [];
        dmList.forEach((directMessage: TempDirectMessage) => {
          if (
            directMessagesKeysString.indexOf(directMessage.channelID) !== -1
          ) {
            tempDirectMessages.push(directMessage);
          }
        });
        const updatedSourceDetails = { ...sourceDetails, data: { slack } };
        childDetails.sources.splice(sourceIndex, 1, updatedSourceDetails);
        const updatedParent = JSON.parse(
          JSON.stringify(tempPanels[parentIndex])
        );
        tempPanels.splice(parentIndex, 1, updatedParent);

        saveSelectedChannelsAndDirectMessages({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: SERVER_CONFIG.API_URL.SOURCE_SLACK_SAVE_SELECTED_WORKSPACE_DIRECTMESSAGES_CHNAELLS(
            item.id
          ),
          data: {
            slack: {
              slackEmail: selectedWorkspace.userName,
              workspaceId: selectedWorkspace.teamId,
            },
            Channels: tempChannels,
            DirectMessages: tempDirectMessages,
          },
        });
        dispatch(updatePanelsDetails([...tempPanels]));
      }
    }
  };

  useEffect(() => {
    // For Child
    if (item.pId && selectedWorkspace) {
      const tempPanels = [...activePanels];
      const parentIndex = tempPanels.findIndex((e: Panel) => e.id === item.pId);
      const childIndex = tempPanels[parentIndex].children.findIndex(
        (e: Child) => e.id === item.id
      );
      const childDetails = tempPanels[parentIndex].children[childIndex];
      const sourceIndex = childDetails.sources.findIndex(
        (e: Source) =>
          e.sourceId === 3 && selectedWorkspace.workspaceId === e.workspaceId
      );
      const sourceDetails = childDetails.sources[sourceIndex];
      const { data = {} } = sourceDetails || {};
      const { slack = {} } = data;
      if (
        slack[selectedWorkspace.userName] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId]
      ) {
        const keys = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId]
            .directMessages
        );
        const tempSavedDM = keys.map((key: string) => {
          return {
            channelID:
              slack[selectedWorkspace.userName][selectedWorkspace.teamId]
                .directMessages[key].channelId,
            userName:
              slack[selectedWorkspace.userName][selectedWorkspace.teamId]
                .directMessages[key].directMessagesName,
          };
        });
        setSelectedDM([...tempSavedDM]);
      }
    } else if (item.id && selectedWorkspace) {
      const tempPanels = [...activePanels];
      const parentIndex = tempPanels.findIndex((e: Panel) => e.id === item.id);
      const sourceIndex = tempPanels[parentIndex].sources.findIndex(
        (e: Source) =>
          e.sourceId === 3 && selectedWorkspace.workspaceId === e.workspaceId
      );
      const sourceDetails = tempPanels[parentIndex].sources[sourceIndex];
      const { data = {} } = sourceDetails || {};
      const { slack = {} } = data;
      if (
        slack[selectedWorkspace.userName] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId]
      ) {
        const keys = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId]
            .directMessages
        );
        const tempSavedDM = keys.map((key: string) => {
          return {
            channelID:
              slack[selectedWorkspace.userName][selectedWorkspace.teamId]
                .directMessages[key].channelId,
            userName:
              slack[selectedWorkspace.userName][selectedWorkspace.teamId]
                .directMessages[key].directMessagesName,
          };
        });
        setSelectedDM([...tempSavedDM]);
      }
    }
  }, [dmList]);

  useEffect(() => {
    if (item.pId && selectedWorkspace) {
      const tempPanels = [...activePanels];
      const parentIndex = tempPanels.findIndex((e: Panel) => e.id === item.pId);
      const childIndex = tempPanels[parentIndex].children.findIndex(
        (e: Child) => e.id === item.id
      );
      const childDetails = tempPanels[parentIndex].children[childIndex];
      const sourceIndex = childDetails.sources.findIndex(
        (e: Source) =>
          e.sourceId === 3 && selectedWorkspace.workspaceId === e.workspaceId
      );
      const sourceDetails = childDetails.sources[sourceIndex];
      const { data = {} } = sourceDetails || {};
      const { slack = {} } = data;
      if (
        slack[selectedWorkspace.userName] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels
      ) {
        const keys = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels
        );
        const tempSavedChannels = keys.map((channel: string) => {
          return {
            channelID: channel,
            channelName:
              slack[selectedWorkspace.userName][selectedWorkspace.teamId]
                .channels[channel].channelName,
          };
        });
        setSelectedChannel([...tempSavedChannels]);
      }
    } else if (item.id && selectedWorkspace) {
      const tempPanels = [...activePanels];
      const parentIndex = tempPanels.findIndex((e: Panel) => e.id === item.id);
      const sourceIndex = tempPanels[parentIndex].sources.findIndex(
        (e: Source) =>
          e.sourceId === 3 && selectedWorkspace.workspaceId === e.workspaceId
      );
      const sourceDetails = tempPanels[parentIndex].sources[sourceIndex];
      const { data = {} } = sourceDetails || {};
      const { slack = {} } = data;
      if (
        slack[selectedWorkspace.userName] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId] &&
        slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels
      ) {
        const keys = Object.keys(
          slack[selectedWorkspace.userName][selectedWorkspace.teamId].channels
        );
        const tempSavedChannels = keys.map((channel: string) => {
          return {
            channelID: channel,
            channelName:
              slack[selectedWorkspace.userName][selectedWorkspace.teamId]
                .channels[channel].channelName,
          };
        });
        setSelectedChannel([...tempSavedChannels]);
      }
    }
  }, [channelList]);

  const isDataLoading =
    fetchWorspacesLoading ||
    fetchChannelsLoading ||
    fetchDMLoading ||
    fetchChannelsLoading1;

  return (
    <Spin size="large" spinning={isDataLoading}>
      <div>
        <div className="channel-info">
          <p>{t<string>("channelMessage")}</p>
        </div>
        <div style={{ margin: "0px 8px" }} className="add-channel">
          <div className="channel-items">
            <div className="heading">
              <p>Workspace</p>
            </div>
            {workspaceList?.length > 0 && <ul>{workspaceListUi}</ul>}
          </div>
          <div className="channel-items">
            <div className="heading">
              <p>Channels</p>
            </div>
            {channelList?.length > 0 && <ul>{channelListUi}</ul>}
          </div>
          <div className="channel-items">
            <div className="heading">
              <p>Direct Messages</p>
            </div>
            {dmList?.length > 0 && <ul>{dmListUi}</ul>}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className="save-cancel-button-container">
            <button
              type="button"
              style={{ color: "#BA3A29", fontWeight: 500 }}
              className="sidebar-button-items"
              onClick={save}
            >
              {t<string>("globalSettings.save")}
            </button>
            <button
              type="button"
              style={{ color: "rgb(103, 103, 103)" }}
              className="setting-cancle"
              onClick={cancel}
            >
              {t<string>("globalSettings.cancel")}
            </button>
          </div>
        </div>
        <hr style={{ border: "2px solid #F2F2F2" }} />
      </div>
    </Spin>
  );
}
export default AddChannel;
