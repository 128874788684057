import { Navigate } from "react-router-dom";
import useAuthProvider from "./authProvider";

const Landing = () => {
  const { isLoggedIn } = useAuthProvider();

  return isLoggedIn() ? <Navigate to="/panels/p1" /> : <Navigate to="/login" />;
};

export default Landing;
