/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import { useCallback, useEffect, useMemo, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import { FilterOutlined } from "@ant-design/icons";
import icons from "../../../assets/icon-export-assets";
import SlackMessageDetails from "./slack-message-details";
import { RootReducer } from "../../../store/reducers/root-reducer";
import AddChannel from "./add-channel";
import {
  updateChannelList,
  updateDMList,
  updateLoading,
  updateWorkspaceList,
} from "../../../store/reducers/loading-states/actions/loading-actions";
import EditorToolbar, { modules, formats } from "./custom-editor";
import useAxios from "../../../services/axios";
import SERVER_CONFIG from "../../../utils/config";
import {
  PanelsData,
  SlackSelectedWorkspaceList,
  TempChannel,
  TempSlackMsg,
} from "../../../interfaces/user-detail-interface";
import "../data-sources.scss";
import { setPanelsData } from "../../../store/reducers/panel-data/actions/panels-data-actions";

const { Option } = Select;
const Slack = ({ item, slackSource }: { item: any; slackSource: any }) => {
  const itemPanelSettings = item.panelSettings.slack;
  const { slackIcon } = icons;
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { connection } = useSelector(
    (state: RootReducer) => state.signalRConnection
  );
  const { username } = useSelector((state: RootReducer) => state.user);
  const { addRemoveSlackChannel } = useSelector(
    (state: RootReducer) => state.loading
  );
  const { channelList, dmList } = addRemoveSlackChannel;
  const panelData: PanelsData = useSelector(
    (state: RootReducer) => state.panelData
  );

  const [editorValues, setEditorValues] = useState<any[]>([]);
  const [modifiedWorkspaceId, setModifiedWorkspaceId] = useState("");
  const [sendMessageResponse, sendingMessage, error, axiosFetch] = useAxios();

  const [
    sourcePanelData,
    sourcePanelLoading,
    sourcePanelLoadingError,
    sourcePanelDataFetch,
  ] = useAxios();

  const updateSlackInfo = (panelId: string) => {
    sourcePanelDataFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.PANEL_DATA(panelId),
    });
  };

  useEffect(() => {
    if (sourcePanelData) {
      const res = sourcePanelData;
      if (res && res.data.success) {
        const panel = res.data.panelsData[0];
        const tempPanelsData = { ...panelData } as any;
        tempPanelsData[panel.panelId] = {
          email: { gmail: {}, outlook: {} },
          slack: {},
        };
        const gmailDetails = panel.email.gmail[0];
        if (gmailDetails) {
          const gmailId = gmailDetails.emailId;
          const gmailMails = { data: gmailDetails.data };
          tempPanelsData[panel.panelId].email.gmail[gmailId] = gmailMails;
        }
        const outlookDetails = panel.email.outlook[0];
        if (outlookDetails) {
          const outlookId = outlookDetails.emailId;
          const outlookMails = { data: outlookDetails.data };
          tempPanelsData[panel.panelId].email.outlook[outlookId] = outlookMails;
        }
        const slackDetails = panel.slack;
        if (slackDetails && slackDetails.length > 0) {
          const slackObj = {} as any;
          slackDetails.forEach((slack: TempSlackMsg) => {
            if (!slackObj[slack.emailId]) {
              slackObj[slack.emailId] = {};
            }
            slackObj[slack.emailId][slack.slackId.workSpaceId] = {};
            slackObj[slack.emailId][slack.slackId.workSpaceId].channels = {};
            slackObj[slack.emailId][slack.slackId.workSpaceId].directMessages =
              {};
            slack.slackId.data.directMessage.forEach((message) => {
              if (
                !slackObj[slack.emailId][slack.slackId.workSpaceId]
                  .directMessages[message.channelId]
              ) {
                slackObj[slack.emailId][
                  slack.slackId.workSpaceId
                ].directMessages[message.channelId] = {};
                slackObj[slack.emailId][
                  slack.slackId.workSpaceId
                ].directMessages[message.channelId].messages = [];
              }
              slackObj[slack.emailId][slack.slackId.workSpaceId].directMessages[
                message.channelId
              ].messages.push(message);
            });
            slack.slackId.data.channels.forEach((channel: TempChannel) => {
              if (
                !slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ]
              ) {
                slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ] = {};
                slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ].messages = [];
              }
              slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                channel.channelId
              ].messages.push(channel);
            });
          });

          tempPanelsData[panel.panelId].slack = slackObj;
        }
        tempPanelsData[panel.panelId].storage = {};
        if (panel.storage?.oneDrive?.length > 0) {
          tempPanelsData[panel.panelId].storage.oneDrive = {} as any;
          panel.storage?.oneDrive.forEach((oneDrive: any) => {
            if (
              oneDrive &&
              oneDrive?.oneDriveData &&
              oneDrive?.storageEmailId
            ) {
              tempPanelsData[panel.panelId].storage.oneDrive[
                oneDrive.storageEmailId
              ] = oneDrive.oneDriveData;
            }
          });
        }

        if (panel.storage?.googleDrive?.length > 0) {
          tempPanelsData[panel.panelId].storage.googleDrive = {} as any;
          panel.storage?.googleDrive.forEach((googleDrive: any) => {
            if (
              googleDrive &&
              googleDrive.googleDriveData &&
              googleDrive.storageEmailId
            ) {
              tempPanelsData[panel.panelId].storage.googleDrive[
                googleDrive.storageEmailId
              ] = googleDrive.googleDriveData;
            }
          });
        }

        if (panel.storage?.dropBox?.length > 0) {
          tempPanelsData[panel.panelId].storage.dropBox = {} as any;
          panel.storage?.dropBox.forEach((dropBox: any) => {
            if (dropBox && dropBox.dropBoxData && dropBox.storageEmailId) {
              tempPanelsData[panel.panelId].storage.dropBox[
                dropBox.storageEmailId
              ] = dropBox.dropBoxData;
            }
          });
        }
        dispatch(setPanelsData(tempPanelsData));
      }
    }
  }, [sourcePanelData]);

  const maxLinesPerMessage = isMobile
    ? itemPanelSettings.mobileMaxLinesViewPerSlackMessage
    : itemPanelSettings.desktopMaxLinesViewPerSlackMessage;
  const maxMessagesPerChannel = isMobile
    ? itemPanelSettings.mobileMaxMessagesViewPerSlackChannel
    : itemPanelSettings.desktopMaxMessagesViewPerSlackChannel;

  const defaultMaxMessages = 10;

  const associatedEmailList = useMemo(() => {
    let tempAssociatedEmailList: { sourceId: number; emailId: string }[] = [];
    if (slackSource) {
      const { data = {} } = slackSource || {};
      if (data && Object.keys(data).length !== 0) {
        const { slack = {} } = data;
        if (Object.keys(slack).length !== 0) {
          const slackEmails = Object.keys(slack);
          slackEmails.forEach((slackEmail: string) => {
            const tempSelectedSlackEmail = { sourceId: 3, emailId: slackEmail };
            tempAssociatedEmailList = [
              ...tempAssociatedEmailList,
              tempSelectedSlackEmail,
            ];
          });
        }
      }
    }
    return tempAssociatedEmailList;
  }, [slackSource]);

  // Get All Associated WorkSpace List for selectedEmail
  const emailAssociatedWorkspace = useMemo(() => {
    let workspaceList: SlackSelectedWorkspaceList[] = [];
    if (associatedEmailList.length > 0) {
      const { data = {} } = slackSource || {};
      if (data && Object.keys(data).length !== 0) {
        const { slack = {} } = data;
        if (Object.keys(slack).length !== 0) {
          Object.keys(slack).forEach((selectSlackEmail: any) => {
            const tempList = Object.keys(slack[selectSlackEmail] || {});
            const tempEmailData = slack[selectSlackEmail];
            const tempWorkspaceList = tempList?.map((e: string) => {
              return {
                sourceId: 3,
                emailId: selectSlackEmail,
                workspaceId: e,
                slackName: tempEmailData[e].slackName,
                tokenId: tempEmailData[e].tokenId,
              };
            });
            if (tempWorkspaceList.length > 0) {
              workspaceList = [...workspaceList, ...tempWorkspaceList];
            }
          });
        }
      }
    }
    return workspaceList;
  }, [associatedEmailList]);

  // Get All Associated Dm's and Channels List for selectedWorkspace
  const workspaceAssociatedChannelsAndDM = useCallback(
    (workspace: SlackSelectedWorkspaceList) => {
      let channelsAndDM: {
        channels?: {
          [n: string]: {
            channelName: string;
          };
        };
        directMessages?: {
          [n: string]: {
            channelId: string;
            directMessagesName: string;
          };
        };
      } = {
        channels: {},
        directMessages: {},
      };
      if (slackSource) {
        const { data = {} } = slackSource || {};
        if (Object.keys(data).length !== 0) {
          const { slack = {} } = data;
          // if (Object.keys(slack).length !== 0 && slack[selectedSlackWorkspace.emailId]) {
          if (slack[workspace.emailId]) {
            const tempEmailData = slack[workspace.emailId] || {};
            if (tempEmailData[workspace.workspaceId]) {
              channelsAndDM = tempEmailData[workspace.workspaceId];
            }
          }
        }
      }
      return channelsAndDM;
    },
    [item, channelList, dmList, panelData]
  );

  const getChannelMessages = useCallback(
    (channelDetail: any) => {
      let messages: any = [];
      const tempId = `${item.id}${channelDetail.channelId}`;
      if (panelData && panelData[item.id]) {
        const { slack = {} } = panelData[item.id];
        if (slack[channelDetail.emailId]) {
          const panelSlackData = slack[channelDetail.emailId];
          if (panelSlackData[channelDetail.workspaceId]) {
            const { channels = {} } = panelSlackData[channelDetail.workspaceId];
            if (channels[channelDetail.channelId]) {
              const channelData = channels[channelDetail.channelId];
              messages = channelData.messages;
            }
          }
        }
      }
      const listEle = document.getElementById(tempId);
      if (listEle) {
        listEle.scrollTop = listEle.scrollHeight;
      }

      if (messages && messages?.length > 0) {
        const uniqueIds: string[] = [];

        const unique = messages.filter((message: { id: string }) => {
          const isDuplicate = uniqueIds.includes(message.id);

          if (!isDuplicate) {
            uniqueIds.push(message.id);

            return true;
          }

          return false;
        });

        const viewMsgs = unique.slice(-maxMessagesPerChannel || -10);
        return (
          <ul id={tempId}>
            {viewMsgs.map((message: TempChannel) => {
              return (
                <li key={message.id}>
                  <SlackMessageDetails
                    message={message}
                    maxLinesPerMessage={maxLinesPerMessage}
                  />
                </li>
              );
            })}
          </ul>
        );
      }
      return <div />;
    },
    [panelData, item, maxLinesPerMessage, maxMessagesPerChannel, channelList]
  );
  const getDMMessages = useCallback(
    (dmDetail: any) => {
      let messages: any = [];
      const tempId = `${item.id}${dmDetail.dmId}`;
      if (panelData && panelData[item.id]) {
        const { slack = {} } = panelData[item.id];
        if (slack[dmDetail.emailId]) {
          const panelSlackData = slack[dmDetail.emailId];
          if (panelSlackData[dmDetail.workspaceId]) {
            const { directMessages = {} } =
              panelSlackData[dmDetail.workspaceId];
            if (directMessages[dmDetail.dmId]) {
              const dmData = directMessages[dmDetail.dmId];
              messages = dmData.messages;
            }
          }
        }
      }
      const listEle = document.getElementById(tempId);
      if (listEle) {
        listEle.scrollTop = listEle.scrollHeight;
      }

      if (messages && messages.length > 0) {
        const uniqueIds: string[] = [];

        const unique = messages.filter((message: { id: string }) => {
          const isDuplicate = uniqueIds.includes(message.id);

          if (!isDuplicate) {
            uniqueIds.push(message.id);

            return true;
          }

          return false;
        });

        const viewMsgs = unique.slice(-maxMessagesPerChannel || -10);

        return (
          <ul id={tempId}>
            {viewMsgs.map((message: TempChannel) => {
              return (
                <li key={message.id}>
                  <SlackMessageDetails
                    message={message}
                    maxLinesPerMessage={maxLinesPerMessage}
                  />
                </li>
              );
            })}
          </ul>
        );
      }
      return <div />;
    },
    [panelData, item, maxLinesPerMessage, maxMessagesPerChannel, dmList]
  );

  const getMessageValue = (id: string) => {
    if (editorValues?.length > 0) {
      const tempIndex = editorValues.findIndex((e: any) => e.id === id);
      if (tempIndex > -1) {
        return editorValues[tempIndex].value;
      }
    }
    return "";
  };

  const changeMessage = (value: any, id: string) => {
    const tempArray = [...editorValues];
    if (editorValues?.length >= 0) {
      const tempIndex = editorValues.findIndex((e: any) => e.id === id);
      if (tempIndex > -1) {
        tempArray.splice(tempIndex, 1, { id, value });
        setEditorValues([...tempArray]);
      } else {
        tempArray.push({ id, value });
        setEditorValues([...tempArray]);
      }
    }
  };

  const sendMessage = (dataObj: any) => {
    // eslint-disable-next-line
    // @ts-ignore
    axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.SOURCE_SLACK_SEND_MESSAGES,
      data: dataObj,
    });
  };

  const sendDMMessage = (
    id: string,
    channelId: string,
    email: string,
    workspaceId: string
  ) => {
    const tempIndex = editorValues.findIndex((e: any) => e.id === id);
    const tempMessage = editorValues[tempIndex]?.value.replace(
      /(<([^>]+)>)/gi,
      ""
    );
    if (tempMessage) {
      sendMessage({
        channelId,
        message: tempMessage,
        slackUserName: email,
        workspaceId,
      });
      const tempData = editorValues;
      tempData.splice(tempIndex, 1);
      setEditorValues([...tempData]);
    }
  };

  const sendChannelMessage = (
    id: string,
    email: string,
    workspaceId: string
  ) => {
    const tempIndex = editorValues.findIndex((e: any) => e.id === id);
    const tempMessage = editorValues[tempIndex]?.value.replace(
      /(<([^>]+)>)/gi,
      ""
    );
    if (tempMessage) {
      sendMessage({
        channelId: id,
        message: tempMessage,
        slackUserName: email,
        workspaceId,
      });
      const tempData = editorValues;
      tempData.splice(tempIndex, 1);
      setEditorValues([...tempData]);
    }
  };

  // const sendMessage = (dataObj: any) => {
  //   // eslint-disable-next-line
  //   // @ts-ignore
  //   axiosFetch({
  //     method: SERVER_CONFIG.HTTP_METHOD.POST,
  //     url: SERVER_CONFIG.API_URL.SOURCE_SLACK_SEND_MESSAGES,
  //     data: dataObj,
  //   });
  // };
  const currentChannels = useCallback(
    (workspace: SlackSelectedWorkspaceList) => {
      const { channels = {} } =
        workspaceAssociatedChannelsAndDM(workspace) || {};
      let channelsList: {
        channelId: string;
        channelName: string;
        workspaceId: string;
        emailId: string;
      }[] = [
        {} as {
          channelId: string;
          channelName: string;
          workspaceId: string;
          emailId: string;
        },
      ];
      if (Object.keys(channels).length > 0) {
        const tempChannelList: any = Object.keys(channels).map(
          (channelId: string) => {
            return {
              channelId,
              channelName: channels[channelId].channelName,
              workspaceId: workspace?.workspaceId,
              tokenId: workspace?.tokenId,
              emailId: workspace?.emailId,
            };
          }
        );
        channelsList = [...tempChannelList];
      }
      return channelsList;
    },
    [maxLinesPerMessage, channelList]
  );

  type DmType = {
    dmId: string;
    directMessagesName: string;
    channelId: string;
    channelName?: string;
    workspaceId: string;
    tokenId: number;
    emailId: string;
  };

  const currentDms = useCallback(
    (workspace: SlackSelectedWorkspaceList) => {
      const { directMessages = {} } =
        workspaceAssociatedChannelsAndDM(workspace) || {};
      let dmList: DmType[] = [{} as DmType];
      if (Object.keys(directMessages).length > 0) {
        const tempDMList: DmType[] = Object.keys(directMessages).map(
          (dmId: string) => {
            return {
              dmId,
              directMessagesName: directMessages[dmId].directMessagesName,
              channelId: directMessages[dmId].channelId,
              workspaceId: workspace?.workspaceId,
              tokenId: workspace?.tokenId,
              emailId: workspace?.emailId,
            };
          }
        );
        dmList = [...tempDMList];
      }
      return dmList;
    },
    [dmList]
  );

  const dmLayout = useCallback(
    (workspace: SlackSelectedWorkspaceList) => {
      const emptyArray = Object.keys(currentDms(workspace)[0]);
      if (currentDms.length > 0 && emptyArray.length > 0) {
        const tempDmLayout = currentDms(workspace).map((element: DmType) => {
          const editorUniqueId = Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substring(0, 7);
          return (
            <div key={element.dmId} className="slack-channel-heading">
              <span id="slack-message-heading">
                {`# ${element.directMessagesName}`}
              </span>
              {getDMMessages(element)}
              <div className="slack-message-container">
                <EditorToolbar
                  toolbarId={element.dmId || editorUniqueId}
                  sendMessage={() =>
                    sendDMMessage(
                      element.dmId,
                      element.channelId,
                      element.emailId,
                      element.workspaceId
                    )
                  }
                />
                <ReactQuill
                  theme="snow"
                  value={getMessageValue(element.dmId)}
                  onChange={(value: any) => changeMessage(value, element.dmId)}
                  placeholder={`Send a message to #${element.directMessagesName}`}
                  modules={modules(element.dmId || editorUniqueId)}
                  formats={formats}
                  className="slack-message-quill"
                />
              </div>
            </div>
          );
        });
        return tempDmLayout;
      }
      return <div />;
    },
    [currentDms, changeMessage, panelData, dmList]
  );

  const channelLayout = useCallback(
    (workspace: SlackSelectedWorkspaceList) => {
      const emptyArray = Object.keys(currentChannels(workspace)[0]);
      if (currentChannels.length > 0 && emptyArray.length > 0) {
        const tempChannelLayout = currentChannels(workspace).map(
          (element: any) => {
            const editorUniqueId = Math.random()
              .toString(36)
              .replace(/[^a-z]+/g, "")
              .substring(0, 7);
            return (
              <div key={element.channelId} className="slack-channel-heading">
                <span>{`# ${element.channelName}`}</span>
                {getChannelMessages(element)}
                <div className="slack-message-container">
                  <EditorToolbar
                    toolbarId={element.channelId || editorUniqueId}
                    sendMessage={() =>
                      sendChannelMessage(
                        element.channelId,
                        element.emailId,
                        element.workspaceId
                      )
                    }
                  />
                  <ReactQuill
                    theme="snow"
                    value={getMessageValue(element.channelId)}
                    onChange={(value: any) =>
                      changeMessage(value, element.channelId)
                    }
                    placeholder={`Send a message to #${element.channelName}`}
                    modules={modules(element.channelId || editorUniqueId)}
                    formats={formats}
                    className="slack-message-quill"
                  />
                </div>
              </div>
            );
          }
        );
        return tempChannelLayout;
      }
      return <div />;
    },
    [currentChannels, changeMessage, panelData, maxLinesPerMessage, channelList]
  );

  const updateLoadingPanelId = () => {
    const temp: any = {
      panelId: item.id,
      workspaceList: [],
      channelList: [],
      dmList: [],
    };
    dispatch(updateLoading("addRemoveSlackChannel", temp));
  };

  return (
    <Spin size="large" spinning={sourcePanelLoading}>
      <div className="sourceContainer">
        {emailAssociatedWorkspace.map((workspace) => (
          <Fragment key={workspace.tokenId}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                flexWrap: "wrap",
                alignItems: "center",
                paddingBottom: "2px",
              }}
            >
              <div className="workspace-name">{workspace.slackName}</div>

              <button
                type="button"
                className="headerButtonItems headerButtonIconRed"
                onClick={() => {
                  setModifiedWorkspaceId(workspace.workspaceId);
                  updateLoadingPanelId();
                }}
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FilterOutlined
                  style={{
                    color: "#BA3A29",
                    fontSize: 18,
                    height: "fit-content",
                  }}
                  title={t<string>("addEditFilterButton")}
                  alt={t<string>("addEditFilterButton")}
                />
              </button>

              <span className="p-10px">
                <img src={slackIcon} alt="slack" width={20} height={20} />
              </span>
            </div>
            <div>
              {addRemoveSlackChannel?.panelId === item.id &&
                modifiedWorkspaceId === workspace.workspaceId && (
                  <AddChannel
                    item={item}
                    workspace={workspace}
                    updateData={updateSlackInfo}
                  />
                )}

              {channelLayout(workspace)}
              {dmLayout(workspace)}
            </div>
          </Fragment>
        ))}
      </div>
    </Spin>
  );
};

export default Slack;
