export const PANELS_PARTIAL_UPDATE = (id: string) => `/api/panels/${id}`;
export const PANELS_DELETE = (id: string) => `/api/panels/${id}`;
export const SOURCE_PANELS_ADD_STEP_1 = (id: string, type: string) =>
  `/api/panels/${id}/sources/${type}`;
export const SOURCE_PANELS_ALL = (id: string) =>
  `/api/panels/${id}/all-data-sources`;
export const SOURCE_SUBPANELS_ADD_STEP_1 = (
  pId: string,
  id: string,
  type: string
) => `/api/panels/${pId}/subpanels/${id}/sources/${type}`;
export const SOURCE_SUBPANEL_ALL = (pId: string, id: string) =>
  `/api/panels/${pId}/subpanel/${id}/all-data-sources`;
export const SOURCE_PANEL_ADD = (id: string) =>
  `/api/panels/${id}/add-data-source`;
export const SOURCE_SUBPANEL_ADD = (pId: string, id: string) =>
  `/api/panels/${pId}/subpanel/${id}/add-data-source`;
export const SOURCE_SUBPANEL_DELETE = (pId: string, id: string) =>
  `/api/panels/${pId}/subpanel/${id}/delete-data-source`;
export const SOURCE_PANEL_DELETE = (id: string) =>
  `/api/panels/${id}/delete-data-source`;
export const FILTERS_PANELS_UPDATE = (id: string) =>
  `/api/panels/filters/${id}`;
export const FILTERS_PANELS_DELETE = (id: string) =>
  `/api/panels/filters/${id}`;
export const SOURCE_SLACK_GET_WORKSPACES = (id: string) =>
  `/api/panels/${id}/slackworkspaces`;
export const SOURCE_SLACK_GET_WORKSPACE_CHANNELS = (id: string) =>
  `/api/panels/${id}/slackchannels`;
export const SOURCE_SLACK_GET_WORKSPACE_DIRECTMESSAGES = (id: string) =>
  `/api/panels/${id}/slackdirectmessages`;
export const SOURCE_SLACK_SAVE_SELECTED_WORKSPACE_DIRECTMESSAGES_CHNAELLS = (
  id: string
) => `/api/panels/${id}/slackchanneldirectmessages`;
export const PANEL_DATA = (id: string) => `/api/panels/data/${id}`;
export const SOURCE_DRIVE_GET = (type: string) => `/api/panels/${type}`;
export const SOURCE_DRIVE_GET_FOLDER_ITEMS = (type: string) =>
  `/api/panels/${type}`;
export const SOURCE_DRIVE_SAVE = (type: string) => `/api/panels/${type}/save`;
export const FETCH_DATASOURCE_EMAILS = (
  panelId: string,
  dataSourceId: string
) => `/api/panels/data/${panelId}/${dataSourceId}`;
export const DELETE_PANEL_EMAILS = (panelId: string) =>
  `/api/panels/delete-emails/${panelId}`;
export const TODO_LIST_UPDATE = (id: string) =>
  `/api/todoList/todoMaster/${id}`;
export const TODO_LIST_TASK_UPDATE = (id: string) => `/api/todoList/item/${id}`;
export const TODO_LIST_TASK_DELETE = (id: string) => `/api/todoList/${id}`;
export const WIDGETS_LIST_USER_DELETE = (id: string) =>
  `api/users/widgets/${id}`;
export const WIDGETS_LIST_USER_UPDATE = (id: string) =>
  `api/users/widgets/${id}`;
export const WIDGETS_TODOS_LISTS_UPDATE = (mId: string, uId: string) =>
  `/api/users/update-widgets/${mId}/${uId}`;
export const DELETE_PARTICIPANT_BY_ID = (id: string) =>
  `/api/users/delete-participant/${id}`;
export const RESEND_PARTICIPANT_INVITATION = (id: string) =>
  `/api/users/resend-invite-participant/${id}`;

const URLS = {
  COMMON_APIS: {
    LOGIN: "/api/Users/Authenticate",
    USER_INFO: "/api/users/profileInfo",
    LOGIN_DOUBLE: "/api/users/authenticate-updated",
    SELECT_VERIFICATION_METHOD: "/api/users/select-auth-verification-method",
    AUTH_SEND_SMS: "/api/users/select-auth-send-sms",
    AUTH_RESEND_SMS: "/api/users/select-auth-resend-sms",
    VALIDATE_SEND_SMS: "/api/users/select-auth-validate-sms",
    RESET_PASSWORD: "/api/users/reset-password-send-email",
    RESET_PASSWORD_CONFIRM: "/api/users/reset-password",
    RESEND_VALIDATION_EMAIL: "/api/users/resend-validation-email",
    RESET_ALL_DATA: "/api/users/delete-data",
    SEND_FEEDBACK: "/api/help/save-comments",
    PARTICIPANTS_LIST: "/api/users/list-participants",
    ADD_PARTICIPANT: "/api/users/add-participant",
  },
  DATA_SOURCES: {
    ALL: "/api/panels/allDataSources",
    DELETE: "/api/panels/delete/datasource",
  },
  EMAIL_SETTINGS: {
    OUTLOOK: "/api/users/settingsemail/Outlook",
    GMAIL: "/api/users/settingsemail/Gmail",
  },
  MESSENGER_SETTINGS: {
    SLACK: "/api/users/settingsslack",
  },
  DRIVE_SETTINGS: {
    ONEDRIVE: "/api/users/settingsdrive/OneDrive",
    GDRIVE: "/api/users/settingsdrive/GoogleDrive",
    DROPBOX: "/api/users/settingsdrive/DropBox",
  },
  USER: {
    UPDATE: "/api/users/updateProfile",
  },
  PANELS_GET: "/api/panels",
  PANELS_ADD: "/api/panels",
  PANELS_DATA: "/api/panels/data",
  PANELS_REORDERING: "/api/panels/re-ordering",
  PANEL_DATA,
  PANELS_PARTIAL_UPDATE,
  PANELS_DELETE,
  SOURCE_PANELS_ADD_STEP_1,
  SOURCE_PANELS_ALL,
  SOURCE_SUBPANEL_ALL,
  SOURCE_PANEL_ADD,
  SOURCE_SUBPANEL_ADD,
  SOURCE_PANEL_DELETE,
  SOURCE_SUBPANEL_DELETE,
  SOURCE_SUBPANELS_ADD_STEP_1,
  SOURCE_SAVE_ADD_SOURCE_RESPONSE: "api/panels/sources/responsedata",
  FILTERS_PANELS_ADD: "/api/panels/filters",
  FILTERS_PANELS_UPDATE,
  FILTERS_PANELS_DELETE,
  FILTERS_PANELS_VALIDATE: "/api/panels/filters/validate",
  SOURCE_SLACK_GET_WORKSPACES,
  SOURCE_SLACK_GET_WORKSPACE_CHANNELS,
  SOURCE_SLACK_GET_WORKSPACE_DIRECTMESSAGES,
  SOURCE_SLACK_SAVE_SELECTED_WORKSPACE_DIRECTMESSAGES_CHNAELLS,
  SOURCE_SLACK_SEND_MESSAGES: "/api/panels/sendmessage",
  SOURCE_SLACK_FETCH_IMAGES: "api/panels/downloadSlackFile",
  SOURCE_DRIVE_GET,
  SOURCE_DRIVE_GET_FOLDER_ITEMS,
  SOURCE_DRIVE_SAVE,
  FETCH_DATASOURCE_EMAILS,
  DELETE_PANEL_EMAILS,
  //  WIDGETS
  WIDGETS_MASTER_LIST_GET: "api/widgets/master",
  WIDGETS_LIST_USER_GET: "api/users/widgets",
  WIDGETS_LIST_USER_SAVE: "api/users/widgets",
  WIDGETS_LIST_USER_DELETE: "api/users/widgets/",
  WIDGETS_LIST_USER_UPDATE,
  WIDGETS_TODOS_LISTS_UPDATE,
  TODO_LIST_GET: "/api/todoList/",
  TODO_LIST_UPDATE_TODOS: "api/todoList/update-todos",
  TODO_LIST_SAVE: "/api/todoList/todoMaster",
  TODO_LIST_UPDATE,
  TODO_LIST_TASK_SAVE: "/api/todoList/item",
  TODO_LIST_TASK_UPDATE,
  TODO_PRIORITY_LIST: "api/todoList/priority",
  TODO_STATUS_LIST: "api/todoList/status",
  TODO_LIST_TASK_DELETE,
  DELETE_PARTICIPANT_BY_ID,
  RESEND_PARTICIPANT_INVITATION,
};

export default URLS;
