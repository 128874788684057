import { useNavigate } from "react-router-dom";
import { HeaderSubscription } from "./header-subscription";
import "./subscription-page.scss";

const SubscriptionResetPasswordConfirm = () => {
  const navigate = useNavigate();

  return (
    <>
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">Password Reset Successful</h1>
        <div>
          <p className="text-left">
            Your password was updated successfully. Please{" "}
            <span className="text-link" onClick={() => navigate("/")}>
              click here
            </span>{" "}
            to sign in.
          </p>
        </div>
      </div>
    </>
  );
};

export default SubscriptionResetPasswordConfirm;
