/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactRouterPrompt from "react-router-prompt";
import c from "classnames";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DefaultSettings, Option } from "./use-email-settings";
import ResetToDefaultModal from "./reset-to-default-modal";
import "./slack.scss";
import SERVER_CONFIG from "../../utils/config";
import useAxios from "../../services/axios";
import DropdownPreview from "./dropdown-preview";
import { Child } from "../../interfaces/user-detail-interface";
import { Loader } from "../reusable-components/loader/loader";
import ConfirmLeavePageModal from "./confirm-leave-page-modal";
import { pathnameLocationAction } from "../../store/reducers/pathname-location/actions/pathname-location-action";
import {
  updateAllPanelSettings,
  updatePanelChildrenById,
  updatePanelSettingsById,
} from "../../store/reducers/panels/actions/panel-actions";
import { RootReducer } from "../../store/reducers/root-reducer";

const linesPerSlackMessageDesktop: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const linesPerSlackMessageDesktopDefaultValue = 2;

const maxMessagesPerChannelDesktop: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxMessagesPerChannelDesktopDefaultValue = 3;

const linesPerSlackMessageMobile: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const linesPerSlackMessageMobileDefaultValue = 1;

const maxMessagesPerChannelMobile: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxMessagesPerChannelMobileDefaultValue = 2;

const maxRetentions: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxRetentionDefaultValue = 10;

type SlackProps = {
  item?: Child;
};

function Slack({ item }: SlackProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();

  const [standardDesktopView, setStandardDesktopView] = useState<
    Option["value"]
  >(linesPerSlackMessageDesktopDefaultValue);

  const [maxMessagesDesktop, setMaxMessagesDesktop] = useState<Option["value"]>(
    maxMessagesPerChannelDesktopDefaultValue
  );

  const [standardMobileView, setStandardMobileView] = useState<Option["value"]>(
    linesPerSlackMessageMobileDefaultValue
  );

  const [maxMessagesMobile, setMaxMessagesMobile] = useState<Option["value"]>(
    maxMessagesPerChannelMobileDefaultValue
  );

  const [isResetModalOpened, setIsResetModalOpened] = useState(false);
  const [autoDelete, setAutoDelete] = useState<Option["value"]>("yes");

  const [maxRetention, setMaxRetention] = useState<Option["value"]>(
    maxRetentionDefaultValue
  );

  const [response, loading, error, axiosFetch] = useAxios();
  const [settings, setSettings] = useState<Record<
    string,
    Record<string, unknown>
  > | null>(null);

  const { pathname } = useSelector(
    (state: RootReducer) => state.pathnameLocation
  );
  const navigate = useNavigate();

  const url = () => {
    if (item?.pId && item?.id) {
      return `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.pId}/subpanels/${item?.id}/settings-slack`;
    }
    if (item?.id) {
      return `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.id}/settings-slack`;
    }

    return `${SERVER_CONFIG.BASE_URL}${SERVER_CONFIG.API_URL.MESSENGER_SETTINGS.SLACK}`;
  };

  const loadSettings = async () => {
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: url(),
    });

    const userOptions = data?.userOptions || data;

    setSettings(data);
    setStandardDesktopView(userOptions?.desktopMaxLinesViewPerSlackMessage);
    setMaxMessagesDesktop(userOptions?.desktopMaxMessagesViewPerSlackChannel);
    setStandardMobileView(userOptions?.mobileMaxLinesViewPerSlackMessage);
    setMaxMessagesMobile(userOptions?.mobileMaxMessagesViewPerSlackChannel);
    setAutoDelete(userOptions?.autoDeleteOlderMessages.toLowerCase());
    setMaxRetention(userOptions?.maxRetentionSlackMessagePerChannel);
  };

  const handleSave = async () => {
    const newSettings = {
      desktopMaxLinesViewPerSlackMessage: standardDesktopView,
      desktopMaxMessagesViewPerSlackChannel: maxMessagesDesktop,
      mobileMaxLinesViewPerSlackMessage: standardMobileView,
      mobileMaxMessagesViewPerSlackChannel: maxMessagesMobile,
      autoDeleteOlderMessages: autoDelete,
      maxRetentionSlackMessagePerChannel: maxRetention,
    };

    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: url(),
      data: newSettings,
    });

    const newData = {
      slack: item
        ? {
            ...data,
          }
        : newSettings,
    };
    if (item && item.pId) {
      dispatch(
        updatePanelChildrenById({ id: item.id, pId: item.pId }, newData)
      );
    } else if (item) {
      dispatch(updatePanelSettingsById({ id: item.id }, newData));
    }

    if (!item) {
      dispatch(updateAllPanelSettings(newData));
    }

    if (pathname) {
      const nextPath = pathname;
      dispatch(
        pathnameLocationAction({
          pathname: "",
        })
      );
      navigate(nextPath);
    }
  };

  const handleResetToDefault = async () => {
    const defaultValues = {
      autoDeleteOlderMessages: "Yes",
      desktopMaxLinesViewPerSlackMessage:
        linesPerSlackMessageDesktopDefaultValue,
      desktopMaxMessagesViewPerSlackChannel:
        maxMessagesPerChannelDesktopDefaultValue,
      maxRetentionSlackMessagePerChannel: maxRetentionDefaultValue,
      mobileMaxLinesViewPerSlackMessage: linesPerSlackMessageMobileDefaultValue,
      mobileMaxMessagesViewPerSlackChannel:
        maxMessagesPerChannelMobileDefaultValue,
    };
    const itemUrl =
      item && item.pId
        ? `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.pId}/subpanels/${item?.id}/settings-reset-to-global/slack`
        : `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.id}/settings-reset-to-global/slack`;
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: item ? itemUrl : url(),
      data: item
        ? {}
        : ({
            ...settings?.options,
          } as DefaultSettings),
    });

    const newData = {
      slack: item
        ? {
            ...data,
          }
        : defaultValues,
    };

    if (item && item.pId) {
      dispatch(
        updatePanelChildrenById({ id: item.id, pId: item.pId }, newData)
      );
    } else if (item) {
      dispatch(updatePanelSettingsById({ id: item.id }, newData));
    }

    if (!item) {
      dispatch(updateAllPanelSettings(newData));

      const options = settings?.options;
      setStandardDesktopView(
        Number(options?.desktopMaxLinesViewPerSlackMessage)
      );
      setMaxMessagesDesktop(
        Number(options?.desktopMaxMessagesViewPerSlackChannel)
      );
      setStandardMobileView(Number(options?.mobileMaxLinesViewPerSlackMessage));
      setMaxMessagesMobile(
        Number(options?.mobileMaxMessagesViewPerSlackChannel)
      );
      setAutoDelete(`${options?.autoDeleteOlderMessages}`.toLowerCase());
      setMaxRetention(Number(options?.maxRetentionSlackMessagePerChannel));
    }

    setIsResetModalOpened(false);
  };
  const { activePanels } = useSelector((state: RootReducer) => state.panels);
  useEffect(() => {
    loadSettings();
  }, [activePanels]);

  const radioOptionsAutoDelete = [
    {
      value: "yes",
      label: t<string>("globalSettings.slack.yes"),
    },
    {
      value: "no",
      label: t<string>("globalSettings.slack.no"),
    },
  ];

  let shouldSaveChanges = false;

  if (settings && item) {
    shouldSaveChanges =
      standardDesktopView !==
        Number(settings.desktopMaxLinesViewPerSlackMessage) ||
      maxMessagesDesktop !==
        Number(settings.desktopMaxMessagesViewPerSlackChannel) ||
      standardMobileView !==
        Number(settings.mobileMaxLinesViewPerSlackMessage) ||
      maxMessagesMobile !==
        Number(settings.mobileMaxMessagesViewPerSlackChannel);
  } else if (settings) {
    shouldSaveChanges =
      standardDesktopView !==
        Number(settings.userOptions.desktopMaxLinesViewPerSlackMessage) ||
      maxMessagesDesktop !==
        Number(settings.userOptions.desktopMaxMessagesViewPerSlackChannel) ||
      standardMobileView !==
        Number(settings.userOptions.mobileMaxLinesViewPerSlackMessage) ||
      maxMessagesMobile !==
        Number(settings.userOptions.mobileMaxMessagesViewPerSlackChannel);
  }

  const handleSaveClick = () => {
    dispatch(
      pathnameLocationAction({
        pathname: "",
      })
    );
    handleSave();
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <div
      className={c("slack-container", { "panel-settings": Boolean(item?.id) })}
    >
      <h2 className="heading">{t<string>("globalSettings.slack.title")}</h2>
      {item?.id ? (
        <p className="individual-note">
          {t<string>("individualSettingNote1")}
          <div className="gap" />
          {t<string>("individualSettingNote2")}
          <div className="gap" />
          {t<string>("individualSettingNote3")}
        </p>
      ) : null}
      <div className="settings-container">
        <h6 className="section-heading">
          {t<string>("globalSettings.slack.desktop")}
        </h6>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.slack.maxLines")}
          </p>
          <DropdownPreview
            options={linesPerSlackMessageDesktop}
            value={standardDesktopView}
            defaultValue={linesPerSlackMessageDesktopDefaultValue}
            onChange={(newValue: Option["value"]) => {
              setStandardDesktopView(newValue);
            }}
          />
        </div>
        <div className="dropdown-container-wide">
          <p className="view-heading">
            {t<string>("globalSettings.slack.maxMessages")}
          </p>
          <DropdownPreview
            options={maxMessagesPerChannelDesktop}
            value={maxMessagesDesktop}
            defaultValue={maxMessagesPerChannelDesktopDefaultValue}
            onChange={(newValue: Option["value"]) => {
              setMaxMessagesDesktop(newValue);
            }}
          />
        </div>

        <h6 className="section-heading">
          {t<string>("globalSettings.slack.mobile")}
        </h6>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.slack.maxLines")}
          </p>
          <DropdownPreview
            options={linesPerSlackMessageMobile}
            value={standardMobileView}
            defaultValue={linesPerSlackMessageMobileDefaultValue}
            onChange={(newValue: Option["value"]) => {
              setStandardMobileView(newValue);
            }}
          />
        </div>
        <div className="dropdown-container-wide">
          <p className="view-heading">
            {t<string>("globalSettings.slack.maxMessages")}
          </p>
          <DropdownPreview
            options={maxMessagesPerChannelMobile}
            value={maxMessagesMobile}
            defaultValue={maxMessagesPerChannelMobileDefaultValue}
            onChange={(newValue: Option["value"]) => {
              setMaxMessagesMobile(newValue);
            }}
          />
        </div>
        <div className="divider" />

        <ResetToDefaultModal
          open={isResetModalOpened}
          onSubmit={handleResetToDefault}
          onClose={() => {
            setIsResetModalOpened(false);
          }}
        />

        <ReactRouterPrompt
          when={({ nextLocation }) => {
            const data = {
              pathname: nextLocation.pathname,
            };
            if (shouldSaveChanges) {
              dispatch(pathnameLocationAction(data));
            }
            return shouldSaveChanges;
          }}
        >
          {({ isActive, onConfirm, onCancel }) => (
            <ConfirmLeavePageModal
              isActive={isActive}
              onCancel={onCancel}
              onConfirm={onConfirm}
              saveData={handleSave}
            />
          )}
        </ReactRouterPrompt>

        <div className="button-container">
          {item?.id ? (
            <Button
              type="text"
              className="reset-button"
              onClick={() => {
                setIsResetModalOpened(true);
              }}
            >
              {t<string>("adoptAll")}
            </Button>
          ) : (
            <Button
              type="text"
              className="reset-button"
              onClick={() => {
                setIsResetModalOpened(true);
              }}
            >
              {t<string>("globalSettings.slack.resetToDefault")}
            </Button>
          )}
          <Button danger className="save-button" onClick={handleSaveClick}>
            {t<string>("globalSettings.slack.save")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Slack;
