/* eslint-disable no-console */
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Child, Panel } from "../../interfaces/user-detail-interface";
import { RootReducer } from "../../store/reducers/root-reducer";
import { Marker } from "../reusable-components/marker/marker";
import { TodoList } from "../../prop-types/prop-type";
import {
  updateActiveTodos,
  updateArchiveTodos,
} from "../../store/reducers/todo/action/todo";

export const MenuItem = ({
  data,
  setEditName,
  setDropdown,
  setEditColor,
  archivePanel,
  deletePanel,
  addToParentGroup,
}: any) => {
  const { t } = useTranslation();
  const { activePanels } = useSelector((state: RootReducer) => state.panels);
  const filteredElements = activePanels.filter(
    (e: Panel) => e.isStandalone === false
  );
  const menuOnClick = (e: any) => {
    const { key, keyPath } = e;
    if (keyPath.length === 1) {
      if (key === "1") {
        setEditName();
      }
      if (key === "3") {
        setEditColor();
      }
      if (key === "4") {
        archivePanel(data.id, data.pId);
      }
      if (key === "5") {
        deletePanel(data.id, data.pId, data.name, data?.children?.length);
      }
    } else {
      addToParentGroup(key);
    }
    setDropdown("");
  };

  function getItem(
    label: string,
    key: any,
    icon: any,
    children: any,
    type: any
  ) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  // const onClick = (e: any) => {
  //   console.log("click", e);
  // };
  const items = [
    getItem(
      data.name,
      "0",
      <Marker color={data.color} fill={!(data.children?.length > 0)} />,
      null,
      null
    ),
    getItem(t<string>("Rename"), "1", null, null, null),
    (data.pId || data.isStandalone) &&
      getItem(
        t<string>("AddToParentGroup"),
        "sub",
        null,
        filteredElements
          .filter((el: Panel) => el.color !== data.color)
          .map((e: Panel) => {
            return getItem(
              e.name,
              e.id,
              <Marker
                color={e.color}
                fill={!(e.children?.length > 0 || e.isStandalone === false)}
              />,
              null,
              null
            );
          }),
        null
      ),
    getItem(t<string>("ChangeColor"), "3", null, null, null),
    getItem(t<string>("Archive"), "4", null, null, null),
    getItem(
      t<string>("globalSettings.permanentlyDelete"),
      "5",
      null,
      null,
      null
    ),
  ];
  return (
    // <Menu
    //   onClick={(e: any) => menuOnClick(e)}
    //   style={{ background: '#f9f9f9' }}
    // >
    //   <Menu.Item key="0" className="menu-mobile-header">
    //     <Marker color={data.color} fill={!(data.children?.length > 0)} />
    //     {data.name}
    //   </Menu.Item>
    //   <Menu.Item key="1">{t<string>('Rename')}</Menu.Item>
    //   {(data.pId || data.isStandalone) && (
    //     <li className="righ-click-sub-menu" key="sub3" title="Add to Parent Group" style={{ borderBottom: '1px solid #f2f2f2', alignItems: 'center !important', margin: '0px', height: '30px', display: 'flex' }}>
    //       <span className="add-topanels-group">
    //         <span>{t<string>('AddToParentGroup')}</span>
    //         <span>{'>'}</span>
    //       </span>
    //       {/* Generating list of all available parents */}
    //       <ul className="abcd">
    //         {filteredElements.map((e:Panel) => {
    //           return (
    //             // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    //             <li key={e.id} onKeyDown={(e: any) => menuOnClick(e)} onClick={() => menuOnClick({ key: e.id, keyPath: ['2', e.id] })}>
    //               <Marker color={e.color} fill={!(e.children?.length > 0 || e.isStandalone === false)} />
    //               {e.name}
    //             </li>
    //           );
    //         })}
    //       </ul>
    //     </li>
    //   )}
    //   {!data.pId && <Menu.Item key="3">{t<string>('ChangeColor')}</Menu.Item>}
    //   <Menu.Item key="4">{t<string>('Archive')}</Menu.Item>
    //   <Menu.Item key="5">{t<string>('globalSettings.permanentlyDelete')}</Menu.Item>
    // </Menu>
    <Menu onClick={menuOnClick} mode="vertical" items={items} />
  );
};

export const switchItem = (
  dropped: any,
  droppedIn: any,
  activePanels: any,
  dispatch: any,
  updatePanelsDetails: any,
  updateAfterPanelDrag: any,
  updatePanelsOrder: (
    payload: {
      id: string;
      order: number;
    }[]
  ) => Promise<void>,
  changeParentOnReorder: (newPid: string, id: string) => void,
  isDroppedUpper: boolean,
  isLastChild: boolean,
  isLastParent: boolean
) => {
  if (
    dropped.id === droppedIn.id ||
    (!droppedIn.pId && droppedIn.id === dropped.pId) ||
    (dropped.pId && !droppedIn.pId && droppedIn.isStandalone)
  ) {
    return;
  }
  // Both are parent or stand alone just swap
  if (!dropped.pId && !droppedIn.pId) {
    const tempActivePanels = [...activePanels];

    const droppedParentIndex = tempActivePanels.findIndex(
      (e: Panel) => e.id === dropped.id
    );

    tempActivePanels.splice(droppedParentIndex, 1);

    const droppedInParentIndex = tempActivePanels.findIndex(
      (e: Panel) => e.id === droppedIn.id
    );
    const indexToDrop = isDroppedUpper
      ? droppedInParentIndex
      : droppedInParentIndex + 1;

    if (isLastParent) {
      tempActivePanels.push(dropped);
    } else {
      tempActivePanels.splice(indexToDrop, 0, dropped);
    }
    const updatedActivePanels = tempActivePanels.map(
      (e: any, index: number) => {
        return { ...e, order: index };
      }
    );

    const payload = updatedActivePanels.map((e: Panel) => ({
      id: e.id,
      order: e.order,
    }));

    dispatch(updatePanelsDetails(updatedActivePanels));
    updatePanelsOrder(payload);
    return;
  }

  const shiftChildWithSameParent = (
    droppedItem: Child,
    droppedInItem: Child,
    currentPanel: any
  ) => {
    const droppedIndex = currentPanel.children.findIndex(
      (e: Child) => e.id === droppedItem.id
    );
    currentPanel.children.splice(droppedIndex, 1);
    const droppedInIndex = currentPanel.children.findIndex(
      (e: Child) => e.id === droppedInItem.id
    );
    const indexToDrop = isDroppedUpper ? droppedInIndex : droppedInIndex + 1;
    currentPanel.children.splice(indexToDrop, 0, droppedItem);
    const updatedChildrenList: Child[] = [];
    currentPanel.children.forEach((ele: Child, index: number) => {
      updatedChildrenList.push({ ...ele, order: index });
    });
    return updatedChildrenList;
  };
  // Both are children and have same parent just swap within children like panels
  if (dropped.pId && droppedIn.pId && droppedIn.pId === dropped.pId) {
    const currentActivePanels = activePanels.filter(
      (e: any) => e.id === dropped.pId
    );
    const activeParentindex = activePanels.findIndex(
      (e: any) => e.id === dropped.pId
    );
    const updatedChildrenList = shiftChildWithSameParent(
      dropped,
      droppedIn,
      currentActivePanels[0]
    );
    const updatedParent = {
      ...activePanels[activeParentindex],
      children: updatedChildrenList,
    };
    dispatch(updateAfterPanelDrag(updatedParent, activeParentindex));
    const payload = updatedChildrenList.map((child: Child) => ({
      id: child.id,
      order: child.order,
    }));
    updatePanelsOrder(payload);
    return;
  }
  // Both are children and have different parent deattach dropped from its parent and push to droppedIn\'s parent
  if (dropped.pId && droppedIn.pId && dropped.pId !== droppedIn.pId) {
    const tempActivePanels = activePanels;
    const indexOfDroppedParent = tempActivePanels.findIndex(
      (e: any) => e.id === dropped.pId
    );
    const updatedDroppedElement = {
      ...dropped,
      pId: droppedIn.pId,
      color: droppedIn.color,
    };
    const droppedElementIndex = tempActivePanels[
      indexOfDroppedParent
    ].children.findIndex((e: any) => e.id === dropped.id);
    tempActivePanels[indexOfDroppedParent].children.splice(
      droppedElementIndex,
      1
    );
    const updatedChildrenList = tempActivePanels[
      indexOfDroppedParent
    ].children.map((e: any, index: number) => {
      return { ...e, order: index };
    });
    tempActivePanels[indexOfDroppedParent] = {
      ...tempActivePanels[indexOfDroppedParent],
      children: updatedChildrenList,
    };
    const indexOfDroppedInParent = tempActivePanels.findIndex(
      (e: any) => e.id === droppedIn.pId
    );
    const droppedInElementIndex = tempActivePanels[
      indexOfDroppedInParent
    ].children.findIndex((e: any) => e.id === droppedIn.id);
    const indexToDrop = isDroppedUpper
      ? droppedInElementIndex
      : droppedInElementIndex + 1;
    if (isLastChild) {
      tempActivePanels[indexOfDroppedInParent].children.push(
        updatedDroppedElement
      );
    } else {
      tempActivePanels[indexOfDroppedInParent].children.splice(
        indexToDrop,
        0,
        updatedDroppedElement
      );
    }
    const updatedDroppedInChildrenList = tempActivePanels[
      indexOfDroppedInParent
    ].children.map((e: any, index: number) => {
      return { ...e, order: index };
    });
    tempActivePanels[indexOfDroppedInParent] = {
      ...tempActivePanels[indexOfDroppedInParent],
      children: updatedDroppedInChildrenList,
    };
    const updatedPanelDetails = [...tempActivePanels];
    dispatch(updatePanelsDetails(updatedPanelDetails));
    changeParentOnReorder(droppedIn.pId, dropped.id);
    return;
  }

  if (dropped.pId && !droppedIn.isStandalone && dropped.pId !== droppedIn.id) {
    const tempActivePanels = [...activePanels];
    const indexOfDroppedParent = tempActivePanels.findIndex(
      (e: any) => e.id === dropped.pId
    );

    const droppedElementIndex = tempActivePanels[
      indexOfDroppedParent
    ].children.findIndex((e: any) => e.id === dropped.id);
    tempActivePanels[indexOfDroppedParent].children.splice(
      droppedElementIndex,
      1
    );
    const updatedChildrenList = tempActivePanels[
      indexOfDroppedParent
    ].children.map((e: any, index: number) => {
      return { ...e, order: index };
    });
    tempActivePanels[indexOfDroppedParent] = {
      ...tempActivePanels[indexOfDroppedParent],
      children: updatedChildrenList,
    };
    const indexOfDroppedInParent = tempActivePanels.findIndex(
      (e: any) => e.id === droppedIn.id
    );

    tempActivePanels[indexOfDroppedInParent].children.unshift({
      ...dropped,
      pId: droppedIn.id,
      color: droppedIn.color,
    });

    const updatedDroppedInChildrenList = tempActivePanels[
      indexOfDroppedInParent
    ].children.map((e: any, index: number) => {
      return { ...e, order: index };
    });
    tempActivePanels[indexOfDroppedInParent] = {
      ...tempActivePanels[indexOfDroppedInParent],
      children: updatedDroppedInChildrenList,
    };
    const updatedPanelDetails = [...tempActivePanels];
    dispatch(updatePanelsDetails(updatedPanelDetails));
    changeParentOnReorder(droppedIn.id, dropped.id);
    return;
  }

  //  remove child from its parent Make child stand alone'
  // TODO:
  // currently there is no api endpoint to save these functionality
  // will be used after creating api to make a child panel as a standalone panel

  // if (dropped.pId && droppedIn.isStandalone) {
  //   const tempActivePanels = activePanels;
  //   const droppedParentIndex = tempActivePanels.findIndex(
  //     (e: any) => e.id === dropped.pId
  //   );
  //   const droppedParentDetails = tempActivePanels[droppedParentIndex];
  //   const updatedDroppedParentChildren = droppedParentDetails.children.filter(
  //     (e: any) => e.id !== dropped.id
  //   );
  //   const updatedDroppedParent = {
  //     ...droppedParentDetails,
  //     children: updatedDroppedParentChildren,
  //   };
  //   tempActivePanels.splice(droppedParentIndex, 1, updatedDroppedParent);
  //   const updateDroppedElement = { ...dropped, isStandalone: true };
  //   delete updateDroppedElement.pId;
  //   const droppedStandaloneIndex = tempActivePanels.findIndex(
  //     (e: any) => e.id === droppedIn.id
  //   );
  //   tempActivePanels.splice(droppedStandaloneIndex, 0, updateDroppedElement);
  //   const updatedActivePanelsList = tempActivePanels.map(
  //     (e: any, index: number) => {
  //       return { ...e, order: index };
  //     }
  //   );
  //   dispatch(updatePanelsDetails(updatedActivePanelsList));
  //   return;
  // }

  // Make standalone child and add to droppedIn\'s parent'
  if (droppedIn.pId && dropped.isStandalone) {
    const tempActivePanels = activePanels;
    const updatedDropped = {
      ...dropped,
      pId: droppedIn.pId,
      color: droppedIn.color,
      isStandalone: false,
    };
    const indexOfDropped = tempActivePanels.findIndex(
      (e: any) => e.id === dropped.id
    );
    tempActivePanels.splice(indexOfDropped, 1);
    const indexOfDroppedIn = tempActivePanels.findIndex(
      (e: any) => e.id === droppedIn.pId
    );
    const parentToBeUpdated = tempActivePanels[indexOfDroppedIn];
    const indexOfDroppedInChild = parentToBeUpdated.children.findIndex(
      (e: any) => e.id === droppedIn.id
    );
    parentToBeUpdated.children.splice(indexOfDroppedInChild, 0, updatedDropped);
    const updatedParentChild = parentToBeUpdated.children.map(
      (e: any, index: number) => {
        return { ...e, order: index };
      }
    );
    const updatedParent = {
      ...parentToBeUpdated,
      children: updatedParentChild,
    };
    tempActivePanels.splice(indexOfDroppedIn, 1, updatedParent);
    const updatedPanelsList = tempActivePanels.map((e: any, index: number) => {
      return { ...e, order: index };
    });
    dispatch(updatePanelsDetails(updatedPanelsList));
    changeParentOnReorder(droppedIn.pId, dropped.id);
  }
};

export const updatePanelsInCache = (
  data: any,
  activePanels: any,
  archivedPanels: any,
  dispatch: any,
  updateArchivedPanelsDetails: any,
  updatePanelsDetails: any
) => {
  const { id, pId }: any = data;
  // If parent
  const tempActivePanels = [...activePanels];
  const tempArchivedPanels = archivedPanels;
  if (!pId) {
    const indexOfArchived = tempArchivedPanels.findIndex(
      (e: Panel) => e.id === id
    );
    const activeParent = tempActivePanels.filter((e: Panel) => {
      return e.id === id;
    });
    let archivedParentDetails: Panel = {} as Panel;
    if (activeParent[0].children.length > 0) {
      archivedParentDetails = {
        ...activeParent[0],
        active: false,
        children: activeParent[0].children.map((e: Child) => {
          return {
            ...e,
            active: false,
          };
        }),
      };
    } else {
      archivedParentDetails = {
        ...activeParent[0],
        active: false,
      };
    }
    if (indexOfArchived > -1) {
      const archivedpanelsupdated = {
        ...archivedParentDetails,
        order: indexOfArchived,
        children: [
          ...archivedParentDetails.children,
          ...tempArchivedPanels[indexOfArchived].children,
        ].map((e: Child, index: number) => {
          return {
            ...e,
            order: index,
          };
        }),
      };
      tempArchivedPanels.splice(indexOfArchived, 1, archivedpanelsupdated);
    } else {
      tempArchivedPanels.splice(tempArchivedPanels.length, 0, {
        ...archivedParentDetails,
        order: tempArchivedPanels.length,
      });
    }
    // code for changes if currently that panel is open
    const updatedActivePanelIndex = tempActivePanels.findIndex(
      (e: Panel) => e.id === id
    );
    tempActivePanels.splice(updatedActivePanelIndex, 1);
    const updatedActivePanel = tempActivePanels.map(
      (e: Panel, index: number) => {
        return {
          ...e,
          order: index,
        };
      }
    );
    dispatch(updateArchivedPanelsDetails(tempArchivedPanels));
    dispatch(updatePanelsDetails(updatedActivePanel));
  } else {
    // If Child
    const indexofParentInArchive = tempArchivedPanels.findIndex(
      (e: Panel) => e.id === pId
    );
    const indexOfParentInActive = tempActivePanels.findIndex(
      (e: Panel) => e.id === pId
    );
    const archiveElementDetails = tempActivePanels[
      indexOfParentInActive
    ].children.filter((e: Child) => e.id === id)[0];
    const updatedActiveParent = {
      ...tempActivePanels[indexOfParentInActive],
      children: tempActivePanels[indexOfParentInActive].children
        .filter((e: Child) => e.id !== id)
        .map((e: Child, index: number) => {
          return {
            ...e,
            order: index,
          };
        }),
    };
    // If No Child is already Archived
    if (indexofParentInArchive < 0) {
      const updatedArchivedParent = {
        ...updatedActiveParent,
        children: [
          {
            ...tempActivePanels[indexOfParentInActive].children.filter(
              (e: Child) => e.id === id
            )[0],
            active: false,
          },
        ],
      };
      tempArchivedPanels.splice(0, 0, updatedArchivedParent);
    } else {
      const updatedArchiveParent = {
        ...tempArchivedPanels[indexofParentInArchive],
        children: [
          ...tempArchivedPanels[indexofParentInArchive].children,
          {
            ...archiveElementDetails,
            active: false,
            order: tempArchivedPanels[indexofParentInArchive].children.length,
          },
        ],
      };
      tempArchivedPanels.splice(
        indexofParentInArchive,
        1,
        updatedArchiveParent
      );
    }
    tempActivePanels.splice(indexOfParentInActive, 1, updatedActiveParent);
    dispatch(updateArchivedPanelsDetails(tempArchivedPanels));
    dispatch(updatePanelsDetails(tempActivePanels));
  }
};
export const renamePanelInCache = (
  data: any,
  activePanels: any,
  dispatch: any,
  updatePanelsDetails: any
) => {
  const { id, pId, name }: any = data;
  if (pId) {
    const updatedPanel = [...activePanels];
    const parentIndex = updatedPanel.findIndex((ed: Panel) => ed.id === pId);
    // const parentDetails = updatedPanel.filter((es:Panel) => es.id === pId);
    const parentDetails = updatedPanel[parentIndex];
    const updateParent = {
      ...parentDetails,
      children: parentDetails.children.map((ef: Child) => {
        if (ef.id === id) {
          return {
            ...ef,
            name,
          };
        }
        return ef;
      }),
    };
    updatedPanel.splice(parentIndex, 1, updateParent);
    dispatch(updatePanelsDetails(updatedPanel));
  } else {
    const panelsArray = [...activePanels];
    const panelIndex = panelsArray.findIndex((es: Panel) => es.id === id);
    const panelDetails = panelsArray[panelIndex];
    const updatedPanel = {
      ...panelDetails,
      name,
    };
    panelsArray.splice(panelIndex, 1, updatedPanel);
    dispatch(updatePanelsDetails([...panelsArray]));
  }
};
export const addToParentGroup = (
  data: any,
  oldPid: string,
  activePanelsList: any,
  activePanels: any,
  dispatch: any,
  updatePanelsDetails: any
) => {
  const newPid: string = data.pId;
  const { id } = data;
  const tempDroppedInParent = activePanels.filter(
    (e: Panel) => e.id === newPid
  );
  const tempDroppedOutParent = activePanels.filter((e: Panel) => {
    return (
      e.id === id || e.children.filter((child) => child.id === id).length > 0
    );
  });

  if (tempDroppedOutParent[0].id !== newPid) {
    const tempDroppedElement = tempDroppedOutParent[0].isStandalone
      ? [tempDroppedOutParent[0]]
      : tempDroppedOutParent[0].children.filter(
          (element: Child) => element.id === id
        );

    const droppedElement = tempDroppedElement[0];
    const droppedInParent = tempDroppedInParent[0];
    const tempActivePanels = [...activePanels];

    const isStandalonePanel = tempActivePanels.find(
      (panel) => panel.id === droppedElement.id
    );

    const indexOfDroppedParent = tempActivePanels.findIndex((e: Panel) =>
      isStandalonePanel
        ? e.id === droppedElement.id
        : e.id === droppedElement.pId
    );

    const updatedDroppedElement = {
      ...droppedElement,
      pId: newPid,
      color: droppedInParent.color,
    };

    if (!tempDroppedOutParent[0].isStandalone) {
      const droppedElementIndex = tempActivePanels[
        indexOfDroppedParent
      ].children.findIndex((e: Child) => e.id === droppedElement.id);

      tempActivePanels[indexOfDroppedParent].children.splice(
        droppedElementIndex,
        1
      );

      const updatedChildrenList = tempActivePanels[
        indexOfDroppedParent
      ].children.map((e: Child, index: number) => {
        return { ...e, order: index };
      });

      tempActivePanels[indexOfDroppedParent] = {
        ...tempActivePanels[indexOfDroppedParent],
        children: updatedChildrenList,
      };

      const indexOfDroppedInParent = tempActivePanels.findIndex(
        (e: Panel) => e.id === droppedInParent.id
      );

      tempActivePanels[indexOfDroppedInParent].children.push(
        updatedDroppedElement
      );

      const updatedDroppedInChildrenList = tempActivePanels[
        indexOfDroppedInParent
      ].children.map((e: Child, index: number) => {
        return { ...e, order: index };
      });

      tempActivePanels[indexOfDroppedInParent] = {
        ...tempActivePanels[indexOfDroppedInParent],
        children: updatedDroppedInChildrenList,
      };

      dispatch(updatePanelsDetails([...tempActivePanels]));
    } else {
      tempActivePanels.splice(indexOfDroppedParent, 1);

      const indexOfDroppedInParent = tempActivePanels.findIndex(
        (e: Panel) => e.id === droppedInParent.id
      );

      tempActivePanels[indexOfDroppedInParent].children.push(
        updatedDroppedElement
      );

      const updatedDroppedInChildrenList = tempActivePanels[
        indexOfDroppedInParent
      ].children.map((e: Child, index: number) => {
        return { ...e, order: index };
      });

      tempActivePanels[indexOfDroppedInParent] = {
        ...tempActivePanels[indexOfDroppedInParent],
        children: updatedDroppedInChildrenList,
      };

      dispatch(updatePanelsDetails([...tempActivePanels]));
    }
  } else {
    const dropped = tempDroppedOutParent[0];
    const droppedIn = tempDroppedInParent[0];
    const tempActivePanels = activePanels;
    const updatedDropped = {
      ...dropped,
      newPid,
      color: droppedIn.color,
      isStandalone: false,
    };
    const indexOfDropped = tempActivePanels.findIndex(
      (e: Panel) => e.id === dropped.id
    );
    tempActivePanels.splice(indexOfDropped, 1);
    const indexOfDroppedIn = tempActivePanels.findIndex(
      (e: Panel) => e.id === droppedIn.id
    );
    const parentToBeUpdated = tempActivePanels[indexOfDroppedIn];
    parentToBeUpdated.children.splice(0, 0, updatedDropped);
    const updatedParentChild = parentToBeUpdated.children.map(
      (e: Child, index: number) => {
        return { ...e, order: index };
      }
    );
    const updatedParent = {
      ...parentToBeUpdated,
      children: updatedParentChild,
    };
    tempActivePanels.splice(indexOfDroppedIn, 1, updatedParent);
    const updatedPanelsList = tempActivePanels.map(
      (e: Panel, index: number) => {
        return { ...e, order: index };
      }
    );
    dispatch(updatePanelsDetails(updatedPanelsList));
  }
};
export const updatePanelsColorInCache = (
  data: any,
  activePanels: any,
  dispatch: any,
  updatePanelsDetails: any
) => {
  const { id, pId, color }: any = data;
  // If parent
  if (!pId) {
    const tempActivePanels = [...activePanels];
    const parentIndex = tempActivePanels.findIndex((e: Panel) => e.id === id);
    const parent = tempActivePanels[parentIndex];
    let updatedParent = { ...parent, color };
    // change the color of children as well
    if (updatedParent.children.length > 0) {
      updatedParent = {
        ...updatedParent,
        children: updatedParent.children.map((e: Child) => {
          return { ...e, color };
        }),
      };
    }
    tempActivePanels.splice(parentIndex, 1, updatedParent);
    dispatch(updatePanelsDetails([...tempActivePanels]));
  }
};

export const deletePanelInCache = (
  data: any,
  activePanels: any,
  dispatch: any,
  updatePanelsDetails: any,
  activeTodos: TodoList[],
  archiveTodos: TodoList[]
) => {
  const { id, pId }: any = data;
  const tempDetails = [...activePanels];
  if (!pId) {
    const tempIndex = tempDetails.findIndex((e: Panel) => e.id === id);
    tempDetails.splice(tempIndex, 1);
    const updatedActivepanels = tempDetails.map((e: Panel, index: number) => {
      return { ...e, order: index };
    });
    const updatedActiveTodos = activeTodos.map((todo) => {
      return {
        ...todo,
        children: todo.children.map((child) => {
          if (child.panelId === id) {
            return {
              ...child,
              panelId: null,
              panel: null,
            };
          }

          return child;
        }),
      };
    });
    const updatedArchivedTodos = archiveTodos.map((todo) => {
      return {
        ...todo,
        children: todo.children.map((child) => {
          if (child.panelId === id) {
            return {
              ...child,
              panelId: null,
              panel: null,
            };
          }

          return child;
        }),
      };
    });

    dispatch(updateActiveTodos(updatedActiveTodos));
    dispatch(updateArchiveTodos(updatedArchivedTodos));
    dispatch(updatePanelsDetails(updatedActivepanels));
  } else {
    const parentIndex = tempDetails.findIndex((e: Panel) => e.id === pId);
    const parentDetails = {
      ...tempDetails[parentIndex],
      children: tempDetails[parentIndex].children
        .filter((e: Panel) => e.id !== id)
        .map((e: Panel, index: number) => {
          return { ...e, order: index };
        }),
    };
    tempDetails.splice(parentIndex, 1, parentDetails);

    const updatedActiveTodos = activeTodos.map((todo) => {
      return {
        ...todo,
        children: todo.children.map((child) => {
          if (child.panelId === id) {
            return {
              ...child,
              panelId: null,
              panel: null,
            };
          }

          return child;
        }),
      };
    });
    const updatedArchivedTodos = archiveTodos.map((todo) => {
      return {
        ...todo,
        children: todo.children.map((child) => {
          if (child.panelId === id) {
            return {
              ...child,
              panelId: null,
              panel: null,
            };
          }

          return child;
        }),
      };
    });

    dispatch(updateActiveTodos(updatedActiveTodos));
    dispatch(updateArchiveTodos(updatedArchivedTodos));

    dispatch(updatePanelsDetails(tempDetails));
  }
};
