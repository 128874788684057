/* eslint-disable import/no-extraneous-dependencies */
// import { load, save } from "redux-localstorage-simple";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./reducers/root-reducer";
import { subscriptionApi, usersApi } from "./api";

const persistConfig = {
  key: "root",
  whitelist: ["Auth", "user", "zoom"],
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, subscriptionApi.middleware, usersApi.middleware],
});
export default store;
