/* eslint-disable @typescript-eslint/no-unused-vars */
import { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet";

const Page = forwardRef(
  (
    {
      children,
      title = "",
      ...rest
    }: { children: React.ReactNode; title?: string },
    ref: any
  ) => {
    useEffect(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      // eslint-disable-next-line
    }, [window.location.pathname]);
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div ref={ref} {...rest} style={{ height: "100%" }}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);
Page.displayName = "Page";
export default Page;
