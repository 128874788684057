/* eslint-disable jsx-a11y/label-has-associated-control */
import c from "classnames";
import { FC, PropsWithChildren } from "react";
import { AddressElement, AddressElementProps } from "@stripe/react-stripe-js";

type AddressInputBlockProps = {
  className?: string;
  addressElement: {
    onChange: AddressElementProps["onChange"];
  };
  isStartTrailPage?: boolean;
};
const AddressInputBlock: FC<PropsWithChildren<AddressInputBlockProps>> = ({
  className,
  addressElement,
}) => {
  return (
    <div className={c("flex-f-col-100", className)}>
      <label htmlFor="card-cvc" className="font-14-pb-5px">
        Subscription Address
      </label>
      <AddressElement
        id="card-address"
        options={{
          mode: "billing",
          display: { name: "split" },
          fields: {
            phone: "always",
          },
          validation: {
            phone: {
              required: "always",
            },
          },
          autocomplete: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            ? {
                mode: "google_maps_api",
                apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              }
            : { mode: "automatic" },
        }}
        {...addressElement}
      />
    </div>
  );
};

export { AddressInputBlock };
