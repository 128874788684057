import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Menu, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Child, Panel } from "../../interfaces/user-detail-interface";
import { RootReducer } from "../../store/reducers/root-reducer";
import { Marker } from "../reusable-components/marker/marker";
import {
  updatePanelsDetails,
  updateArchivedPanelsDetails,
} from "../../store/reducers/panels/actions/panel-actions";
import SERVER_CONFIG, { Constants } from "../../utils/config";
import useAxios from "../../services/axios";

export const MenuItem = ({ data, deletePanel, makeActive }: any) => {
  type OnClickMenu = (e: { key: string; keyPath: string[] }) => void;
  const menuOnClick: OnClickMenu = (e) => {
    const { key, keyPath } = e;
    if (keyPath.length === 1) {
      if (key === "2") {
        makeActive(data.id, data.pId);
      }
      if (key === "3") {
        deletePanel(data.id, data.pId, data.name, data?.children?.length);
      }
    }
  };
  return (
    <Menu onClick={(e) => menuOnClick(e)} style={{ background: "#f9f9f9" }}>
      <Menu.Item key="2">Make Active</Menu.Item>
      <Menu.Item key="3">Permanently Delete</Menu.Item>
    </Menu>
  );
};

function ArchivedPanels() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activePanels = useSelector(
    (state: RootReducer) => state.panels.activePanels
  );
  const archivedPanelList = useSelector(
    (state: RootReducer) => state.panels.archivedPanels
  );
  const { archivedPanels } = useSelector((state: RootReducer) => state.panels);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [delPanelName, setDelPanelName] = useState({
    id: "",
    pId: "",
    panelName: "",
    childrenLength: 0,
  });

  const [
    updatePanelResponse,
    updatePanelLoading,
    updatePanelLoadingError,
    updatePanel,
  ] = useAxios();

  const archivedPanelsList = useMemo(() => {
    return archivedPanels
      .map((parent: Panel) => {
        const { children = [] } = parent;
        return [
          parent,
          ...children.map((e: Child) => {
            return { ...e, pActive: parent.active, parentName: parent.name };
          }),
        ];
      })
      .flat();
  }, [archivedPanels]);
  const makeActive = (id: string, pId: string) => {
    const partialUpdateParams = {
      op: Constants.REPLACE,
      path: Constants.CHANGE_ARCHIVE_STATUS_PATH,
      value: "true",
    };

    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
    // If parent
    const tempActivePanels = [...activePanels];
    const tempArchivedPanels = [...archivedPanelList];
    if (!pId) {
      const indexOfArchived = tempArchivedPanels.findIndex(
        (e: Panel) => e.id === id
      );
      const archivedParent = tempArchivedPanels.filter((e: Panel) => {
        return e.id === id;
      });
      const updatedPanel = {
        ...archivedParent[0],
        active: true,
        order: tempActivePanels.length,
        children: archivedParent[0].children.map((e: Child) => {
          return { ...e, active: true };
        }),
      };
      tempArchivedPanels.splice(indexOfArchived, 1);
      const updatedArchivedPanelList = tempArchivedPanels.map(
        (e: Panel, index: number) => {
          return { ...e, order: index };
        }
      );
      const updatedActivePanelList = [...tempActivePanels, updatedPanel];
      dispatch(updatePanelsDetails(updatedActivePanelList));
      dispatch(updateArchivedPanelsDetails(updatedArchivedPanelList));
      navigate(`/panels/${id}`);
    } else {
      // If Child
      const indexofParentInArchive = tempArchivedPanels.findIndex(
        (e: Panel) => e.id === pId
      );
      const indexOfParentInActive = tempActivePanels.findIndex(
        (e: Panel) => e.id === pId
      );
      const activeElementDetails = {
        ...tempArchivedPanels[indexofParentInArchive].children.filter(
          (e: Child) => e.id === id
        )[0],
        active: true,
      };
      const activeParentDetails = {
        ...tempArchivedPanels[indexofParentInArchive],
        active: true,
      };
      // const indexOfElementInArchive = tempArchivedPanels[indexofParentInArchive].children.findIndex((e:Child) => e.id === id);
      if (tempArchivedPanels[indexofParentInArchive].children.length > 1) {
        const updatedArchiveParent = {
          ...tempArchivedPanels[indexofParentInArchive],
          children: tempArchivedPanels[indexofParentInArchive].children
            .filter((e: Child) => e.id !== id)
            .map((e: Child, index: number) => {
              return { ...e, order: index };
            }),
          active: true,
        };
        tempArchivedPanels.splice(
          indexofParentInArchive,
          1,
          updatedArchiveParent
        );
      } else {
        tempArchivedPanels.splice(indexofParentInArchive, 1);
      }
      if (indexOfParentInActive > 0) {
        const currentActiveParent = tempActivePanels[indexOfParentInActive];
        const updatedActiveParent = {
          ...currentActiveParent,
          children: [
            ...currentActiveParent.children,
            {
              ...activeElementDetails,
              order: currentActiveParent.children.length,
            },
          ],
        };
        tempActivePanels.splice(indexOfParentInActive, 1, updatedActiveParent);
      } else {
        tempActivePanels.splice(tempActivePanels.length, 0, {
          ...activeParentDetails,
          children: [{ ...activeElementDetails, order: 0 }],
        });
      }

      dispatch(updatePanelsDetails([...tempActivePanels]));
      dispatch(updateArchivedPanelsDetails([...tempArchivedPanels]));
      navigate(`/panels/${pId}/${id}`);
    }
    // If child
  };

  const handleOk = () => {
    const { id, pId } = delPanelName;
    const tempDetails = [...archivedPanelList];
    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.PANELS_DELETE(id),
    });
    if (!pId) {
      const tempIndex = tempDetails.findIndex((e: Panel) => e.id === id);
      tempDetails.splice(tempIndex, 1);
      const updatedActivepanels = tempDetails.map((e: Panel, index: number) => {
        return { ...e, order: index };
      });
      dispatch(updateArchivedPanelsDetails(updatedActivepanels));
      // if tempDetails is empty  and this parent is selected in browser if this condition will not add then
      //  it throw error because data has deleted and browser selected id is not exist in store.
      if (tempDetails.length === 0 && activePanels.length > 0) {
        navigate(`/panels/${activePanels[0].id}`);
      } else if (tempDetails.length === 0 && activePanels.length < 1) {
        navigate("/");
      }
    } else {
      const parentIndex = tempDetails.findIndex((e: Panel) => e.id === pId);
      const parentDetails = {
        ...tempDetails[parentIndex],
        children: tempDetails[parentIndex].children
          .filter((e: Panel) => e.id !== id)
          .map((e: Panel, index: number) => {
            return { ...e, order: index };
          }),
      };
      if (tempDetails[0].children.length === 1 && tempDetails[0].active) {
        tempDetails.splice(parentIndex, 1);
      } else {
        tempDetails.splice(parentIndex, 1, parentDetails);
      }
      dispatch(updateArchivedPanelsDetails(tempDetails));
      // if tempDetails is empty  and this child is selected in browser if this condition will not add then
      //  it throw error because data has deleted and browser selected id and pId are not exist in store.
      if (tempDetails.length === 0 && activePanels.length > 0) {
        navigate(`/panels/${activePanels[0].id}`);
      } else if (tempDetails.length === 0 && activePanels.length < 1) {
        navigate("/");
      }
    }
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const deletePanel = (
    id: string,
    pId: string,
    panelName: string,
    childrenLength: number
  ) => {
    setIsModalVisible(true);
    setDelPanelName({ id, pId, panelName, childrenLength });
  };
  return (
    <Spin size="large" spinning={updatePanelLoading}>
      <div style={{ width: "100%" }}>
        <Modal
          visible={isModalVisible}
          centered
          footer={false}
          closable={false}
          width="385px"
        >
          <div className="delete-modal-container">
            <p>
              {t<string>("deleteConfirm")}
              <span className="cus-panel-name">{delPanelName.panelName}</span>
              {delPanelName.id &&
                !delPanelName.pId &&
                delPanelName.childrenLength > 0 && (
                  <span> and all its children</span>
                )}
              {t<string>("archiveUndoneText")}
            </p>
            <div className="delete-modal-footer">
              <button
                type="button"
                className="modal-cancel-button"
                onClick={handleCancel}
              >
                {t<string>("globalSettings.cancel")}
              </button>
              <button
                type="button"
                className="modal-ok-button"
                onClick={handleOk}
              >
                {t<string>("globalSettings.permanentlyDelete")}
              </button>
            </div>
          </div>
        </Modal>
        <ul style={{ padding: "0px" }}>
          {archivedPanelsList.map((e: any) => {
            const path = e.pId
              ? `/apanels/${e.pId}/${e.id}`
              : `/apanels/${e.id}`;
            if (!e.active) {
              return (
                <li key={e.id} style={{ opacity: 0.85 }}>
                  <Dropdown
                    overlay={
                      <MenuItem // passing menu item for each sidebar element
                        data={e}
                        makeActive={(id: string, pId: string) =>
                          makeActive(id, pId)
                        }
                        deletePanel={(
                          id: string,
                          pId: string,
                          name: string,
                          childrenLength: number
                        ) => deletePanel(id, pId, name, childrenLength)}
                      />
                    }
                    trigger={["contextMenu"]}
                    arrow
                  >
                    <Link
                      className={
                        !e.pId
                          ? "sidebar-items"
                          : "sidebar-items sidebar-items-child"
                      }
                      key={e.id}
                      to={path}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                        }}
                      >
                        <Marker
                          color={e.color}
                          fill={!(!e.pId && e.isStandalone === false)}
                        />
                        {e.pId && e.pActive ? (
                          <>
                            {" "}
                            <span>{e.name}</span>
                            <small>{`(Child of ${e.parentName})`}</small>
                          </>
                        ) : (
                          <span className="panel-name">{e.name}</span>
                        )}
                      </div>
                    </Link>
                  </Dropdown>
                </li>
              );
            }
            return "";
          })}
        </ul>
      </div>
    </Spin>
  );
}

export default ArchivedPanels;
