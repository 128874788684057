import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { RootReducer } from "../../store/reducers/root-reducer";
import useAuthProvider from "../../authProvider";

declare global {
  interface Window {
    Tawk_API: any;
  }
}
const ProtectedRoute = ({ component }: any) => {
  const { isLoggedIn } = useAuthProvider();
  const location = useLocation();

  if (!isLoggedIn()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }
  const user = useSelector((state: RootReducer) => state.user);
  const tawkToParams = `${process.env.REACT_APP_TAWKTO_ID}/${process.env.REACT_APP_TAWKTO_KEY}`;
  const isTawkToOnPage = document.getElementById("tawkToId");
  const isTawkToOn =
    isTawkToOnPage && typeof window.Tawk_API.hideWidget === "function";

  if (isTawkToOn && location.pathname !== "/help/faq") {
    window.Tawk_API.hideWidget();
  }

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            Tawk_API.onLoad = function(){
              Tawk_API.setAttributes({
              'name' : '${user.firstName} ${user.lastName}',
              'email': '${user.emailAddress}',
              'hash' : '<?php echo hash_hmac(“sha256″,”${user.emailAddress}”,”${process.env.REACT_APP_TAWKTO_ID}”); ?>'
              }, function (error) {});
            };
            Tawk_API.customStyle = {
              visibility : {
                desktop : {
                  position : 'bl',
                  xOffset : '920px',
                  yOffset : 25
                },
                mobile : {
                  position : 'br',
                  xOffset : 0,
                  yOffset : 10
                },
                bubble : {
                  rotate : '0deg',
                  xOffset : -20,
                  yOffset : 0
                }
              }
            };
            (function(){
            var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
            s1.async=true;
            s1.src='https://embed.tawk.to/${tawkToParams}';
            s1.charset='UTF-8';
            s1.setAttribute('crossorigin','*');
            s1.id="tawkToId"
            s0.parentNode.insertBefore(s1,s0);
            })();
          `}
        </script>
      </Helmet>
      {component?.children ? component.children : <Outlet />}
    </>
  );
};

export default ProtectedRoute;
