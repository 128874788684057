/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState, useMemo } from "react";
import { Button, Cascader, Select, Typography } from "antd";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { v4 as uuid } from "uuid";
import $ from "jquery";
import "jqueryui";
import "jquery-ui-dist/jquery-ui";
import { AxiosResponse } from "axios";
import { Resizable } from "re-resizable";
import { useTranslation } from "react-i18next";
import { CaretRightOutlined } from "@ant-design/icons";
import {
  clickWidgetBtn,
  priorityTodoList,
  todoBoardShow,
  toDoList,
  todoMasterStatusData,
  updateActiveTodos,
  updateArchiveTodos,
  updateColumns,
} from "../../../../../store/reducers/todo/action/todo";
import StoreAPI from "./StoreAPI";
import ToDoList from "./list";

import { ReactComponent as MainGrabIcon } from "../../../../../assets/Icons/mainGrab.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/Icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/Icons/arrow-right.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/Icons/cancel.svg";
import { ReactComponent as ExpandIcon } from "../../../../../assets/Icons/expand-icon.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./todo-board.scss";
import { RootReducer } from "../../../../../store/reducers/root-reducer";
import { Child, Panel } from "../../../../../interfaces/user-detail-interface";
import { Marker } from "../../../marker/marker";
import useAxios from "../../../../../services/axios";
import SERVER_CONFIG, { Constants } from "../../../../../utils/config";
import {
  Card,
  CardProps,
  CardSizeProps,
  FTodoList,
  PriorityKeys,
  StatusKeys,
  TaskPriority,
  TaskStatus,
  TaskStatusList,
  TodoList,
  TodoListProps,
} from "../../../../../prop-types/prop-type";
import { StrictModeDroppable } from "../../../droppable-dnd/strict-mode-droppable";
import { ALL_LISTS } from "../../../../../utils/constants";

const { Text } = Typography;
const { Option } = Select;

const ALL_PANELS = "All Panels";

function displayRender(label: string[]) {
  return label[label.length - 1];
}

type DataProps = {
  lists: { [key: string]: TodoListProps };
  listIds: string[];
};
const TodoBoard = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<DataProps>({ lists: {}, listIds: [] });
  const [mainWidth, setMainWidth] = useState<number>(0);
  const [mainHeight, setMainHeight] = useState<number>(0);
  const [width, setWidth] = useState(0);
  const [disableC3, setDisableC3] = useState(false);
  const [disableC4, setDisableC4] = useState(false);
  const [ccHeight, setccHeight] = useState<CardSizeProps[]>([]);
  const [selectedList, setSelectedList] = useState(ALL_LISTS);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [fullView, setfullView] = useState<number>(-1);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    activeTodos,
    columnsToShow,
    todoWidgetId,
    priorityData,
    todoMasterStatusList,
  } = useSelector((state: RootReducer) => state.todo);

  const [columns, setColumns] = useState(columnsToShow);
  const { activePanels } = useSelector((state: RootReducer) => state.panels);
  const [panels, setPanels] = useState<FTodoList[]>([]);
  const [maxBoxWidth, setMaxBoxWidth] = useState<string>(
    `${columnsToShow <= 3 ? width / 3 : width / columnsToShow}px`
  );
  useEffect(() => {
    setMaxBoxWidth(
      `${
        columnsToShow <= 3 || activeTodos.length <= 3
          ? width / 3
          : width / columnsToShow
      }px`
    );
  }, [width, columnsToShow, activeTodos.length]);

  const [response, loading, error, axiosFetch] = useAxios();
  const [updateCardResponse, updateCardLoading, updateCardError, updateCard] =
    useAxios();
  const [deleteCardResponse, deleteCardLoading, deleteCardError, deleteCard] =
    useAxios();
  const [statusList, setStatusList] = useState<TaskStatusList>({
    items: [],
    masters: [],
  });
  const [priorityKeys, setpriorityKeys] = useState<PriorityKeys>({});
  const [statusKeys, setstatusKeys] = useState<StatusKeys>({});
  const [selectedPanel, setSelectedPanel] = useState<(string | number)[]>([
    ALL_PANELS,
  ]);

  const isDefaultSelectedPanel = useMemo(() => {
    return selectedPanel[0] === ALL_PANELS;
  }, [selectedPanel]);

  const [selectedListId, setselectedListId] = useState<string>("");
  const [
    statusListResponse,
    statusListLoading,
    statusListError,
    statusListFetch,
  ] = useAxios();
  const [
    priorityListResponse,
    priorityListLoading,
    priorityListError,
    priorityListFetch,
  ] = useAxios();
  const [priorityList, setPriorityList] =
    useState<TaskPriority[]>(priorityData);

  const { t } = useTranslation();
  const [panelsAssigned, setPanelsAssigned] = useState<string[]>([]);
  useEffect(() => {
    if (data.listIds.length > 0) {
      const panelsWithToDo: string[] = [];
      data.listIds.forEach((todo: string) => {
        if (data.lists[todo].cards.length > 0) {
          data.lists[todo].cards.forEach((card: Card) => {
            if (card.panelId) {
              panelsWithToDo.push(card.panelId);
            }
          });
        }
      });
      setPanelsAssigned(panelsWithToDo);
    }
  }, [data]);
  useEffect(() => {
    if (activeTodos.length === 0) {
      axiosFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.TODO_LIST_GET,
      });
    }
    if (priorityData.length === 0) {
      priorityListFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.TODO_PRIORITY_LIST,
      });
    }
    if (todoMasterStatusList.length === 0) {
      statusListFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.TODO_STATUS_LIST,
      });
    }
  }, []);
  useEffect(() => {
    if (response && response?.data?.success) {
      const { data } = response;
      dispatch(updateActiveTodos(data.data.activeToDo));
      dispatch(updateArchiveTodos(data.data.archivedToDo));
    }
  }, [response]);

  const [filteredTodos, setFilteredTodos] = useState<{
    [key: string]: TodoListProps;
  }>({});

  const [
    columnChangeResponse,
    columnChangeLoading,
    columnChangeError,
    columnChangeFetch,
  ] = useAxios();

  const [
    todosListsUpdateResponse,
    todosListsUpdateLoading,
    todosListsUpdateError,
    todosListsUpdateFetch,
  ] = useAxios();

  const [
    updateTodosListResponse,
    updateTodosListLoading,
    updateTodosListError,
    updateTodosListFetch,
  ] = useAxios();

  const [responseUpdate, loadingUpdate, errorUpdate, axiosUpdateFetch] =
    useAxios();

  useEffect(() => {
    if (activeTodos && activeTodos.length) {
      let finalData: DataProps = {
        lists: {},
        listIds: [],
      };
      const idList: string[] = [];
      let listObj = {};
      activeTodos.map((item: TodoList) => {
        idList?.push(item?.id);
        const innerData = item?.children?.map((innerItem: Card) => {
          const obj = {
            ...innerItem,
            description: innerItem?.notes,
            panel: innerItem?.panelId,
            priority: innerItem?.priorityId,
            title: innerItem?.name,
            status: innerItem?.statusId,
          };
          return obj;
        });
        const mainObj = {
          ...item,
          title: item?.name,
          cards: innerData,
        };
        listObj = {
          ...listObj,
          [item?.id]: mainObj,
        };
        finalData = {
          listIds: idList,
          lists: listObj,
        };
        return null;
      });
      setData(finalData);
    }
  }, [activeTodos]);

  useEffect(() => {
    if (priorityList.length) {
      const tempObj: PriorityKeys = {};
      priorityList.forEach((record: TaskPriority) => {
        tempObj[record.priorityName] = record.id;
      });
      setpriorityKeys(tempObj);
    }
  }, []);

  useEffect(() => {
    if (priorityListResponse && priorityListResponse?.data?.success) {
      const tempObj: PriorityKeys = {};
      priorityListResponse?.data?.data?.priorities?.forEach(
        (record: TaskPriority) => {
          tempObj[record.priorityName] = record.id;
        }
      );
      setpriorityKeys(tempObj);
      dispatch(priorityTodoList(priorityListResponse?.data?.data?.priorities));
      setPriorityList(priorityListResponse?.data?.data?.priorities);
    }
  }, [priorityListResponse]);
  useEffect(() => {
    if (statusListResponse && statusListResponse?.data?.success) {
      const tempObj: StatusKeys = {};
      statusListResponse?.data?.data?.items?.forEach((record: TaskStatus) => {
        tempObj[record.statusName] = record.id;
      });
      setstatusKeys(tempObj);
      setStatusList(statusListResponse?.data?.data);
      dispatch(toDoList(statusListResponse?.data?.data?.items));
      dispatch(todoMasterStatusData(statusListResponse?.data?.data?.masters));
    }
  }, [statusListResponse]);
  useEffect(() => {
    const optionss: FTodoList[] = [];
    optionss[0] = {
      value: ALL_PANELS,
      label: (
        <div className="panel-item-box">
          <Text>{ALL_PANELS}</Text>
        </div>
      ),
      children: [],
    };

    activePanels.forEach((parent: Panel) => {
      if (panelsAssigned.includes(parent.id)) {
        const optionToAdd = {
          value: parent.id,
          label: (
            <div className="panel-item-box">
              <Marker
                color={parent.color}
                fill={!(parent.isStandalone === false)}
              />
              <Text>{parent.name}</Text>
            </div>
          ),
          children: [],
        };
        optionss.push(optionToAdd);
      } else if (!parent.isStandalone) {
        const panelsChildren = parent.children.filter((child) =>
          panelsAssigned.includes(child.id)
        );
        if (panelsChildren.length > 0) {
          const optionToAdd = {
            value: parent.id,
            label: (
              <div className="panel-item-box">
                <Marker
                  color={parent.color}
                  fill={!(parent.isStandalone === false)}
                />
                <Text>{parent.name}</Text>
              </div>
            ),
            children: panelsChildren.map((child: Child) => ({
              value: child.id,
              label: (
                <div className="panel-item-box">
                  <Marker
                    color={child.color}
                    fill={!(child.isStandalone === false)}
                  />
                  <Text>{child.name}</Text>
                </div>
              ),
            })),
          };
          optionss.push(optionToAdd);
        }
      }
    });
    setPanels(optionss);
  }, [activePanels, data, panelsAssigned]);

  // TODO
  const selectedListViewChangeHandler = (val: string) => {
    setSelectedList(val);
    const listIndex = data.listIds.indexOf(val);
    setSelectedIndex(val === ALL_LISTS ? -1 : listIndex);
    $(".todo-wrapper").animate({ scrollLeft: 0 }, 500);
  };

  // CLOSE TODO WIDGET
  const closeWidget = () => {
    dispatch(clickWidgetBtn(false));
    dispatch(todoBoardShow(false));
  };

  // TODO
  // COLUMNS CHANGE HANDLER
  const columnChangeHandler = (val: number) => {
    setColumns(val);
    dispatch(updateColumns(val));
    columnChangeFetch({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.WIDGETS_LIST_USER_UPDATE(todoWidgetId),
      data: [{ value: `${val}`, path: "/columns", op: "replace" }],
    });
    $(".todo-wrapper").animate({ scrollLeft: 0 }, 100);
  };

  const addMoreCard = async (listId: string, title: string) => {
    setselectedListId(listId);
    await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_SAVE,
      data: {
        statusId: statusKeys?.InComplete,
        toDoMId: listId,
        name: title,
        notes: "",
        priorityId: priorityKeys?.Normal,
      },
    });

    setselectedListId("-1");
  };

  // REMOVE CARD
  const removeCard = async (index: number, listId: string) => {
    setselectedListId(listId);
    const list = data.lists[listId];
    // @ts-expect-error We have type errors in axiosFetch
    const { data: responseData } = await deleteCard({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_DELETE(list.cards[index].id),
    });
    if (responseData?.success) {
      const tempCards = [...list.cards];
      tempCards.splice(index, 1);
      list.cards = [...tempCards];
      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };
      setData(newState);
    }
    setselectedListId("-1");
  };

  //   UPDATE CARD
  const updateCardDetails = async (
    taskId: string,
    value: string,
    path: string
  ) => {
    return (await updateCard({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_UPDATE(taskId),
      data: [
        {
          value,
          path,
          op: Constants.REPLACE,
        },
      ],
    })) as AxiosResponse;
  };
  const updateCardTitle = async (
    title: string,
    index: number,
    listId: string
  ) => {
    setselectedListId(listId);
    const list = data.lists[listId];
    const updateCardRes = await updateCardDetails(
      list.cards[index].id,
      title,
      "/name"
    );
    const carddata = updateCardRes?.data;
    if (carddata.success) {
      list.cards[index].title = title;
      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };
      setData(newState);
    }
    setselectedListId("-1");
  };

  const updateCardDescription = async (
    description: string,
    index: number,
    listId: string
  ) => {
    setselectedListId(listId);
    const list = data.lists[listId];
    if (list.cards[index].description !== description) {
      const updateCardRes = await updateCardDetails(
        list.cards[index].id,
        description,
        "/notes"
      );
      const carddata = updateCardRes?.data;
      if (carddata.success) {
        list.cards[index].description = description;
        const newState = {
          ...data,
          lists: {
            ...data.lists,
            [listId]: list,
          },
        };
        setData(newState);
      }
    }
    setselectedListId("-1");
  };

  const updateCardPriority = async (
    priorityId: string,
    index: number,
    listId: string
  ) => {
    setselectedListId(listId);
    const list = data.lists[listId];
    const updateCardRes = await updateCardDetails(
      list.cards[index].id,
      priorityId,
      "/priorityId"
    );
    const carddata = updateCardRes?.data;
    if (carddata.success) {
      list.cards[index].priority = priorityId;
      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };
      setData(newState);
    }
    setselectedListId("-1");
  };

  const updateCardPanel = async (
    panel: string,
    index: number,
    listId: string
  ) => {
    setselectedListId(listId);
    const list = data.lists[listId];
    const updateCardRes = await updateCardDetails(
      list.cards[index].id,
      panel,
      "/panelId"
    );
    const carddata = await updateCardRes?.data;
    if (carddata.success) {
      list.cards[index].panel = panel;
      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };
      setData(newState);
    }
    setselectedListId("-1");
  };

  const updateCardStatus = async (
    status: string,
    index: number,
    listId: string
  ) => {
    setselectedListId(listId);
    const list = data.lists[listId];
    const updateCardRes = await updateCardDetails(
      list.cards[index].id,
      status,
      "/statusId"
    );
    const carddata = await updateCardRes?.data;
    if (carddata.success) {
      list.cards[index].status = status;
      const newState = {
        ...data,
        lists: {
          ...data.lists,
          [listId]: list,
        },
      };
      setData(newState);
    }
    setselectedListId("-1");
  };
  //   ADD MORE LIST
  const addMoreList = () => {
    const newListId = `new-id-${uuid()}`;
    const newList = {
      id: newListId,
      title: "",
      status: true,
      cards: [],
      order: activeTodos.length > 0 ? activeTodos.length : 0,
    };
    const newState = {
      listIds: [...data.listIds, newListId],
      lists: {
        ...data.lists,
        [newListId]: newList,
      },
    };
    setData(newState);
  };

  //   UPDATE LIST TITLE
  const updateListTitle = (title: string, listId: string) => {
    const list = data.lists[listId];
    list.title = title;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };
    setData(newState);
  };

  const updateListStatus = (status: string, listId: string) => {
    const list = data.lists[listId];
    list.status = status;

    const newState = {
      ...data,
      lists: {
        ...data.lists,
        [listId]: list,
      },
    };

    setData(newState);
  };

  //   DELETE LIST
  const deleteList = async (listId: string) => {
    // @ts-expect-error We have type errors in axiosFetch
    const { data: responseData } = await deleteCard({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_DELETE(listId),
    });
    if (responseData?.success) {
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === listId
      );

      tempTodos.splice(indexModifiedToDo, 1);
      dispatch(updateActiveTodos(tempTodos));
    }
  };
  const onDragEnd = (result: any) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (type === "list" && isDefaultSelectedPanel) {
      const tempTodos: TodoList[] = [...activeTodos];
      const draggingTodo = tempTodos.splice(source.index, 1);
      tempTodos.splice(destination.index, 0, draggingTodo[0]);
      tempTodos.forEach((todo, i) => {
        todo.order = i;
      });
      dispatch(updateActiveTodos(tempTodos));
      const payload = tempTodos.map((todo) => ({
        id: todo.id,
        name: todo.name,
        statusId: todo.statusId,
        order: todo.order,
      }));
      updateTodosListFetch({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: SERVER_CONFIG.API_URL.TODO_LIST_UPDATE_TODOS,
        data: payload,
      });
      return;
    }
    if (type === "list" && !isDefaultSelectedPanel) {
      const tempTodos: TodoList[] = [...activeTodos];
      const draggingTodoIndex = tempTodos.findIndex(
        (todo: TodoList) => todo.id === source.droppableId
      );
      const destinationTodoIndex = tempTodos.findIndex(
        (todo: TodoList) => todo.id === destination.droppableId
      );
      const draggingTodo = tempTodos.splice(draggingTodoIndex, 1);
      tempTodos.splice(destinationTodoIndex, 0, draggingTodo[0]);
      tempTodos.forEach((todo, i) => {
        todo.order = i;
      });
      dispatch(updateActiveTodos(tempTodos));
      const payload = tempTodos.map((todo) => ({
        id: todo.id,
        name: todo.name,
        statusId: todo.statusId,
        order: todo.order,
      }));
      updateTodosListFetch({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: SERVER_CONFIG.API_URL.TODO_LIST_UPDATE_TODOS,
        data: payload,
      });
      return;
    }
    if (type === "task" && isDefaultSelectedPanel) {
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === source.droppableId
      );
      const tepmModifiedTodo = tempTodos[indexModifiedToDo];
      const draggingCard = tepmModifiedTodo.children.splice(source.index, 1);
      if (source.droppableId === destination.droppableId) {
        tepmModifiedTodo.children.splice(destination.index, 0, draggingCard[0]);
        tepmModifiedTodo.children.forEach((child, i) => {
          child.order = i;
        });
        tempTodos.splice(indexModifiedToDo, 1, tepmModifiedTodo);
        dispatch(updateActiveTodos(tempTodos));
        axiosUpdateFetch({
          method: SERVER_CONFIG.HTTP_METHOD.PUT,
          url: SERVER_CONFIG.API_URL.TODO_LIST_UPDATE(tepmModifiedTodo.id),
          data: tepmModifiedTodo,
        });
      } else {
        const indexUpdatedToDo: number = tempTodos.findIndex(
          (todoList: TodoList) => todoList.id === destination.droppableId
        );
        const tepmUpdatedTodo = tempTodos[indexUpdatedToDo];
        tepmUpdatedTodo.children.splice(destination.index, 0, draggingCard[0]);

        tepmModifiedTodo.children.forEach((child, i) => {
          child.order = i;
        });
        tempTodos.splice(indexModifiedToDo, 1, tepmModifiedTodo);
        tepmUpdatedTodo.children.forEach((child, i) => {
          child.order = i;
        });
        tempTodos.splice(indexUpdatedToDo, 1, tepmUpdatedTodo);
        dispatch(updateActiveTodos(tempTodos));
        todosListsUpdateFetch({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: SERVER_CONFIG.API_URL.WIDGETS_TODOS_LISTS_UPDATE(
            tepmModifiedTodo.id,
            tepmUpdatedTodo.id
          ),
          data: {
            updatedFirstId: tepmModifiedTodo.children,
            updatedSecondId: tepmUpdatedTodo.children,
          },
        });
      }
      return;
    }
    if (type === "task" && !isDefaultSelectedPanel) {
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === source.droppableId
      );
      const tepmModifiedTodo = tempTodos[indexModifiedToDo];
      const draggingCardIndex = tepmModifiedTodo.children.findIndex(
        (card) => card.id === draggableId
      );

      if (source.droppableId === destination.droppableId) {
        const destinationTodo = filteredTodos[destination.droppableId];
        const destinationCardId = destinationTodo.cards[destination.index].id;
        const destinationCardIndex = tepmModifiedTodo.children.findIndex(
          (card) => card.id === destinationCardId
        );
        const draggingCard = tepmModifiedTodo.children.splice(
          draggingCardIndex,
          1
        );

        tepmModifiedTodo.children.splice(
          destinationCardIndex,
          0,
          draggingCard[0]
        );
        tepmModifiedTodo.children.forEach((child, i) => {
          child.order = i;
        });
        tempTodos.splice(indexModifiedToDo, 1, tepmModifiedTodo);
        dispatch(updateActiveTodos(tempTodos));
        axiosUpdateFetch({
          method: SERVER_CONFIG.HTTP_METHOD.PUT,
          url: SERVER_CONFIG.API_URL.TODO_LIST_UPDATE(tepmModifiedTodo.id),
          data: tepmModifiedTodo,
        });
      } else {
        const draggingCard = tepmModifiedTodo.children.splice(
          draggingCardIndex,
          1
        );
        const indexDestinationToDo: number = tempTodos.findIndex(
          (todoList: TodoList) => todoList.id === destination.droppableId
        );
        const tepmDestinationTodo = tempTodos[indexDestinationToDo];
        const destinationTodo = filteredTodos[destination.droppableId];
        if (destination.index === 0) {
          const destinationCardId = destinationTodo.cards[0].id;
          const destinationCardIndex = tepmDestinationTodo.children.findIndex(
            (card) => card.id === destinationCardId
          );
          tepmDestinationTodo.children.splice(
            destinationCardIndex,
            0,
            draggingCard[0]
          );
        } else {
          const destinationCardId =
            destinationTodo.cards[destination.index - 1].id;
          const destinationCardIndex = tepmDestinationTodo.children.findIndex(
            (card) => card.id === destinationCardId
          );
          tepmDestinationTodo.children.splice(
            destinationCardIndex + 1,
            0,
            draggingCard[0]
          );
        }
        tepmModifiedTodo.children.forEach((child, i) => {
          child.order = i;
        });
        tempTodos.splice(indexModifiedToDo, 1, tepmModifiedTodo);
        tepmDestinationTodo.children.forEach((child, i) => {
          child.order = i;
        });
        tempTodos.splice(indexDestinationToDo, 1, tepmDestinationTodo);
        dispatch(updateActiveTodos(tempTodos));
        todosListsUpdateFetch({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: SERVER_CONFIG.API_URL.WIDGETS_TODOS_LISTS_UPDATE(
            tepmModifiedTodo.id,
            tepmDestinationTodo.id
          ),
          data: {
            updatedFirstId: tepmModifiedTodo.children,
            updatedSecondId: tepmDestinationTodo.children,
          },
        });
      }
    }
  };

  // useEffect(() => {
  //   console.log("width", containerRef.current ? containerRef.current : 0);
  // }, [containerRef.current]);

  // TODO
  const [countToClick, setCountToClick] = useState({ left: 0, right: 0 });

  const slideWidth = Number($("#todo-container").width()) / columns;
  const scrollTodo = (direction: number) => {
    const far = slideWidth * direction;
    const pos = Number($(".todo-wrapper").scrollLeft()) + far;

    $(".todo-wrapper").animate({ scrollLeft: pos }, 500);

    setCountToClick((prev) => ({
      left: direction > 0 ? prev.left + 1 : prev.left - 1,
      right: direction > 0 ? prev.right - 1 : prev.right + 1,
    }));
  };

  // TODO
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    if (data?.listIds?.length === 0 && activeTodos.length === 0) addMoreList();
    setWidth(Number($("#todo-container").width()));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.listIds?.length !== 0 && activeTodos.length === 0) {
      const newListId = `new-id-${uuid()}`;
      const newList = {
        id: newListId,
        title: "",
        status: true,
        cards: [],
        order: activeTodos.length > 0 ? activeTodos.length : 0,
      };
      const newState = {
        listIds: [newListId],
        lists: {
          [newListId]: newList,
        },
      };

      setData(newState);
    }
  }, [activeTodos]);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: columns,
    slidesToScroll: 1,
    // nextArrow: null,
    // prevArrow: null,
    variableWidth: true,
    draggable: false,
  };

  let slider: Slider | null;

  const ccVals = ccHeight.map((y) => parseInt(y?.val, 10));
  let maxccHeight = Math.max(...ccVals.filter((x: number) => x !== 0));
  // eslint-disable-next-line no-restricted-globals
  maxccHeight = isNaN(maxccHeight) ? 0 : maxccHeight;
  maxccHeight = maxccHeight === -Infinity ? 0 : maxccHeight;

  const isFullHeight = 346 + maxccHeight > mainHeight;

  useEffect(() => {
    if (!isFullHeight) {
      setMainHeight(346 + maxccHeight);
    }

    // eslint-disable-next-line
  }, [maxccHeight]);

  const myOptions = data?.listIds
    .map((x, i) => {
      return {
        name: data.lists[x]?.title,
        value: x,
        index: i,
      };
    })
    .filter((y) => y.name !== "");

  const archivedIds = data.listIds
    .map((x) => {
      const list = data.lists[x];
      return list;
    })
    .filter((z) => z?.status === false)
    .map((e) => e?.id);

  const selectedPanelHandler = (value: (string | number)[]) => {
    setSelectedPanel(value);
  };

  const isNewTodo =
    data.listIds.length &&
    data.listIds[data.listIds.length - 1].startsWith("new-id");
  const newToDosCreated = () => {
    let count = 0;
    activeTodos.forEach((todo: TodoList) => {
      if (!todo.createdDate) {
        count += 1;
      }
    });
    return count;
  };

  const setRightClickCount = () => {
    let count = 0;
    if (isNewTodo || data.listIds.length <= columns || newToDosCreated()) {
      return count;
    }
    count = data.listIds.length - columns;
    return count;
  };

  const setLeftClickCount = () => {
    let count = 0;
    if (isNewTodo && data.listIds.length > columns) {
      count = data.listIds.length - columns;
      return count;
    }
    if (newToDosCreated() && data.listIds.length > columns) {
      count = data.listIds.length - columns;
    }
    return count;
  };

  useEffect(() => {
    setCountToClick({
      left: setLeftClickCount(),
      right: setRightClickCount(),
    });
  }, [data.listIds]);

  useEffect(() => {
    setCountToClick({
      left: 0,
      right: data.listIds.length > columns ? data.listIds.length - columns : 0,
    });
  }, [columns]);

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (data?.listIds?.length && activeTodos && activeTodos.length) {
      const isNewToDoIdAdded =
        data.listIds[data.listIds.length - 1].startsWith("new-id");
      const isNewToDoAdded = activeTodos.some(
        (todo: TodoList) => todo.createdDate === undefined
      );
      if (scrollToRef.current && !isNewToDoAdded && !isNewToDoIdAdded) {
        scrollToRef.current.scrollTo({ left: 0 });
      }
    }
  }, [data?.listIds.length, activeTodos.length]);

  const parentPanelFiltered = useMemo(() => {
    return selectedPanel[1] || isDefaultSelectedPanel
      ? undefined
      : activePanels.find(
          (panelParent: Panel) =>
            panelParent.id === selectedPanel[0] && !panelParent.isStandalone
        );
  }, [selectedPanel, activePanels]);

  const filteredLists = useMemo(() => {
    const parentListIds: string[] = [];

    if (parentPanelFiltered) {
      parentPanelFiltered.children.forEach((child: Child) => {
        parentListIds.push(child.id);
      });
    }
    const selectedPanelId =
      selectedPanel.length === 1 ? selectedPanel[0] : selectedPanel[1];
    const selectedTodoIds: string[] = [];
    const listsWithSelected: { [key: string]: TodoListProps } = {};
    data.listIds.forEach((id: string) => {
      const cards = data.lists[id].cards.filter((card: Card) => {
        if (!parentPanelFiltered) {
          return card.panelId === selectedPanelId;
        }
        return parentListIds.includes(card.panelId || "");
      });
      if (cards.length > 0) {
        selectedTodoIds.push(id);
        listsWithSelected[id] = { ...data.lists[id], cards };
      }
    });

    setFilteredTodos(listsWithSelected);

    return selectedTodoIds.map((listId, index) => {
      const list = listsWithSelected[listId];
      return (
        <StrictModeDroppable
          droppableId={listId}
          type="list"
          direction="horizontal"
          key={listId}
        >
          {(provided) => (
            <ToDoList
              provided={provided}
              list={list}
              key={listId}
              listId={listId}
              index={index}
              slideWidth={slideWidth}
              mainWidth={mainWidth}
              mainHeight={mainHeight}
              selectedIndex={fullView}
              setSelectedCard={setfullView}
              ccHeight={ccHeight}
              setccHeight={setccHeight}
              viewAll={isFullHeight}
              allIDs={data.listIds}
              maxBoxWidth={maxBoxWidth}
              priorityList={priorityList}
              statusList={statusList}
              priorityKeys={priorityKeys}
              statusKeys={statusKeys}
              loadingCard={
                listId === selectedListId &&
                (statusListLoading ||
                  priorityListLoading ||
                  loading ||
                  updateCardLoading ||
                  deleteCardLoading)
              }
            />
          )}
        </StrictModeDroppable>
      );
    });
  }, [selectedPanel, data, parentPanelFiltered]);

  const [isTodoView, setIsTodoView] = useState(true);
  return (
    <StoreAPI.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        addMoreCard,
        addMoreList,
        updateListTitle,
        removeCard,
        updateCardTitle,
        deleteList,
        updateCardDescription,
        updateCardPriority,
        updateCardPanel,
        updateCardStatus,
        updateListStatus,
      }}
    >
      <div className="todo-board" id="tboard">
        <Resizable
          minWidth={375}
          minHeight={isTodoView ? 115 : 40}
          boundsByDirection={false}
          maxHeight={346}
          className="box"
          enable={{
            top: false,
            right: false,
            bottom: mainWidth > 959,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          onResize={() => {
            const mainCwidth = Number($("#handle").width());

            if (mainCwidth < 959) {
              setWidth(1198 - 70);
              setMainHeight(Number($("body").height()) - 40);
              setDisableC4(true);
              setColumns(1);
              setDisableC3(true);
            } else {
              setMainHeight(Number($("#handle").height()));
              setDisableC4(false);
              setColumns(3);
              setDisableC3(false);
            }

            setMainWidth(mainCwidth);
            setWidth(Number($("#todo-container").width()));
          }}
        >
          {/* <div className="box" id="handle"> */}

          {/* CONTENT */}
          <div className="content-bx" id="box-container">
            <div className="right-header" style={{ maxWidth: width }}>
              <div
                className="box-move-hand"
                onClick={() => setIsTodoView(!isTodoView)}
              >
                <CaretRightOutlined
                  rotate={isTodoView ? 90 : 0}
                  style={{
                    color: "rgb(133, 132, 133)",
                    alignSelf: "center",
                    cursor: "pointer",
                  }}
                />
              </div>
              <div className="center-select-box">
                {/* COLUMNS TO SHOW */}
                {width > 959 ? (
                  <Select
                    style={{
                      width: 120,
                      marginRight: width > 959 ? 51 : 0,
                      marginLeft: 15,
                    }}
                    bordered={false}
                    value={columns}
                    onChange={columnChangeHandler}
                  >
                    {[3, 4, 5, 6, 7].map((x, i) => {
                      return (
                        <Option value={x} key={i} className="col-opt-c">
                          <div className="todo-list-column">
                            <div>{`${x} Columns`}</div>
                          </div>
                        </Option>
                      );
                    })}
                    <Option value={8} key={8} disabled className="col-opt-c">
                      <div className="todo-list-column">
                        <div>8 Columns</div>
                      </div>
                    </Option>
                  </Select>
                ) : null}

                <Cascader
                  style={{
                    width: 150,
                    marginRight: width > 959 ? 51 : 0,
                  }}
                  bordered={false}
                  options={panels}
                  displayRender={displayRender}
                  defaultValue={selectedPanel}
                  value={selectedPanel}
                  expandTrigger="hover"
                  onChange={selectedPanelHandler}
                  dropdownMenuColumnStyle={{ width: 200 }}
                  changeOnSelect
                />

                {/* LIST TYPE */}
                <Select
                  defaultValue={ALL_LISTS}
                  value={selectedList}
                  onChange={selectedListViewChangeHandler}
                  style={{
                    width: 100,
                    marginRight: width > 959 ? 51 : 0,
                  }}
                  bordered={false}
                >
                  <Option value={ALL_LISTS} key={0} className="list-opts">
                    {t<string>("widgets.allLists")}
                  </Option>
                  {myOptions
                    ?.filter((x) => x?.name !== "")
                    ?.map((z, i) => {
                      return (
                        <Option
                          value={z.value}
                          // eslint-disable-next-line react/no-array-index-key
                          key={i + 1}
                          className="list-opts"
                        >
                          {z?.name}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              {width > 959 ? (
                <div className="slider-acts">
                  <Button
                    type="text"
                    className="add-list-btn"
                    onClick={addMoreList}
                  >
                    {t<string>("todo.add_todo_list")}
                  </Button>
                  <Button
                    type="text"
                    icon={
                      <ArrowLeftIcon
                        className={`${countToClick.left !== 0 && "arrowIcons"}`}
                      />
                    }
                    onClick={() => {
                      scrollTodo(-1);
                    }}
                    disabled={countToClick.left === 0}
                  />
                  <Button
                    type="text"
                    icon={
                      <ArrowRightIcon
                        style={{ fill: "red" }}
                        className={`${
                          countToClick.right !== 0 && "arrowIcons"
                        }`}
                      />
                    }
                    onClick={() => {
                      scrollTodo(1);
                    }}
                    disabled={countToClick.right === 0}
                  />
                </div>
              ) : null}
              <div className="right-cancel">
                <Button
                  type="text"
                  icon={<CloseIcon height={15} width={15} />}
                  onClick={closeWidget}
                />
              </div>
            </div>

            {/* TODO DRAG & DROP CONTAINER */}
            <div
              className={isTodoView ? "todoContent" : "todoContent-close"}
              id="todo-container"
              ref={containerRef}
            >
              <DragDropContext
                onDragEnd={
                  selectedList === ALL_LISTS
                    ? onDragEnd
                    : () => {
                        $(".todo-wrapper").animate({ scrollLeft: 0 }, 0);
                      }
                }
              >
                <div
                  className="todo-wrapper"
                  style={{
                    display: "flex",
                    width: `${width}px`,
                    flexDirection: width > 959 ? "row" : "column",
                    overflowX: width > 959 ? "auto" : "hidden",
                    overflowY: "scroll",
                  }}
                  ref={scrollToRef}
                >
                  {/* SELECETD LIST FROM DROPDOWN */}
                  {selectedList !== ALL_LISTS &&
                  isDefaultSelectedPanel &&
                  data.lists[selectedList] ? (
                    <StrictModeDroppable
                      droppableId={data.lists[selectedList].id}
                      type="list"
                      direction="horizontal"
                    >
                      {(provided) => (
                        <ToDoList
                          provided={provided}
                          list={data.lists[selectedList]}
                          key={selectedList}
                          listId={selectedIndex}
                          index={selectedIndex}
                          slideWidth={slideWidth}
                          mainWidth={mainWidth}
                          mainHeight={mainHeight}
                          selectedIndex={fullView}
                          setSelectedCard={setfullView}
                          ccHeight={ccHeight}
                          setccHeight={setccHeight}
                          viewAll={isFullHeight}
                          allIDs={data.listIds}
                          maxBoxWidth={maxBoxWidth}
                          priorityList={priorityList}
                          statusList={statusList}
                          priorityKeys={priorityKeys}
                          statusKeys={statusKeys}
                          loadingCard={false}
                        />
                      )}
                    </StrictModeDroppable>
                  ) : null}

                  {/* NORMAL DATA */}
                  {isDefaultSelectedPanel &&
                    selectedList === ALL_LISTS &&
                    data.listIds
                      ?.filter((z) => archivedIds.indexOf(z) === -1)
                      ?.map((listId, index) => {
                        const list = data.lists[listId];

                        return (
                          <StrictModeDroppable
                            droppableId={listId}
                            type="list"
                            direction="horizontal"
                            key={listId}
                          >
                            {(provided) => (
                              <ToDoList
                                provided={provided}
                                list={list}
                                listId={listId}
                                index={index}
                                slideWidth={slideWidth}
                                mainWidth={mainWidth}
                                mainHeight={mainHeight}
                                selectedIndex={fullView}
                                setSelectedCard={setfullView}
                                ccHeight={ccHeight}
                                setccHeight={setccHeight}
                                viewAll={isFullHeight}
                                allIDs={data.listIds}
                                maxBoxWidth={maxBoxWidth}
                                priorityList={priorityList}
                                statusList={statusList}
                                priorityKeys={priorityKeys}
                                statusKeys={statusKeys}
                                loadingCard={
                                  listId === selectedListId &&
                                  (statusListLoading ||
                                    priorityListLoading ||
                                    loading ||
                                    updateCardLoading ||
                                    deleteCardLoading)
                                }
                              />
                            )}
                          </StrictModeDroppable>
                        );
                      })}

                  {!isDefaultSelectedPanel && filteredLists}

                  {/* ARCHIEVED DATA */}
                  {archivedIds?.map((listId, index) => {
                    const list = data.lists[listId];
                    return (
                      <StrictModeDroppable
                        droppableId={listId}
                        type="list"
                        direction="horizontal"
                        key={listId}
                      >
                        {(provided) => (
                          <ToDoList
                            provided={provided}
                            list={list}
                            key={listId}
                            listId={listId}
                            index={index}
                            slideWidth={slideWidth}
                            mainWidth={mainWidth}
                            mainHeight={mainHeight}
                            selectedIndex={fullView}
                            setSelectedCard={setfullView}
                            ccHeight={ccHeight}
                            setccHeight={setccHeight}
                            viewAll={isFullHeight}
                            allIDs={data.listIds}
                            maxBoxWidth={maxBoxWidth}
                            priorityList={priorityList}
                            statusList={statusList}
                            priorityKeys={priorityKeys}
                            statusKeys={statusKeys}
                            loadingCard
                          />
                        )}
                      </StrictModeDroppable>
                    );
                  })}
                </div>
              </DragDropContext>

              {maxccHeight > 0 && width > 959 && 344 + maxccHeight > 346 ? (
                <div className="view-all-content">
                  {isFullHeight ? (
                    <Button
                      type="text"
                      onClick={() => {
                        setMainHeight(344 + maxccHeight);
                      }}
                    >
                      View All
                    </Button>
                  ) : (
                    <Button
                      type="text"
                      onClick={() => {
                        setMainHeight(346);
                      }}
                      icon={<ExpandIcon />}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>

          {/* CLOSE / CANCEL WIDGET */}
          {/* </div> */}
        </Resizable>
      </div>
    </StoreAPI.Provider>
  );
};

export default TodoBoard;
