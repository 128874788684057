import Actions from "../../action";

type PanelStorages = {
  errorMessage: string;
  panelDetail: object;
  panelMaster: object;
  panelSetting: object;
  panelSource: object;
  panelStorage: object;
  saveType: string;
  status: string;
  children: string[];
};

const panelStorage: PanelStorages = {
  errorMessage: "",
  panelDetail: {},
  panelMaster: {},
  panelSetting: {},
  panelSource: {},
  panelStorage: {},
  saveType: "",
  status: "",
  children: [],
};
// eslint-disable-next-line @typescript-eslint/default-param-last
const AddNewPanelReducer = (state = panelStorage, action: Actions) => {
  switch (action.type) {
    case "savePanelStorage":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default AddNewPanelReducer;
