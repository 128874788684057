import { Dispatch } from "redux";
import { PanelIdToSetup } from "../reducer/panel-to-set-reducer";

export const SET_PANELID_INFO = "panelIdToSetup";
export const panelIdToSetupAction = (data: PanelIdToSetup) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_PANELID_INFO,
      payload: data,
    });
  };
};
