/* eslint-disable import/no-extraneous-dependencies */
import Select from "react-select";
import { useMemo } from "react";
import countryList from "react-select-country-list";
import "./subscription-page.scss";

type Country = {
  value: string;
  label: string;
};

type CountrySelectorProps = {
  country: Country;
  changeCountryHandler: (country: Country) => void;
};

const CountrySelector: React.FC<
  React.PropsWithChildren<CountrySelectorProps>
> = ({ country, changeCountryHandler }) => {
  const options = useMemo(() => countryList().getData(), []);

  return (
    <div>
      <div className="subscription-input-label">Country or region</div>
      <Select
        options={options}
        value={country}
        onChange={(country) =>
          country && changeCountryHandler(country as Country)
        }
      />
    </div>
  );
};

export default CountrySelector;
