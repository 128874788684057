import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import useAxios from "../../services/axios";
import { RootReducer } from "../../store/reducers/root-reducer";
import SERVER_CONFIG from "../../utils/config";
import { HeaderSubscription } from "./header-subscription";
import "./subscription-page.scss";

const SubscriptionCheckMailLink = () => {
  const { email } = useSelector((state: RootReducer) => state.user);
  const [wasResent, setWasResent] = useState(false);

  const [
    resendMailResponse,
    loadingResendMail,
    errorResendMail,
    axiosResendMailFetch,
  ] = useAxios();

  const handleClick = () => {
    axiosResendMailFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.COMMON_APIS.RESEND_VALIDATION_EMAIL,
      data: {
        email,
      },
    });
  };

  useEffect(() => {
    if (resendMailResponse?.data.success) {
      setWasResent(true);
    }
  }, [resendMailResponse]);
  return (
    <Spin size="large" spinning={loadingResendMail}>
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">Check Your Email and Click the Link</h1>
        <div>
          <p>
            Please check your email for a welcome message that includes a
            validation link.
          </p>
          <p>
            Simply click the link and you will be taken to the next page in
            order to create a password and confirm your phone number.
          </p>
          <p>
            If you have not received an email within one minute, please check
            your spam filter for an email from PanelLogic.com, or contact
            Customer Service at{" "}
            <a href="mailto:support@panellogic.com">support@panellogic.com</a>.
          </p>
          <p>This validation link will expire after ten minutes.</p>
          <p>
            <button type="button" className="btn-link" onClick={handleClick}>
              Request New Validation Link
            </button>
          </p>

          {wasResent && <p>Validation link was resent to your e-mail</p>}
          {errorResendMail && <p>Please, try once again</p>}
        </div>
      </div>
    </Spin>
  );
};

export default SubscriptionCheckMailLink;
