import images from "../../assets/image-export-assets";

export const faq = [
  {
    id: 1,
    title: "Create a Panel",
    content: [
      "Step 1. Use the Panel menu along the left side of your screen. Click <strong>+ Add Panel</strong> and enter a name.",
      "Step 2. Select either <strong>Create New Parent Group</strong> or <strong>Create New Panel</strong> (stand-alone).",
      "Step 3a. Select a color and click Add Panel.",
      "Step 3b. If creating a child panel, select “As a child of” and the appropriate parent Panel, and click Add Panel.",
      "There you go!",
      "Tip: You can always change the name of a Panel by right clicking on the name in the Panel menu, selecting <strong>Rename</strong> and making a change. Or, simply click inside the name at the top of the Panel.",
    ],
    imgs: [
      images.createParentPanel1,
      images.createParentPanel2withColorPicker,
      images.createParentPanel3,
    ],
  },
  {
    id: 2,
    title: "Create a Child Panel",
    content: [
      "Step 1. Use the Panel menu along the left side of your screen. Click <strong>Add Panel</strong> and Enter a Name.",
      'Step 2. Next, select <strong>"As a child of"</strong> and the appropriate parent Panel.',
      "Step 3. Click <strong>Add Panel</strong> and congratulations, you’re on your way! ",
      "Tip: You can always change the name of a Panel by right clicking on the name in the Panel menu, selecting Rename and making a change. Or, simply click inside the name at the top of the Panel.",
      "Tip: To reorder Panels, you can use the grab bar to the left of the Panel name at the top of the Panel to drag the Panel to a new location. Or, click Reorder Panels at the bottom of the Panel menu and use the grab bars in the menu above.",
    ],
    imgs: [images.createChildPanel1, images.createChildPanel2],
  },
  {
    id: 3,
    title: "Change a Panel Name",
    content: [
      "In the Panel menu along the left side of your screen, right click on the Panel name, select <strong>Rename</strong> and make a change. Or, simply click inside the name at the top of the Panel.",
      "It’s that easy!",
    ],
    imgs: [],
  },
  {
    id: 4,
    title: "Reorder Panels",
    content: [
      "You have two ways to reorder panels: at the bottom of the left Panel menu click <strong>Reorder Menu</strong>. Then use the small grab bars to the left of the Panel names above to reorder them.",
      "The other way to reorder Panels is to use the grab bar to the left of the Panel name at the top of the Panel. When viewing a number of Panels or child Panels, simply drag the Panel where you’d like it to go.",
      "It’s that easy!",
      "Tip: If you have several or more child Panels, it can be helpful to use the <strong>zoom buttons</strong> in the lower left of the Panels view to zoom out until you see them all, then drag them into the order you prefer.",
    ],
    imgs: [],
  },
  {
    id: 5,
    title: "Add a Connection",
    content: [
      "Usually, when you create a Panel, you'll link at least one Connection, such as an email account.",
      "You can link as many email, storage, and Slack Connections as you wish to an individual Panel.",
      "Step 1. In the upper right of the Panel, click the <strong>+ Add / Edit Connection</strong> icon (located just to the left of the ? help icon).",
      "Step 2. You will see a list of applications currently supported by Panels. Click <strong>+ Add New</strong> to the right of the source you wish to add.",
      "Note: If you already added an account you will see it listed below the application name. If you want to use that same source, just click it, or click <strong>+ Add New</strong>.",
      "Step 3. You will see a pop-up describing the next step. Click the <strong>Install</strong> button.",
      "Step 4. You will receive a few prompts from Panels and from the program's publisher asking you to sign in and grant permission. Continue to click <strong>Yes</strong> and/or enter information until complete, even if the program says that Panels is not yet an officially approved app.",
      "You're on your way!",
      "Next you'll want to add one or more filters for email accounts, Slack Workspaces and Channels, etc.",
      "Tip: You can always remove a Connection in the future by deleting it from General Settings > Connections. Simply click the adjacent red <strong>x</strong>. Panels will then ask you if you want to save the existing data in your Panel(s) or completely erase it.",
      "In the same prompt, Panels will present you with a list of Panels that currently use that Connection so that you don't accidentally remove a Connection used by other Panels.",
    ],
    imgs: [
      images.addaDataSource1,
      images.addaDataSource2,
      images.addaDataSource3,
      images.addaDataSource4,
    ],
  },
  {
    id: 6,
    title: "Email: Add a Filter",
    content: [
      "Adding Filters is similar to how filters work in Gmail and Outlook. You'll search on keywords, domains, or other variables and you'll be able to preview the results.",
      "Step 1. First, make sure you've added an email account as a Connection and added it to your Panel. (See help: Add a Connection.)",
      "Step 2. If you're not already viewing the Filter window for your Panel, click <strong>+ Add / Edit a Filter</strong>.",
      "Step 3. On the Filter screen, make sure the *Source field at the top shows the correct account, or click the field and select an email account.",
      "Step 4. Decide whether to search by <strong>From or Domain:</strong>, <strong>Subject:</strong>, or <strong>Has the words:</strong> - or use a combination.",
      "Tip: Some Filters are easy. For example, if you're working with one or more people from a catering firm, you can simply search on their domain (e.g., splendidoccasions-dc.com) to capture any of their emails into a Panel called Caterers.",
      "However, if you are working with the same catering company for multiple events, you'll probably want to search by domain and the event client or name that will be included in your emails back and forth.",
      "Step 5. Click the <strong>From date:</strong> and select a date far back enough to capture the emails you wish to add which could be days, weeks, or months ago.",
      "Step 6. Click <strong>Search</strong>. After a moment, you'll see the results of the search on the right side of the screen.",
      "Step 7. If you like the results, click <strong>Save</strong>. If you don't, click <strong>Clear</strong> and start again. A little trial and error will go a long way to making all your filters accurate and relevant to each Panel.",
      "Tip: Within a Panel, click an email once to see a taller view. Click again to shorten the view. Double click to view the email in its original program such as Gmail or Outlook. (You'll need to be signed into Gmail or Outlook online for this to work).",
      "Tip: In Panel or Global Settings (click the <strong>gear icon</strong> in the upper right of a Panel), you can change the maximum number of lines of an email message that you see, and the maximum number of messages in a Panel.",
      "Note: If you add multiple email accounts to a Panel, all emails will be shown together in one email section.",
    ],
    imgs: [images.addaFilter1, images.addaFilter2, images.addaFilter3a],
  },
  {
    id: 7,
    title: "Email: Delete from a Panel",
    content: [
      "Step 1. Select an email by clicking in the top, From / Subject area of the email. The top of the email will turn a light gray and the date will be replaced by a red trash can. ",
      "Step 2. Click the red trash can. The email will be deleted only from this Panel, not other Panels and not your email program. ",
      "Tip: You can also select multiple emails at one time, and then click any trash can to delete all selected emails.",
      "If you delete an email by mistake, you can always go to Filters, select the Filter that included the email, click Search, and then Save the filter again.",
    ],
    imgs: [
      images.deleteFromPanel1,
      images.deleteFromPanel2,
      images.deleteFromPanel3,
    ],
  },
  {
    id: 8,
    title: "Slack: Add a Channel",
    content: [
      "You can add multiple Slack Workspaces, Channels or DM's to a Panel.",
      "Step 1. First, make sure you've added a Slack account as a Connection and added it to your Panel. (See help: Add a Connection.) You will then see the Slack Connection in the Panel.",
      "Step 2. Click <strong>+ Add / Remove Channel</strong>.",
      "Step 3. Select a Workspace and a Channel or DM. If there are other Channels already selected, do not deselect them. Simply, click the Channel or DM you wish to add.",
      "Step 4. Click <strong>Save</strong>.",
      "Tip: In either Panel or Global Settings (click the <strong>gear icon</strong> in the upper right of a Panel), you can change the maximum number of lines of a Slack message that you see, and the number of messages in a Slack window.",
      "Note: You can respond to a Slack message inline within a Panel but formatting and functionality is limited (e.g., no emojis).",
    ],
    imgs: [
      images.addaSlackChannel1,
      images.addaSlackChannel2,
      images.addaSlackChannel3,
    ],
  },
  {
    id: 9,
    title: "Add a Drive",
    content: [
      "You can connect OneDrive, Google Drive and Dropbox to Panels.",
      "Note: Panels doesn't store the actual files, it just retains lists and links of files you choose. When you double click a file in a folder it will open from the Connection, assuming you are signed in online to that particular drive (e.g., OneDrive).",
      'When you connect a Drive\'s folder, you will first select what files in the folder to view (and you can <strong>Select All</strong>). This gives you the flexibility, for example, to have only a Caterer\'s contract in the "Contracts" folder in the Caterer Panel. Or, if you have an event Panel called Legal then for your "Contracts" folder you might Select All.',
      "Step 1. First, make sure you've added a Drive account as a Connection. (See help: Add a Connection.) You will see the Connection in the Panel.",
      "Step 2. Click <strong>+ Add / Edit a Folder</strong>. You will see a standard view of your Drive and folders. Select a folder.",
      "Step 3. When you click the folder you will see a list of the folder's contents. Select only the files from this folder that you wish to see in your Panel. You can also click <strong>Select All</strong>.",
      "The folder and selected files will appear at the bottom of the window. You can deselect files by clicking the red <strong>x</strong>.",
      "Step 4. When you've selected the files within a folder you wish to see in the Panel, click <strong>Save</strong>.",
      "Tip: When you open, or close and open, the arrow to the left of a drive folder, Panels will query the Drive in real-time. If Select All was chosen then new files will automatically appear. If selected files were deleted within a drive app, they will no longer appear in Panels.",
    ],
    imgs: [
      images.driveAddFolderSourceAdded,
      images.driveAddFolderFolderOpened,
      images.driveAddFolderFilesSelected,
    ],
  },
  {
    id: 10,
    title: "Invite a Perticipant",
    content: [
      "Step 1. Click the Participants icon in the far upper right of the screen. The Participants Panel will open. ",
      "If you see your subscription is Standard, you will need to upgrade to Premium by clicking the red button.",
      "Step 2. Enter the Participant’s first name, last name and email. Click Add Participant. It’s that simple!",
      "When your invited Participant clicks the link in the email from Panels, they will be prompted to create a Panels account. They will be able to view and edit your Panels and ToDo lists. ",
      "Note: A Participant cannot already be a user of Panels. If you try to add a user who already has a Panels account, you will receive the following error message: “Invitee user is already associated with another primary user.” If you need help, click the support icon to the right of this Help Center Panel.",
      "Tip: You can always remove a Participant by clicking the x to the right of their name in the Participants Panel.",
    ],
    imgs: [images.InviteAPerticipant1, images.InviteAPerticipant2],
  },
];
