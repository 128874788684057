export type Actions = {
  type: string;
  payload: any;
};
interface SocketConnectionInterface {
  connection: any;
}

const signalRConnection: SocketConnectionInterface = {
  connection: null,
};

// eslint-disable-next-line default-param-last
const SocketConnectionReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = signalRConnection,
  action: Actions
) => {
  switch (action.type) {
    case "updateConnection":
      return { ...state, connection: action.payload };
    default:
      return state;
  }
};

export default SocketConnectionReducer;
