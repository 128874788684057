import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CaretRightOutlined,
  SettingOutlined,
  EditOutlined,
  PlusOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./logged-in-sidebar.scss";
import { useDispatch } from "react-redux";
import ArchivedPanels from "./archived-panels";
import AddPanel from "../panel/add-panel/add-panel";
import ActivePanels from "./active-panels";
import images from "../../assets/image-export-assets";
import { PreviousPanelInfoType } from "../../store/reducers/prev-panel/reducer/prev-panel-reducer";
import { setPreviousPanelInfoAction } from "../../store/reducers/prev-panel/actions/prev-panel-action";

const LoggedInSidebar = ({ panels }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showArchivedPanels, setShowArchivedPanels] = useState(false);
  const [startReorder, setStartReorder] = useState(false);
  const [showAddPanel, setShowAddPanel] = useState(false);
  const { panelId, subpanelId } = useParams();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    /* Showing and hiding Acrhived Panels view in sidebar based on routing params */

    if (panels.includes("panels")) {
      setShowArchivedPanels(false);
    }
    if (panels.includes("apanels")) {
      setShowArchivedPanels(true);
    }
  }, [panels]);
  return (
    <div className="sidebar-main-container" style={{ height: "100%" }}>
      <div className="sidebar-main-top-container">
        <img src={images.PanelsBetaLogo} alt="logo" className="logo-demo" />

        {!showArchivedPanels ? (
          <div className="sidebar-items-wrapper">
            <ActivePanels isDraggable={startReorder} />
          </div>
        ) : (
          <div className="sidebar-items-wrapper">
            <button
              type="button"
              className="sidebar-button-items"
              style={{ color: "#676567" }}
              onClick={() => {
                setShowArchivedPanels(!showArchivedPanels);
              }}
            >
              <ArrowLeftOutlined />
              {t<string>("backToActivePanelsButton")}
            </button>
            <ArchivedPanels />
          </div>
        )}
        <div className="add-panel-bottom-button-container">
          {!showAddPanel && !showArchivedPanels && (
            <button
              type="button"
              className="sidebar-button-items add-panel-btn"
              onClick={() => {
                setShowAddPanel(!showAddPanel);
              }}
            >
              <span>
                <PlusOutlined />
                {t<string>("addPanelsButton")}
              </span>
            </button>
          )}
        </div>
        {showAddPanel && !showArchivedPanels && (
          <AddPanel
            showAddPanel={showAddPanel}
            setShowAddPanel={setShowAddPanel}
          />
        )}
      </div>
      <div className="sidebar-main-bottom-container">
        <div className="bottom-button-container">
          {!showArchivedPanels && (
            <button
              type="button"
              className="sidebar-button-items reorder-panel"
              onClick={() => {
                setStartReorder(!startReorder);
              }}
            >
              <span>
                <EditOutlined />
                {t<string>("reorderMenuButton")}
              </span>
            </button>
          )}
          <button
            type="button"
            className="sidebar-button-items reorder-panel"
            onClick={() => {
              setShowArchivedPanels(!showArchivedPanels);
            }}
          >
            <span>
              <CaretRightOutlined />
              {!showArchivedPanels
                ? t<string>("archivedPanelsButton")
                : t<string>("activePanelsButton")}
            </span>
          </button>
          <button
            type="button"
            className="sidebar-button-items global_setting_btn"
            onClick={() => {
              const prevPanelInfo: PreviousPanelInfoType = {
                panelId: panelId || "",
              };
              if (subpanelId) {
                prevPanelInfo.subpanelId = subpanelId;
              }
              dispatch(setPreviousPanelInfoAction(prevPanelInfo));
              navigate("/globalsettings/profile");
            }}
          >
            <span>
              <SettingOutlined />
              {t<string>("globalSettingsButton")}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoggedInSidebar;
