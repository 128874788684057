import images from "../../assets/image-export-assets";
import "./subscription-page.scss";

export const HeaderSubscription = () => {
  const { PPanels } = images;

  return (
    <header className="mail-header">
      <div className="mail-header__container">
        <div className="mail-header__logo">
          <img src={PPanels} alt="logo" className="header__img" />
        </div>
      </div>
    </header>
  );
};
