import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import { HeaderSubscription } from "./header-subscription";
import "./subscription-page.scss";

const SubscriptionResetPassword = () => {
  const [mailValue, setMailValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const [resetResponse, loadingReset, errorReset, axiosResetFetch] = useAxios();
  const handleResetPassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    try {
      const payload = {
        email: mailValue,
      };
      axiosResetFetch({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: SERVER_CONFIG.API_URL.COMMON_APIS.RESET_PASSWORD,
        data: payload,
      });
    } catch (e) {
      setErrorMessage(`${(e as { data: { message: string } })?.data?.message}`);
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (resetResponse && resetResponse.data.success) {
      navigate("/subscription/check-mail-reset");
    }
    if (errorReset?.response) {
      // @ts-ignore
      setErrorMessage(errorReset?.response.data.message);
    }
  }, [resetResponse, errorReset]);

  return (
    <Spin size="large" spinning={loadingReset}>
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">Reset Password</h1>

        <form onSubmit={handleResetPassword} autoComplete="new-sms">
          <div>
            <p>Please enter your email.</p>

            <div>
              <label htmlFor="sms" className="form-label">
                Email
                <input
                  id="sms"
                  type="text"
                  className="md:w-[528px] w-[300px] border-[1px] border-grey rounded-md p-[10px] flex-1 text-[14px] shadow-sm max-h-[43px] mb-2"
                  value={mailValue}
                  onChange={(e) => {
                    setMailValue(e.target.value);
                    setErrorMessage("");
                  }}
                  autoComplete="off"
                />
              </label>
              {errorMessage && (
                <div className="text-left">
                  <span className="subscription-input-text-error">
                    {errorMessage}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="max-w-[581px] mx-auto mb-[47px] flex flex-col md:flex-row md:gap-5 gap-0 items-center sm:items-start">
            <div className="form-button-width">
              <button
                type="submit"
                className="form-button"
                disabled={mailValue === "" || loadingReset}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Spin>
  );
};

export default SubscriptionResetPassword;
