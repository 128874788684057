import Actions from "../../../action";

export type EmailDeleteType = {
  panelId: string;
  emailIdList: string[];
};

const emailDeleteStorage: EmailDeleteType = {
  panelId: "",
  emailIdList: [],
};

const EmailDeleteReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = emailDeleteStorage,
  action: Actions
) => {
  switch (action.type) {
    case "setEmailDeleteInfo":
      state = action.payload;
      return state;
    case "resetEmailDelete":
      state = emailDeleteStorage;
      return state;
    default:
      return state;
  }
};

export default EmailDeleteReducer;
