const newTabHTML = (url: string, type: string, logo: string) => {
  const a = `<div style="display:flex;height:100%;">
    <div
        style="display:flex;
        flex-direction:column;
        justify-content:flex-start;
        align-items: center;
        width:230px;
        padding: 10px 10px 10px 40px;
        background-color: #f2f2f2;">
        <img src=${logo}
            alt=${type} width="80%" />
        <a href=${url} target="_self"
            style="width:80%;height:40px;background-color:rgb(5, 121, 216);color:white;border-radius:3px;border:0; display: flex;"><span
                style="width:100%;display: flex; justify-content: center; align-items: center; height:40px;font-size: 18px; font-family: Open Sans; ">Install</span></a>
    </div>
    <div style="width:100%">
        <div style="display:flex;flex:1;height: fit-content;margin-left: 38px;margin-top: 30px; width: 100%;">
            <div style="margin: 12px;">
                <h1
                    style="font-size:100px;padding:0px 10px;margin:0;line-height:1;border:3px solid red;border-radius:5px;">
                    P
                </h1>
            </div>
            <div style="display:flex;flex-direction:column;justify-content: space-between;flex:1;margin: 12px;">
                <h1 style="margin:0;">Panels for ${type}</h1>
                <span style="font-size: 18px; font-family: Open Sans;">Send emails into Panels by filters in order
                    <br /> to see. project-relevent info.</span>
                <span style="font-size: 16px;">By: <a href="google.com"
                        style="font-size: 18px; font-family: Open Sans;">Panels Technologies Inc.</a></span>
            </div>
        </div>
        <div style="height:calc(100% - 175px);width: 100%; background-color: #f2f2f2; ">
            <ul
                style="display: flex; justify-content: space-between; font-size: 14px; list-style: none; width: 70%; height: 40px;  border-bottom: 1px solid #969296;">
                <li style="padding: 15px;">Overview</li>
                <li style="padding: 15px;">Permissions</li>
                <li style="padding: 15px;">Reviews</li>
            </ul>
        </div>
    </div>
</div>`;
  return a;
};

export default newTabHTML;
