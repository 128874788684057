/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootReducer } from "../../store/reducers/root-reducer";
import UpdateConnection from "../../store/reducers/signalR/connection/actions/connection-actions";
import ReceiveMessage from "../../store/reducers/signalR/receive-message/actions/receive-message-actions";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import store from "../../store/store";
import SERVER_CONFIG from "../config";
import SocketResponseHandler from "./socket-response-handler";

const SocketConnection = () => {
  const dispatch = useDispatch<any>();
  const { username } = useSelector((state: RootReducer) => state.user);
  const { connection } = useSelector(
    (state: RootReducer) => state.signalRConnection
  );
  // const activePanels = useSelector((state: RootReducer) => state.panels);
  // const { panelData } = useSelector((state: RootReducer) => state);
  // const receiveMessage = useSelector((state: RootReducer) => state.signalRReceiveMessage);
  // const test = useState('hello');

  // const getActivePanels = async () => {
  //   return { activePanels1: activePanels, panelsData1: panelData };
  // };

  // Creating Socket Connection on App start
  useEffect(() => {
    if (username) {
      let hubUrl = SERVER_CONFIG.SERVER_SOCKET_URL;
      hubUrl = `${hubUrl}?username=${username}`;
      const newConnection = new HubConnectionBuilder()
        .withUrl(hubUrl)
        .withAutomaticReconnect()
        .build();
      dispatch(UpdateConnection(newConnection));
    }
  }, [username]);

  // starting listening to server if connection is created
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result: any) => {
          // connection.invoke('GetPanels', username);
          connection.on("ReceiveMessage", async (message: any) => {
            dispatch(ReceiveMessage(message));
            // const tempData: any = await getActivePanels();
            // const state = store.getState();
            // const { activePanels1, panelsData1, paramsRoute1 } = tempData;
            // console.log(message);
            // console.log('paramsRoute1', paramsRoute1);
          });
        })
        // eslint-disable-next-line no-console
        .catch((e: Error) => console.log("Connection failed: ", e));
    }
  }, [connection]);
};
export default SocketConnection;
