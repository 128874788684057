/* eslint-disable no-nested-ternary */
import { InputHTMLAttributes, useState } from "react";
import c from "classnames";
import "./input.scss";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  inputClassName?: string;
  error?: string | boolean;
  showEye?: boolean;
};

function Input({
  label,
  className,
  inputClassName,
  type,
  error,
  showEye,
  ...props
}: InputProps) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [resolvedType, setResolvedType] = useState(type);

  const handleShowPassword = () => {
    setIsPasswordShown(true);
    setResolvedType("text");
  };

  const handleHidePassword = () => {
    setIsPasswordShown(false);
    setResolvedType("password");
  };

  return (
    <div className={c("input-container", className)}>
      {label ? (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className="label">
          {label} <span className="text-red">{props.required ? "*" : ""}</span>
        </label>
      ) : null}
      <div className="input-with-icon-container">
        <input
          {...props}
          className={c("input", inputClassName)}
          type={resolvedType}
        />
        {type === "password" && showEye ? (
          isPasswordShown ? (
            <EyeInvisibleOutlined
              className="icon-eye"
              onClick={handleHidePassword}
            />
          ) : (
            <EyeOutlined className="icon-eye" onClick={handleShowPassword} />
          )
        ) : null}
      </div>
      <p className="error">{error || " "}</p>
    </div>
  );
}

export default Input;
