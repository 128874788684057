import { Dispatch } from "redux";

export const SET_USER_INFO = "SetUserInfo";
export const setUserInfoAction = (userData: Record<string, unknown>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_INFO,
      payload: userData,
    });
  };
};
