/* eslint-disable import/no-extraneous-dependencies */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

type User = {
  userName: string;
  subscriptionId: string;
};
type TrialCode = {
  period: number;
};
type ConfirmPassword = {
  id: string;
  firstName: string;
  lastName: string | null;
  username: string;
  emailAddress: string;
  token: string;
};
type ValidateEmail = {
  isTaken: boolean;
};

type ConfirmSmsCode = {
  smsCode: string;
  token: string;
};

type CreateNewParticipant = {
  success: boolean;
  data: {
    subscriptionId: string;
    userName: string;
  };
  message: string;
  messageType: string;
};

const { REACT_APP_API_PATH } = process.env;
const subscriptionBaseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_API_PATH,
  prepareHeaders: (headers) => {
    return headers;
  },
});

const usersApi = createApi({
  reducerPath: "users",
  baseQuery: subscriptionBaseQuery,
  endpoints: (builder) => ({
    validateTrialCode: builder.query<
      TrialCode,
      {
        trialCode: string;
      }
    >({
      query: ({ trialCode }) => ({
        url: "/users/validateTrialCode",
        method: "POST",
        params: {
          trialCode,
        },
      }),
      transformResponse: (response: { data: TrialCode }) => response.data,
    }),

    createUser: builder.mutation<
      User,
      {
        subscriptionId?: string;
        customerId?: string;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
        trialCode: string;
        phoneNumber: string;
        subscriptionType: string;
        subscriptionStatus: string;
      }
    >({
      query: ({
        subscriptionId,
        customerId,
        firstName,
        lastName,
        email,
        trialCode,
        phoneNumber,
        subscriptionType,
        subscriptionStatus,
      }) => ({
        url: "/users/create",
        method: "POST",
        body: {
          subscriptionId,
          customerId,
          firstName,
          lastName,
          email,
          phoneNumber,
          trialCode,
          subscriptionType,
          subscriptionStatus,
        },
      }),
      transformResponse: (response: { data: User }) => response.data,
    }),

    validateEmail: builder.mutation<
      ValidateEmail,
      {
        email: string;
      }
    >({
      query: ({ email }) => ({
        url: "/users/validateEmailAddress",
        method: "POST",
        params: {
          emailAddress: email,
        },
      }),
      transformResponse: (response: { data: ValidateEmail }) => response.data,
    }),

    confirmPassword: builder.mutation<
      ConfirmPassword,
      {
        password?: string | null;
        confirmPassword: string | null;
        token: string | null;
        phoneNumber: string | null;
      }
    >({
      query: ({ password, confirmPassword, phoneNumber, token }) => ({
        url: `/users/create-password/${token}`,
        method: "POST",
        body: {
          password,
          confirmPassword,
          phoneNumber,
        },
      }),
      transformResponse: (response: { data: ConfirmPassword }) => response.data,
    }),

    confirmSmsCode: builder.mutation<
      ConfirmSmsCode,
      {
        smsCode?: string | null;
        token?: string | null;
        action?: string | null;
      }
    >({
      query: ({ smsCode, token, action }) => ({
        url: `/users/create-password-${action}-sms/${token}`,
        method: "POST",
        body: {
          smsCode,
        },
      }),
      transformResponse: (response: { data: ConfirmSmsCode }) => response.data,
    }),

    createParticipant: builder.mutation<
      CreateNewParticipant,
      {
        firstName: string;
        lastName: string;
        email: string;
        subscriptionId: string;
        customerId: string;
        phoneNumber: string;
        trialCode: string;
        subscriptionType: string;
        subscriptionStatus: string;
      }
    >({
      query: (participantData) => ({
        url: `/users/create-participant`,
        method: "POST",
        body: participantData,
      }),
      transformResponse: (response: { data: CreateNewParticipant }) =>
        response.data,
    }),
  }),
});

export { usersApi };
