/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DataSourcesDeleteModal from "./data-sources-delete-modal";
import "./data-sources.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import { Loader } from "../reusable-components/loader/loader";
import { updatePanel } from "../../store/reducers/panels/actions/panel-actions";

import {
  TempChildType,
  TempParentType,
  TempPreparedChennelsType,
  TempPreparedDMType,
  TempSourceType,
} from "../../interfaces/user-detail-interface";
import { DataSourceId } from "../../prop-types/prop-type";

export type DataSourceProps = Record<string, unknown>;
export type DataSourceData = Partial<{
  datasources: {
    id: string;
    source_id: number;
    name: string;
    logo: string;
    order: number;
  }[];
  [key: string]: Record<string, string | number>[];
}>;

const DataSource: FC<DataSourceProps> = () => {
  const { t } = useTranslation();
  const [response, loading, error, axiosFetch] = useAxios();
  const [
    responseUpdateSource,
    loadingUpdateSource,
    errorUpdateSource,
    axiosFetchUpdateSource,
  ] = useAxios();
  const [dataSourcesData, setDataSourcesData] = useState<DataSourceData>({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [accountForDelete, setAccountForDelete] = useState({});
  const loadDataSources = async () => {
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: `${SERVER_CONFIG.BASE_URL}${SERVER_CONFIG.API_URL.DATA_SOURCES.ALL}`,
    });

    setDataSourcesData(data);
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const [panelsResponse, panelsLoading, panelsError, axiosFetchPanels] =
    useAxios();
  const dispatch = useDispatch<any>();

  const handleModalSubmit = async (retainOlderData: boolean) => {
    try {
      const {
        // @ts-expect-error We have type errors in axiosFetch
        data: { data },
      } = await axiosFetch({
        method: SERVER_CONFIG.HTTP_METHOD.DELETE,
        url: `${SERVER_CONFIG.BASE_URL}${SERVER_CONFIG.API_URL.DATA_SOURCES.DELETE}`,
        data: { ...accountForDelete, retainOlderData },
      });

      if (data) {
        axiosFetchPanels({
          method: SERVER_CONFIG.HTTP_METHOD.GET,
          url: SERVER_CONFIG.API_URL.PANELS_GET,
        });
      }
    } finally {
      loadDataSources();
      setIsDeleteModalOpen(false);
    }
  };

  type TempSlackInner = {
    [x: string]: {
      [x: string]: {
        slackName: string;
        channels: { [x: string]: { channelName: string } };
        directMessages: {
          [x: string]: { directMessagesName?: string; channelId?: string };
        };
      };
    };
  };

  useEffect(() => {
    if (panelsResponse) {
      const { data } = panelsResponse;
      if (data && data.success) {
        const tempPanels = [...data.panels.activePanels];
        tempPanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempPanels[itemIndex].children[childIndex].sources.splice(
                    sourceIndex,
                    1,
                    updatedSourceDetails
                  );
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempPanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        const tempArchivePanels = [...data.panels.archivedPanels];
        tempArchivePanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempArchivePanels[itemIndex].children[
                    childIndex
                  ].sources.splice(sourceIndex, 1, updatedSourceDetails);
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempArchivePanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        dispatch(updatePanel(tempPanels, tempArchivePanels));
      }
    }
  }, [panelsResponse]);

  useEffect(() => {
    loadDataSources();
  }, []);

  const handleDeleteItem = ({
    id,
    email,
    sourceType,
  }: Record<string, string>) => {
    setAccountForDelete({ id, email, sourceType });
    setIsDeleteModalOpen(true);
  };

  useEffect(() => {
    const addPanelSource = () => {
      const srcState = localStorage.getItem("source_state");
      if (srcState) {
        axiosFetchUpdateSource({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: SERVER_CONFIG.API_URL.SOURCE_SAVE_ADD_SOURCE_RESPONSE,
          data: JSON.parse(srcState),
        });
        localStorage.removeItem("source_state");
      }
    };
    window.addEventListener("storage", addPanelSource);
    return () => {
      window.removeEventListener("storage", addPanelSource);
    };
  }, []);

  useEffect(() => {
    if (responseUpdateSource) {
      loadDataSources();
    }
  }, [responseUpdateSource]);

  const renderDataSource: FC<{
    id: string;
    name: string;
    logo: string;
  }> = ({ id, name, logo }) => {
    return (
      <div className="data-source-item" key={id}>
        <div>
          <img alt="data-source-logo" className="item-logo" src={logo} />
        </div>
        <div className="info-container">
          <h3 className="item-heading">{name}</h3>
          <ul className="item-list">
            {dataSourcesData[name.toLowerCase()]?.map(
              ({
                id,
                email,
                sourceType,
                workspaceName,
                isSourceConnected,
                signInUrl,
              }) =>
                isSourceConnected ? (
                  <li key={id} className="account-item">
                    {email}{" "}
                    {sourceType === "Slack" && <span>- {workspaceName} </span>}
                    <span
                      className="description-x delete-icon"
                      onClick={() => {
                        handleDeleteItem({
                          id,
                          email,
                          sourceType,
                        } as Record<string, string>);
                      }}
                    >
                      {t<string>("globalSettings.dataSources.deleteText")}
                    </span>
                  </li>
                ) : (
                  <li key={id} style={{ marginBottom: "10px" }}>
                    <div className="unconnected-data-source">
                      <div className="warning">!</div>
                      <p style={{ marginBottom: "0px" }}>
                        {email}{" "}
                        {sourceType === "Slack" && (
                          <span>- {workspaceName} </span>
                        )}
                        <span
                          className="description-x delete-icon"
                          onClick={() => {
                            handleDeleteItem({
                              id,
                              email,
                              sourceType,
                            } as Record<string, string>);
                          }}
                        >
                          {t<string>("globalSettings.dataSources.deleteText")}
                        </span>
                        <br />
                        {signInUrl && (
                          <a
                            href={`${signInUrl}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Re-connect this Connection
                          </a>
                        )}
                      </p>
                    </div>
                  </li>
                )
            )}
          </ul>
        </div>
      </div>
    );
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <>
      <div className="data-sources-container">
        <h2 className="heading">
          {t<string>("globalSettings.dataSources.subtitle")}
        </h2>
        <p className="description">
          {t<string>("globalSettings.dataSources.descriptionPart1")}{" "}
          <span className="description-x">
            {t<string>("globalSettings.dataSources.deleteText")}
          </span>{" "}
          {t<string>("globalSettings.dataSources.descriptionPart2")}
        </p>
        <ul className="list">
          {dataSourcesData?.datasources?.map(renderDataSource)}
        </ul>
      </div>
      <DataSourcesDeleteModal
        open={isDeleteModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};

export default DataSource;
