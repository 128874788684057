import { useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { useFormik } from "formik";
import { Navigate, useNavigate } from "react-router-dom";
import useAuthProvider from "../authProvider";
import { LoginParams } from "../interfaces/user-detail-interface";
import { HeaderSubscription } from "./user-signin/header-subscription";

import "./user-signin/subscription-page.scss";
import useAxios from "../services/axios";
import SERVER_CONFIG, { STRIPE_CONFIG } from "../utils/config";

const Login = () => {
  const {
    isLoggedIn,
    loginInLoginPage,
    loadingLoginPage,
    loginLoginPageResponse,
  } = useAuthProvider();

  const isSubscriptionExpired = useMemo(() => {
    return (
      loginLoginPageResponse &&
      loginLoginPageResponse.subscriptionStatus !== "Active"
    );
  }, [loginLoginPageResponse]);

  const customerId: string = useMemo(() => {
    return loginLoginPageResponse?.customerId || "";
  }, [loginLoginPageResponse]);

  const subscriptionType: string = useMemo(() => {
    return loginLoginPageResponse?.subscriptionType || "";
  }, [loginLoginPageResponse]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  const clickOnSubmit = () => {
    const payload: LoginParams = {
      Username: formik.values.email,
      Password: formik.values.password,
    };
    loginInLoginPage(payload);
  };

  const handleResetPass = () => {
    navigate("/subscription/password-reset");
  };

  const [dashboardUrl, setDashboardUrl] = useState("");
  const [response, loading, error, axiosFetch] = useAxios();

  const getBillingLink = async (stripeCustomerId: string) => {
    await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: STRIPE_CONFIG.CREATE_PORTAL_URL,
      params: {
        customer: stripeCustomerId,
        return_url: `${SERVER_CONFIG.UI_MAIN_URL}/login`,
      },
      headers: {
        Authorization: `Bearer ${STRIPE_CONFIG.SECRET_KEY}`,
      },
    });
  };

  useEffect(() => {
    if (!!customerId && !!subscriptionType) {
      getBillingLink(customerId);
    }
  }, [customerId, subscriptionType]);

  useEffect(() => {
    if (response?.data?.url) {
      setDashboardUrl(response.data.url);
    }
  }, [response]);

  return isLoggedIn() ? (
    <Navigate to="/panels/p1" replace />
  ) : (
    <Spin size="large" spinning={loadingLoginPage}>
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">Sign In</h1>
        <div className="text-left">Please enter your email and password.</div>
        <div className="space">
          <div>
            <label htmlFor="password" className="form-label">
              Email
              <input
                id="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
          </div>

          <div className="subscription-data__space">
            <label htmlFor="confirmPass" className="form-label">
              Password
              <input
                id="password"
                type="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>

            {isSubscriptionExpired && (
              <div className="error-text">
                {!subscriptionType &&
                  "Your Premium user’s subscription has expired, please let them know."}

                {subscriptionType && !loading && (
                  <span>
                    {`Your ${subscriptionType} subscription has expired, please `}

                    <a href={dashboardUrl} className="stripe-renewal">
                      click here
                    </a>
                    {" to renew."}
                  </span>
                )}
              </div>
            )}

            <div className="flex">
              <div className="form-button-width">
                <button
                  type="button"
                  className="form-button"
                  onClick={clickOnSubmit}
                  disabled={loadingLoginPage || !!loginLoginPageResponse}
                >
                  <span>Submit</span>
                </button>
              </div>

              <div className="form-button-width">
                <button
                  type="button"
                  className="form-button-white"
                  onClick={handleResetPass}
                  disabled={loadingLoginPage || !!loginLoginPageResponse}
                >
                  <span>Forgot Password</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </Spin>
  );
};

export default Login;
