import Actions from "../../../action";
import { ADD_DATA_SOURCE } from "../action/addDataSource-action";

export const intialState: any = {
  code: null,
  state: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const AddDataSourceReducer = (state = intialState, action: Actions) => {
  switch (action.type) {
    case ADD_DATA_SOURCE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default AddDataSourceReducer;
