const paramsData = { panelId: "", subpanelId: "" };

export type Actions = {
  type: string;
  payload: any;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const paramsReducer = (state = paramsData, action: Actions) => {
  switch (action.type) {
    case "addparams":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default paramsReducer;
