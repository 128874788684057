/* eslint-disable no-console */
import { useState, useMemo, useEffect, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { Menu, Dropdown } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  DownOutlined,
  CheckOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import icons from "../../../assets/icon-export-assets";
import MailTemplate from "./mail-template";
import { OutlookMailBody, DataSourceId } from "../../../prop-types/prop-type";
import { PanelsData, Source } from "../../../interfaces/user-detail-interface";
import { RootReducer } from "../../../store/reducers/root-reducer";
import "../data-sources.scss";

type SelectedEmail = {
  sourceId: number;
  emailId: string;
};

const sortEmails = (emails: any[], settings: Record<string, any>) => {
  const sortBy = isMobile
    ? settings?.mobileOrganisedBy
    : settings?.desktopOrganisedBy;
  if (sortBy === "date") {
    return [...emails].sort(
      (a, b) =>
        new Date(b.emailDate).getTime() - new Date(a.emailDate).getTime()
    );
  }

  const withFromName = emails
    .filter((mail) => mail.fromName)
    .sort((letterA, letterB) => {
      return letterA.fromName.localeCompare(letterB.fromName);
    });
  const withoutFromName = emails
    .filter((mail) => !mail.fromName)
    .sort((letterA, letterB) => {
      return letterA.fromAddress.localeCompare(letterB.fromAddress);
    });

  return [...withFromName, ...withoutFromName];
};

function Email({ item, sources, changeIsShowAll }: any) {
  const { outlookIcon, gmailLarge } = icons;
  const { t } = useTranslation();
  const panelData: PanelsData = useSelector(
    (state: RootReducer) => state.panelData
  );
  const navigate = useNavigate();
  const [mails, setMails] = useState<OutlookMailBody[]>([]);
  const [selectedEmailList, setSelectedEmailList] = useState<SelectedEmail[]>(
    []
  );
  const [recordsPerPage, setRecordsPerPage] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [isViewAllClicked, setIsViewAllClicked] = useState(false);

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState("");

  const itemPanelSettings =
    sources[0].sourceId !== DataSourceId.OUTLOOK
      ? item.panelSettings?.gmail
      : item.panelSettings?.outlook;

  const { panelSettings } = item;

  const maxCountPerPage = isMobile
    ? itemPanelSettings?.mobileMaxEmailViewedPerPanel
    : itemPanelSettings?.desktopMaxEmailViewedPerPanel;

  useEffect(() => {
    setRecordsPerPage(Number(maxCountPerPage));
  }, [
    itemPanelSettings?.mobileMaxEmailViewedPerPanel,
    itemPanelSettings?.desktopMaxEmailViewedPerPanel,
  ]);

  useEffect(() => {
    if (selectedEmailList.length > 0) {
      const mailList: any = [];
      if (panelData[item.id]) {
        selectedEmailList.forEach((selectedEmail: SelectedEmail) => {
          if (selectedEmail.sourceId === DataSourceId.GMAIL) {
            const { email = {} } = panelData[item.id];
            const { gmail = {} } = email;
            const { data = [] } = gmail[selectedEmail.emailId] || {};
            if (data.length > 0) {
              const preparedData: OutlookMailBody[] = [];
              data.forEach((mail) => {
                const indexInList = preparedData.findIndex(
                  (val) => val.id === mail.id
                );
                if (indexInList < 0) {
                  preparedData.push(mail);
                }
              });
              mailList.push(...preparedData);
            }
          }
          if (selectedEmail.sourceId === DataSourceId.OUTLOOK) {
            const { email = {} } = panelData[item.id];
            const { outlook = {} } = email;
            const { data = [] } = outlook[selectedEmail.emailId] || {};
            if (data.length > 0) {
              const preparedData: OutlookMailBody[] = [];
              data.forEach((mail) => {
                const indexInList = preparedData.findIndex(
                  (val) => val.id === mail.id
                );
                if (indexInList < 0) {
                  preparedData.push(mail);
                }
              });
              mailList.push(...preparedData);
            }
          }
        });
      }

      if (mailList.length > 0) {
        setMails(sortEmails(mailList, itemPanelSettings));
      } else {
        setMails([]);
      }
    }
  }, [panelData, selectedEmailList, sources, panelSettings]);

  const standardViewLines = isMobile
    ? itemPanelSettings?.mobileStdViewLinesPerEmail
    : itemPanelSettings?.desktopStdViewLinesPerEmail;
  const expandedViewLines = isMobile
    ? itemPanelSettings?.mobileExpViewLinesPerEmail
    : itemPanelSettings?.desktopExpViewLinesPerEmail;

  const mailList = useMemo(() => {
    const tempMails = [...mails];
    if (isViewAllClicked) {
      return tempMails.map((e: OutlookMailBody) => (
        <MailTemplate
          key={e.id || Math.random()}
          data={e}
          panelSettings={panelSettings[e.emailSourceType.toLowerCase()]}
        />
      ));
    }

    return tempMails
      ?.splice(pageNumber * recordsPerPage, recordsPerPage)
      .map((e: OutlookMailBody) => (
        <MailTemplate
          key={e.id || Math.random()}
          data={e}
          panelSettings={panelSettings[e.emailSourceType.toLowerCase()]}
        />
      ));
  }, [
    mails,
    pageNumber,
    recordsPerPage,
    standardViewLines,
    expandedViewLines,
    sources,
  ]);

  const emailIdList = useMemo(() => {
    let emailList: any = [];
    sources.forEach((e: Source) => {
      emailList = [
        ...emailList,
        { sourceId: e.sourceId, emailId: e.data.email },
      ];
    });
    return emailList;
  }, [item]);
  useEffect(() => {
    if (emailIdList.length > 0) {
      setSelectedEmailList([...emailIdList]);
    }
    if (currentLocation === "") {
      setCurrentLocation(location.pathname);
    } else if (currentLocation !== location.pathname && isViewAllClicked) {
      setIsViewAllClicked(false);
      changeIsShowAll();
      setCurrentLocation(location.pathname);
    }
  }, [item]);

  const sourceIconList = useMemo(() => {
    const areAllGmail = sources.every(
      (source: Source) => source.sourceId === DataSourceId.GMAIL
    );
    const areAllOutlook = sources.every(
      (source: Source) => source.sourceId === DataSourceId.OUTLOOK
    );
    if (sources.length > 1 && !areAllGmail && !areAllOutlook) {
      return (
        <>
          <img
            alt="outlook"
            width={20}
            height={20}
            src={outlookIcon}
            style={{ marginRight: "4px" }}
          />
          <span className="link-emails">+</span>
          <img alt="gmail" width={20} height={20} src={gmailLarge} />
        </>
      );
    }
    if (sources.length > 0) {
      if (areAllOutlook) {
        return <img alt="outlook" width={20} height={20} src={outlookIcon} />;
      }
      return <img alt="gmail" width={20} height={20} src={gmailLarge} />;
    }
    return <span>{"  "}</span>;
  }, [sources]);

  const onDropdownClick = useCallback(
    ({ key }: { key: string }) => {
      const emailData = JSON.parse(key);
      const tempSelectedEmailList = selectedEmailList;
      const index = tempSelectedEmailList.findIndex(
        (ele: SelectedEmail) =>
          ele.sourceId === emailData.sourceId &&
          ele.emailId === emailData.emailId
      );
      if (index === -1) {
        setSelectedEmailList([...tempSelectedEmailList, JSON.parse(key)]);
      } else {
        tempSelectedEmailList.splice(index, 1);
        setSelectedEmailList([...tempSelectedEmailList]);
      }
      setPageNumber(0);
    },
    [selectedEmailList]
  );

  const menu = (
    <Menu onClick={(e: any) => onDropdownClick(e)} multiple>
      {emailIdList.map((emailData: any) => {
        return (
          <Menu.Item
            key={JSON.stringify(emailData)}
            className="email-list-dropdown"
          >
            {/* {checkedEmail(emailData)} */}
            <CheckOutlined
              hidden={
                selectedEmailList.findIndex(
                  (ele: SelectedEmail) =>
                    ele.sourceId === emailData.sourceId &&
                    ele.emailId === emailData.emailId
                ) === -1
              }
            />
            <img
              alt={
                emailData.sourceId === DataSourceId.OUTLOOK
                  ? "outlook"
                  : "gmail"
              }
              width={20}
              height={20}
              src={
                emailData.sourceId === DataSourceId.OUTLOOK
                  ? outlookIcon
                  : gmailLarge
              }
              className="cursor-default"
            />
            <span className="cursor-default">{emailData.emailId}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const menuMain = useMemo(() => {
    return (
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <button
          className="ant-dropdown-button-email-list"
          type="submit"
          onClick={(e) => e.preventDefault()}
        >
          {sourceIconList}
          <DownOutlined />
        </button>
      </Dropdown>
    );
  }, [item, sources, emailIdList, sourceIconList, selectedEmailList]);

  const prevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const nextPage = () => {
    const maxPage = Math.ceil(mails.length / recordsPerPage);
    if (pageNumber < maxPage) {
      setPageNumber(pageNumber + 1);
    }
  };
  const disableNextPage = () => {
    const maxPage = Math.ceil(mails.length / recordsPerPage) - 1;
    if (pageNumber === maxPage) {
      return true;
    }
    return false;
  };

  const viewAll = () => {
    if (recordsPerPage === maxCountPerPage) {
      const maxPage = Math.ceil(mails.length / 9) - 1;
      setRecordsPerPage(maxCountPerPage * 2);
      if (maxPage <= pageNumber) {
        setPageNumber(maxPage);
      }
    } else {
      setRecordsPerPage(Number(maxCountPerPage));
      setPageNumber(0);
    }
    changeIsShowAll();
    setIsViewAllClicked((prev) => !prev);
  };

  const isMailPagination = useMemo(() => {
    return mails.length > maxCountPerPage;
  }, [mails, maxCountPerPage]);

  const paginationInfo = useMemo(() => {
    return `${pageNumber * recordsPerPage + 1}-${
      pageNumber * recordsPerPage + recordsPerPage > mails.length
        ? mails.length
        : pageNumber * recordsPerPage + recordsPerPage
    } of ${mails.length}`;
  }, [pageNumber, recordsPerPage, mails]);

  const paginationBlock = useMemo(() => {
    if (!isMailPagination) {
      return <div className="email-result-header">{` `}</div>;
    }

    return !isViewAllClicked ? (
      <div className="email-result-header">
        <span>{paginationInfo}</span>
        <span>
          <button
            type="button"
            title="Prev"
            onClick={prevPage}
            disabled={pageNumber <= 0}
          >
            <LeftOutlined />
          </button>
          <button
            type="button"
            title="Next"
            onClick={nextPage}
            disabled={disableNextPage()}
          >
            <RightOutlined />
          </button>
        </span>
      </div>
    ) : (
      <div className="view-all-button-container">
        <button
          type="button"
          className="view-all-inner-button"
          title={t<string>("showLess")}
          onClick={viewAll}
        >
          {t<string>("showLess")}
        </button>
      </div>
    );
  }, [paginationInfo, isViewAllClicked, pageNumber, isMailPagination]);

  return (
    <div className="sourceContainer">
      <div className="pagination-filter-logo">
        {paginationBlock}

        <button
          type="button"
          className="headerButtonItems"
          style={{
            paddingTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          onClick={() => {
            if (item.pId) {
              navigate(`/filters/${item.pId}/${item.id}`);
            } else {
              navigate(`/filters/${item.id}`);
            }
          }}
        >
          <FilterOutlined
            style={{ color: "#BA3A29", fontSize: 18, height: "fit-content" }}
            title={t<string>("addEditFilterButton")}
            alt={t<string>("addEditFilterButton")}
          />
        </button>

        <div className="p-10px">{menuMain}</div>
      </div>

      <div className="mail-list-container">{mailList}</div>
      <div className="view-all-button-container-end">
        <button
          type="button"
          className="view-all-inner-button"
          title={
            !isViewAllClicked ? t<string>("viewAll") : t<string>("showLess")
          }
          onClick={viewAll}
        >
          {!isViewAllClicked ? t<string>("viewAll") : t<string>("showLess")}
        </button>
      </div>
    </div>
  );
}

export default Email;
