/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FC,
  PropsWithChildren,
  FormEventHandler,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import "./subscription.scss";
import SERVER_CONFIG, { STRIPE_CONFIG } from "../../utils/config";
import useAxios from "../../services/axios";
import { RootReducer } from "../../store/reducers/root-reducer";
import { Loader } from "../reusable-components/loader/loader";

const Subscription: FC<PropsWithChildren<Record<string, unknown>>> = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootReducer) => state.user);
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [subscriptionInfo, setSubscriptionInfo] = useState<Record<string, any>>(
    {}
  );
  const [response, loading, error, axiosFetch] = useAxios();

  const getBillingLink = async () => {
    // @ts-expect-error We have type errors in axiosFetch
    const { data } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: STRIPE_CONFIG.CREATE_PORTAL_URL,
      params: {
        customer: user.customerId,
        return_url: `${SERVER_CONFIG.UI_MAIN_URL}/globalsettings/subscription`,
      },
      headers: {
        Authorization: `Bearer ${STRIPE_CONFIG.SECRET_KEY}`,
      },
    });

    if (data?.url) setDashboardUrl(data?.url);
  };

  const getSubscriptionInfo = async () => {
    // @ts-expect-error We have type errors in axiosFetch
    const { data } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: `${STRIPE_CONFIG.SUBSCRIPTION_URL}/${user.subscriptionId}`,
      headers: {
        Authorization: `Bearer ${STRIPE_CONFIG.SECRET_KEY}`,
      },
    });

    setSubscriptionInfo(data);
  };

  useEffect(() => {
    getBillingLink();
    getSubscriptionInfo();
  }, []);

  const handleManageBilling: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <div className="subscription-container">
      <div>
        <div>
          <span className="myaccount-highlighted-text">
            {t<string>("globalSettings.subscription.title")}
          </span>
          <p className="subscription-text">
            {t<string>("globalSettings.subscription.yourSubscription")}{" "}
            <span className="myaccount-highlighted-text">
              {moment.unix(subscriptionInfo?.current_period_end) > moment()
                ? t<string>("globalSettings.subscription.active")
                : t<string>("globalSettings.subscription.inactive")}
            </span>
          </p>
          <p className="subscription-text">
            {t<string>("globalSettings.subscription.yourPayment")}{" "}
            <span className="myaccount-highlighted-text">
              {moment
                .unix(subscriptionInfo?.current_period_end)
                .format("MMMM Do YYYY")}
            </span>
          </p>
        </div>
      </div>
      <p className="subscription-helper-text">
        {t<string>("globalSettings.subscription.helperText")}
      </p>
      <form className="manage-billing-form" onSubmit={handleManageBilling}>
        <a href={dashboardUrl}>
          <Button danger className="subscription-button">
            {t<string>("globalSettings.subscription.makeChangesAtStripe")}
          </Button>
        </a>
      </form>
    </div>
  );
};

export { Subscription };
