import Actions from "../../../action";

export type ZoomPanelType = {
  [parentId: string]: {
    columns: number;
    rows: number;
  };
};

const initialState: ZoomPanelType = {};

const panelZoomInfoReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: Actions
) => {
  switch (action.type) {
    case "setPanelZoomInfo":
      state = {
        ...state,
        ...action.payload,
      };
      return state;

    default:
      return state;
  }
};

export default panelZoomInfoReducer;
