import { Dispatch } from "redux";
import { PathnameLocation } from "../reducer/pathname-location-reducer";

export const SET_PATHNAME_INFO = "setPathnameLocation";
export const pathnameLocationAction = (data: PathnameLocation) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_PATHNAME_INFO,
      payload: data,
    });
  };
};
