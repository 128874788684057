import {
  WIDGET_DIALOG_OPEN,
  TODOBOARD_SHOW,
  UPDATE_TODOS,
  UPDATE_COLUMNS,
  UPDATE_TODO_WIDGETID,
  UPDATE_ACTIVE_TODOS,
  UPDATE_ARCHIVE_TODOS,
  TODO_PRIORITY_LIST,
  TODO_PANEL_LIST,
  TODO_LIST,
  TODO_MASTER_STATUS_DATA,
  WIDGETS_UPDATED,
} from "../constants";
import { StateInterface } from "../../../../prop-types/prop-type";

const initialState: StateInterface = {
  widget_dialog_show: false,
  todoboard_show: false,
  widgets_updated: false,
  columnsToShow: 3,
  todoWidgetId: "",
  todos: [],
  activeTodos: [],
  archiveTodos: [],
  priorityData: [],
  todoPanelList: [],
  activeStatusList: [],
  todoMasterStatusList: [],
};

export type Actions = {
  type: string;
  data: any;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const todoReducer = (state = initialState, action: Actions) => {
  switch (action.type) {
    case WIDGET_DIALOG_OPEN:
      return { ...state, widget_dialog_show: action.data };
    case TODOBOARD_SHOW:
      return { ...state, todoboard_show: action.data };
    case WIDGETS_UPDATED:
      return { ...state, widgets_updated: action.data };
    case UPDATE_TODOS:
      return { ...state, todos: action.data };
    case UPDATE_COLUMNS:
      return { ...state, columnsToShow: action.data };
    case UPDATE_TODO_WIDGETID:
      return { ...state, todoWidgetId: action.data };
    case TODO_PRIORITY_LIST:
      return { ...state, priorityData: action.data };
    case TODO_MASTER_STATUS_DATA:
      return { ...state, todoMasterStatusList: action.data };
    case TODO_PANEL_LIST:
      return { ...state, todoPanelList: action.data };
    case TODO_LIST:
      return { ...state, todoList: action.data };
    case UPDATE_ACTIVE_TODOS:
      return { ...state, activeTodos: action.data };
    case UPDATE_ARCHIVE_TODOS:
      return { ...state, archiveTodos: action.data };
    default:
      return state;
  }
};

export default todoReducer;
