/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Input, Modal, Dropdown } from "antd";
import cn from "classnames";
import {
  SearchOutlined,
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  MenuOutlined,
  LeftOutlined,
  LogoutOutlined,
  BellOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "./navbar.scss";
import { ItemType } from "antd/es/menu/hooks/useItems";
import useAuthProvider from "../../authProvider";
import icons, { AddCollaboratorIcon } from "../../assets/icon-export-assets";
import {
  LoggedInNavbarProp,
  StateInterface,
  StripeProduct,
} from "../../prop-types/prop-type";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import {
  clickWidgetBtn,
  todoBoardShow,
  updateColumns,
  updateTodoWidgetId,
  widgetsUpdated,
} from "../../store/reducers/todo/action/todo";
import { RootReducer } from "../../store/reducers/root-reducer";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import { User as UserType } from "../../interfaces/user-detail-interface";

type TestMsg = {
  title: string;
  text: string;
  isImportant: boolean;
  wasRead: boolean;
};

const testMsgs: TestMsg[] = [];

type UserWidget = {
  widgetId: string;
  show: string;
  name: string;
  columns: number;
};
function Navbar({
  setVisibleAddWidget,
  setOpen,
  open,
  showDrawer,
  visible,
}: LoggedInNavbarProp) {
  const dispatch = useDispatch<any>();
  const { logOut } = useAuthProvider();
  const [isWarningClicked, setIsWarningClicked] = useState(false);

  const { t } = useTranslation();
  const clickOnLogOut = () => logOut();
  const closeModal = () => setIsWarningClicked((prev) => !prev);

  const { GreyCircle } = icons;
  const isAllMsgsRead = testMsgs.every((msg) => msg.wasRead === true);
  const importantMsg = testMsgs.filter((msg) => msg.isImportant === true);
  const wasReadImportantMsg = importantMsg.every((msg) => msg.wasRead === true);
  const numberUnReadMsgs = testMsgs.reduce((sum, msgTest) => {
    if (!msgTest.wasRead) {
      sum += 1;
    }
    return sum;
  }, 0);

  const loc = useLocation();
  const todoRedux: StateInterface = useSelector(
    (state: RootReducer) => state.todo
  );
  const user: UserType = useSelector((state: RootReducer) => state.user);
  useEffect(() => {
    if (todoRedux.widget_dialog_show) {
      dispatch(clickWidgetBtn(false));
    }
  }, [loc]);

  const [items, setItems] = useState<ItemType[]>([]);
  const [
    userWidgetsResponse,
    userWidgetsloading,
    userWidgetsError,
    userWidgetsFetch,
  ] = useAxios();
  const todo: StateInterface = useSelector((state: RootReducer) => state.todo);
  useEffect(() => {
    userWidgetsFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.WIDGETS_LIST_USER_GET,
    });
    dispatch(widgetsUpdated(false));
  }, [todo.widgets_updated]);

  useEffect(() => {
    const res = userWidgetsResponse?.data;
    if (res && res.success) {
      const tempItems = [];
      let id = 0;
      res.data.widgetDetails.forEach((userWidget: UserWidget) => {
        if (userWidget.show && userWidget.name === "todo") {
          dispatch(updateColumns(userWidget.columns));
          dispatch(updateTodoWidgetId(userWidget.widgetId));
          id += 1;
          tempItems.push({
            label: (
              <div
                onClick={() => {
                  if (typeof setVisibleAddWidget === "function") {
                    setVisibleAddWidget(true);
                  }
                  dispatch(clickWidgetBtn(false));
                  dispatch(todoBoardShow(true));
                }}
                onKeyDown={() => {
                  if (typeof setVisibleAddWidget === "function") {
                    setVisibleAddWidget(true);
                  }
                  dispatch(clickWidgetBtn(false));
                  dispatch(todoBoardShow(true));
                }}
                role="button"
                tabIndex={0}
              >
                {t<string>(`widgets.${userWidget.name}`)}
              </div>
            ),
            key: id,
          });
        }
      });
      id += 1;
      tempItems.push({
        label: (
          <div
            onClick={() => {
              if (typeof setVisibleAddWidget === "function") {
                setVisibleAddWidget(true);
              }
              dispatch(todoBoardShow(false));
              dispatch(clickWidgetBtn(true));
            }}
            onKeyDown={() => {
              if (typeof setVisibleAddWidget === "function") {
                setVisibleAddWidget(true);
              }
              dispatch(todoBoardShow(false));
              dispatch(clickWidgetBtn(true));
            }}
            role="button"
            tabIndex={0}
          >
            <p>+Add/Edit a Widget</p>
          </div>
        ),
        key: id,
      });
      setItems(tempItems);
    }
  }, [userWidgetsResponse]);

  const participantsLink =
    user.subscription.type === StripeProduct.Premium ? "invite" : "upgrade";

  const isParticipantsPage = loc.pathname.includes("/participants");

  return (
    <div className="topHeader">
      <div className="headerSearch">
        <div className="sidebar-button-div">
          <button
            type="button"
            className="sidebar-menus-btn"
            onClick={() => {
              setOpen(!open);
              dispatch(
                setUserInfoAction({
                  ...user,
                  isTawkToOn: !open,
                })
              );
            }}
          >
            {open ? <LeftOutlined /> : <MenuOutlined />}
          </button>
        </div>
        <div className="drawer-button-div">
          <button
            type="button"
            className="sidebar-menus-btn"
            onClick={() => showDrawer()}
          >
            {visible ? <LeftOutlined /> : <MenuOutlined />}
          </button>
        </div>
        {/* temporary commented due to 408 task */}
        {/* <Input
          placeholder={t<string>("searchbarPlaceholder")}
          style={{ width: "40%" }}
          suffix={<SearchOutlined className="headerButtonItems" />}
          size="small"
        /> */}
        <div />
        <div className="headerButtons">
          <Link to="/help/faq">
            <button
              title="Help Center"
              type="button"
              className="headerButtonItems space-right"
            >
              <QuestionCircleOutlined />
            </button>
          </Link>

          {!user.isParticipant && (
            <Link to={`/participants/${participantsLink}`}>
              <button
                title="Participants"
                type="button"
                className="headerButtonItems"
              >
                <AddCollaboratorIcon
                  className={cn("navbar-icon", {
                    "link-inactive": !isParticipantsPage,
                    "link-active": isParticipantsPage,
                  })}
                />
              </button>
            </Link>
          )}
          {/* temporary commented due to PDP01-616 task */}
          {/* <div
            className="bell-icon"
            onClick={() => setIsWarningClicked((prev) => !prev)}
          >
            <button
              title="Notifications"
              type="button"
              className="headerButtonItems"
            >
              <BellOutlined className="btn-svg" />
            </button>
            {!isAllMsgsRead && wasReadImportantMsg && (
              <div className="bell-icon__numbers">{numberUnReadMsgs}</div>
            )}
            {!isAllMsgsRead && !wasReadImportantMsg && (
              <div className="bell-icon__warning">!</div>
            )}
          </div> */}
          {/* <Link to="/"> */}
          <Dropdown menu={{ items }} trigger={["click"]} arrow>
            <button title="Widgets" type="button" className="headerButtonItems">
              <AppstoreAddOutlined rotate={180} />
            </button>
          </Dropdown>
          {/* </Link> */}

          <Link to="/" className="space-left">
            <button
              title="Sign Out"
              onClick={clickOnLogOut}
              type="button"
              className="headerButtonItems"
            >
              <LogoutOutlined />
            </button>
          </Link>
        </div>
      </div>
      {isWarningClicked && (
        <Modal
          open={isWarningClicked}
          closable={false}
          footer={false}
          onCancel={closeModal}
          onOk={closeModal}
          mask={false}
          className="modalStyle"
        >
          <div>
            {testMsgs.map((msg, i) => (
              <div key={i}>
                <div className="msg">
                  <div className="msg__dots">
                    {msg.wasRead ? (
                      <img alt="circle" width={7} height={7} src={GreyCircle} />
                    ) : (
                      <div
                        className={`msg__dots-round ${
                          msg.isImportant ? "background-red" : "background-blue"
                        }`}
                      />
                    )}
                  </div>
                  <div className="msg__text">
                    <strong>{msg.title}</strong> <span>{msg.text}</span>
                  </div>
                </div>
                <div className="msg__bottom-line" />
              </div>
            ))}
            {testMsgs.length > 5 && (
              <div className="msg__view-all">
                {t<string>("viewAllNotifications")}
              </div>
            )}
            {testMsgs.length === 0 && (
              <div className="msg__empty">{t<string>("noNotifications")}</div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Navbar;
