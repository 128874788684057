/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/naming-convention */
import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { usersApi } from "../../store/api";
import icons from "../../assets/icon-export-assets";
import { HeaderSubscription } from "./header-subscription";
import useAuthProvider from "../../authProvider";

import "./subscription-page.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";

type DecodeToken = {
  panelUserId: string;
  userEmail?: string;
  verificationCode: string;
  phoneNumber?: string;
};

type ErrorLogin = {
  data: null;
  message: string;
  messageType: string;
  success: boolean;
};

const SubscriptionSmsConfirm = () => {
  const [smsValue, setSmsValue] = useState("");
  const [isErrorSmsValue, setIsErrorSmsValue] = useState(false);
  const { logIn, loading, error } = useAuthProvider();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const params = useParams();
  const { token = "" } = params;
  const { errorCheck } = icons;
  const decoded: DecodeToken = jwt_decode(token, { header: true });

  const [
    authSmsNewResponse,
    loadingAuthSmsNew,
    errorAuthSmsNew,
    axiosAuthSmsNewFetch,
  ] = useAxios();

  const validateEnterUser = () => {
    logIn(smsValue, token);
  };

  const isNewUser = !!decoded?.userEmail && !!decoded?.phoneNumber;

  const { useConfirmSmsCodeMutation } = usersApi;
  const [confirmSmsCode, _user] = useConfirmSmsCodeMutation();

  const handleConfirmSmsCode: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    if (isNewUser) {
      try {
        await confirmSmsCode({
          smsCode: smsValue,
          token,
          action: "validate",
        }).unwrap();
        toast("Congrats!");
        navigate(`/`);
      } catch (e) {
        toast.error(`${(e as { data: { message: string } })?.data?.message}`);
        if (
          (e as { data: { message: string } })?.data?.message.includes(
            "expired"
          )
        ) {
          navigate("/subscription/start");
        }
        setIsErrorSmsValue(true);
      }
    } else {
      try {
        validateEnterUser();
      } catch {
        setIsErrorSmsValue(true);
      }
    }
  };

  const handleRequestNewSmsCode: MouseEventHandler<HTMLButtonElement> = async (
    e
  ) => {
    e.preventDefault();

    if (isNewUser) {
      try {
        await confirmSmsCode({
          smsCode: smsValue,
          token,
          action: "resend",
        }).unwrap();
        toast("Sms was sent once again!");
        setSmsValue("");
      } catch (e) {
        toast.error(`${(e as { data: { message: string } })?.data?.message}`);
        if (
          (e as { data: { message: string } })?.data?.message.includes(
            "expired"
          )
        ) {
          navigate("/subscription/start");
        }
        setIsErrorSmsValue(true);
        setSmsValue("");
      }
    } else {
      setIsErrorSmsValue(false);
      setSmsValue("");

      try {
        axiosAuthSmsNewFetch({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: `${SERVER_CONFIG.API_URL.COMMON_APIS.AUTH_RESEND_SMS}/${token}`,
        });
      } catch {
        setIsErrorSmsValue(true);
      }
    }
  };

  useEffect(() => {
    if (error || errorAuthSmsNew) {
      const combineError = error || errorAuthSmsNew;
      const messageError = combineError?.response?.data as ErrorLogin;
      if (messageError.message.includes("expired")) {
        navigate("/login");
      } else {
        setIsErrorSmsValue(true);
      }
    }
  }, [error, errorAuthSmsNew]);

  return (
    <Spin
      size="large"
      spinning={loadingAuthSmsNew || _user.isLoading || loading}
    >
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">{t<string>("pageSMSConfirm.header")}</h1>

        <form onSubmit={handleConfirmSmsCode} autoComplete="new-sms">
          <div>
            <p>{t<string>("pageSMSConfirm.contentFirst")}</p>
            <p>
              {t<string>("pageSMSConfirm.contentNote")}
              <span>{t<string>("pageSMSConfirm.contentWithin")}</span>{" "}
              {t<string>("pageSMSConfirm.contentRedirect")}
            </p>
            <div>
              <label htmlFor="sms" className="form-label">
                {t<string>("pageSMSConfirm.labelEnterCode")}
                <input
                  id="sms"
                  type="text"
                  className={`${isErrorSmsValue ? "input-sms-error" : ""}`}
                  value={smsValue}
                  onChange={(e) => {
                    setSmsValue(e.target.value);
                    setIsErrorSmsValue(false);
                  }}
                  autoComplete="off"
                />
              </label>

              {isErrorSmsValue && (
                <div>
                  <div className="flex-space">
                    <img src={errorCheck} alt="error" />
                    <span className="subscription-input-text-error">
                      {t<string>("pageSMSConfirm.errorIncorrectCode")}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="form-button-width">
              <button
                type="submit"
                className="form-button"
                disabled={smsValue === "" || loadingAuthSmsNew}
              >
                <span>{t<string>("buttonSubmit")}</span>
              </button>
            </div>

            <div className="form-button-width">
              <button
                type="button"
                className="form-button-white"
                onClick={handleRequestNewSmsCode}
              >
                <span>{t<string>("buttonRequestNewCode")}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Spin>
  );
};

export default SubscriptionSmsConfirm;
