import Actions from "../../../action";

export type PreviousPanelInfoType = {
  panelId: string;
  subpanelId?: string;
};

const previousPanelInfoStorage: PreviousPanelInfoType = {
  panelId: "",
};

const AddPreviousPanelInfoReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = previousPanelInfoStorage,
  action: Actions
) => {
  switch (action.type) {
    case "setPrevPanelInfo":
      state = action.payload;
      return state;
    case "resetPrevPanelInfo":
      state = previousPanelInfoStorage;
      return state;
    default:
      return state;
  }
};

export default AddPreviousPanelInfoReducer;
