import { Dispatch } from "redux";
import { ZoomPanelType } from "../reducer/zoom-panels-reducer";

export const SET_PANEL_ZOOM_INFO = "setPanelZoomInfo";

export const setZoomPanelInfoAction = (data: ZoomPanelType) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_PANEL_ZOOM_INFO,
      payload: data,
    });
  };
};
