/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import Actions from "../../../action";

export type PanelIdToSetup = {
  panelIdToSetup: string;
};

const panelIdToSetupStorage: PanelIdToSetup = {
  panelIdToSetup: "",
};

const AddPanelIdToSetupReducer = (
  state = panelIdToSetupStorage,
  action: Actions
) => {
  switch (action.type) {
    case "panelIdToSetup":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default AddPanelIdToSetupReducer;
