/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "antd/lib/form/interface";
import { Button, Col, Form, Input, Row, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { PlusSquareOutlined } from "@ant-design/icons";
import { ChromePicker, TwitterPicker } from "react-color";
import { AddPanelPropType, DataSourceId } from "../../../prop-types/prop-type";
import { RootReducer } from "../../../store/reducers/root-reducer";
import {
  Panel,
  TempChildType,
  TempParentType,
  TempPreparedChennelsType,
  TempPreparedDMType,
  TempSourceType,
} from "../../../interfaces/user-detail-interface";
import "./add-panel.scss";
import { updatePanel } from "../../../store/reducers/panels/actions/panel-actions";
import { Marker } from "../../reusable-components/marker/marker";
import useAxios from "../../../services/axios";
import SERVER_CONFIG from "../../../utils/config";
import { savePanelStorage } from "../../../store/reducers/save-panel-storage/add-new-panel-action";

const { Option, OptGroup } = Select;

function AddPanel({ showAddPanel, setShowAddPanel }: AddPanelPropType) {
  const { connection } = useSelector(
    (state: RootReducer) => state.signalRConnection
  );
  // const { newAddedPanel } = useSelector(
  //   (state: RootReducer) => state,
  // );
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { activePanels } = useSelector((state: RootReducer) => state.panels);
  const [showPicker, setShowPicker] = useState(false);
  const [panelName, setPanelName] = useState("");
  const [showChromePicker, setShowChromePicker] = useState(false);
  const [color, setColor] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState("");
  const { username } = useSelector((state: RootReducer) => state.user);
  const [addPanelResponse, addPanelLoading, error, axiosFetch] = useAxios();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleSelectChange = (e: any) => {
    setShowPicker(false);
    setShowChromePicker(false);
    if (e === 1) {
      setSelectedOptionId("1");
      setShowPicker(true);
    } else if (e === 2) {
      setSelectedOptionId("2");
      setShowPicker(true);
    } else {
      setSelectedOptionId(e);
      setColor(activePanels.filter((el: Panel) => el.id === e)[0].color);
    }
  };

  const [isNeedColor, setIsNeedColor] = useState(false);

  useEffect(() => {
    if (isNeedColor) {
      setIsNeedColor(false);
    }
  }, [color]);

  const addPanel = (formFields: any) => {
    if (!color) {
      setIsNeedColor(true);
      return;
    }

    if (formFields.selectedOptionId && formFields.panelName && color) {
      const payload = {
        name: formFields.panelName,
        pId:
          selectedOptionId === "1" || selectedOptionId === "2"
            ? null
            : selectedOptionId,
        isStandalone: selectedOptionId !== "1",
        color,
        order: activePanels.length,
        active: true,
      };

      axiosFetch({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: SERVER_CONFIG.API_URL.PANELS_ADD,
        data: payload,
      });
    }
  };
  const [panelsAllResponse, panelsAllLoading, errorAll, axiosPanelsAllFetch] =
    useAxios();

  useEffect(() => {
    if (panelsAllResponse) {
      const { data } = panelsAllResponse;
      if (data && data.success) {
        const tempPanels = [...data.panels.activePanels];
        tempPanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempPanels[itemIndex].children[childIndex].sources.splice(
                    sourceIndex,
                    1,
                    updatedSourceDetails
                  );
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempPanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        const tempArchivePanels = [...data.panels.archivedPanels];
        tempArchivePanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempArchivePanels[itemIndex].children[
                    childIndex
                  ].sources.splice(sourceIndex, 1, updatedSourceDetails);
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempArchivePanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        dispatch(updatePanel(tempPanels, tempArchivePanels));
      }
      const newAddedPanel = addPanelResponse?.data?.data;
      if (!newAddedPanel.pId) {
        navigate(`/panels/${newAddedPanel.id}`);
      } else {
        navigate(`/panels/${newAddedPanel.pId}/${newAddedPanel.id}`);
      }
      setShowAddPanel(false);
    }
  }, [panelsAllResponse]);

  useEffect(() => {
    if (addPanelResponse) {
      const res = addPanelResponse;
      if (res && res.data.success) {
        axiosPanelsAllFetch({
          method: SERVER_CONFIG.HTTP_METHOD.GET,
          url: SERVER_CONFIG.API_URL.PANELS_GET,
        });
      }
    }
  }, [addPanelResponse]);
  const validateName = (name: string) => {
    if (
      selectedOptionId &&
      selectedOptionId !== "1" &&
      selectedOptionId !== "2"
    ) {
      const index = activePanels.findIndex(
        (item: Panel) => item.id === selectedOptionId
      );
      return activePanels[index].children.findIndex(
        (item: Panel) => item.name === name
      );
    }
    return activePanels.findIndex((item: Panel) => item.name === name);
  };
  const onFieldsChange = async (changedValues: Store, allValues: Store) => {
    const changedKey = Object.keys(changedValues)[0];
    console.log("Changed values", changedValues);
    // const changedPaths: NamePath[] = Object.keys(
    //   changedValues[changedKey]
    // ).map((key) => [changedKey, key]);
    // console.log('Changed paths', changedPaths);
    setTimeout(() => {
      form
        .validateFields()
        .then((values) => {
          console.log("do something you should do ONLY if validated", values);
        })
        .catch((errors) => console.error("ERRORS", errors));
    });
  };

  const pageLoader = addPanelLoading || panelsAllLoading;

  const defaultColorsValues = [
    "#FF9800",
    "#FCB900",
    "#7BDCB5",
    "#4CAF50",
    "#8ED1FC",
    "#0693E3",
    "#ABB8C3",
    "#D32F2F",
    "#F78DA7",
    "#9900EF",
    "#FFEB3B",
    "#E91E63",
    "#795548",
    "#607D8B",
  ];

  const twitterStyle = {
    default: {
      input: {
        display: "none",
      },
      hash: {
        display: "none",
      },
      body: {
        padding: "6px 0 6px 9px",
      },
    },
  };

  return showAddPanel ? (
    <Spin size="large" spinning={pageLoader}>
      <div className="show-add-panels">
        <div style={{ width: "90%", textAlign: "left" }}>
          <Form
            form={form}
            name="basic"
            onFinish={addPanel}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            // onFieldsChange={onFieldsChange}
          >
            <Row gutter={{ xs: 16 }}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name="panelName"
                  dependencies={["selectedOptionId"]}
                  rules={[
                    { required: true, message: "Please enter panel name!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          validateName(getFieldValue("panelName")) === -1
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Name already exists"));
                      },
                    }),
                  ]}
                >
                  <Input
                    size="small"
                    value={panelName}
                    placeholder="Enter Panel Name"
                    maxLength={31}
                    prefix={
                      color && (
                        <Marker color={color} fill={selectedOptionId !== "1"} />
                      )
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  style={{ marginBottom: "0px" }}
                  name="selectedOptionId"
                  rules={[
                    { required: true, message: "Please select panel type!" },
                  ]}
                >
                  <Select
                    placeholder="Select"
                    style={{ textAlign: "center" }}
                    size="small"
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <Option value={1} size="small" className="select-options">
                      <PlusSquareOutlined style={{ marginRight: "4px" }} />
                      {t<string>("addPanel.newParentGroup")}
                    </Option>
                    <Option value={2} size="small" className="select-options">
                      <PlusSquareOutlined style={{ marginRight: "4px" }} />
                      {t<string>("addPanel.addNewPanel")}
                    </Option>
                    <OptGroup label={t<string>("addPanel.asChildOf")}>
                      {activePanels
                        .filter((e: Panel) => e.isStandalone === false)
                        .map((e: Panel) => {
                          return (
                            <Option
                              value={e.id}
                              size="small"
                              className="select-options"
                              key={e.id}
                            >
                              <Marker
                                color={e.color}
                                fill={!(e.isStandalone === false)}
                              />
                              {e.name}
                            </Option>
                          );
                        })}
                    </OptGroup>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* using two Pickers GitHub Picker for short view and ChromePicker for Detailed Selection */}
            {showPicker && (
              <Row>
                <Col className="gutter-row" span={24}>
                  <div className="add-panel-color-picker-container">
                    <TwitterPicker
                      className="add-panel-chrome-picker-custom"
                      color={color}
                      colors={defaultColorsValues}
                      onChange={(updatedColor) => {
                        setColor(updatedColor.hex);
                        setShowPicker(false);
                        if (showChromePicker) {
                          setShowChromePicker(false);
                        }
                      }}
                      triangle="hide"
                      styles={twitterStyle}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {showChromePicker && (
              <Row>
                <Col className="gutter-row" span={24}>
                  <div className="add-panel-color-picker-container">
                    <ChromePicker
                      className="add-panel-chrome-picker-custom"
                      color={color}
                      onChange={(updatedColor) => {
                        setColor(updatedColor.hex);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {(showPicker || showChromePicker) && (
              <Row>
                <Col className="gutter-row color-picker-btn-container" span={4}>
                  <Button
                    // type="button"
                    size="small"
                    style={{ padding: "0px" }}
                    // className="show-crom-picker-btn"
                    type="text"
                    onClick={() => {
                      if (showChromePicker && color) {
                        setShowPicker(false);
                      }
                      setShowChromePicker(!showChromePicker);
                    }}
                  >
                    {!showChromePicker ? "Color Picker" : "Close"}
                  </Button>
                </Col>
              </Row>
            )}

            {isNeedColor && (
              <Row>
                <Col className="gutter-row" span={24}>
                  <div className="error-msg">Please select panel color!</div>
                </Col>
              </Row>
            )}

            <Row justify="end" style={{ marginTop: "20px" }}>
              <Col className="gutter-row" span={4}>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button
                    size="small"
                    // className="show-crom-picker-btn"
                    type="text"
                    onClick={() => setShowAddPanel(!showAddPanel)}
                  >
                    {t<string>("globalSettings.cancel")}
                  </Button>
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={4}>
                <Form.Item style={{ marginBottom: "0px" }}>
                  <Button size="small" type="primary" htmlType="submit">
                    Add Panel
                  </Button>
                </Form.Item>
                {/* wrapperCol={{ offset: 16, span: 8 }} */}
              </Col>
            </Row>
            {/* <Row>
              <Col className="gutter-row" span={24}>
                <div style={{ padding: "8px 0px" }}>
                  <span className="template-or">or</span>
                  <span className="template-main">
                    {t<string>("addPanel.createFromTemp")}
                  </span>
                </div>
              </Col>
            </Row> */}
          </Form>
        </div>
      </div>
    </Spin>
  ) : (
    <div> </div>
  );
}

export default AddPanel;
