import { useEffect } from "react";
import { Spin } from "antd";
// import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
// import { addDataSourceAction } from "../store/reducers/addSource/action/addDataSource-action";

const Authorized = () => {
  const [searchParams] = useSearchParams();
  // const dispatch = useDispatch();
  // const t = `${searchParams.get('code')}|~|${searchParams.get('state')}`;
  useEffect(() => {
    const obj = {
      code: searchParams.get("code"),
      state: searchParams.get("state"),
      error: null,
    };
    // dispatch(addDataSourceAction({
    //   code: searchParams.get('code'),
    //   state: searchParams.get('state'),
    // }));
    // window.localStorage.setItem('source_state', Math.random() as unknown as string);
    window.localStorage.setItem("source_state", JSON.stringify(obj));
    window.close();
  }, []);
  // useEffect(() => {
  //   // eslint-disable-next-line
  //   // @ts-ignore
  //   axiosFetch({
  //     method: SERVER_CONFIG.HTTP_METHOD.GET,
  //     url: SERVER_CONFIG.API_URL.SOURCE_PANELS_ADD_STEP_2,
  //     params: {
  //       state: searchParams.get('state'),
  //       code: searchParams.get('code'),
  //     },
  //   });
  //   // debugger; // eslint-disable-line no-debugger
  //   // window.close();
  // }, []);
  // useEffect(() => {
  //   if (addPanelDataSourceResponse) {
  //     const res = addPanelDataSourceResponse as any;
  //     if (res && res.data === 'Google Signed In') {
  //       debugger; // eslint-disable-line no-debugger
  //       window.close();
  //     }
  //   }
  // }, [addPanelDataSourceResponse]);
  return <Spin size="large" spinning />;
};

export default Authorized;
