/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Cascader,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  Radio,
  Select,
  Spin,
  Typography,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Draggable } from "react-beautiful-dnd";
// import { EditorState, ContentState, convertToRaw } from "draft-js";

import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { AxiosResponse } from "axios";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import c from "classnames";
import storeApi from "./StoreAPI";
import { ReactComponent as OptionsIcon } from "../../../../../assets/Icons/card-options.svg";
import { ReactComponent as ShowTextIcon } from "../../../../../assets/Icons/show-text.svg";
import DarkULIcon from "../../../../../assets/Icons/footer-d-ul.svg";
import DarkNumberIcon from "../../../../../assets/Icons/footer-d-number.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/Icons/delete-card.svg";
import { ReactComponent as AlertIcon } from "../../../../../assets/Icons/alert.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/Icons/check-icon.svg";
import { RootReducer } from "../../../../../store/reducers/root-reducer";
import { Marker } from "../../../marker/marker";
import {
  Card,
  CardProps,
  TaskPriority,
  TaskStatus,
  TodoList,
  TodoStatusIDItems,
  TodoStatusNameItems,
} from "../../../../../prop-types/prop-type";
import {
  Child as ChildType,
  Panel as PanelType,
} from "../../../../../interfaces/user-detail-interface";
import useAxios from "../../../../../services/axios";
import SERVER_CONFIG, { Constants } from "../../../../../utils/config";
import { updateActiveTodos } from "../../../../../store/reducers/todo/action/todo";

const { Title, Text } = Typography;
const { Option } = Select;
// JUST SHOW THE LATEST ITEM
function displayRender(label: string[]) {
  return label[label.length - 1];
}

const TaskCard = ({
  card,
  index,
  listId,
  measureHeights,
  priorityList,
  statusList,
  priorityKeys,
  statusKeys,
}: CardProps) => {
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(
    card.statusId === TodoStatusIDItems.completed
  );
  const [newTitle, setNewTitle] = useState(card.title);
  const [priorityTask, setPriorityTask] = useState<string>(card?.priority);
  const [assignedTo, setAssignedTo] = useState<Array<string | number>>([
    "None Assigned",
  ]);
  const [assignedColor, setAssignedColor] = useState("");

  const [allColors, setAllColors] = useState<Record<string, string>>({
    "None Assigned": "#000",
  });
  const [newAssignedPanelId, setNewAssignedPanelId] = useState<string | number>(
    ""
  );
  const [valueToUpdate, setValueToUpdate] = useState("");
  const [taskTxt, setTaskText] = useState(false);
  const [taskActShow, setTaskActShow] = useState(false);
  const [panelList, setPanelList] = useState([]);
  const {
    updateCardTitle,
    updateCardDescription,
    updateCardPriority,
    updateCardPanel,
    updateCardStatus,
  } = useContext(storeApi);

  const [responseDelete, loadingDelete, errorDelete, axiosDeleteFetch] =
    useAxios();
  const [responseUpdate, loadingUpdate, errorUpdate, axiosUpdateFetch] =
    useAxios();

  const removeHandle = () => {
    axiosDeleteFetch({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_DELETE(card.id),
    });
    measureHeights();
  };

  const { activePanels, archivedPanels } = useSelector(
    (state: RootReducer) => state.panels
  );
  const { activeTodos, priorityData, columnsToShow } = useSelector(
    (state: RootReducer) => state.todo
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (responseDelete && responseDelete.data?.success) {
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === listId
      );
      const tepmTodo = tempTodos[indexModifiedToDo];

      tepmTodo.children = tepmTodo.children.filter(
        (todoCard: Card) => todoCard.id !== card.id
      );
      tempTodos.splice(indexModifiedToDo, 1, tepmTodo);
      dispatch(updateActiveTodos(tempTodos));
    }
  }, [responseDelete]);

  useEffect(() => {
    const colors = { ...allColors };
    activePanels?.forEach((item: PanelType) => {
      colors[item.id] = item.color;
      if (item.id === card.panelId) {
        setAssignedTo([item.name]);
        setAssignedColor(item.color);
        return;
      }
      item?.children?.forEach((innerItem: ChildType) => {
        colors[innerItem.id] = innerItem.color;
        if (innerItem.id === card.panelId) {
          setAssignedTo([innerItem.name]);
          setAssignedColor(innerItem.color);
        }
      });
    });
    setAllColors(colors);
  }, [activePanels]);

  useEffect(() => {
    const getResult = () => {
      const finalData = activePanels?.map((item: PanelType) => {
        const obj = {
          id: item?.id,
          value: item?.id,
          label: (
            <div className="panel-item-box">
              <div>
                {assignedTo[0] === item?.name && (
                  <CheckIcon className="cascade-check" />
                )}
              </div>
              <Marker
                color={item.color}
                fill={!(item.isStandalone === false)}
              />
              <Text>{item?.name}</Text>
            </div>
          ),
          children: item?.children?.map((innerItem: ChildType) => {
            const innerObj = {
              id: innerItem?.id,
              value: innerItem?.id,
              label: (
                <div className="panel-item-box">
                  <div>
                    {assignedTo?.length > 1 &&
                      assignedTo[1] === innerItem?.name && (
                        <CheckIcon className="cascade-check" />
                      )}
                  </div>
                  <Marker
                    color={innerItem.color}
                    fill={!(innerItem.isStandalone === false)}
                  />
                  <Text>{innerItem?.name}</Text>
                </div>
              ),
            };
            return innerObj;
          }),
        };
        return obj;
      });
      const optionReset = {
        value: "None Assigned",
        id: "",
        label: (
          <div className="panel-item-box">
            <Text>None Assigned</Text>
          </div>
        ),
      };
      finalData.push(optionReset);
      setPanelList(finalData);
    };
    getResult();
  }, [activePanels, archivedPanels]);

  const getInitialState = (values: string) => {
    if (values) {
      const blocksFromHtml = htmlToDraft(values);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [editorState, setEditorState] = useState(
    getInitialState(card.description)
  );

  const updateHandler = (value: string, path: string) => {
    axiosUpdateFetch({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_UPDATE(card.id),
      data: [
        {
          value,
          path,
          op: Constants.REPLACE,
        },
      ],
    });
  };

  const priorityChangeHandler = (priorityId: string) => {
    setPriorityTask(priorityId);
    setValueToUpdate("/priorityId");
    updateHandler(priorityId, "/priorityId");
    measureHeights();
  };

  const handleOnBlur = () => {
    setValueToUpdate("/name");
    updateHandler(newTitle, "/name");
    setOpen(false);
    measureHeights();
  };

  const AssignHandler = (value?: (string | number)[]) => {
    if (value) {
      setValueToUpdate("/panelId");
      if (value.length > 1) {
        updateHandler(`${value[1]}`, "/panelId");
        setNewAssignedPanelId(value[1]);
        setAssignedTo(value);
      } else {
        updateHandler(`${value[0]}`, "/panelId");
        setNewAssignedPanelId(value[0]);
        setAssignedTo(value);
      }
    } else {
      setNewAssignedPanelId("None Assigned");
    }
    measureHeights();
  };

  useEffect(() => {
    if (!card.panelId && !valueToUpdate) {
      setAssignedTo(["None Assigned"]);
      setAssignedColor("#000");
      AssignHandler();
    }
  }, [card.panelId]);

  const getPriorityName = (priorityId: string) => {
    let priorityName = "";
    priorityData.forEach((priorityInfo: TaskPriority) => {
      if (priorityInfo.id === priorityId) {
        priorityName = priorityInfo.priorityName;
      }
    });
    return priorityName;
  };

  const getStatusName = (statusId: string) => {
    return statusId === TodoStatusIDItems.completed
      ? TodoStatusNameItems.completed
      : TodoStatusNameItems.inComplete;
  };

  useEffect(() => {
    if (responseUpdate && responseUpdate.data.success) {
      const newTodo: Card = responseUpdate.data?.data;
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === newTodo.toDoMId
      );
      const tepmTodo = tempTodos[indexModifiedToDo];

      tepmTodo.children = tepmTodo.children.map((card: Card) => {
        if (card.id === newTodo.id) {
          switch (valueToUpdate) {
            case "/panelId":
              return { ...card, panelId: newTodo.panelId };
            case "/priorityId":
              return {
                ...card,
                priorityId: newTodo.priorityId,
                priorityName: getPriorityName(newTodo.priorityId),
              };
            case "/name":
              return {
                ...card,
                name: newTodo.name,
              };
            case "/notes":
              return {
                ...card,
                notes: newTodo.notes,
              };
            case "/statusId":
              return {
                ...card,
                statusId: newTodo.statusId,
                statusName: getStatusName(newTodo.statusId),
              };
            default:
              break;
          }
        }
        return card;
      });
      tempTodos.splice(indexModifiedToDo, 1, tepmTodo);
      dispatch(updateActiveTodos(tempTodos));
      setValueToUpdate("");
    }
  }, [responseUpdate]);

  useEffect(() => {
    if (newAssignedPanelId) {
      setAssignedColor(allColors[newAssignedPanelId]);
    }
  }, [newAssignedPanelId]);

  // TASK ACTIONS
  const menu = (
    <Menu className="todo-menu">
      <Menu.Item key={2}>
        <Button
          type="text"
          onClick={() => {
            measureHeights();
            updateCardStatus(
              card?.status === "done" ? "" : "done",
              index,
              listId
            );
          }}
        >
          {card?.status === "done" ? "InComplete" : "Complete"}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const updateStatus = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);
    setValueToUpdate("/statusId");
    updateHandler(
      e.target.checked
        ? TodoStatusIDItems.completed
        : TodoStatusIDItems.inComplete,
      "/statusId"
    );
    measureHeights();
  };

  const currentContentAsHTML = draftToHtml(
    convertToRaw(editorState.getCurrentContent())
  );

  useEffect(() => {
    const getData = setTimeout(() => {
      if (card.description !== currentContentAsHTML) {
        setValueToUpdate("/notes");
        updateHandler(currentContentAsHTML, "/notes");
      }
    }, 2000);
    return () => clearTimeout(getData);
  }, [editorState]);

  const priorityClass = () => {
    if (priorityTask === priorityKeys.Normal) {
      return "p-normal";
    }
    return "p-high";
  };

  useEffect(() => {
    const colorElements = () => {
      const antdCard = document.getElementById(`card-${card.id}`);
      if (antdCard) {
        const checkboxAntdInner =
          antdCard?.getElementsByClassName("ant-checkbox-inner")[0];
        const selectionItem = antdCard?.getElementsByClassName(
          "ant-select-selection-item"
        )[1];

        if (checkboxAntdInner && assignedColor) {
          checkboxAntdInner.setAttribute(
            "style",
            `border-color:${assignedColor};`
          );
        }
        if (checkboxAntdInner && checked && assignedColor) {
          checkboxAntdInner.setAttribute(
            "style",
            `border-color:${assignedColor}`
          );
        }
        if (selectionItem) {
          selectionItem.setAttribute(
            "style",
            `color:${assignedColor};font-weight:400;`
          );
        }
      }
    };
    colorElements();
  }, [
    assignedTo,
    taskActShow,
    checked,
    assignedColor,
    activePanels,
    archivedPanels,
  ]);

  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className="card-content" id={`card-${card.id}`}>
            <div className="task-card" {...provided.dragHandleProps}>
              <div
                className={c("todo-titles", {
                  "wide-input": open,
                })}
              >
                <Checkbox
                  checked={checked}
                  onChange={updateStatus}
                  style={{
                    height: "18px",
                  }}
                />
                {open ? (
                  <Input
                    value={newTitle}
                    type="text"
                    onChange={(e) => setNewTitle(e.target.value)}
                    onBlur={handleOnBlur}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleOnBlur();
                        measureHeights();
                      }
                    }}
                    // autoFocus
                  />
                ) : (
                  <div
                    onDoubleClick={() => {
                      setOpen(true);
                      measureHeights();
                    }}
                  >
                    <Title
                      level={5}
                      onClick={() => {
                        setTaskActShow(!taskActShow);
                        setTaskText(!taskTxt);
                        measureHeights();
                      }}
                      className={`task-title task-title-text ${
                        checked
                          ? "task-title-complete"
                          : "task-title-incomplete"
                      }`}
                    >
                      {card?.title}
                    </Title>
                  </div>
                )}
              </div>
              <div className="task-actions">
                {card?.priority === priorityKeys.High ? (
                  <AlertIcon className="p-high-task" />
                ) : null}
              </div>
            </div>
            {taskTxt && (
              <div className="task-desc">
                <div className="desc-text-container">
                  <Editor
                    toolbarClassName="task-toolbar-custom"
                    wrapperClassName="task-wrapper"
                    editorClassName="task-editor-custom"
                    placeholder="Enter notes..."
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbar={{
                      options: ["list"],
                      list: {
                        options: ["ordered", "unordered"],
                        ordered: {
                          icon: DarkNumberIcon,
                          className: "order-opt",
                        },
                        unordered: {
                          icon: DarkULIcon,
                          className: "unorder-opt",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
            {taskActShow && (
              <div className="task-actionsbar">
                <div
                  className="pr-panel"
                  style={{
                    flex: 1,
                    flexDirection: `${columnsToShow > 3 ? "column" : "row"}`,
                    alignItems: `${
                      columnsToShow > 3 ? "flex-start" : "center"
                    }`,
                  }}
                >
                  {/* PRIORITY */}
                  <div className="priority-opt">
                    <Text className="priority-title">Priority:</Text>
                    <Select
                      bordered={false}
                      value={priorityTask}
                      onChange={priorityChangeHandler}
                      className={priorityClass()}
                    >
                      {priorityList?.map((item: TaskPriority) => {
                        if (item.priorityName === "Medium") {
                          return null;
                        }
                        return (
                          <Option key={item?.id} value={item?.id}>
                            {item?.priorityName}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  {/* PANEL */}
                  <div className="panel-opt">
                    <Text className="panel-title">Panel:</Text>
                    <div>
                      <Cascader
                        bordered={false}
                        options={panelList}
                        value={assignedTo}
                        onChange={AssignHandler}
                        expandTrigger="hover"
                        displayRender={displayRender}
                      />
                    </div>
                  </div>
                  <div className="delete-icon-btn">
                    <Button
                      type="text"
                      icon={<DeleteIcon />}
                      onClick={removeHandle}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* {provided.placeholder} */}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
