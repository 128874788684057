import { FC, useEffect, useMemo, useState } from "react";

import "./participants.scss";
import { useFormik } from "formik";
import { Spin } from "antd";
import * as Yup from "yup";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Input from "../reusable-components/input/input";
import { DeleteParticipantModal } from "./DeleteParticipantModal";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import URLS from "../../utils/urls";

type ParticipantsList = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  inviteStatus: string;
  primaryUserId: string;
  primaryUserName: string;
}[];

const getValidationSchema = (t: TFunction<"translation", undefined>) =>
  Yup.object({
    firstName: Yup.string().required(t<string>("participants.firstNameError")),
    lastName: Yup.string().required(t<string>("participants.lastNameError")),
    email: Yup.string()
      .required(t<string>("globalSettings.profile.validations.emailRequired"))
      .email(t<string>("globalSettings.profile.validations.emailNotValid")),
  });

enum ParticipantStatus {
  InviteSent = "Invite Sent",
  Accepted = "Accepted",
  Decline = "Decline",
}

export const InviteParticipants: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deleteUserId, setDeleteUserId] = useState("");

  const [
    listParticipantsResponse,
    listParticipantsLoading,
    listParticipantsError,
    listParticipantsFetch,
  ] = useAxios();

  const [
    addParticipantResponse,
    addParticipantLoading,
    addParticipantError,
    addParticipantFetch,
  ] = useAxios();

  const [
    deleteParticipantResponse,
    deleteParticipantLoading,
    deleteParticipantError,
    deleteParticipantFetch,
  ] = useAxios();

  const [
    resendParticipantInviteResponse,
    resendParticipantInviteLoading,
    resendParticipantInviteError,
    resendParticipantInviteFetch,
  ] = useAxios();

  const handleSubmitForm = async (values: {
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    await addParticipantFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: URLS.COMMON_APIS.ADD_PARTICIPANT,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: getValidationSchema(t),
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const loadParticipantsList = () => {
    if (!listParticipantsLoading) {
      listParticipantsFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: URLS.COMMON_APIS.PARTICIPANTS_LIST,
      });
    }
  };

  useEffect(() => {
    loadParticipantsList();
  }, []);

  useEffect(() => {
    if (addParticipantResponse && !addParticipantLoading) {
      formik.resetForm();
      loadParticipantsList();
    }
    if (deleteParticipantResponse) {
      setDeleteUserId("");
      loadParticipantsList();
    }
    if (resendParticipantInviteResponse) {
      loadParticipantsList();
    }
  }, [
    addParticipantResponse,
    deleteParticipantResponse,
    resendParticipantInviteResponse,
  ]);

  const handleReturn = () => {
    navigate("/panels");
  };

  const deleteInviteMail = (id: string) => {
    setDeleteUserId(id);
  };

  const handleDeleteParticipant = async (id: string) => {
    await deleteParticipantFetch({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: URLS.DELETE_PARTICIPANT_BY_ID(id),
    });
  };

  const resendInviteMail = async (id: string) => {
    await resendParticipantInviteFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: URLS.RESEND_PARTICIPANT_INVITATION(id),
    });
  };

  const list: ParticipantsList = useMemo(() => {
    return listParticipantsResponse?.data?.data
      ? listParticipantsResponse.data.data
      : [];
  }, [listParticipantsResponse]);

  const isDataLoading =
    listParticipantsLoading ||
    addParticipantLoading ||
    deleteParticipantLoading ||
    resendParticipantInviteLoading;

  return (
    <div>
      <h2 className="collab-title collab-16px">
        {t<string>("participants.addParticipants")}
      </h2>

      <p className="collab-text">{t<string>("participants.whenYouAddUser")}</p>

      <div>
        {list.length >= 5 ? (
          <div className="collab-text mb-20px">
            {t<string>("participants.youAreLimitedToFiveParticipants")}
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="collab-input-container">
              <div className="input-row">
                <Input
                  label="Participant’s First Name"
                  required
                  id="firstName"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={formik.touched.firstName && formik.errors.firstName}
                  className="grow collab-text-left"
                />

                <Input
                  label="Participant’s Last Name"
                  required
                  id="lastName"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && formik.errors.lastName}
                  className="grow collab-text-left"
                />
              </div>

              <div className="collab-input">
                <Input
                  label="Participant’s Email"
                  required
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email}
                  className="grow collab-text-left"
                />
              </div>
            </div>

            <div className="collab-text-right mb-20px">
              <button className="collab-send-button" type="submit">
                {t<string>("participants.addParticipant")}
              </button>
            </div>
          </form>
        )}

        <div className="collab-input-container collab-border mb-20px">
          <h3 className="collab-invitedlist-title">
            {t<string>("participants.existingParticipants")}
          </h3>

          <div className="collab-invited-list-container">
            <Spin size="large" spinning={isDataLoading}>
              <ul className="item-list">
                {list &&
                  list.map(({ id, email, inviteStatus }) => (
                    <li key={id} className="collab-account-item">
                      <div className="collab-invitedlist-item mail-data">
                        <div className="truncate" title={email}>
                          {`${email} `}
                        </div>

                        <div
                          className="x-delete"
                          onClick={() => deleteInviteMail(id)}
                        >
                          {t<string>("globalSettings.dataSources.deleteText")}
                        </div>
                      </div>

                      <div className="collab-invitedlist-item">
                        {inviteStatus === ParticipantStatus.InviteSent
                          ? t<string>("participants.inviteSent")
                          : `${inviteStatus}`}
                      </div>

                      <div className="collab-invitedlist-item">
                        {inviteStatus !== ParticipantStatus.Accepted ? (
                          <button
                            type="button"
                            className="collab-send-button"
                            onClick={() => resendInviteMail(id)}
                          >
                            {t<string>("participants.resendInvite")}
                          </button>
                        ) : (
                          <div />
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </Spin>
          </div>
        </div>

        <div className="collab-text-right mb-20px">
          <button
            className="collab-send-button"
            type="button"
            onClick={handleReturn}
          >
            {t<string>("participants.returnToPanels")}
          </button>
        </div>
      </div>

      {deleteUserId && (
        <DeleteParticipantModal
          open={!!deleteUserId}
          onClose={() => setDeleteUserId("")}
          onSubmit={() => {
            handleDeleteParticipant(deleteUserId);
          }}
        />
      )}
    </div>
  );
};
