import { Quill } from "react-quill";
// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly

// Redo button icon component for Quill editor
const CustomSend = () => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      d="M12.4908 5.55476L1.49081 0.054759C1.4046 0.0116424 1.30776 -0.0056335 1.21195 0.00501157C1.11614 0.0156566 1.02546 0.0537688 0.950811 0.114759C0.879523 0.174505 0.826314 0.252952 0.797166 0.341281C0.768018 0.42961 0.764085 0.524318 0.785811 0.614759L2.26581 5.99976L0.765811 11.3698C0.745425 11.4453 0.743045 11.5245 0.758863 11.6012C0.774682 11.6778 0.808258 11.7496 0.856891 11.8109C0.905524 11.8722 0.967857 11.9212 1.03888 11.954C1.1099 11.9868 1.18763 12.0025 1.26581 11.9998C1.34408 11.9993 1.42115 11.9805 1.49081 11.9448L12.4908 6.44476C12.5727 6.4028 12.6414 6.33905 12.6894 6.26054C12.7374 6.18202 12.7628 6.09178 12.7628 5.99976C12.7628 5.90774 12.7374 5.8175 12.6894 5.73898C12.6414 5.66047 12.5727 5.59672 12.4908 5.55476ZM2.04081 10.5548L3.14581 6.49976H7.76581V5.49976H3.14581L2.04081 1.44476L11.1458 5.99976L2.04081 10.5548Z"
      fill="#676567"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  //   quill.history.undo();
}
function redoChange() {
  //   quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "Inter",
  "lucida",
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = (props: any) => {
  return {
    toolbar: {
      container: `#${props}`,
      handlers: {
        undo: undoChange,
        redo: redoChange,
      },
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
  };
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  // 'link',
  // 'image',
  // 'video',
  "background",
  "list",
  "bullet",
  "indent",
  "color",
  // 'code-block',
];
export const QuillToolbar = ({ toolbarId, sendMessage }: any) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {toolbarId !== undefined && (
        <div id={toolbarId} className="ql-toolbar ql-snow">
          <div className="slack-message-quill-text" />
          <div>
            <button
              title="Send"
              type="button"
              className="ql-send"
              onClick={sendMessage}
            >
              <CustomSend />
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default QuillToolbar;
