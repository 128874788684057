import {
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { PaperClipOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { OutlookMailBody } from "../../../prop-types/prop-type";
import "../data-sources.scss";
import { PanelSettings } from "../../../interfaces/user-detail-interface";
import { DEFAULT_FONT_SIZE } from "../../../utils/constants";
import {
  resetEmailDeleteAction,
  setEmailDeleteAction,
} from "../../../store/reducers/email-delete/actions/email-delete-action";
import { RootReducer } from "../../../store/reducers/root-reducer";
import icons from "../../../assets/icon-export-assets";
import useAxios from "../../../services/axios";
import SERVER_CONFIG from "../../../utils/config";

import "./email-template.scss";
import { useGetPanelsData } from "../../../utils/hooks/useGetPanelsData";

dayjs.extend(weekOfYear);

const measureText = (pFontSize: string) => {
  let lDiv: HTMLDivElement | null = document.createElement("p");

  document.body.appendChild(lDiv);

  lDiv.style.fontSize = `${pFontSize}`;
  lDiv.style.fontFamily = `'Open Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif`;
  lDiv.style.position = "absolute";
  lDiv.style.left = `-1000px`;
  lDiv.style.top = "-1000px";

  lDiv.textContent = "pText";

  const lResult = {
    height: lDiv.clientHeight,
  };

  document.body.removeChild(lDiv);
  lDiv = null;

  return lResult.height;
};

export type MailTemplateProps = {
  data: OutlookMailBody;
  panelSettings: PanelSettings;
};

const MailTemplate: FC<PropsWithChildren<MailTemplateProps>> = ({
  data,
  panelSettings = {},
}) => {
  const resolvedFontSize = `${panelSettings.fontSize}px`;
  const resolvedTitlesFontSize = `${
    (panelSettings.fontSize || DEFAULT_FONT_SIZE) - 1
  }px`;

  const [isClickedMore, setIsClickedMore] = useState(false);

  const standardViewLines = isMobile
    ? panelSettings.mobileStdViewLinesPerEmail
    : panelSettings.desktopStdViewLinesPerEmail;
  const expandedViewLines = isMobile
    ? panelSettings.mobileExpViewLinesPerEmail
    : panelSettings.desktopExpViewLinesPerEmail;

  const lines = standardViewLines || 3;
  const maxLines = expandedViewLines || 7;
  const lineHeight = measureText(resolvedFontSize);

  const handleClick = () => setIsClickedMore((prev) => !prev);
  const handleDoubleClick = () => window.open(data?.webLink, "_blank");

  const handleClickEvent: MouseEventHandler<HTMLDivElement> = (event) => {
    switch (event.detail) {
      case 1: {
        handleClick();
        break;
      }
      case 2: {
        handleDoubleClick();
        break;
      }

      default: {
        break;
      }
    }
  };

  const dateJS = dayjs(data.emailDate);
  const todayWeek = dayjs().week();
  const weekOfLetter = dayjs(dateJS).week();

  const isSameWeek = todayWeek === weekOfLetter;

  const formattedLetterDate = isSameWeek
    ? dayjs(dateJS).format("ddd hh:mm A")
    : dayjs(dateJS).format("MM/DD/YY");

  const dispatch = useDispatch<any>();

  const location = useLocation();

  const isFilterPage = location.pathname.startsWith("/filters/");

  const { panelsDataResponse, loadPanelsData, panelsDataLoading } =
    useGetPanelsData();

  const { trashBinIcon } = icons;

  const { emailIdList, panelId: mailsPanelId } = useSelector(
    (state: RootReducer) => state.emailDelete
  );

  const isEmailSelected = emailIdList.includes(data.id);

  const handleSelectEmail = (id: string, panelId: string) => {
    if (isFilterPage) {
      return;
    }

    if (mailsPanelId !== panelId) {
      dispatch(setEmailDeleteAction({ panelId, emailIdList: [id] }));
    } else {
      const uniqMails = emailIdList.includes(id)
        ? emailIdList.filter((mailId: string) => mailId !== id)
        : [...emailIdList, id];

      dispatch(
        setEmailDeleteAction({
          panelId,
          emailIdList: uniqMails,
        })
      );
    }
  };

  const [deleteMailResponse, deleteEmailLoading, deleteEmailError, axiosFetch] =
    useAxios();

  const isDeletingMail = deleteEmailLoading || panelsDataLoading;

  const deletePanelMails = () => {
    if (isDeletingMail) {
      return;
    }

    axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.DELETE_PANEL_EMAILS(mailsPanelId),
      data: emailIdList.map((id) => ({ id })),
    });
  };

  useEffect(() => {
    if (deleteMailResponse) {
      loadPanelsData();
    }
  }, [deleteMailResponse, loadPanelsData, deleteEmailError]);

  useEffect(() => {
    if (panelsDataResponse) {
      dispatch(resetEmailDeleteAction());
    }
  }, [deleteMailResponse]);

  return data?.id ? (
    <Spin size="small" spinning={isDeletingMail}>
      <div key={data?.id} className="template-mail-container">
        <div
          className={classNames(isEmailSelected && "selected-bg")}
          onClick={() => handleSelectEmail(data.id, data.panelId)}
        >
          <div
            className="template-mail-header"
            style={{
              fontSize: resolvedTitlesFontSize,
            }}
          >
            <span
              className="template-mail-from-data"
              title={panelSettings.showEmailFrom ? data?.fromName : ""}
            >
              {panelSettings.showEmailFrom ? data?.fromName : " "}
            </span>
            <div className="template-mail-date">
              {isEmailSelected || isDeletingMail ? (
                <button
                  type="button"
                  className="delete-mail-icon"
                  onClick={deletePanelMails}
                >
                  <img src={trashBinIcon} alt="delete-icon" />
                </button>
              ) : (
                panelSettings.showEmailDate && `${formattedLetterDate}`
              )}
            </div>
          </div>
          <div className="template-mail-header template-mail-header-second">
            <span
              className="email-subject-span"
              style={{
                fontSize: resolvedTitlesFontSize,
              }}
            >
              {panelSettings.showEmailSubject ? data?.subject : " "}
            </span>
            {data?.attachments !== "False" &&
            panelSettings.showEmailAttachments ? (
              <span>
                <PaperClipOutlined />
              </span>
            ) : (
              <div />
            )}
          </div>
        </div>
        <p
          onClick={handleClickEvent}
          className="template-mail-body"
          style={{
            fontSize: resolvedFontSize,
            WebkitLineClamp: isClickedMore
              ? expandedViewLines
              : standardViewLines,
            maxHeight: isClickedMore
              ? `${maxLines * lineHeight}px`
              : `${lines * lineHeight}px`,
          }}
        >
          {data?.emailBody?.replace(/(\r\n|\n|\r)/gm, "")}
        </p>
      </div>
    </Spin>
  ) : (
    <div />
  );
};

export default MailTemplate;
