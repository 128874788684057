import { Dispatch } from "redux";

export const updateLoading = (item: any, id: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateLoading",
      payload: { id, item },
    });
  };
};

export const updateWorkspaceList = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateWorkspaceList",
      payload: item,
    });
  };
};

export const updateChannelList = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateChannelList",
      payload: item,
    });
  };
};

export const updateDMList = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateDMList",
      payload: item,
    });
  };
};

export const updateOneDriveList = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateOneDriveList",
      payload: item,
    });
  };
};
export const updateDriveList = (item: any, key: string, id: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateDriveList",
      payload: { item, key, id },
    });
  };
};
export const updateOneDriveLoading = (key: any, data: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateOneDriveLoading",
      payload: { data, key },
    });
  };
};

export const updateGDriveList = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateGDriveList",
      payload: item,
    });
  };
};

export const updateGDriveLoading = (item: any, id: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateGDriveLoading",
      payload: { id, item },
    });
  };
};

export const addEditSouceCheck = (data: boolean) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "addEditSouceCheck",
      payload: data,
    });
  };
};
