/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-extraneous-dependencies */
import "yup-phone";
import * as Yup from "yup";
import jwt_decode from "jwt-decode";
import { Spin } from "antd";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { usersApi } from "../../store/api";
import icons from "../../assets/icon-export-assets";
import { HeaderSubscription } from "./header-subscription";
import "./subscription-page.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";

const phoneSchema = Yup.string().phone().required();

const SubscriptionConfirmation = () => {
  const params = useParams();
  const { token = "" } = params;
  const navigate = useNavigate();
  const { errorCheck, successCheck } = icons;

  type DecodeToken = {
    panelUserId: string;
    userEmail: string;
    verificationCode?: string;
    phoneNumber?: string;
  };

  const decoded: DecodeToken = jwt_decode(token, { header: true });
  const isNewUser = !!decoded.userEmail && !!decoded.phoneNumber;

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPass: "",
      phone: decoded.phoneNumber || "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  let isLowerCaseInPass = false;
  let isUpperCaseInPass = false;
  let isNumberInPass = false;
  let isSpecialInPass = false;
  const isCharactersInPass = formik.values.password.length >= 12;

  const isUpperCase = (string: string) => /^[A-Z]/.test(string);
  const isLowerCase = (string: string) => /^[a-z]/.test(string);
  const isNumberChar = (string: string) => /^[0-9]/.test(string);
  const isSpecialChar = (string: string) =>
    /[-!$%^&*()_+|~=`{}[\]:/;<>?,.@#]/.test(string);

  for (let i = 0; i < formik.values.password.length; i += 1) {
    if (!isUpperCaseInPass && isUpperCase(formik.values.password[i])) {
      isUpperCaseInPass = true;
    }
    if (!isLowerCaseInPass && isLowerCase(formik.values.password[i])) {
      isLowerCaseInPass = true;
    }
    if (!isNumberInPass && isNumberChar(formik.values.password[i])) {
      isNumberInPass = true;
    }
    if (!isSpecialInPass && isSpecialChar(formik.values.password[i])) {
      isSpecialInPass = true;
    }
  }
  const isAllChecked =
    isLowerCaseInPass &&
    isUpperCaseInPass &&
    isNumberInPass &&
    isSpecialInPass &&
    isCharactersInPass;

  const checks = [
    {
      label: "lowercase letter",
      value: isLowerCaseInPass,
    },
    {
      label: "uppercase character",
      value: isUpperCaseInPass,
    },
    {
      label: "number",
      value: isNumberInPass,
    },
    {
      label: "special character",
      value: isSpecialInPass,
    },
    {
      label: "12 characters or more",
      value: isCharactersInPass,
    },
  ];

  const isPassMatch = formik.values.password === formik.values.confirmPass;
  const isPassLengthMatch =
    isAllChecked &&
    formik.values.confirmPass.length >= formik.values.password.length;

  const firstWrongRule = checks.find((rule) => rule.value === false);

  const { useConfirmPasswordMutation } = usersApi;
  const [confirmPassword, _user] = useConfirmPasswordMutation();

  const [resetResponse, loadingReset, errorReset, axiosResetFetch] = useAxios();

  const handleConfirmPassword: React.FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();

    if (isNewUser) {
      try {
        await confirmPassword({
          password: formik.values.password,
          confirmPassword: formik.values.confirmPass,
          phoneNumber: formik.values.phone,
          token,
        }).unwrap();
        toast("Your password was updated.");
        navigate(`/subscription/sms-confirm/${token}`);
      } catch (e) {
        toast.error(`${(e as { data: { message: string } })?.data?.message}`);
      }
    } else {
      try {
        const payload = {
          password: formik.values.password,
          confirmPassword: formik.values.confirmPass,
        };
        axiosResetFetch({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: `${SERVER_CONFIG.API_URL.COMMON_APIS.RESET_PASSWORD_CONFIRM}/${token}`,
          data: payload,
        });
        navigate("/reset-password-confirm");
      } catch (e) {
        toast.error(`${(e as { data: { message: string } })?.data?.message}`);
      }
    }
  };

  return (
    <Spin size="large" spinning={_user.isLoading || loadingReset}>
      <HeaderSubscription />

      <div className="confirm-container">
        <h1 className="text-left">Create Password</h1>

        <form onSubmit={handleConfirmPassword}>
          <div>
            <p>Please enter a strong password of at least 12 characters.</p>
            <div>
              <label htmlFor="password" className="form-label">
                Password
                <input
                  id="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </label>

              <div className="text-[10px] font-normal leading-[13.62px]">
                <div className="flex-space">
                  {firstWrongRule ? (
                    <>
                      <div
                        className={`${
                          !isAllChecked && "subscription-input-text-error"
                        }`}
                      >
                        Password must include:
                      </div>
                      <div className="flex-space">
                        <img src={errorCheck} alt="error" />
                        <span className="subscription-input-text-error">
                          {firstWrongRule.label}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="flex-space">
                      <img src={successCheck} alt="all good" />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="subscription-data__space">
              <label htmlFor="confirmPass" className="form-label">
                Confirm Password
                <input
                  id="confirmPass"
                  type="password"
                  value={formik.values.confirmPass}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </label>

              <div className="text-[10px] font-normal leading-[13.62px] absolute">
                {isPassLengthMatch && (
                  <div className="flex-space">
                    <img
                      src={isPassMatch ? successCheck : errorCheck}
                      alt="error"
                    />
                    <span
                      className={`${
                        isPassMatch
                          ? "text-[#0B9E11]"
                          : "subscription-input-text-error"
                      }`}
                    >
                      {isPassMatch
                        ? "Your passwords match!"
                        : "Your passwords not correct!"}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {isNewUser && (
            <div className="max-w-[633px] mx-auto mt-[53px]">
              <h2>
                Enter Phone Number to Receive <br /> a One-time Code for Secure
                Authorization
              </h2>
              <p className="text-[17px] font-normal leading-[30px]">
                By submitting your number you agree to receive SMS messages via
                your phone. Your carrier’s messaging and data rates may apply.
              </p>
              <div>
                <label htmlFor="phone" className="form-label">
                  <input
                    id="phone"
                    type="tel"
                    placeholder="+1 201 555-0123"
                    className="md:w-[528px] w-[300px] border-[1px] border-grey rounded-md p-[10px] flex-1 text-[14px] shadow-sm max-h-[43px] mb-2"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </label>
                <div className="subscription-input-text-error">
                  {formik.touched.phone &&
                  !phoneSchema.isValidSync(formik.values.phone)
                    ? "Enter your phone number"
                    : ""}
                </div>
              </div>
            </div>
          )}

          <div className="max-w-[633px] mx-auto mb-[47px] flex justify-center sm:justify-start">
            <div className="form-button-width">
              <button
                type="submit"
                className="form-button"
                disabled={!isPassMatch || _user.isLoading || loadingReset}
              >
                <span>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </Spin>
  );
};

export default SubscriptionConfirmation;
