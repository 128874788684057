import c from "classnames";
import {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  PropsWithChildren,
} from "react";

type SubscriptionInputProps = {
  id: string;
  value?: string;
  label: string;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  inputClassName?: string;
  placeholder?: string;
  handleBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  inputRef?: any;
  disabled?: boolean;
};
const SubscriptionInput: FC<PropsWithChildren<SubscriptionInputProps>> = ({
  handleChange,
  id,
  value,
  label,
  className,
  inputClassName,
  placeholder,
  handleBlur,
  error,
  inputRef,
  disabled,
}) => {
  const basicStyles = c("subscription-input-basic", inputClassName);

  const choseInputType = (id: string) => {
    if (id === "zipCode") {
      return "number";
    }
    if (id === "phone") {
      return "tel";
    }
    if (id === "password" || id === "confirmPass") {
      return "password";
    }
    return "text";
  };

  const basicInput = (
    <input
      id={id}
      type={choseInputType(id)}
      className={basicStyles}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      onBlur={handleBlur}
      ref={inputRef || null}
      disabled={disabled}
    />
  );

  return (
    <div className={c("subscription-input", className)}>
      <label htmlFor={id} className="subscription-input-label">
        {label}
      </label>
      {basicInput}
      {!!error && <p className="subscription-input-text-error">{error}</p>}
    </div>
  );
};

export { SubscriptionInput };
