import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import i18n from "i18next";
import "./app.scss";
import { RootReducer } from "./store/reducers/root-reducer";
import Landing from "./landing";
import Login from "./components/login";
import ProtectedRoute from "./layout/main-layout/protected-route";
import Authorized from "./components/authorized";
import LoggedInLayout, {
  ActivePanelComp,
  ArchivedPanelComp,
} from "./components/logged-in-layout/logged-in-layout";
import MainFilter from "./components/filters/email-filter";
import PathError from "./components/error-page/path-error";
import GlobalSettings from "./components/settings/settings";
import SubscriptionStartTrail from "./components/user-signin/subscription-start-trial";
import SubscriptionCheckMailLink from "./components/user-signin/check-email";
import SubscriptionConfirmation from "./components/user-signin/create-password";
import SubscriptionSmsConfirm from "./components/user-signin/sms-confirm";
import SubscriptionResetPassword from "./components/user-signin/reset-password";
import SubscriptionResetCheck from "./components/user-signin/reset-check";
import SubscriptionEnterConfirm from "./components/user-signin/enter-confirm";
import SubscriptionResetPasswordConfirm from "./components/user-signin/reset-pass-confirm";
import AllNotifications from "./components/notifications/all-natifications";
import HelpPage from "./components/help-page/help-page";
import SubscriptionPage from "./components/user-signin/subscription-page";
import { Participants } from "./components/participants/Participants";
import SubscriptionParticipant from "./components/user-signin/subscription-participant";
import SubscriptionDecline from "./components/user-signin/subscription-decline";

const App = () => {
  /* Settings panels styles, language and other properties based on users info received from backend */
  const { language } = useSelector(
    (state: RootReducer) => state.user.globalSettings.languageTimeZone
  );
  const { zoom } = useSelector(
    (state: RootReducer) => state.user.globalSettings.accessibility
  );
  const root = document.documentElement;
  root.style.setProperty("--primary-theme-color", "rgb(32, 111, 168)");
  root.style.setProperty(
    "--text-font-size",
    `${Math.ceil((zoom / 100) * 14)}px`
  );
  root.style.setProperty(
    "--heading-font-size",
    `${Math.ceil((zoom / 100) * 18)}px`
  );

  i18n.changeLanguage(language);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Landing />} />
        <Route path="login" element={<Login />} />
        <Route
          path="enter-confirm/:token"
          element={<SubscriptionEnterConfirm />}
        />
        <Route
          path="reset-password-confirm"
          element={<SubscriptionResetPasswordConfirm />}
        />
        <Route path="subscription">
          <Route index element={<SubscriptionPage />} />
          <Route path="check-email" element={<SubscriptionCheckMailLink />} />
          <Route
            path="createPassword/:token"
            element={<SubscriptionConfirmation />}
          />
          <Route
            path="sms-confirm/:token"
            element={<SubscriptionSmsConfirm />}
          />
          <Route
            path="password-reset"
            element={<SubscriptionResetPassword />}
          />
          <Route path="check-mail-reset" element={<SubscriptionResetCheck />} />
          <Route path="start" element={<SubscriptionStartTrail />} />
          <Route path="start/premium" element={<SubscriptionStartTrail />} />
          <Route path="premium" element={<SubscriptionPage />} />
          <Route path="participant">
            <Route index element={<SubscriptionParticipant />} />
            <Route path="decline" element={<SubscriptionDecline />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="globalsettings/:id" element={<GlobalSettings />} />
          <Route path="globalsettings" element={<GlobalSettings />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="authorized" element={<Authorized />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="panels" element={<LoggedInLayout />}>
            <Route path=":panelId" element={<ActivePanelComp />} />
            <Route
              path=":panelId/:subpanelId/settings/:source"
              element={<GlobalSettings />}
            />
            <Route
              path=":panelId/:subpanelId/add-source"
              element={<ActivePanelComp />}
            />

            <Route
              path=":panelId/settings/:source"
              element={<GlobalSettings />}
            />
            <Route path=":panelId/add-source" element={<ActivePanelComp />} />
            <Route path=":panelId/:subpanelId" element={<ActivePanelComp />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="apanels" element={<LoggedInLayout />}>
            <Route path=":panelId" element={<ArchivedPanelComp />} />
            <Route
              path=":panelId/:subpanelId"
              element={<ArchivedPanelComp />}
            />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="filters" element={<LoggedInLayout />}>
            <Route path=":panelId" element={<MainFilter />} />
            <Route path=":panelId/:subpanelId" element={<MainFilter />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="notifications" element={<LoggedInLayout />}>
            <Route path="all" element={<AllNotifications />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="help" element={<LoggedInLayout />}>
            <Route path="faq" element={<HelpPage />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="participants" element={<LoggedInLayout />}>
            <Route path="upgrade" element={<Participants />} />
            <Route path="invite" element={<Participants />} />
            <Route path="success" element={<Participants />} />
          </Route>
        </Route>
        <Route path="pathError" element={<PathError />} />
      </>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
