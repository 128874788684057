import { RadioChangeEvent } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_FONT_SIZE } from "../../utils/constants";

export type DefaultSettings = {
  desktopStdViewLinesPerEmail: number;
  desktopExpViewLinesPerEmail: number;
  desktopMaxEmailViewedPerPanel: number;
  desktopOrganisedBy: string;
  mobileStdViewLinesPerEmail: number;
  mobileExpViewLinesPerEmail: number;
  mobileMaxEmailViewedPerPanel: number;
  mobileOrganisedBy: string;
  autoDeleteOlderEmails: string;
  maxEmailCapacityPerPanel: number;
  showEmailFrom: boolean;
  showEmailDate: boolean;
  showEmailSubject: boolean;
  showEmailAttachments: boolean;
  fontSize: number;
};

export type Option = {
  value: string | number;
  label: string;
};

const linesDisplayedPerEmailMessageDesktop: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const linesDisplayedPerEmailMessageDesktopDefaultValue = 3;

const linesDisplayedPerEmailMessageMobile: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const linesDisplayedPerEmailMessageMobileDefaultValue = 1;

const linesDisplayedPerEmailMessageExpandedDesktop: Option[] = new Array(21)
  .fill(0)
  .map((_, i) => ({ label: `${i + 2}`, value: i + 2 }));
const linesDisplayedPerEmailMessageExpandedDesktopDefaultValue = 16;

const linesDisplayedPerEmailMessageExpandedMobile: Option[] = new Array(21)
  .fill(0)
  .map((_, i) => ({ label: `${i + 2}`, value: i + 2 }));
const linesDisplayedPerEmailMessageExpandedMobileDefaultValue = 12;

const maxEmailsPerPanelAtATimeDesktop: Option[] = new Array(40)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxEmailsPerPanelAtATimeDesktopDefaultValue = 6;

const maxEmailsPerPanelAtATimeMobile: Option[] = new Array(20)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxEmailsPerPanelAtATimeMobileDefaultValue = 6;

const maxEmailCapacityPerPanel: Option[] = new Array(970)
  .fill(0)
  .map((_, i) => ({ label: `${i + 30}`, value: i + 30 }));
const maxEmailCapacityPerPanelDefaultValue = 300;

const fontSizes: Option[] = new Array(12)
  .fill(0)
  .map((_, i) => ({ label: `${i + 6}`, value: i + 6 }));
const fontSizeDefaultValue = DEFAULT_FONT_SIZE;

const useEmailSettings = () => {
  const { t } = useTranslation();
  const [standardDesktopView, setStandardDesktopView] = useState<
    Option["value"]
  >(linesDisplayedPerEmailMessageDesktopDefaultValue);
  const [expandedDesktopView, setExpandedDesktopView] = useState<
    Option["value"]
  >(linesDisplayedPerEmailMessageExpandedDesktopDefaultValue);
  const [maxEmailsPerPanelDesktop, setMaxEmailsPerPanelDesktop] = useState<
    Option["value"]
  >(maxEmailsPerPanelAtATimeDesktopDefaultValue);
  const [organizeByDesktop, setOrganizeByDesktop] =
    useState<Option["value"]>("date");

  const [standardMobileView, setStandardMobileView] = useState<Option["value"]>(
    linesDisplayedPerEmailMessageMobileDefaultValue
  );
  const [expandedMobileView, setExpandedMobileView] = useState<Option["value"]>(
    linesDisplayedPerEmailMessageExpandedMobileDefaultValue
  );
  const [maxEmailsPerPanelMobile, setMaxEmailsPerPanelMobile] = useState<
    Option["value"]
  >(maxEmailsPerPanelAtATimeMobileDefaultValue);
  const [organizeByMobile, setOrganizeByMobile] =
    useState<Option["value"]>("date");

  const [autoDelete, setAutoDelete] = useState<Option["value"]>("yes");
  const [maxEmailCapacity, setMaxEmailCapacity] = useState<Option["value"]>(
    maxEmailCapacityPerPanelDefaultValue
  );
  const [show, setShow] = useState<CheckboxValueType[]>([
    "from",
    "subject",
    "date",
    "attachment",
  ]);
  const [fontSize, setFontSize] =
    useState<Option["value"]>(fontSizeDefaultValue);

  const radioOptions = [
    {
      value: "date",
      label: t<string>("globalSettings.email0365.date"),
    },
    {
      value: "from",
      label: t<string>("globalSettings.email0365.from"),
    },
  ];

  const radioOptionsAutoDelete = [
    {
      value: "yes",
      label: t<string>("globalSettings.email0365.yes"),
    },
    {
      value: "no",
      label: t<string>("globalSettings.email0365.no"),
    },
  ];

  const checkboxValuesShow = [
    {
      value: "from",
      label: t<string>("globalSettings.email0365.from"),
    },
    {
      value: "subject",
      label: t<string>("globalSettings.email0365.subject"),
    },
    {
      value: "date",
      label: t<string>("globalSettings.email0365.date"),
    },
    {
      value: "attachment",
      label: t<string>("globalSettings.email0365.attachment"),
    },
  ];

  return {
    linesPerEmailStandardDesktop: {
      options: linesDisplayedPerEmailMessageDesktop,
      value: standardDesktopView,
      defaultValue: linesDisplayedPerEmailMessageDesktopDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setStandardDesktopView(newValue);
      },
    },
    linesPerEmailStandardMobile: {
      options: linesDisplayedPerEmailMessageMobile,
      value: standardMobileView,
      defaultValue: linesDisplayedPerEmailMessageMobileDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setStandardMobileView(newValue);
      },
    },
    linesPerEmailExpandedDesktop: {
      options: linesDisplayedPerEmailMessageExpandedDesktop,
      value: expandedDesktopView,
      defaultValue: linesDisplayedPerEmailMessageExpandedDesktopDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setExpandedDesktopView(newValue);
      },
    },
    linesPerEmailExpandedMobile: {
      options: linesDisplayedPerEmailMessageExpandedMobile,
      value: expandedMobileView,
      defaultValue: linesDisplayedPerEmailMessageExpandedMobileDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setExpandedMobileView(newValue);
      },
    },
    maxEmailsPerPanelDesktop: {
      options: maxEmailsPerPanelAtATimeDesktop,
      value: maxEmailsPerPanelDesktop,
      defaultValue: maxEmailsPerPanelAtATimeDesktopDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setMaxEmailsPerPanelDesktop(newValue);
      },
    },
    maxEmailsPerPanelMobile: {
      options: maxEmailsPerPanelAtATimeMobile,
      value: maxEmailsPerPanelMobile,
      defaultValue: maxEmailsPerPanelAtATimeMobileDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setMaxEmailsPerPanelMobile(newValue);
      },
    },
    organizeByDesktop: {
      onChange: (v: Partial<RadioChangeEvent>) => {
        setOrganizeByDesktop(v?.target?.value);
      },
      value: organizeByDesktop,
      options: radioOptions,
    },
    organizeByMobile: {
      onChange: (v: Partial<RadioChangeEvent>) => {
        setOrganizeByMobile(v?.target?.value);
      },
      value: organizeByMobile,
      options: radioOptions,
    },
    autoDeleteEmails: {
      onChange: (v: Partial<RadioChangeEvent>) => {
        setAutoDelete(v?.target?.value);
      },
      value: autoDelete,
      options: radioOptionsAutoDelete,
    },
    maxEmailCapacity: {
      options: maxEmailCapacityPerPanel,
      value: maxEmailCapacity,
      defaultValue: maxEmailCapacityPerPanelDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setMaxEmailCapacity(newValue);
      },
    },
    display: {
      onChange: (v: CheckboxValueType[]) => {
        setShow(v);
      },
      value: show,
      options: checkboxValuesShow,
    },
    fontSizes: {
      options: fontSizes,
      value: fontSize,
      defaultValue: fontSizeDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setFontSize(newValue);
      },
    },
  };
};

export default useEmailSettings;
