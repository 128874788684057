import { SettingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./settings.scss";

function GlobalSettingsPanel({ children }: any) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const panelHeaderContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <span className="PanelName">{t<string>("globalSettings.title")}</span>
      </div>
      <div className="PanelHeaderSubContainer2">
        <button type="button" className="headerButtonItems" title="Settings">
          <SettingOutlined role="button" />
        </button>
        <button
          type="button"
          className="headerButtonItems"
          title="Help Center"
          onClick={() => navigate("/help/faq")}
        >
          <QuestionCircleOutlined role="button" />
        </button>
      </div>
    </div>
  );
  return (
    <div className="Global-Settings-Panel-Container">
      <div>{panelHeaderContent}</div>
      {children}
    </div>
  );
}

export default GlobalSettingsPanel;
