/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-useless-fragment */
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import "../settings/reset-to-default-modal.scss";

type SaveFilter = {
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
};

export const SaveFilterModal = ({ open, onClose, onSubmit }: SaveFilter) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onCancel={onClose} footer={null} closeIcon={<></>}>
      <h5 className="reset-modal-heading">
        {t<string>("emailFilterDetails.rememberSave")}
      </h5>
      <div className="reset-modal-button-container">
        <Button className="reset-modal-button retain" onClick={onClose}>
          {t<string>("emailFilterDetails.cancel")}
        </Button>
        <Button className="reset-modal-button reset" onClick={onSubmit}>
          {t<string>("emailFilterDetails.continue")}
        </Button>
      </div>
    </Modal>
  );
};
