import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./child-panel.scss";
import { ParentInLayoutProp } from "../../prop-types/prop-type";
import Panel from "../panel/panel";
import {
  Child as ChildType,
  Panel as PanelType,
} from "../../interfaces/user-detail-interface";
import { RootReducer } from "../../store/reducers/root-reducer";

function Child({ panelId, subpanelId, archived }: ParentInLayoutProp) {
  const panels = useSelector((state: RootReducer) => state.panels.activePanels);
  const apanels = useSelector(
    (state: RootReducer) => state.panels.archivedPanels
  );

  const selectedElement: any = useMemo(() => {
    if (archived) {
      const parent = apanels?.filter((ele: PanelType) => {
        return ele.id === panelId;
      });
      if (subpanelId) {
        const child = parent[0]?.children?.filter((ele: ChildType) => {
          return ele.id === subpanelId;
        });
        return child[0];
      }
      return parent[0];
    }
    const parent = panels?.filter((ele: PanelType) => {
      return ele.id === panelId;
    });
    if (subpanelId) {
      const child = parent[0]?.children?.filter((ele: ChildType) => {
        return ele.id === subpanelId;
      });
      return child ? child[0] : parent[0];
    }
    return parent[0];
  }, [panelId, subpanelId, panels, apanels]);
  return panels.length ? (
    <div className="ChildPanelContainer">
      <Panel item={selectedElement} position="1/1/2/2" archived={archived} />
    </div>
  ) : (
    <Navigate to="/panels" />
  );
}

export default Child;
