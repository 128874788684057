/* eslint-disable @typescript-eslint/no-unused-vars */
import { Image } from "antd";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { RootReducer } from "../../../store/reducers/root-reducer";
import SERVER_CONFIG from "../../../utils/config";
import "../data-sources.scss";

function SlackMessageDetails(props: any) {
  const { message, maxLinesPerMessage = 2 } = props;
  const [isClickedMore, setIsClickedMore] = useState(false);
  const { id: userId } = useSelector((state: RootReducer) => state.user);

  const image = (imgURL: string) => {
    return `${
      SERVER_CONFIG.BASE_URL
    }${"/api/panels/downloadSlackFile/"}${userId}${imgURL.substring(
      "https://files.slack.com".length
    )}`;
  };

  const getThumb = (msg: any) => {
    return (
      msg.thumb_480 ||
      msg.thumb_360 ||
      msg.thumb_160 ||
      msg.thumb_80 ||
      msg.thumb_60
    );
  };

  // console.log(message.messageData.split(['~', '*', '_']));
  const maxLength = 44 * maxLinesPerMessage;

  const srcForImg =
    message.image_72 ||
    message.image_24 ||
    message.image_32 ||
    message.image_48 ||
    message.image_192 ||
    message.image_512 ||
    message.image_1024 ||
    message.image_original;
  const webkitBlockHeight = 250;
  return (
    <div style={{ marginTop: "7px" }}>
      <div className="slack_message_container">
        <img src={srcForImg} height="32px" width="30px" alt="slackavatar" />
        <div className="slack_message">
          <p className="slack_message_text">
            <span>{message.userName}</span>
            {message?.messageTime}
          </p>

          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `${ReactHtmlParser(message.messageData)}`,
            }}
            onClick={() => {
              setIsClickedMore((prev) => !prev);
            }}
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: isClickedMore ? 1000 : maxLinesPerMessage,
              WebkitBoxOrient: "vertical",
              textAlign: "left",
              maxHeight: isClickedMore ? "250px" : `${webkitBlockHeight}px`,
              transition: "all 0.6s ease",
            }}
          />
          {JSON.parse(message.completeMessageData) &&
            JSON.parse(message.completeMessageData).files &&
            JSON.parse(message.completeMessageData).files?.map((e: any) => {
              return (
                <>
                  {getThumb(e) && (
                    <Image src={image(getThumb(e))} width={200} />
                  )}
                  {e.url_private_download && (
                    <a
                      href={e.url_private_download}
                      rel="noreferrer"
                      target="_blank"
                      title={e.title}
                    >
                      <span>{e.name}</span>
                    </a>
                  )}
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default SlackMessageDetails;
