/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactRouterPrompt from "react-router-prompt";
import c from "classnames";
import { Radio, Button, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DropdownPreview from "./dropdown-preview";
import useEmailSettings, { DefaultSettings } from "./use-email-settings";
import ResetToDefaultModal from "./reset-to-default-modal";
import "./gmail.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import { Child } from "../../interfaces/user-detail-interface";
import { Loader } from "../reusable-components/loader/loader";
import ConfirmLeavePageModal from "./confirm-leave-page-modal";
import { pathnameLocationAction } from "../../store/reducers/pathname-location/actions/pathname-location-action";
import {
  updateAllPanelSettings,
  updatePanelChildrenById,
  updatePanelSettingsById,
} from "../../store/reducers/panels/actions/panel-actions";
import { RootReducer } from "../../store/reducers/root-reducer";

type GmailProps = {
  item?: Child;
};

function Gmail({ item }: GmailProps) {
  const { t } = useTranslation();
  const [response, loading, error, axiosFetch] = useAxios();
  const [settings, setSettings] = useState<{
    options: Record<string, string | number | boolean>;
    userOptions: Record<string, string | number | boolean>;
  }>({ options: {}, userOptions: {} });
  const dispatch = useDispatch<any>();

  const [isResetModalOpened, setIsResetModalOpened] = useState(false);

  const [desktopStdViewLinesPerEmailM, setDesktopStdViewLinesPerEmailM] =
    useState<string | number>("");
  const [desktopExpViewLinesPerEmailM, setDesktopExpViewLinesPerEmailM] =
    useState<string | number>("");
  const [desktopMaxEmailViewedPerPanelM, setDesktopMaxEmailViewedPerPanelM] =
    useState<string | number>("");
  const [desktopOrganisedByM, setDesktopOrganisedByM] = useState<
    string | number
  >("");
  const [mobileStdViewLinesPerEmailM, setMobileStdViewLinesPerEmailM] =
    useState<string | number>("");
  const [mobileExpViewLinesPerEmailM, setMobileExpViewLinesPerEmailM] =
    useState<string | number>("");
  const [mobileMaxEmailViewedPerPanelM, setMobileMaxEmailViewedPerPanelM] =
    useState<string | number>("");
  const [mobileOrganisedByM, setMobileOrganisedByM] = useState<string | number>(
    ""
  );
  const [fontSizesM, setFontSizesM] = useState<string | number>("");

  const {
    linesPerEmailStandardDesktop,
    linesPerEmailExpandedDesktop,
    maxEmailsPerPanelDesktop,
    linesPerEmailStandardMobile,
    linesPerEmailExpandedMobile,
    maxEmailsPerPanelMobile,
    organizeByDesktop,
    organizeByMobile,
    autoDeleteEmails,
    maxEmailCapacity,
    display,
    fontSizes,
  } = useEmailSettings();

  const [showEmailFromM, setShowEmailFromM] = useState<boolean>(
    display.value.includes("from")
  );
  const [showEmailDateM, setShowEmailDateM] = useState<boolean>(
    display.value.includes("date")
  );
  const [showEmailSubjectM, setShowEmailSubjectM] = useState<boolean>(
    display.value.includes("subject")
  );
  const [showEmailAttachmentsM, setShowEmailAttachmentsM] = useState<boolean>(
    display.value.includes("attachment")
  );

  const { pathname } = useSelector(
    (state: RootReducer) => state.pathnameLocation
  );
  const navigate = useNavigate();

  const url = () => {
    if (item?.pId && item?.id) {
      return `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.pId}/subpanels/${item?.id}/settings-email/Gmail`;
    }
    if (item?.id) {
      return `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.id}/settings-email/Gmail`;
    }

    return `${SERVER_CONFIG.BASE_URL}${SERVER_CONFIG.API_URL.EMAIL_SETTINGS.GMAIL}`;
  };

  const loadSettings = async () => {
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: url(),
    });

    setSettings(data);

    // | Different data for individual and global settings
    const userOptions = data?.userOptions || data;

    setDesktopStdViewLinesPerEmailM(userOptions?.desktopStdViewLinesPerEmail);
    setDesktopExpViewLinesPerEmailM(userOptions?.desktopExpViewLinesPerEmail);
    setDesktopMaxEmailViewedPerPanelM(
      userOptions?.desktopMaxEmailViewedPerPanel
    );
    setDesktopOrganisedByM(userOptions?.desktopOrganisedBy.toLowerCase());
    setMobileStdViewLinesPerEmailM(userOptions?.mobileStdViewLinesPerEmail);
    setMobileExpViewLinesPerEmailM(userOptions?.mobileExpViewLinesPerEmail);
    setMobileMaxEmailViewedPerPanelM(userOptions?.mobileMaxEmailViewedPerPanel);
    setMobileOrganisedByM(userOptions?.mobileOrganisedBy.toLowerCase());
    setFontSizesM(userOptions?.fontSize);

    setShowEmailFromM(userOptions?.showEmailFrom);
    setShowEmailDateM(userOptions?.showEmailDate);
    setShowEmailSubjectM(userOptions?.showEmailSubject);
    setShowEmailAttachmentsM(userOptions?.showEmailAttachments);

    linesPerEmailStandardDesktop.onChange(
      userOptions?.desktopStdViewLinesPerEmail
    );
    linesPerEmailExpandedDesktop.onChange(
      userOptions?.desktopExpViewLinesPerEmail
    );
    maxEmailsPerPanelDesktop.onChange(
      userOptions?.desktopMaxEmailViewedPerPanel
    );
    organizeByDesktop.onChange({
      target: {
        value: userOptions?.desktopOrganisedBy.toLowerCase(),
        checked: Boolean(userOptions?.desktopOrganisedBy),
      },
    });
    linesPerEmailStandardMobile.onChange(
      userOptions?.mobileStdViewLinesPerEmail
    );
    linesPerEmailExpandedMobile.onChange(
      userOptions?.mobileExpViewLinesPerEmail
    );
    maxEmailsPerPanelMobile.onChange(userOptions?.mobileMaxEmailViewedPerPanel);
    organizeByMobile.onChange({
      target: {
        value: userOptions?.mobileOrganisedBy.toLowerCase(),
        checked: Boolean(userOptions?.mobileOrganisedBy),
      },
    });
    autoDeleteEmails.onChange({
      target: {
        value: userOptions?.autoDeleteOlderEmails,
        checked: Boolean(userOptions?.autoDeleteOlderEmails),
      },
    });
    maxEmailCapacity.onChange(userOptions?.maxEmailCapacityPerPanel);

    display?.onChange([
      userOptions?.showEmailFrom && "from",
      userOptions?.showEmailDate && "date",
      userOptions?.showEmailSubject && "subject",
      userOptions?.showEmailAttachments && "attachment",
    ]);
    fontSizes.onChange(userOptions?.fontSize);
  };

  const handleSave = async () => {
    const dataToSave = {
      desktopStdViewLinesPerEmail: linesPerEmailStandardDesktop.value,
      desktopExpViewLinesPerEmail: linesPerEmailExpandedDesktop.value,
      desktopMaxEmailViewedPerPanel: maxEmailsPerPanelDesktop.value,
      desktopOrganisedBy: organizeByDesktop.value,
      mobileStdViewLinesPerEmail: linesPerEmailStandardMobile.value,
      mobileExpViewLinesPerEmail: linesPerEmailExpandedMobile.value,
      mobileMaxEmailViewedPerPanel: maxEmailsPerPanelMobile.value,
      mobileOrganisedBy: organizeByMobile.value,
      autoDeleteOlderEmails: autoDeleteEmails.value,
      maxEmailCapacityPerPanel: maxEmailCapacity.value,
      showEmailFrom: display.value.includes("from"),
      showEmailDate: display.value.includes("date"),
      showEmailSubject: display.value.includes("subject"),
      showEmailAttachments: display.value.includes("attachment"),
      fontSize: fontSizes.value,
    } as DefaultSettings;
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: url(),
      data: dataToSave,
    });

    const newData = {
      gmail: {
        ...data,
      },
    };
    if (item && item.pId) {
      dispatch(
        updatePanelChildrenById({ id: item.id, pId: item.pId }, newData)
      );
    } else if (item) {
      dispatch(updatePanelSettingsById({ id: item.id }, newData));
    }

    if (!item) {
      dispatch(
        updateAllPanelSettings({
          gmail: {
            ...dataToSave,
          },
        })
      );
      loadSettings();
    }

    if (pathname) {
      const nextPath = pathname;
      dispatch(
        pathnameLocationAction({
          pathname: "",
        })
      );
      navigate(nextPath);
    }
  };

  const handleResetToDefault = async () => {
    const itemUrl =
      item && item.pId
        ? `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.pId}/subpanels/${item?.id}/settings-reset-to-global/gmail`
        : `${SERVER_CONFIG.BASE_URL}/api/panels/${item?.id}/settings-reset-to-global/gmail`;
    const {
      // @ts-expect-error We have type errors in axiosFetch
      data: { data },
    } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: item ? itemUrl : url(),
      data: item
        ? {}
        : ({
            ...settings?.options,
          } as DefaultSettings),
    });

    const newData = {
      gmail: {
        ...data,
      },
    };
    if (item && item.pId) {
      dispatch(
        updatePanelChildrenById({ id: item.id, pId: item.pId }, newData)
      );
    } else if (item) {
      dispatch(updatePanelSettingsById({ id: item.id }, newData));
    }

    if (!item) {
      const options = settings?.options;

      setDesktopStdViewLinesPerEmailM(
        Number(options?.desktopStdViewLinesPerEmail)
      );
      setDesktopExpViewLinesPerEmailM(
        Number(options?.desktopExpViewLinesPerEmail)
      );
      setDesktopMaxEmailViewedPerPanelM(
        Number(options?.desktopMaxEmailViewedPerPanel)
      );
      setDesktopOrganisedByM(`${options?.desktopOrganisedBy}`.toLowerCase());
      setMobileStdViewLinesPerEmailM(
        Number(options?.mobileStdViewLinesPerEmail)
      );
      setMobileExpViewLinesPerEmailM(
        Number(options?.mobileExpViewLinesPerEmail)
      );
      setMobileMaxEmailViewedPerPanelM(
        Number(options?.mobileMaxEmailViewedPerPanel)
      );
      setMobileOrganisedByM(`${options?.mobileOrganisedBy}`.toLowerCase());
      setFontSizesM(`${options?.fontSize}`);

      setShowEmailFromM(!!options?.showEmailFrom);
      setShowEmailDateM(!!options?.showEmailDate);
      setShowEmailSubjectM(!!options?.showEmailSubject);
      setShowEmailAttachmentsM(!!options?.showEmailAttachments);

      linesPerEmailStandardDesktop.onChange(
        Number(options?.desktopStdViewLinesPerEmail)
      );
      linesPerEmailExpandedDesktop.onChange(
        Number(options?.desktopExpViewLinesPerEmail)
      );
      maxEmailsPerPanelDesktop.onChange(
        Number(options?.desktopMaxEmailViewedPerPanel)
      );
      organizeByDesktop.onChange({
        target: {
          value: `${options?.desktopOrganisedBy}`.toLowerCase(),
          checked: Boolean(options?.desktopOrganisedBy),
        },
      });
      linesPerEmailStandardMobile.onChange(
        Number(options?.mobileStdViewLinesPerEmail)
      );
      linesPerEmailExpandedMobile.onChange(
        Number(options?.mobileExpViewLinesPerEmail)
      );
      maxEmailsPerPanelMobile.onChange(
        Number(options?.mobileMaxEmailViewedPerPanel)
      );
      organizeByMobile.onChange({
        target: {
          value: `${options?.mobileOrganisedBy}`.toLowerCase(),
          checked: Boolean(options?.mobileOrganisedBy),
        },
      });
      autoDeleteEmails.onChange({
        target: {
          value: options?.autoDeleteOlderEmails,
          checked: Boolean(options?.autoDeleteOlderEmails),
        },
      });
      maxEmailCapacity.onChange(Number(options?.maxEmailCapacityPerPanel));

      display?.onChange([
        options?.showEmailFrom && "from",
        options?.showEmailDate && "date",
        options?.showEmailSubject && "subject",
        options?.showEmailAttachments && "attachment",
      ]);
      fontSizes.onChange(`${options?.fontSize}`);
    }

    setIsResetModalOpened(false);
  };

  const { activePanels } = useSelector((state: RootReducer) => state.panels);

  useEffect(() => {
    loadSettings();
  }, [activePanels]);

  const shouldSaveChanges =
    desktopStdViewLinesPerEmailM !== linesPerEmailStandardDesktop.value ||
    desktopExpViewLinesPerEmailM !== linesPerEmailExpandedDesktop.value ||
    desktopMaxEmailViewedPerPanelM !== maxEmailsPerPanelDesktop.value ||
    desktopOrganisedByM !== organizeByDesktop.value ||
    mobileStdViewLinesPerEmailM !== linesPerEmailStandardMobile.value ||
    mobileExpViewLinesPerEmailM !== linesPerEmailExpandedMobile.value ||
    mobileMaxEmailViewedPerPanelM !== maxEmailsPerPanelMobile.value ||
    mobileOrganisedByM !== organizeByMobile.value ||
    fontSizesM !== fontSizes.value ||
    showEmailFromM !== display.value.includes("from") ||
    showEmailDateM !== display.value.includes("date") ||
    showEmailSubjectM !== display.value.includes("subject") ||
    showEmailAttachmentsM !== display.value.includes("attachment");

  const handleSaveClick = () => {
    dispatch(
      pathnameLocationAction({
        pathname: "",
      })
    );
    handleSave();
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <div
      className={c("email-0365-container", {
        "panel-setting": Boolean(item?.id),
      })}
    >
      <h2 className="heading">{t<string>("globalSettings.gmail.title")}</h2>
      {item?.id ? (
        <p className="individual-note">
          {t<string>("individualSettingNote1")}
          <div className="gap" />
          {t<string>("individualSettingNote2")}
          <div className="gap" />
          {t<string>("individualSettingNote3")}
        </p>
      ) : null}
      <div className="settings-container">
        <h6 className="section-heading">
          {t<string>("globalSettings.email0365.desktop")}
        </h6>
        <p className="view-heading">
          {t<string>("globalSettings.email0365.standardView")}
        </p>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.linesPerEmail")}
          </p>
          <DropdownPreview {...linesPerEmailStandardDesktop} />
        </div>
        <p className="view-heading">
          {t<string>("globalSettings.email0365.expandedView")}
        </p>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.linesPerEmail")}
          </p>
          <DropdownPreview {...linesPerEmailExpandedDesktop} />
        </div>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.maxEmails")}
          </p>
          <DropdownPreview {...maxEmailsPerPanelDesktop} />
        </div>
        <div className="radio-container">
          <span className="radio-label">
            {t<string>("globalSettings.email0365.organizeBy")}
          </span>
          <Radio.Group
            onChange={organizeByDesktop.onChange}
            value={organizeByDesktop.value}
            className="radio"
          >
            {organizeByDesktop.options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>

        <h6 className="section-heading-mobile">
          {t<string>("globalSettings.email0365.Mobile")}
        </h6>
        <p className="view-heading">
          {t<string>("globalSettings.email0365.standardView")}
        </p>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.linesPerEmail")}
          </p>
          <DropdownPreview {...linesPerEmailStandardMobile} />
        </div>
        <p className="view-heading">
          {t<string>("globalSettings.email0365.expandedView")}
        </p>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.linesPerEmail")}
          </p>
          <DropdownPreview {...linesPerEmailExpandedMobile} />
        </div>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.maxEmails")}
          </p>
          <DropdownPreview {...maxEmailsPerPanelMobile} />
        </div>
        <div className="radio-container">
          <span className="radio-label">
            {t<string>("globalSettings.email0365.organizeBy")}
          </span>
          <Radio.Group
            onChange={organizeByMobile.onChange}
            value={organizeByMobile.value}
            className="radio"
          >
            {organizeByMobile.options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>

        <div className="divider" />

        <p className="view-heading-bold">
          {t<string>("globalSettings.email0365.display")}
        </p>
        <div className="dropdown-container-radio">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.show")}
          </p>
          <Checkbox.Group
            onChange={display.onChange}
            value={display.value}
            className="radio"
          >
            {display.options.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>

        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>("globalSettings.email0365.fontSize")}
          </p>
          <DropdownPreview {...fontSizes} />
        </div>

        <div className="divider" />

        {/* Return this section when backend will support that */}
        {/*
        <p className="view-heading-bold">
          {t<string>('globalSettings.email0365.retention')}
        </p>
        <div className="dropdown-container-radio">
          <p className="view-heading">
            {t<string>('globalSettings.email0365.autodelete')}
          </p>
          <Radio.Group
            onChange={autoDeleteEmails.onChange}
            value={autoDeleteEmails.value}
            className="radio"
          >
            {autoDeleteEmails.options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>('globalSettings.email0365.maxEmailCapacity')}
          </p>
          <DropdownPreview {...maxEmailCapacity} />
        </div>
        <p className="view-heading-bold">
          {t<string>('globalSettings.email0365.display')}
        </p>
        <div className="dropdown-container-radio">
          <p className="view-heading">
            {t<string>('globalSettings.email0365.show')}
          </p>
          <Checkbox.Group
            onChange={display.onChange}
            value={display.value}
            className="radio"
          >
            {display.options.map((option) => (
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
        <div className="dropdown-container">
          <p className="view-heading">
            {t<string>('globalSettings.email0365.fontSize')}
          </p>
          <DropdownPreview {...fontSizes} />
        </div>

        <div className="divider" />
        */}

        <ResetToDefaultModal
          open={isResetModalOpened}
          onSubmit={handleResetToDefault}
          onClose={() => {
            setIsResetModalOpened(false);
          }}
        />

        <ReactRouterPrompt
          when={({ nextLocation }) => {
            const data = {
              pathname: nextLocation.pathname,
            };
            if (shouldSaveChanges) {
              dispatch(pathnameLocationAction(data));
            }
            return shouldSaveChanges;
          }}
        >
          {({ isActive, onConfirm, onCancel }) => (
            <ConfirmLeavePageModal
              isActive={isActive}
              onCancel={onCancel}
              onConfirm={onConfirm}
              saveData={handleSave}
            />
          )}
        </ReactRouterPrompt>

        <div className="button-container">
          {item?.id ? (
            <Button
              type="text"
              className="reset-button"
              onClick={() => {
                setIsResetModalOpened(true);
              }}
            >
              {t<string>("adoptAll")}
            </Button>
          ) : (
            <Button
              type="text"
              className="reset-button"
              onClick={() => {
                setIsResetModalOpened(true);
              }}
            >
              {t<string>("globalSettings.email0365.resetToDefault")}
            </Button>
          )}
          <Button danger className="save-button" onClick={handleSaveClick}>
            {t<string>("globalSettings.email0365.save")}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Gmail;
