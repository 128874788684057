/* eslint-disable import/no-extraneous-dependencies */
import qs from "qs";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CustomerOptions,
  PaymentIntent,
  SetupIntent,
  StripeAddressElementOptions,
} from "@stripe/stripe-js";

export type Subscription = {
  id: string;
  customer: string;
};
export type Price = {
  id: string;
  active: boolean;
  lookup_key: string;
  recurring: {
    trial_period_days: number;
  };
  unit_amount: number;
};
export type Customer = {
  id: string;
  email: string;
  name: string;
  phone: string;
};

type ClientSubscriptions = {
  id: string;
  object: string;
  billing_cycle_anchor: number;
  cancel_at: null | number;
  cancel_at_period_end: boolean;
  canceled_at: null | number;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  default_payment_method: string;
  latest_invoice: string;
  livemode: boolean;
  plan: {
    id: string;
    object: string;
    active: boolean;
    amount: number;
    amount_decimal: string;
    billing_scheme: string;
    created: number;
    currency: string;
    interval: string;
    interval_count: number;
    livemode: boolean;
    nickname: string;
    product: string;
  };
  quantity: number;
  start_date: number;
  status: string;
  trial_end: number;
  trial_start: number;
}[];

type SubscriptionCurrent = {
  id: string;
  billing_cycle_anchor: number;
  cancel_at: null;
  cancel_at_period_end: false;
  canceled_at: null | number;
  collection_method: string;
  created: number;
  currency: string;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  default_payment_method: string;
  items: {
    data: [
      {
        id: string;
        object: string;
        created: number;
        plan: {
          id: string;
          object: string;
          active: boolean;
          amount: number;
          amount_decimal: string;
          billing_scheme: string;
          created: number;
          currency: string;
          interval: string;
          interval_count: number;
          livemode: boolean;
          nickname: string;
          product: string;
          trial_period_days: number;
          usage_type: string;
        };
        price: {
          id: string;
          object: string;
          active: boolean;
          billing_scheme: string;
          created: number;
          currency: string;
          livemode: boolean;
          lookup_key: string;
          nickname: string;
          product: string;
          recurring: {
            interval: string;
            interval_count: number;
            trial_period_days: number;
            usage_type: string;
          };
          tax_behavior: string;
          type: string;
          unit_amount: number;
          unit_amount_decimal: string;
        };
        quantity: number;
        subscription: string;
      }
    ];
    has_more: boolean;
    total_count: number;
    url: string;
  };
  latest_invoice: string;
  livemode: boolean;
  plan: {
    id: string;
    object: string;
    active: boolean;
    amount: number;
    amount_decimal: string;
    billing_scheme: string;
    created: number;
    currency: string;
    interval: string;
    interval_count: number;
    livemode: boolean;
    nickname: string;
    product: string;
    trial_period_days: number;
    usage_type: string;
  };
  quantity: number;
  start_date: number;
  status: string;
  trial_end: number;
  trial_settings: {
    end_behavior: {
      missing_payment_method: string;
    };
  };
  trial_start: number;
};

const { REACT_APP_STRIPE_API_PATH } = process.env;
const subscriptionBaseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_STRIPE_API_PATH,
  prepareHeaders: (headers) => {
    if (process.env.REACT_APP_STRIPE_SECRET_KEY)
      headers.set(
        "Authorization",
        `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
      );
    headers.set("Content-Type", "application/x-www-form-urlencoded");

    return headers;
  },
});

const subscriptionApi = createApi({
  reducerPath: "subscription",
  baseQuery: subscriptionBaseQuery,
  endpoints: (builder) => ({
    getPrices: builder.query<Price[], {}>({
      query: () => ({
        url: "/prices",
        method: "GET",
      }),
      transformResponse: (response: { data: Price[] }) => response.data,
    }),

    getSubscriptionById: builder.mutation<SubscriptionCurrent, string>({
      query: (id) => ({
        url: `/subscriptions/${id}`,
        method: "GET",
      }),
      transformResponse: (response: { data: SubscriptionCurrent }) =>
        response.data,
    }),

    getUserSubscriptions: builder.mutation<ClientSubscriptions, { id: string }>(
      {
        query: ({ id }) => ({
          url: "/subscriptions",
          method: "GET",
          params: {
            customer: id,
          },
        }),
        transformResponse: (response: { data: ClientSubscriptions }) =>
          response.data,
      }
    ),

    setupIntent: builder.query<SetupIntent, { paymentMethod: string }>({
      query: ({ paymentMethod }) => ({
        url: "/setup_intents",
        method: "POST",
        body: { "payment_method_types[]": paymentMethod },
      }),
      transformResponse: (response: SetupIntent) => response,
    }),

    subscribeCustomer: builder.mutation<
      Subscription,
      {
        customerId: CustomerOptions["customer"];
        priceId: string;
        trialPeriodDays: number;
        defaultPaymentMethod?: string;
      }
    >({
      query: ({
        customerId,
        priceId,
        trialPeriodDays,
        defaultPaymentMethod,
      }) => ({
        url: "/subscriptions",
        method: "POST",
        body: qs.stringify({
          customer: customerId,
          trial_period_days: trialPeriodDays,
          "items[0][price]": priceId,
          default_payment_method: defaultPaymentMethod,
        }),
      }),
      transformResponse: (response: Subscription) => response,
    }),

    createPaymentIntent: builder.mutation<
      PaymentIntent,
      {
        amount: number;
        currency: string;
        paymentMethod: string;
        customer: string;
      }
    >({
      query: ({ amount, currency, paymentMethod, customer }) => ({
        url: "/payment_intents",
        method: "POST",
        body: qs.stringify({
          amount,
          currency,
          customer,
          "payment_method_types[]": paymentMethod,
        }),
      }),
      transformResponse: (response: PaymentIntent) => response,
    }),

    createCustomer: builder.mutation<
      Customer,
      {
        email: string;
        defaultPaymentMethod?: string;
        address?: StripeAddressElementOptions["defaultValues"];
        phone: string;
      }
    >({
      query: ({ email, defaultPaymentMethod, address, phone }) => ({
        url: "/customers",
        method: "POST",
        body: qs.stringify({
          email,
          name: `${address?.firstName} ${address?.lastName}`,
          payment_method: defaultPaymentMethod,
          address: address?.address,
          phone,
        }),
      }),
      transformResponse: (response: Customer) => response,
    }),
  }),
});

export { subscriptionApi };
