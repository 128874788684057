/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "yup-phone";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { FocusEventHandler, useMemo, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { usersApi } from "../../store/api/users";
import { subscriptionApi, Subscription } from "../../store/api/subscription";
import PPanels from "../../assets/Images/P_Panels.png";

import CountrySelector from "./country-selector";
import { SubscriptionInput } from "./subscription-input";
import "./subscription-page.scss";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import { Footer } from "../footer/footer";
import {
  StripePriceLookupKey,
  StripeProduct,
} from "../../prop-types/prop-type";

type Country = {
  value: string;
  label: string;
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("Please enter your First Name").trim(),
  lastName: Yup.string().required("Please enter your Last Name").trim(),
  email: Yup.string()
    .required("* Please enter e-mail")
    .email("* Please enter a valid email address")
    .trim(),
  addressLine1: Yup.string().required("* Please enter your address").trim(),
  addressLine2: Yup.string().trim(),
  city: Yup.string().required("* Please enter your city").trim(),
  chosenState: Yup.string().required("* Please enter your state").trim(),
  zipCode: Yup.string().required("* Please enter your ZIP Code").trim(),
});

const phoneSchema = Yup.string().phone().required();

const SubscriptionStartTrail = () => {
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      chosenState: "",
      zipCode: "",
      phone: "",
      addressAnother: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
    validationSchema,
  });

  const dispatch = useDispatch<any>();

  const [countryInput, setCountryInput] = useState<Country>({
    value: "US",
    label: "United States",
  });

  const changeCountryHandler = (country: Country) => setCountryInput(country);

  const stripe = useStripe();

  const elements = useElements();

  const {
    useSetupIntentQuery,
    useGetPricesQuery,
    useCreateCustomerMutation,
    useSubscribeCustomerMutation,
  } = subscriptionApi;
  const { useCreateUserMutation, useValidateEmailMutation } = usersApi;

  const { data: _intent } = useSetupIntentQuery({ paymentMethod: "card" });
  const { data: prices } = useGetPricesQuery({});
  const [createCustomer, _customer] = useCreateCustomerMutation();
  const [subscribeCustomer, _subscription] = useSubscribeCustomerMutation();
  const [createUser, _user] = useCreateUserMutation();
  const [validateEmail, _validateEmailResposne] = useValidateEmailMutation();

  const location = useLocation();

  const isPremiumPage = location.pathname.includes("/premium");

  const trialPeriodPrice = useMemo(() => {
    if (!prices) {
      return;
    }

    const currentLookupKey = isPremiumPage
      ? StripePriceLookupKey.PremiumSpecial
      : StripePriceLookupKey.Standard;

    const trialPeriodPrice = prices.find(
      (price) => price.active && price.lookup_key === currentLookupKey
    );

    return trialPeriodPrice;
  }, [prices]);

  const priceId = trialPeriodPrice?.id;
  const trialPeriodDays = 14;

  type SubscriptionHandler = () => Promise<Subscription | undefined>;

  const handleSubscriptionWithoutCard: SubscriptionHandler = async () => {
    if (!stripe || !elements) {
      toast.error("Something went wrong");
      return;
    }

    const address = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      name: `${formik.values.firstName} ${formik.values.lastName}`,
      address: {
        line1: formik.values.addressLine1,
        line2: formik.values.addressLine2,
        city: formik.values.city,
        state: formik.values.chosenState,
        postal_code: formik.values.zipCode,
        country: countryInput.value,
      },
    };

    const customerData = await createCustomer({
      email: formik.values.email,
      address,
      phone: formik.values.phone,
    }).unwrap();

    const customerId = customerData?.id;

    if (customerId && priceId) {
      return await subscribeCustomer({
        customerId,
        trialPeriodDays,
        priceId,
      }).unwrap();
    }
  };
  const navigate = useNavigate();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      const subscriptionData = await handleSubscriptionWithoutCard();

      await createUser({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        customerId: subscriptionData?.customer,
        subscriptionId: subscriptionData?.id,
        trialCode: `${trialPeriodDays}`,
        phoneNumber: formik.values.phone,
        subscriptionType: isPremiumPage
          ? StripeProduct.Premium
          : StripeProduct.Standard,
        subscriptionStatus: "Active",
      }).unwrap();
      dispatch(
        setUserInfoAction({
          email: formik.values.email,
        })
      );
      toast("You have successfully subscribed.");
      navigate("/subscription/check-email");
    } catch (e) {
      toast.error(`${(e as { data: { message: string } })?.data?.message}`);
    }
  };

  const handleEmailOnBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    await validateEmail({ email: e.target.value });
  };

  const emailErrorHandler = () => {
    if (_validateEmailResposne?.data?.isTaken) {
      return "This email is already taken";
    }
    if (formik.touched.email && formik.errors.email) {
      return "Please enter a valid email address";
    }
    return "";
  };

  const isSubmitDisabled =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.email ||
    _customer.isLoading ||
    _subscription.isLoading ||
    _user.isLoading;

  return (
    <>
      <div className="container">
        <form className="subscription-form" onSubmit={handleSubmit}>
          <div className="subscription-title">
            <div className="subscription-title__img">
              <img src={PPanels} alt="logo" className="logo-img" />
            </div>
            <div>
              <p className="subscription-title__text">
                {`We invite you to subscribe to a ${
                  isPremiumPage ? "Premium" : "Standard"
                } subscription for $${
                  trialPeriodPrice ? trialPeriodPrice.unit_amount / 100 : 12
                }/mo including `}
                <strong>a two-week free trial.</strong>
                <br />
                <br />
                {`You may cancel, or ${
                  isPremiumPage ? "downgrade" : "upgrade"
                } to ${isPremiumPage ? "" : "or downgrade from "}a ${
                  isPremiumPage ? "Standard" : "Premium "
                } subscription at any time. Just go to Global Settings >
                Subscription in your Panels menu.`}
                <br />
                <br />
                <strong>After you sign up, </strong>take advantage of a live
                session with one of our experts to get you onboard quickly and
                to see the value of Panels!
              </p>
            </div>
          </div>
          <div className="subscription-data">
            <div className="subscription-data__container">
              <div className="subscription-data__title">
                Account Information
              </div>
              <div className="subscription-data__subtitle">
                Please enter your account information below.
              </div>
              <div className="subscription-data__double">
                <div className="subscription-data__input">
                  <SubscriptionInput
                    id="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    placeholder="First Name"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.firstName && formik.errors.firstName
                        ? formik.errors.firstName
                        : ""
                    }
                  />
                </div>
                <div className="subscription-data__input">
                  <SubscriptionInput
                    id="lastName"
                    label="Last Name"
                    value={formik.values.lastName}
                    placeholder="Last Name"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.lastName && formik.errors.lastName
                        ? formik.errors.lastName
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="flex subscription-data__space">
                <SubscriptionInput
                  id="email"
                  label="Email"
                  value={formik.values.email}
                  placeholder="Email"
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                    handleEmailOnBlur(e);
                  }}
                  handleChange={formik.handleChange}
                  error={emailErrorHandler()}
                />
              </div>

              <div className="flex subscription-data__space">
                <SubscriptionInput
                  id="addressLine1"
                  label="Address line 1"
                  value={formik.values.addressLine1}
                  placeholder="48824 Romeo Plank Road"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={
                    formik.touched.addressLine1 && formik.errors.addressLine1
                      ? "Enter your address"
                      : ""
                  }
                />
              </div>

              <div className="subscription-data__space">
                <SubscriptionInput
                  id="addressLine2"
                  label="Address line 2"
                  value={formik.values.addressLine2}
                  placeholder="Apt., suite, unit number, etc. (optional)"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error=""
                />
              </div>
              <div className="subscription-data__space">
                <SubscriptionInput
                  id="city"
                  label="City"
                  value={formik.values.city}
                  placeholder="City"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={
                    formik.touched.city && formik.errors.city
                      ? "Enter your city"
                      : ""
                  }
                />
              </div>

              <div className="subscription-data__double">
                <div className="subscription-data__input">
                  <SubscriptionInput
                    id="chosenState"
                    label="State"
                    value={formik.values.chosenState}
                    placeholder="State"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.chosenState && formik.errors.chosenState
                        ? "Enter your state"
                        : ""
                    }
                  />
                </div>
                <div className="subscription-data__input">
                  <SubscriptionInput
                    id="zipCode"
                    label="ZIP"
                    value={formik.values.zipCode}
                    placeholder="ZIP Code"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.zipCode && formik.errors.zipCode
                        ? "Enter your ZIP Code"
                        : ""
                    }
                  />
                </div>
              </div>

              <div className="subscription-data__space">
                <CountrySelector
                  country={countryInput}
                  changeCountryHandler={changeCountryHandler}
                />
              </div>
              <div className="subscription-data__input">
                <SubscriptionInput
                  id="phone"
                  label="Phone number"
                  value={formik.values.phone}
                  placeholder="+1 201 555-0123"
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  error={
                    formik.touched.phone &&
                    !phoneSchema.isValidSync(formik.values.phone)
                      ? "Enter your phone number"
                      : ""
                  }
                />
              </div>

              <div className="button-area">
                <button
                  type="submit"
                  className="form-button"
                  disabled={isSubmitDisabled}
                >
                  <span>Start Trial</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default SubscriptionStartTrail;
