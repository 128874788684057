/* eslint-disable consistent-return */
import "yup-phone";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { usersApi } from "../../store/api/users";
import { subscriptionApi } from "../../store/api/subscription";
import PPanels from "../../assets/Images/P_Panels.png";

import CountrySelector from "./country-selector";
import { SubscriptionInput } from "./subscription-input";
import "./subscription-page.scss";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import { Footer } from "../footer/footer";

type Country = {
  value: string;
  label: string;
};

type DecodeToken = {
  expirationDate: string;
  participantUserId: string;
  primaryUserId: string;
  userEmail: string;
  verificationCode: string;
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("Please enter your First Name").trim(),
  lastName: Yup.string().required("Please enter your Last Name").trim(),
  email: Yup.string()
    .required("* Please enter e-mail")
    .email("* Please enter a valid email address")
    .trim(),
  addressLine1: Yup.string().required("* Please enter your address").trim(),
  addressLine2: Yup.string().trim(),
  city: Yup.string().required("* Please enter your city").trim(),
  chosenState: Yup.string().required("* Please enter your state").trim(),
  zipCode: Yup.string().required("* Please enter your ZIP Code").trim(),
});

const phoneSchema = Yup.string().phone().required();

const SubscriptionParticipant = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  const decoded: DecodeToken = jwt_decode(token, { header: true });

  const dateJS = dayjs(decoded.expirationDate);
  const isTokenExpired = dayjs().isAfter(dateJS);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: decoded.userEmail,
      addressLine1: "",
      addressLine2: "",
      city: "",
      chosenState: "",
      zipCode: "",
      phone: "",
      addressAnother: "",
    },
    onSubmit: () => {},
    validationSchema,
  });

  const dispatch = useDispatch<any>();

  const [countryInput, setCountryInput] = useState<Country>({
    value: "US",
    label: "United States",
  });

  const changeCountryHandler = (country: Country) => setCountryInput(country);

  const stripe = useStripe();

  const elements = useElements();

  const { useCreateCustomerMutation } = subscriptionApi;

  const { useCreateParticipantMutation } = usersApi;

  const [createCustomer, { data: customer, isLoading: customerIsLoading }] =
    useCreateCustomerMutation();
  const [createParticipant, { isLoading: participantIsLoading }] =
    useCreateParticipantMutation();

  const handleCreateStripeUser = async () => {
    if (!stripe || !elements) {
      toast.error("Something went wrong");
      return;
    }

    const address = {
      firstName: formik.values.firstName,
      lastName: formik.values.lastName,
      name: `${formik.values.firstName} ${formik.values.lastName}`,
      address: {
        line1: formik.values.addressLine1,
        line2: formik.values.addressLine2,
        city: formik.values.city,
        state: formik.values.chosenState,
        postal_code: formik.values.zipCode,
        country: countryInput.value,
      },
    };

    await createCustomer({
      email: formik.values.email,
      address,
      phone: formik.values.phone,
    }).unwrap();
  };

  const navigate = useNavigate();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      await handleCreateStripeUser();
    } catch (e) {
      toast.error(`${(e as { data: { message: string } })?.data?.message}`);
    }
  };

  const handleCreateParticipant = async (id: string) => {
    try {
      const response = await createParticipant({
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        customerId: id,
        trialCode: "",
        subscriptionId: "",
        phoneNumber: formik.values.phone,
        subscriptionType: "",
        subscriptionStatus: "",
      }).unwrap();

      dispatch(
        setUserInfoAction({
          emailAddress: formik.values.email,
        })
      );

      toast(response.message);
      navigate("/subscription/check-email");
    } catch (e) {
      toast.error(`${(e as { data: { message: string } })?.data?.message}`);
    }
  };

  useEffect(() => {
    if (customer && customer.id) {
      handleCreateParticipant(customer.id);
    }
  }, [customer]);

  const isSubmitDisabled =
    !formik.values.firstName ||
    !formik.values.lastName ||
    !formik.values.email ||
    customerIsLoading ||
    participantIsLoading;

  return (
    <>
      <div className="container">
        <form className="subscription-form" onSubmit={handleSubmit}>
          <div className="subscription-title">
            <div className="subscription-title__img">
              <img src={PPanels} alt="logo" className="logo-img" />
            </div>
            <div>
              {isTokenExpired ? (
                <p className="subscription-title__text">
                  Your invitation link has expired.
                  <br />
                  Please contact the person who invited you.
                </p>
              ) : (
                <p className="subscription-title__text">
                  You were invited to join Panels
                  <br />
                  This is absolutely free for you!
                </p>
              )}
            </div>
          </div>
          <div className="subscription-data">
            {isTokenExpired ? (
              <div className="subscription-data__container">
                <div className="subscription-data__title">
                  Your invitation link has expired.
                </div>
                <div className="subscription-data__subtitle">
                  Please contact the person who invited you.
                </div>
              </div>
            ) : (
              <div className="subscription-data__container">
                <div className="subscription-data__title">
                  Account Information
                </div>
                <div className="subscription-data__subtitle">
                  Please enter your account information below.
                </div>
                <div className="subscription-data__double">
                  <div className="subscription-data__input">
                    <SubscriptionInput
                      id="firstName"
                      label="First Name"
                      value={formik.values.firstName}
                      placeholder="First Name"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.firstName && formik.errors.firstName
                          ? formik.errors.firstName
                          : ""
                      }
                    />
                  </div>
                  <div className="subscription-data__input">
                    <SubscriptionInput
                      id="lastName"
                      label="Last Name"
                      value={formik.values.lastName}
                      placeholder="Last Name"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.lastName && formik.errors.lastName
                          ? formik.errors.lastName
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="flex subscription-data__space">
                  <SubscriptionInput
                    id="email"
                    label="Email"
                    value={formik.values.email}
                    placeholder="Email"
                    disabled
                  />
                </div>

                <div className="flex subscription-data__space">
                  <SubscriptionInput
                    id="addressLine1"
                    label="Address line 1"
                    value={formik.values.addressLine1}
                    placeholder="48824 Romeo Plank Road"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.addressLine1 && formik.errors.addressLine1
                        ? "Enter your address"
                        : ""
                    }
                  />
                </div>

                <div className="subscription-data__space">
                  <SubscriptionInput
                    id="addressLine2"
                    label="Address line 2"
                    value={formik.values.addressLine2}
                    placeholder="Apt., suite, unit number, etc. (optional)"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error=""
                  />
                </div>
                <div className="subscription-data__space">
                  <SubscriptionInput
                    id="city"
                    label="City"
                    value={formik.values.city}
                    placeholder="City"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.city && formik.errors.city
                        ? "Enter your city"
                        : ""
                    }
                  />
                </div>

                <div className="subscription-data__double">
                  <div className="subscription-data__input">
                    <SubscriptionInput
                      id="chosenState"
                      label="State"
                      value={formik.values.chosenState}
                      placeholder="State"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.chosenState && formik.errors.chosenState
                          ? "Enter your state"
                          : ""
                      }
                    />
                  </div>
                  <div className="subscription-data__input">
                    <SubscriptionInput
                      id="zipCode"
                      label="ZIP"
                      value={formik.values.zipCode}
                      placeholder="ZIP Code"
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      error={
                        formik.touched.zipCode && formik.errors.zipCode
                          ? "Enter your ZIP Code"
                          : ""
                      }
                    />
                  </div>
                </div>

                <div className="subscription-data__space">
                  <CountrySelector
                    country={countryInput}
                    changeCountryHandler={changeCountryHandler}
                  />
                </div>
                <div className="subscription-data__input">
                  <SubscriptionInput
                    id="phone"
                    label="Phone number"
                    value={formik.values.phone}
                    placeholder="+1 201 555-0123"
                    handleBlur={formik.handleBlur}
                    handleChange={formik.handleChange}
                    error={
                      formik.touched.phone &&
                      !phoneSchema.isValidSync(formik.values.phone)
                        ? "Enter your phone number"
                        : ""
                    }
                  />
                </div>

                <div className="button-area">
                  <button
                    type="submit"
                    className="form-button"
                    disabled={isSubmitDisabled}
                  >
                    <span>Join Panels!</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default SubscriptionParticipant;
