import { Dispatch } from "redux";

export const LOGIN = "login";
export const LOGOUT = "logout";
export const authAction = (token: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: token ? LOGIN : LOGOUT,
      payload: token,
    });
  };
};
