import PPanels from "../../assets/Images/P_Panels.png";
import "./subscription-page.scss";
import { Footer } from "../footer/footer";

const SubscriptionDecline = () => {
  return (
    <>
      <div className="container">
        <div className="subscription-form">
          <div className="subscription-title">
            <div className="subscription-title__img">
              <img src={PPanels} alt="logo" className="logo-img" />
            </div>
            <div>
              <p className="subscription-title__text">
                Thank you! If you change your mind, just ask your Panels user
                <br />
                to invite you again.
              </p>
            </div>
          </div>
          <div className="subscription-data" />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SubscriptionDecline;
