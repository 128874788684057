export type Actions = {
  type: string;
  payload: any;
};

const loadingState: any = {
  connectDatasourcePopupId: "",
  addDatasourceId: "",
  addEditSourceData: false,
  addRemoveSlackChannel: {
    panelId: "",
    workspaceList: [],
    channelList: [],
    dmList: [],
  },
  addRemoveStorageFolders: {
    oneDriveList: {},
    googleDriveList: {},
    dropBoxList: {},
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const loadingStateReducer = (state = loadingState, action: Actions) => {
  switch (action.type) {
    case "updateLoading":
      return { ...state, [action.payload.item]: action.payload.id };
    case "updateWorkspaceList":
      return {
        ...state,
        addRemoveSlackChannel: {
          ...state.addRemoveSlackChannel,
          workspaceList: action.payload,
        },
      };
    case "updateChannelList":
      return {
        ...state,
        addRemoveSlackChannel: {
          ...state.addRemoveSlackChannel,
          channelList: action.payload,
        },
      };
    case "updateDMList":
      return {
        ...state,
        addRemoveSlackChannel: {
          ...state.addRemoveSlackChannel,
          dmList: action.payload,
        },
      };
    case "updateOneDriveLoading":
      return { ...state, [action.payload.item]: action.payload.id };
    case "addEditSouceCheck":
      return { ...state, addEditSourceData: action.payload };
    case "updateOneDriveList":
      return {
        ...state,
        addRemoveStorageFolders: {
          ...state.addRemoveStorageFolders,
          oneDriveList: action.payload,
        },
      };
    case "updateDriveList":
      return {
        ...state,
        addRemoveStorageFolders: {
          ...state.addRemoveStorageFolders,
          [action.payload.key]: {
            [action.payload.id]: action.payload.item,
          },
        },
      };
    case "updateGDriveLoading":
      return { ...state, [action.payload.item]: action.payload.id };
    case "updateGDriveList":
      return {
        ...state,
        addRemoveStorageFolders: {
          ...state.addRemoveGDriveFolders,
          googleDriveList: action.payload,
        },
      };
    default:
      return state;
  }
};

export default loadingStateReducer;
