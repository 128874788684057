/* eslint-disable react/require-default-props */
import c from "classnames";
import { Dropdown, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { Option } from "./use-email-settings";

import "./dropdown-preview.scss";
import icons from "../../assets/icon-export-assets";

const { smallArrowIcon } = icons;

type DropdownPreviewProps = {
  value: Option["value"];
  onChange: (value: Option["value"]) => void;
  options: Option[];
  defaultValue?: Option["value"];
  className?: string;
};

function DropdownPreview({
  value,
  defaultValue,
  options,
  onChange,
  className,
}: DropdownPreviewProps) {
  const { t } = useTranslation();

  return (
    <Dropdown
      overlayClassName="dropdown-overlay-email"
      overlay={
        <Menu>
          {options.map((option) => (
            <Menu.Item
              key={option.value}
              onClick={() => {
                onChange(option.value);
              }}
            >
              {option.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={["click"]}
      placement="bottomRight"
    >
      <div className={c("dropdown-preview", className)}>
        <span className="dropdown-text-preview">
          {`${options.find((o) => o.value === value)?.label || value} ${
            String(value) === String(defaultValue)
              ? t("globalSettings.email0365.default")
              : ""
          }`}
        </span>

        <img src={smallArrowIcon} alt="open-icon" />
      </div>
    </Dropdown>
  );
}

export default DropdownPreview;
