import { User } from "../../../../interfaces/user-detail-interface";
import { SET_USER_INFO } from "../action/user-action";

export type Actions = {
  type: string;
  payload: any;
};

const user: User = {
  name: "",
  username: "",
  id: "",
  emailAddress: "",
  // token: 'abcdfefcdhvh5673ejnh3g',
  subscription: {
    type: "xyz",
    expiration: "12-12-2022",
  },
  subscriptionId: "",
  customerId: "",
  isParticipant: true,
  widgets: [{ type: "calender", data: {} }],
  isTawkToOn: true,
  globalSettings: {
    accessibility: {
      zoom: 100,
      animationsEmoji: true,
    },
    isAllNotification: true,
    languageTimeZone: {
      language: "en",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      setAsDefault: true,
    },
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const UserAccountReducer = (state = user, action: Actions) => {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, ...action.payload };
    case "AddPanel":
      return state;
    default:
      return state;
  }
};

export default UserAccountReducer;
