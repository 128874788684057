import { Dispatch } from "redux";

const ReceiveMessage = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "receiveMessage",
      payload: item,
    });
  };
};

export default ReceiveMessage;
