/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import {
  ArrowLeftOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Collapse,
  Spin,
  Button,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Icons from "../../assets/icon-export-assets";
import {
  Child,
  EmailFilters,
  Panel as PanelType,
  PanelEmails,
  PanelsData,
  Source,
  SelectedMailFilter,
  SelectedMailData,
} from "../../interfaces/user-detail-interface";
import {
  DataSourceId,
  LoggedInLayoutProp,
  OutlookMailBody,
} from "../../prop-types/prop-type";
import { RootReducer } from "../../store/reducers/root-reducer";
import "./email-filter.scss";
import MailTemplate from "../data-sources/email-sources/mail-template";
import { updatePanelsDetails } from "../../store/reducers/panels/actions/panel-actions";
import EmailsInput from "../reusable-components/email-Input/email-input";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import { waringNotification } from "../../utils/notification";
import { setPanelsData } from "../../store/reducers/panel-data/actions/panels-data-actions";
import { faq } from "../help-page/faq";
import { SaveFilterModal } from "./save-filter-modal";

type SelectedEmail = {
  id: string;
  sourceId: number;
  emailId: string;
};

interface DefaultEmail {
  label: JSX.Element;
  value: string;
}

const today = new Date();
const date14DaysBack = new Date();
date14DaysBack.setDate(today.getDate() - 14);
const tempFormData = {
  id: "",
  from: "",
  hasTheWords: "",
  subject: "",
  date: moment(date14DaysBack).format("MM/DD/YYYY"),
  hasAttachment: false,
  mailbox: 2,
  panelId: "",
  email: "",
};
const { Panel } = Collapse;

const MainFilter = ({ panelId, subpanelId }: LoggedInLayoutProp) => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { gmailIcon, outlookIcon } = Icons;
  const panels = useSelector((state: RootReducer) => state.panels.activePanels);
  const [editFilter, setEditFilter] = useState(false);
  const [tempFilter, setTempFilter] = useState(tempFormData);
  const [markedEmail, setMarkedEmail] = useState<SelectedEmail>();
  const [selectedEmailList, setSelectedEmailList] = useState<
    SelectedEmail[] | string[]
  >([]);

  const [validateFiltersResult, setValidateFiltersResult] = useState<any[]>([]);
  const [actionType, setActionType] = useState("");
  const [
    addUpdateFiltersResponse,
    addUpdateFiltersLoading,
    addUpdateFiltersError,
    addUpdateFilters,
  ] = useAxios();
  const [emailSourceId, setEmailSourceId] = useState<string>("");
  const [
    sourcePanelData,
    sourcePanelDataLoading,
    sourcePanelDataLoadingError,
    sourcePanelDataFetch,
  ] = useAxios();
  const panelData: PanelsData = useSelector(
    (state: RootReducer) => state.panelData
  );
  const params = useParams();
  const [form] = Form.useForm();

  const parentPanel = panels.find(
    (panel: PanelType) => panel.id === params.panelId
  );

  if (!parentPanel) {
    navigate("/panels");
  }

  const panelFiltered = params.subpanelId
    ? parentPanel.children.find(
        (child: Child) => child.id === params.subpanelId
      )
    : parentPanel;
  const { panelSettings, name } = panelFiltered;
  const validateFiltersResultList = useMemo(() => {
    const sorted = [...validateFiltersResult].sort(
      (a, b) =>
        new Date(b.emailDate).getTime() - new Date(a.emailDate).getTime()
    );

    return sorted.map((e: OutlookMailBody, index) => (
      <MailTemplate
        key={index}
        data={{ ...e, id: Math.random() as unknown as string }}
        panelSettings={
          panelSettings ? panelSettings[e.emailSourceType.toLowerCase()] : {}
        }
      />
    ));
  }, [validateFiltersResult]);

  const selectedElement: any = useMemo(() => {
    const parent = panels?.filter((ele: PanelType) => {
      return ele.id === panelId;
    });
    if (subpanelId) {
      const child = parent[0]?.children?.filter((ele: Child) => {
        return ele.id === subpanelId;
      });
      return child ? child[0] : {};
    }
    return parent[0];
  }, [panelId, subpanelId, panels]);
  const emailList: any = useMemo(() => {
    const { sources = [] } = selectedElement || {};
    const emails: any = [];
    sources.forEach((source: Source) => {
      if (source.sourceId === 1 || source.sourceId === 2) {
        emails.push({
          sourceId: source.sourceId,
          emailId: source.data.email,
          id: source.id,
        });
      }
    });

    setSelectedEmailList([...emails]);
    return emails;
  }, [selectedElement, panels]);

  const handleChange = (value: SelectedEmail[]) => {
    setSelectedEmailList([...value]);
  };

  const selectedEmailFilters = useMemo(() => {
    let filter: any = [];
    emailList.forEach((selectedEmail: SelectedEmail) => {
      const { sources = [] } = selectedElement || {};
      const { sourceId, emailId, id } = selectedEmail;
      const selectedSource = sources.filter((e: any) => e.id === id)[0];
      const filters = selectedSource?.data?.filters;
      filter = [...filter, { selectedEmail, filters }];
    });
    return filter;
  }, [selectedElement, emailList, panels]);

  const enableFilterEdit = (filterData: any, selectedEmail: SelectedEmail) => {
    setMarkedEmail(selectedEmail);
    setEditFilter(true);
    setTempFilter({
      ...filterData,
      from: filterData.from ? filterData.from : "",
      date: filterData.date ? filterData.date : tempFilter.date,
      hasAttachment: filterData.hasAttachment
        ? filterData.hasAttachment
        : false,
      hasTheWords: filterData.hasTheWords ? filterData.hasTheWords : "",
      subject: filterData.subject ? filterData.subject : "",
    });
    form.setFieldValue("from", filterData.from ? filterData.from : "");
    form.setFieldValue(
      "date",
      filterData.date ? moment(filterData.date) : tempFilter.date
    );
    form.setFieldValue(
      "hasAttachment",
      filterData.hasAttachment ? filterData.hasAttachment : false
    );
    form.setFieldValue(
      "hasTheWords",
      filterData.hasTheWords ? filterData.hasTheWords : ""
    );
    form.setFieldValue("subject", filterData.subject ? filterData.subject : "");
    form.setFieldValue("selectedEmailList", [filterData.email]);
  };

  const updatePanels = (newItem: any) => {
    const panelList = [...panels];
    if (!newItem.pId && newItem.isStandalone) {
      const parentIndex = panelList.findIndex(
        (e: PanelType) => e.id === newItem.id
      );
      panelList.splice(parentIndex, 1, newItem);
    }
    if (newItem.pId) {
      const parentIndex = panelList.findIndex(
        (e: PanelType) => e.id === newItem.pId
      );
      const childIndex = panelList[parentIndex].children.findIndex(
        (e: Child) => e.id === newItem.id
      );
      panelList[parentIndex].children.splice(childIndex, 1, newItem);
    }
    dispatch(updatePanelsDetails([...panelList]));
  };
  const validateOrSaveFilters = (
    action: string,
    url: string,
    method: string,
    body: any
  ) => {
    const requestObj = {
      method,
      url,
    } as any;
    if (action !== "DELETE") {
      requestObj.data = body;
    }

    // eslint-disable-next-line
    // @ts-ignore
    addUpdateFilters(requestObj);
  };
  const removeFilter = (
    tempId: string,
    selectedEmail: SelectedEmail | undefined
  ) => {
    const { emailId, sourceId, id } = selectedEmail || {
      emailid: "",
      sourceId: "",
      id: "",
    };
    if (emailId && sourceId) {
      const source = selectedElement.sources.find((e: any) => e.id === id);
      const sourceIndex = selectedElement.sources.findIndex(
        (e: any) => e.id === id
      );
      const newFilters = source.data.filters?.filter(
        (e: any) => e.id !== tempId
      );
      source.data.filters = [...newFilters];
      const newSelectedElement = selectedElement;
      newSelectedElement.sources.splice(sourceIndex, 1, source);
      setEditFilter(false);
      setMarkedEmail(undefined);
      setTempFilter(tempFormData);
      setEmailSourceId(id);
      setActionType("DELETE");
      validateOrSaveFilters(
        "DELETE",
        SERVER_CONFIG.API_URL.FILTERS_PANELS_UPDATE(tempId),
        SERVER_CONFIG.HTTP_METHOD.DELETE,
        { ...tempFilter, panelId: subpanelId || panelId, email: emailId }
      );
      updatePanels(newSelectedElement);
    }
  };

  type NewFilter = {
    panelId: string | undefined;
    email: string;
    from: string;
    hasTheWords: string;
    subject: string;
    date: string;
    hasAttachment: boolean;
    mailbox: number;
  };

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isSearchSaveModalOpen, setIsSearchSaveModalOpen] = useState(false);

  const addEditFilter = () => {
    setIsSearchClicked(false);

    form
      .validateFields()
      .then((values) => {
        const { id: tempId } = tempFilter;
        const { emailId, sourceId, id } = markedEmail || {
          emailId: "",
          sourceId: "",
          id: "",
        };
        // debugger;
        if (tempId && emailId) {
          setActionType("UPDATE");
          // const source = selectedElement.sources.find((e:any) => e.sourceId === sourceId);
          // const sourceIndex = selectedElement.sources.findIndex((e:any) => e.id === id);
          // const { filters = [] } = source.data.filters;
          // // let newFilters = {};
          // const newSelectedElement = selectedElement;
          // const filter = source?.data?.filters?.find((e:any) => e.id === tempId);
          // filters.splice(filterIndex, 1, tempFilter);
          // newSelectedElement.sources.splice(sourceIndex, 1, source);
          const source = selectedElement.sources.find((e: any) => e.id === id);
          const filter = source?.data?.filters?.find(
            (e: any) => e.id === tempId
          );
          validateOrSaveFilters(
            "UPDATE",
            SERVER_CONFIG.API_URL.FILTERS_PANELS_UPDATE(filter.id),
            SERVER_CONFIG.HTTP_METHOD.PUT,
            { ...tempFilter, panelId: subpanelId || panelId, email: emailId }
          );
          setEditFilter(false);
          setMarkedEmail(undefined);
          setTempFilter(tempFormData);
          form.resetFields();
        }
        if (
          values.selectedEmailList &&
          values.selectedEmailList.length > 0 &&
          !tempId
        ) {
          setActionType("ADD");
          const newFilters: NewFilter[] = [];
          selectedEmailList.forEach((selectedEmail: SelectedEmail | string) => {
            const addedFilter: NewFilter = {
              ...tempFilter,
              panelId: subpanelId || panelId,
              email:
                typeof selectedEmail === "string"
                  ? selectedEmail
                  : selectedEmail.emailId,
            };
            newFilters.push(addedFilter);
          });
          validateOrSaveFilters(
            "ADD",
            SERVER_CONFIG.API_URL.FILTERS_PANELS_ADD,
            SERVER_CONFIG.HTTP_METHOD.POST,
            newFilters
          );
        }
      })
      .catch((errors) => console.error("ERRORS", errors));
  };
  const updateCache = (filter: any, isAdd: boolean) => {
    const newSelectedElement = { ...selectedElement };
    let sourceIndex;
    let source;
    if (isAdd) {
      filter.forEach((item: any) => {
        sourceIndex = newSelectedElement.sources.findIndex(
          (e: any) => e.data.email === item.value.email
        );
        source = newSelectedElement.sources[sourceIndex];
        if (item.value.success) {
          source.data.filters = [
            ...source.data.filters,
            { ...item.value.data },
          ];
        }
      });
    } else {
      sourceIndex = newSelectedElement.sources.findIndex(
        (e: any) => e.data.email === filter.email
      );
      source = newSelectedElement.sources[sourceIndex];
      const filters = [...source.data.filters];
      const filterIndex = filters.findIndex(
        (item: any) => item.id === filter.id
      );
      const prevFilterValues = {
        ...filters[filterIndex],
        ...filter,
      };
      filters.splice(filterIndex, 1, prevFilterValues);
      source.data.filters = [...filters];
    }
    newSelectedElement.sources.splice(sourceIndex, 1, source);
    updatePanels(newSelectedElement);
    // const { filters = [] } = source.data.filters;
    // if (filters.length === 0) {
    //   source.data.filters = [{ ...tempFilter, id: Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5) }];
    // } else {
    //   // { ...tempFilter, id: Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5) };
    //   filters.push({ ...tempFilter, id: Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5) });
    // }
  };

  const validateFilter = () => {
    setIsSearchClicked(true);

    form
      .validateFields()
      .then((values) => {
        setActionType("VALIDATE");
        let validateTempFilters = [] as any;
        if (!editFilter) {
          selectedEmailList.forEach((selectedEmail: SelectedEmail | string) => {
            validateTempFilters = [
              ...validateTempFilters,
              {
                ...tempFilter,
                id: undefined,
                panelId: subpanelId,
                email:
                  typeof selectedEmail === "string"
                    ? selectedEmail
                    : selectedEmail.emailId,
              },
            ];
          });
        } else {
          validateTempFilters = [
            {
              ...tempFilter,
              id: undefined,
              panelId: subpanelId,
              email: markedEmail?.emailId,
            },
          ];
        }
        validateOrSaveFilters(
          "VALIDATE",
          SERVER_CONFIG.API_URL.FILTERS_PANELS_VALIDATE,
          SERVER_CONFIG.HTTP_METHOD.POST,
          validateTempFilters
        );
      })
      .catch((errors) => console.error("ERRORS", errors));
  };

  const defaultEmailValues: DefaultEmail[] = useMemo(() => {
    return emailList.map((mail: SelectedEmail) => ({
      label: (
        <>
          <img
            alt={mail.sourceId === DataSourceId.OUTLOOK ? "outlook" : "gmail"}
            width={20}
            height={20}
            src={
              mail.sourceId === DataSourceId.OUTLOOK ? outlookIcon : gmailIcon
            }
          />
          &nbsp;
          <span>{mail.emailId}</span>
        </>
      ),
      value: mail.emailId,
    }));
  }, [selectedElement, emailList, panels]);

  const clearForm = () => {
    form.setFieldValue("from", tempFormData.from);
    form.setFieldValue("date", moment(tempFormData.date));
    form.setFieldValue("hasAttachment", tempFormData.hasAttachment);
    form.setFieldValue("hasTheWords", tempFormData.hasTheWords);
    form.setFieldValue("subject", tempFormData.subject);
    form.setFieldValue("selectedEmailList", defaultEmailValues);
    form.validateFields();
    setSelectedEmailList(
      defaultEmailValues.map((mail: DefaultEmail) => mail.value)
    );
    setTempFilter(tempFormData);
    if (editFilter) {
      setEditFilter(false);
    }
  };

  useEffect(() => {
    if (addUpdateFiltersResponse) {
      let tempMailList = [] as any;
      const res = addUpdateFiltersResponse as any;
      if (res?.data && actionType === "VALIDATE") {
        res.data.forEach((parentItem: any) => {
          if (parentItem?.value?.data?.length > 0) {
            parentItem?.value?.data?.forEach((item: any) => {
              tempMailList = [...tempMailList, item];
            });
          } else {
            waringNotification(parentItem?.value?.message);
          }
        });
        setValidateFiltersResult([...tempMailList]);
        // const newSelectedElement = { ...selectedElement };
        // updatePanels(newSelectedElement);
      } else if (res?.data && actionType === "ADD") {
        clearForm();
        updateCache(res.data, true);
      } else if (res?.data?.success && actionType === "UPDATE") {
        clearForm();
        updateCache(res.data.data, false);
        if (subpanelId) {
          sourcePanelDataFetch({
            method: SERVER_CONFIG.HTTP_METHOD.GET,
            url: SERVER_CONFIG.API_URL.FETCH_DATASOURCE_EMAILS(
              subpanelId,
              emailSourceId
            ),
          });
        } else if (panelId) {
          sourcePanelDataFetch({
            method: SERVER_CONFIG.HTTP_METHOD.GET,
            url: SERVER_CONFIG.API_URL.FETCH_DATASOURCE_EMAILS(
              panelId,
              emailSourceId
            ),
          });
        }
      } else if (res?.data?.success && actionType === "DELETE") {
        clearForm();
        if (subpanelId) {
          sourcePanelDataFetch({
            method: SERVER_CONFIG.HTTP_METHOD.GET,
            url: SERVER_CONFIG.API_URL.FETCH_DATASOURCE_EMAILS(
              subpanelId,
              emailSourceId
            ),
          });
        } else if (panelId) {
          sourcePanelDataFetch({
            method: SERVER_CONFIG.HTTP_METHOD.GET,
            url: SERVER_CONFIG.API_URL.FETCH_DATASOURCE_EMAILS(
              panelId,
              emailSourceId
            ),
          });
        }
      }
    }
    setActionType("");
  }, [addUpdateFiltersResponse]);

  useEffect(() => {
    if (sourcePanelData) {
      const res = sourcePanelData;
      if (res && res.data.success) {
        const panel = res.data.panelsData[0];
        const tempPanelsData: PanelsData = { ...panelData };
        const tempPanelData = { ...tempPanelsData[panel.panelId] };
        const tempPanelDataEmail: PanelEmails = tempPanelData;
        const emailTypeKeys: string[] = Object.keys(panel.email);
        emailTypeKeys.forEach((emailTypeKey: string) => {
          panel.email[emailTypeKey].forEach(
            (emailDetails: { data: OutlookMailBody[]; emailId: string }) => {
              if (emailDetails && emailTypeKey) {
                const { emailId, data: responseData } = emailDetails;
                const tempEmail = tempPanelDataEmail.email?.[emailTypeKey];
                if (tempEmail && emailId) {
                  tempEmail[emailId] = {
                    data: responseData,
                  };
                }
              }
            }
          );
        });
        dispatch(setPanelsData(tempPanelsData));
      }
    }
  }, [sourcePanelData]);

  const emptyFiltersMailSources = selectedEmailFilters.filter(
    (mail: SelectedMailFilter) => mail.filters.length === 0
  );

  const handleModalClose = () => {
    setIsSearchSaveModalOpen(false);
  };
  const handleModalSubmit = () => {
    setIsSearchSaveModalOpen(false);
    if (selectedElement.pId) {
      navigate(`/panels/${selectedElement.pId}/${selectedElement.id}`);
    } else {
      navigate(`/panels/${selectedElement.id}`);
    }
  };

  const goBack = () => {
    if (isSearchClicked) {
      setIsSearchSaveModalOpen(true);
    } else if (selectedElement.pId) {
      navigate(`/panels/${selectedElement.pId}/${selectedElement.id}`);
    } else {
      navigate(`/panels/${selectedElement.id}`);
    }
  };

  const panelHeaderContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <span className="filter-header-name">{selectedElement.name}</span>
      </div>
      <div className="PanelHeaderSubContainer2">
        <button
          type="button"
          className="headerButtonItems"
          title="Help Center"
          onClick={() => navigate("/help/faq")}
        >
          <QuestionCircleOutlined role="button" />
        </button>
      </div>
    </div>
  );

  const isDataLoading = addUpdateFiltersLoading || sourcePanelDataLoading;

  return (
    // <Spin size="large" spinning={addUpdateFiltersLoading as boolean}>
    <div className="filter-container">
      {panelHeaderContent}
      <div className="filter-main-container">
        {/* {
        emailList.length < 1 && <span>{t<string>('emailFilterDetails.addSource')}</span>
      } */}
        {emailList.length > 0 && (
          <>
            <Spin
              wrapperClassName="filter-sub-container border-right"
              size="large"
              spinning={isDataLoading}
            >
              <div className="filter-result-page-hearder">
                <div className="filter-top-container">
                  <button
                    type="button"
                    title={t<string>("backToPanelsButton")}
                    className="top-button-back-to-panels"
                    onClick={() => goBack()}
                  >
                    <ArrowLeftOutlined />
                  </button>
                </div>
                <div className="filter-info-text">
                  {!editFilter && (
                    <>
                      <h2>
                        {t<string>(
                          "emailFilterDetails.addFilter.addFilterText"
                        )}
                      </h2>
                      <span>
                        {t<string>(
                          "emailFilterDetails.addFilter.createNewFilterText"
                        )}
                      </span>
                    </>
                  )}
                  {editFilter && (
                    <>
                      <div className="edit-filter-header-container">
                        <h2>
                          {t<string>(
                            "emailFilterDetails.editFilter.editFilterText"
                          )}
                        </h2>
                      </div>
                      <span>
                        Enter search parameters and click Search in the lower
                        right.
                      </span>
                    </>
                  )}
                </div>
                <div className="filter-form-container">
                  <div>
                    <Form
                      form={form}
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 14 }}
                      layout="horizontal"
                      initialValues={{
                        selectedEmailList: defaultEmailValues,
                        from: tempFilter.from,
                        subject: tempFilter.subject,
                        hasTheWords: tempFilter.hasTheWords,
                        date: moment(tempFilter.date),
                        hasAttachment: tempFilter.hasAttachment,
                      }}
                    >
                      {editFilter && (
                        <Form.Item
                          label={t<string>(
                            "emailFilterDetails.formItems.source"
                          )}
                        >
                          <div className="form-item-source">
                            <img
                              alt={
                                markedEmail?.sourceId === 1
                                  ? "outlook"
                                  : "gmail"
                              }
                              width={20}
                              height={20}
                              src={
                                markedEmail?.sourceId === 1
                                  ? outlookIcon
                                  : gmailIcon
                              }
                            />
                            <span>{markedEmail?.emailId}</span>
                          </div>
                        </Form.Item>
                      )}
                      {!editFilter && (
                        <Form.Item
                          style={{ textAlign: "left" }}
                          label={t<string>(
                            "emailFilterDetails.formItems.source"
                          )}
                          name="selectedEmailList"
                          rules={[
                            {
                              required: true,
                              message: "Please select at least one source!",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="Select a Source"
                            bordered={false}
                            onChange={handleChange}
                          >
                            {emailList.map((emailData: SelectedEmail) => {
                              return (
                                <Select.Option key={emailData.emailId}>
                                  <img
                                    alt={
                                      emailData.sourceId === 1
                                        ? "outlook"
                                        : "gmail"
                                    }
                                    width={20}
                                    height={20}
                                    src={
                                      emailData.sourceId === 1
                                        ? outlookIcon
                                        : gmailIcon
                                    }
                                  />
                                  &nbsp;
                                  <span>{emailData.emailId}</span>
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}

                      <Form.Item
                        label={t<string>(
                          "emailFilterDetails.formItems.fromLabel"
                        )}
                        name="from"
                      >
                        <EmailsInput
                          value={tempFilter.from}
                          onChange={(val) =>
                            setTempFilter({ ...tempFilter, from: val })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={t<string>(
                          "emailFilterDetails.formItems.subjectLabel"
                        )}
                        name="subject"
                        rules={[
                          {
                            pattern: /^[^'#&]+$/,
                            message:
                              "Search terms may not include a single quote, hash or an ampersand.",
                          },
                        ]}
                      >
                        <Input
                          value={tempFilter.subject}
                          onChange={(e) =>
                            setTempFilter({
                              ...tempFilter,
                              subject: e.target.value,
                            })
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        label={t<string>(
                          "emailFilterDetails.formItems.hasWordLabel"
                        )}
                        name="hasTheWords"
                        rules={[
                          {
                            pattern: /^[^'#&]+$/,
                            message:
                              "Search terms may not include a single quote, hash or an ampersand.",
                          },
                        ]}
                      >
                        <Input
                          value={tempFilter.hasTheWords}
                          onChange={(e) =>
                            setTempFilter({
                              ...tempFilter,
                              hasTheWords: e.target.value,
                            })
                          }
                          placeholder={t<string>(
                            "emailFilterDetails.formItems.inbox"
                          )}
                        />
                      </Form.Item>

                      <div className="form-date-input-container">
                        <Form.Item
                          label={t<string>(
                            "emailFilterDetails.formItems.formDateLable"
                          )}
                          name="date"
                        >
                          <DatePicker
                            allowClear={false}
                            bordered={false}
                            value={moment(tempFilter.date)}
                            format="MM/DD/YYYY"
                            onChange={(e: any) =>
                              setTempFilter({
                                ...tempFilter,
                                date: e.format("MM/DD/YYYY"),
                              })
                            }
                            disabledDate={(current) => {
                              return current <= moment().subtract(2, "years");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          label={t<string>(
                            "emailFilterDetails.formItems.hasAttached"
                          )}
                          valuePropName="checked"
                          name="hasAttachment"
                        >
                          <Checkbox
                            checked={tempFilter.hasAttachment}
                            onChange={(e) =>
                              setTempFilter({
                                ...tempFilter,
                                hasAttachment: !tempFilter.hasAttachment,
                              })
                            }
                          />
                        </Form.Item>
                      </div>
                      <Form.Item label="">
                        <div className="filter-search-button-container">
                          <button type="button" onClick={clearForm}>
                            {t<string>("emailFilterDetails.clear")}
                          </button>
                          <button type="button" onClick={validateFilter}>
                            <SearchOutlined />
                            {t<string>(
                              "emailFilterDetails.formItems.searchlabel"
                            )}
                          </button>
                        </div>
                      </Form.Item>
                      <Form.Item label="">
                        <div className="filter-search-button-container">
                          <Button
                            size="small"
                            type="text"
                            style={{ color: "#E13525" }}
                            // htmlType="submit"
                            onClick={addEditFilter}
                          >
                            {t<string>("emailFilterDetails.saveFilter")}
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>

                  {selectedEmailFilters?.length > 0 && (
                    <div>
                      <Collapse defaultActiveKey={["1"]}>
                        <Panel header="Existing Filters" key="1">
                          <div className="existing-filters-body-container">
                            <h4>
                              {t<string>("emailFilterDetails.filterText")}
                            </h4>
                            {selectedEmailFilters.map(
                              (selectedEmailfilter: SelectedMailFilter) => {
                                const {
                                  selectedEmail = {} as SelectedMailData,
                                  filters = [] as EmailFilters[],
                                } = selectedEmailfilter;
                                return (
                                  <ul key={selectedEmail.emailId}>
                                    <li className="filter-list-email-header">
                                      <img
                                        alt={
                                          selectedEmail.sourceId === 1
                                            ? "outlook"
                                            : "gmail"
                                        }
                                        width={20}
                                        height={20}
                                        src={
                                          selectedEmail.sourceId === 1
                                            ? outlookIcon
                                            : gmailIcon
                                        }
                                      />
                                      <span>{selectedEmail.emailId}</span>
                                    </li>
                                    {filters &&
                                      filters?.map((filter: EmailFilters) => {
                                        const {
                                          id,
                                          from,
                                          subject,
                                          hasTheWords,
                                          date,
                                          hasAttachment,
                                          mailbox,
                                        } = filter;
                                        if (tempFilter.id === id) {
                                          return <li key={tempFilter.id} />;
                                        }
                                        return (
                                          <li
                                            key={id}
                                            className="filter-item"
                                            onClick={() =>
                                              enableFilterEdit(
                                                filter,
                                                selectedEmail
                                              )
                                            }
                                          >
                                            <div className="filter-item-list-container">
                                              <div className="filter-name-container">
                                                {from && <span>{from}</span>}
                                                {subject && (
                                                  <span>{subject}</span>
                                                )}
                                                {hasTheWords && (
                                                  <span>{hasTheWords}</span>
                                                )}
                                                {date && <span>{date}</span>}
                                                {hasAttachment && (
                                                  <span>
                                                    {t<string>(
                                                      "emailFilterDetails.formItems.hasAttached"
                                                    )}
                                                  </span>
                                                )}
                                              </div>
                                              <div className="filter-search-button-container">
                                                <button
                                                  type="button"
                                                  style={{ color: "#E13525" }}
                                                  onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    removeFilter(
                                                      id,
                                                      selectedEmail
                                                    );
                                                  }}
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                );
                              }
                            )}
                          </div>
                        </Panel>
                      </Collapse>
                    </div>
                  )}
                  <button
                    type="button"
                    title={t<string>("emailFilterDetails.backToPanel")}
                    className="bottom-button-back-to-panels"
                    onClick={() => goBack()}
                  >
                    <ArrowLeftOutlined />
                    {t<string>("emailFilterDetails.backToPanelWithSpace")}
                  </button>
                </div>
              </div>
            </Spin>
            <div className="filter-sub-container">
              <div className="search-results-header-container">
                <div className="search-result-hearder">
                  <span>
                    Search Result -
                    {` ${validateFiltersResultList.length} ${" "}`}
                    mail(s)
                  </span>
                </div>

                {!validateFiltersResultList.length && (
                  <div className="search-result-hearder">
                    <span>
                      {t<string>("emailFilterDetails.maxSearchResult")}
                    </span>
                  </div>
                )}
              </div>
              <div>{validateFiltersResultList}</div>
            </div>
          </>
        )}
      </div>
      {isSearchSaveModalOpen && (
        <SaveFilterModal
          open={isSearchClicked}
          onClose={handleModalClose}
          onSubmit={handleModalSubmit}
        />
      )}
    </div>
    // </Spin>
  );
};

export default MainFilter;
