import { notification } from "antd";

export const errorNotification = (message: string) => {
  notification.error({
    message: "Error",
    description: message,
    placement: "bottomRight",
  });
};

export const successNotification = (message: string) => {
  notification.info({
    message: "Success",
    description: message,
    placement: "bottomRight",
  });
};
export const waringNotification = (message: string) => {
  notification.warning({
    message: "Warning",
    description: message,
    placement: "bottomRight",
  });
};
