import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./help-page.scss";
import { useEffect, useState } from "react";
import { Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Answer from "./answer";
import { faq } from "./faq";
import images from "../../assets/image-export-assets";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import URLS from "../../utils/urls";
import { ZOOM_CALL_LINK } from "../../utils/constants";

const HelpPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [comments, setComments] = useState("");

  const panelHeaderContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <span className="message-header-name">{t<string>("helpCenter")}</span>
      </div>
      <div className="PanelHeaderSubContainer2" />
    </div>
  );

  const goBack = () => {
    navigate(-1);
  };

  const [feedbackResponse, feedbackLoading, feedbackError, addFeedback] =
    useAxios();

  useEffect(() => {
    if (feedbackResponse) {
      setComments("");
    }
  }, [feedbackResponse]);

  const handleSendFeedback = () => {
    if (comments) {
      addFeedback({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: URLS.COMMON_APIS.SEND_FEEDBACK,
        data: {
          comments,
        },
      });
    }
  };

  useEffect(() => {
    if (window.Tawk_API && typeof window.Tawk_API.showWidget === "function") {
      window.Tawk_API.showWidget();
    }
    return () => {
      if (window.Tawk_API && typeof window.Tawk_API.hideWidget === "function") {
        window.Tawk_API.hideWidget();
      }
    };
  }, []);

  return (
    <div className="messages-container help-panel">
      {panelHeaderContent}
      <div className="message-main-container">
        <div className="message-close-row">
          <button
            type="button"
            className="back-to-panels-button"
            title={t<string>("backToPanelsButton")}
            onClick={() => goBack()}
          >
            <ArrowLeftOutlined />
            <span style={{ paddingLeft: "4px" }}>
              {t<string>("backToPanelsButton")}
            </span>
          </button>
        </div>
        <h1 className="help-title">{t<string>("whatYouTryingToDo")}</h1>

        <div className="answers-all-container">
          {faq.map((answer) => (
            <Answer answer={answer} key={answer.id} />
          ))}
        </div>

        <div className="answers-divider" />

        <div>
          <h2 className="help-tutorials">{t<string>("starterTutorials")}</h2>
          <div
            className="help-page__card-container"
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "self-start",
              margin: "0",
            }}
          >
            <div className="help-page__card" id="starttour001">
              <div className="help-page__card-img">
                <img
                  src={images.wpStartCreatingSmall}
                  alt="Start Creating Small"
                />
              </div>
              <div className="help-page__card-title">
                {t<string>("welcomePanelCard1Text")}
              </div>
            </div>
            <div className="help-page__card">
              <div
                className="help-page__card-img"
                style={{
                  border: "1.5px solid #226fa9",
                  borderRadius: "6px",
                  padding: "2px",
                  height: "105px",
                }}
              >
                <img
                  src={images.ScheduleCall}
                  alt="Schedule a Call"
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                />
              </div>
              <div className="help-page__card-title">
                <a
                  href={ZOOM_CALL_LINK}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#555355",
                  }}
                >
                  {t<string>("welcomePanelCard2Text")}
                </a>
              </div>
            </div>
            <div className="help-page__card">
              <div className="help-page__card-img">
                <img src={images.wpQuickOverview} alt="Quick Overview" />
              </div>
              <div className="help-page__card-title">
                {t<string>("welcomePanelCard3Text")}{" "}
                <span className="help-page__card-soon">
                  {t<string>("welcomePanelComingSoon")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="answers-divider" />

        <div className="help-comments-container">
          <h2 className="help-tutorials">{t<string>("giveFeedback")}</h2>

          <p className="help-feedback">{t<string>("submitCommentsFeedback")}</p>

          <label className="help-comments" htmlFor="help-textarea">
            {t<string>("comments")}
            <Spin spinning={feedbackLoading}>
              <textarea
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                className="help-textarea"
                id="help-textarea"
              />
            </Spin>
            {feedbackError && !comments && (
              <p className="error">{t<string>("sendFeedbackError")}</p>
            )}
          </label>

          <div className="message-close">
            <button
              type="button"
              className="help-send-button-black"
              onClick={() => setComments("")}
            >
              {t<string>("cancel")}
            </button>

            <button
              type="button"
              className="help-send-button"
              onClick={handleSendFeedback}
            >
              {t<string>("sendFeedback")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
