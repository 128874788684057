import createParentPanel1 from "./Images/Create_Parent_Panel_1.png";
import createParentPanel2withColorPicker from "./Images/createParentPanel2withColorPicker.png";
import createParentPanel3 from "./Images/createParentPanel3.png";
import createChildPanel1 from "./Images/createChildPanel1.png";
import createChildPanel2 from "./Images/createChildPanel2b.png";
import addaDataSource1 from "./Images/addaDataSource1.png";
import addaDataSource2 from "./Images/addaDataSource2.png";
import addaDataSource3 from "./Images/addaDataSource3.png";
import addaDataSource4 from "./Images/addaDataSource4.jpg";
import addaFilter1 from "./Images/addaFilter1.png";
import addaFilter2 from "./Images/addaFilter2.jpg";
import addaFilter3a from "./Images/addaFilter3a.jpg";
import addaSlackChannel1 from "./Images/addaSlackChannel1.jpg";
import addaSlackChannel2 from "./Images/addaSlackChannel2.jpg";
import addaSlackChannel3 from "./Images/addaSlackChannel3.jpg";
import driveAddFolderSourceAdded from "./Images/driveAddFolderSourceAdded.jpg";
import driveAddFolderFolderOpened from "./Images/driveAddFolderFolderOpened.jpg";
import driveAddFolderFilesSelected from "./Images/driveAddFolderFilesSelected.jpg";
import emptyPageLogo from "./Images/emptyPageLogo.png";
import wpStartCreatingSmall from "./Images/wpStartCreatingSmall.jpg";
import wpTemplatesExamples from "./Images/wpTemplatesExamples.jpg";
import wpQuickOverview from "./Images/wpQuickOverview.jpg";
import facebookIconFooter from "./Images/facebookIconFooter.jpg";
import instagramIconFooter from "./Images/instagramIconFooter.jpg";
import linkedInIconFooter from "./Images/linkedInIconFooter.jpg";
import twitterIconFooter from "./Images/twitterIconFooter.jpg";
import PanelsBetaLogo from "./Images/PanelsBetaLogo.png";
import PPanels from "./Images/P_Panels.png";
import ScheduleCall from "./Images/ScheduleCall.jpg";
import InviteAPerticipant1 from "./Images/InviteAPerticipant1.png";
import InviteAPerticipant2 from "./Images/InviteAPerticipant2.png";
import deleteFromPanel1 from "./Images/deleteFromPanel1.png";
import deleteFromPanel2 from "./Images/deleteFromPanel2.png";
import deleteFromPanel3 from "./Images/deleteFromPanel3.png";

const images = {
  createParentPanel1,
  createParentPanel2withColorPicker,
  createParentPanel3,
  createChildPanel1,
  createChildPanel2,
  addaDataSource1,
  addaDataSource2,
  addaDataSource3,
  addaDataSource4,
  addaFilter1,
  addaFilter2,
  addaFilter3a,
  addaSlackChannel1,
  addaSlackChannel2,
  addaSlackChannel3,
  driveAddFolderSourceAdded,
  driveAddFolderFolderOpened,
  driveAddFolderFilesSelected,
  emptyPageLogo,
  wpStartCreatingSmall,
  wpTemplatesExamples,
  wpQuickOverview,
  facebookIconFooter,
  instagramIconFooter,
  linkedInIconFooter,
  twitterIconFooter,
  PanelsBetaLogo,
  PPanels,
  ScheduleCall,
  InviteAPerticipant1,
  InviteAPerticipant2,
  deleteFromPanel1,
  deleteFromPanel2,
  deleteFromPanel3,
};

export default images;
