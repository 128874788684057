const panelStorage = [
  {
    children: [
      {
        createdDate: "5/27/2022 8:56:14 AM",
        id: "id:mkFNHfL6Ye0AAAAAAAAABw",
        isChecked: true,
        itemType: "File",
        kind: "drive#file",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        modifiedDate: "5/27/2022 8:47:15 AM",
        name: "file 1.docx",
        record_Id: 1,
        webUrl:
          "https://prabhaatinc-my.sharepoint.com/personal/amans_prabhaat_com/_layouts/15/Doc.aspx?sourcedoc=%7B8AB4D1F9-3EC4-43C9-8DE7-A35811573E5B%7D&file=abc.docx&action=default&mobileredirect=true",
      },
    ],
    folder: "Projects",
    id: "id:mkFNHfL6Ye0AAAAAAAAABg",
    parentRefId: "id:mkFNHfL6Ye0AAAAAAAAABg",
    itemType: "Folder",
  },
];
type Actions = {
  type: string;
  payload: any;
};

const UpdateDropBoxStoragePanelsData = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = panelStorage,
  action: Actions
) => {
  switch (action.type) {
    case "updateDropBoxStoragePanelsData":
      state = action.payload.data;
      return state;
    default:
      return state;
  }
};

export default UpdateDropBoxStoragePanelsData;
