import { useEffect } from "react";
import { useDispatch } from "react-redux";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../config";
import { setPanelsData } from "../../store/reducers/panel-data/actions/panels-data-actions";

export const useGetPanelsData = () => {
  const [
    panelsDataResponse,
    panelsDataLoading,
    panelsDataLoadingError,
    panelsData,
  ] = useAxios();

  const dispatch = useDispatch<any>();

  const loadPanelsData = () => {
    panelsData({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.PANELS_DATA,
    });
  };

  useEffect(() => {
    if (panelsDataResponse) {
      const res = panelsDataResponse as any;
      if (res && res.data.success) {
        const panelsData = {} as any;
        res.data.panelsData.forEach((item: any) => {
          panelsData[item.panelId] = {
            email: { gmail: {}, outlook: {} },
            slack: {},
          };
          item.email?.gmail.forEach((gmailDetail: any) => {
            if (gmailDetail) {
              const gmailId = gmailDetail.emailId;
              const gmailMails = { data: gmailDetail.data };
              panelsData[item.panelId].email.gmail[gmailId] = gmailMails;
            }
          });
          item.email?.outlook.forEach((outlookDetail: any) => {
            if (outlookDetail) {
              const outlookId = outlookDetail.emailId;
              const outlookMails = { data: outlookDetail.data };
              panelsData[item.panelId].email.outlook[outlookId] = outlookMails;
            }
          });
          const slackDetails = item.slack;
          if (slackDetails && slackDetails.length > 0) {
            const slackObj = {} as any;
            slackDetails.forEach((slack: any) => {
              if (!slackObj[slack.emailId]) {
                slackObj[slack.emailId] = {};
              }
              slackObj[slack.emailId][slack.slackId.workSpaceId] = {};
              slackObj[slack.emailId][slack.slackId.workSpaceId].channels = {};
              slackObj[slack.emailId][
                slack.slackId.workSpaceId
              ].directMessages = {};
              slack.slackId.data.directMessage.forEach((message: any) => {
                if (
                  !slackObj[slack.emailId][slack.slackId.workSpaceId]
                    .directMessages[message.channelId]
                ) {
                  slackObj[slack.emailId][
                    slack.slackId.workSpaceId
                  ].directMessages[message.channelId] = {};
                  slackObj[slack.emailId][
                    slack.slackId.workSpaceId
                  ].directMessages[message.channelId].messages = [];
                }
                slackObj[slack.emailId][
                  slack.slackId.workSpaceId
                ].directMessages[message.channelId].messages.push(message);
              });
              slack.slackId.data.channels.forEach((channel: any) => {
                if (
                  !slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ]
                ) {
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ] = {};
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ].messages = [];
                }
                slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ].messages.push(channel);
              });
            });
            panelsData[item.panelId].slack = slackObj;
          }
          panelsData[item.panelId].storage = {};
          if (item.storage?.oneDrive?.length > 0) {
            panelsData[item.panelId].storage.oneDrive = {} as any;
            item.storage?.oneDrive.forEach((oneDrive: any) => {
              if (
                oneDrive &&
                oneDrive?.oneDriveData &&
                oneDrive?.storageEmailId
              ) {
                panelsData[item.panelId].storage.oneDrive[
                  oneDrive.storageEmailId
                ] = oneDrive.oneDriveData;
              }
            });
          }

          if (item.storage?.googleDrive?.length > 0) {
            panelsData[item.panelId].storage.googleDrive = {} as any;
            item.storage?.googleDrive.forEach((googleDrive: any) => {
              if (
                googleDrive &&
                googleDrive.googleDriveData &&
                googleDrive.storageEmailId
              ) {
                panelsData[item.panelId].storage.googleDrive[
                  googleDrive.storageEmailId
                ] = googleDrive.googleDriveData;
              }
            });
          }

          if (item.storage?.dropBox?.length > 0) {
            panelsData[item.panelId].storage.dropBox = {} as any;
            item.storage?.dropBox.forEach((dropBox: any) => {
              if (dropBox && dropBox.dropBoxData && dropBox.storageEmailId) {
                panelsData[item.panelId].storage.dropBox[
                  dropBox.storageEmailId
                ] = dropBox.dropBoxData;
              }
            });
          }
        });
        dispatch(setPanelsData(panelsData));
      }
    }
  }, [panelsDataResponse]);

  return {
    panelsDataResponse,
    panelsDataLoading,
    panelsDataLoadingError,
    loadPanelsData,
  };
};
