/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { Cascader, Typography } from "antd";
import { Draggable, Droppable, DroppableProvided } from "react-beautiful-dnd";
import { ReactComponent as CheckIcon } from "../../../../../assets/Icons/check-icon.svg";
import { RootReducer } from "../../../../../store/reducers/root-reducer";
import ListTitle from "./list-title";
import AddTask from "./add-task";
import Card from "./card";
import useAxios from "../../../../../services/axios";
import SERVER_CONFIG from "../../../../../utils/config";
import {
  PriorityKeys,
  StatusKeys,
  TaskPriority,
  Card as CardData,
  CardSizeProps,
  TodoListProps,
  TaskStatusList,
  TodoStatusIDItems,
} from "../../../../../prop-types/prop-type";
import {
  Child as ChildType,
  Panel as PanelType,
} from "../../../../../interfaces/user-detail-interface";
import { Marker } from "../../../marker/marker";
import { updateActiveTodos } from "../../../../../store/reducers/todo/action/todo";
import { StrictModeDroppable } from "../../../droppable-dnd/strict-mode-droppable";

const { Text } = Typography;

const sortMenu = ["Default", "Priority", "Panels", "A-Z", "Z-A"];

const ToDoList = ({
  list,
  index,
  slideWidth,
  listId,
  mainWidth,
  mainHeight,
  selectedIndex,
  setSelectedCard,
  ccHeight,
  setccHeight,
  allIDs,
  viewAll,
  maxBoxWidth,
  priorityList,
  statusList,
  priorityKeys,
  statusKeys,
  loadingCard,
  provided,
}: {
  list: TodoListProps;
  index: number;
  slideWidth: number | undefined;
  listId: string | number | null;
  mainWidth: number;
  mainHeight: number;
  selectedIndex: number;
  setSelectedCard: Dispatch<SetStateAction<number>>;
  ccHeight: CardSizeProps[];
  setccHeight: Dispatch<SetStateAction<CardSizeProps[]>>;
  allIDs: string[];
  viewAll: boolean;
  maxBoxWidth: string;
  priorityList: TaskPriority[];
  statusList: TaskStatusList;
  priorityKeys: PriorityKeys;
  statusKeys: StatusKeys;
  loadingCard: boolean;
  provided?: DroppableProvided;
}) => {
  const [filterBy, setFilterBy] = useState<string>("Default");
  const [seeBy, setSeeBy] = useState("View All");
  const [panelList, setPanelList] = useState([]);
  const [selectPanelItem, setSelectPanelItem] = useState<ChildType[]>([]);
  const [assignedTo, setAssignedTo] = useState<(string | number)[]>([
    "None Assigned",
  ]);
  const [cardsData, setCardsData] = useState<CardData[]>([]);

  const { todoPanelList } = useSelector((state: RootReducer) => state.todo);
  const { activeTodos } = useSelector((state: RootReducer) => state.todo);
  const [createListResponse, loading, error, axiosFetch] = useAxios();
  // FILTER BY HANDLER
  const sortByHandler = (val: string) => {
    setFilterBy(val);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (createListResponse && createListResponse.data?.success) {
      dispatch(
        updateActiveTodos([
          ...activeTodos,
          {
            ...createListResponse.data.data,
            children: [],
            statusName: "Active",
          },
        ])
      );
    }
  }, [createListResponse]);

  useEffect(() => {
    const getResult = () => {
      const finalData = todoPanelList?.map((item: PanelType) => {
        const obj = {
          id: item?.id,
          value: item?.name,
          label: (
            <div className="panel-item-box">
              <div>
                {assignedTo[0] === item?.name && (
                  <CheckIcon className="cascade-check" />
                )}
              </div>
              <Marker
                color={item.color}
                fill={!(item.isStandalone === false)}
              />
              <Text>{item?.name}</Text>
            </div>
          ),
          children: item?.children?.map((innerItem: ChildType) => {
            const innerObj = {
              id: innerItem?.id,
              value: innerItem?.name,
              label: (
                <div className="panel-item-box">
                  <div>
                    {assignedTo?.length > 1 &&
                      assignedTo[1] === innerItem?.name && (
                        <CheckIcon className="cascade-check" />
                      )}
                  </div>
                  <Marker
                    color={innerItem.color}
                    fill={!(innerItem.isStandalone === false)}
                  />
                  <Text>{innerItem?.name}</Text>
                </div>
              ),
            };
            return innerObj;
          }),
        };
        return obj;
      });
      setPanelList(finalData);
    };
    getResult();
  }, [todoPanelList]);

  if (mainWidth < 959) {
    $(`#card-cover-${index}`).css({
      height: selectedIndex === index ? "auto" : "100%",
    });
  } else {
    $(`#card-cover-${index}`).css({
      height: "auto",
    });
  }

  const hh = $(`#taskcontainer-${index}`).prop("scrollHeight") - 212;

  const measureHeights = () => {
    const dd = hh > 0 ? hh : 0;
    const dataccHri = ccHeight
      .filter((x: CardSizeProps) => x?.id !== listId)
      .filter((z: CardSizeProps) => {
        return allIDs.indexOf(z?.id) !== -1;
      });
    setccHeight([...dataccHri, { id: `${listId}`, val: `${dd}` }]);
  };

  const onSubmitMainTodoTitle = async (val: string) => {
    const jsonToDoData = {
      id: null,
      name: val,
      statusId: "624eec6df6041cc524b96038",
      order: activeTodos.length,
    };

    await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.TODO_LIST_SAVE,
      data: jsonToDoData,
    });
  };

  useEffect(() => {
    measureHeights();
    // eslint-disable-next-line
  }, [list.cards, hh]);

  useEffect(() => {
    const cards = list?.cards;
    let cardListData = [];
    if (filterBy === "Priority") {
      cardListData = cards?.sort((a, b) => {
        if (a.priority < b?.priority) return -1;
        if (a.priority > b?.priority) return 1;
        return 0;
      });
    }
    if (filterBy === "Panels") {
      cardListData = cards?.filter((item: CardData) => {
        if (selectPanelItem?.length > 0 && selectPanelItem?.length <= 1) {
          if (selectPanelItem?.[0]?.id === item?.panelId) {
            return item;
          }
        }
        if (selectPanelItem?.length > 1) {
          if (selectPanelItem?.[1]?.id === item?.panelId) {
            return item;
          }
        }
        if (selectPanelItem?.length === 0) {
          return item;
        }
        return null;
      });
    }

    if (filterBy === "A-Z") {
      cardListData = cards?.sort((a, b) => {
        if (a.title < b?.title) return -1;
        if (a.title > b?.title) return 1;
        return 0;
      });
    }
    if (filterBy === "Z-A") {
      cardListData = cards?.sort((a, b) => {
        if (a.title > b?.title) return -1;
        if (a.title < b?.title) return 1;
        return 0;
      });
    }
    if (
      seeBy === "View All" ||
      seeBy === "Complete" ||
      seeBy === "InComplete"
    ) {
      cardListData = cards?.filter((item) => {
        if (
          item?.statusId === TodoStatusIDItems.inComplete &&
          seeBy === "InComplete"
        ) {
          return item;
        }
        if (
          item?.statusId === TodoStatusIDItems.completed &&
          seeBy === "Complete"
        ) {
          return item;
        }
        if (seeBy === "View All") {
          return item;
        }
        return null;
      });
    } else {
      cardListData = cards;
    }
    setCardsData(cardListData);
  }, [list?.cards, seeBy]);

  const AssignHandler = (value: (string | number)[]) => {
    // setSelectPanelItem(item);
    setAssignedTo(value);
    measureHeights();
  };

  return (
    <div
      ref={provided?.innerRef}
      id={`card-cover-${index}`}
      style={{
        flexBasis: maxBoxWidth,
        flexShrink: 0,
        order: list?.order,
      }}
    >
      <Draggable draggableId={list?.id} index={index}>
        {(provided) => (
          <div
            // style={{ width: `${slideWidth}px`, maxWidth: '100%' }}
            className="todo-card-container"
            onClick={() => {
              setSelectedCard(index);
              measureHeights();
            }}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <ListTitle
              provided={provided}
              title={list?.title}
              status={list?.status}
              listId={list?.id}
              list={list}
              slideWidth={slideWidth}
              sortMenu={sortMenu}
              filterBy={filterBy}
              sortByHandler={sortByHandler}
              seeBy={seeBy}
              setSeeBy={setSeeBy}
              measureHeights={measureHeights}
              onSubmitMainTodoTitle={onSubmitMainTodoTitle}
              loading={loading || loadingCard}
            />
            <div className="todo-card-parent">
              <AddTask
                listId={list?.id}
                measureHeights={measureHeights}
                isTitle={!!list?.title}
                isNewTask={!list?.createdDate}
              />
              {filterBy === "Panels" && (
                <Cascader
                  defaultOpen
                  bordered={false}
                  value={assignedTo}
                  options={panelList}
                  placement="bottomRight"
                  onChange={AssignHandler}
                />
              )}
            </div>

            <StrictModeDroppable droppableId={list?.id} type="task">
              {(providedP) => (
                <div
                  ref={providedP.innerRef}
                  {...providedP.droppableProps}
                  className="task-container"
                >
                  {cardsData &&
                    cardsData.map((card: CardData, i: number) => (
                      <Card
                        key={card?.id}
                        card={card}
                        index={i}
                        listId={list?.id}
                        measureHeights={measureHeights}
                        priorityList={priorityList}
                        statusList={statusList}
                        priorityKeys={priorityKeys}
                        statusKeys={statusKeys}
                        loadingCard={loadingCard}
                      />
                    ))}
                  {providedP.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default ToDoList;
