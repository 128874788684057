import { Dispatch } from "redux";
import { EmailDeleteType } from "../reducer/email-delete-reducer";

export const SET_EMAIL_DELETE_INFO = "setEmailDeleteInfo";
export const RESET_EMAIL_DELETE = "resetEmailDelete";
export const setEmailDeleteAction = (data: EmailDeleteType) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_EMAIL_DELETE_INFO,
      payload: data,
    });
  };
};
export const resetEmailDeleteAction = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RESET_EMAIL_DELETE,
    });
  };
};
