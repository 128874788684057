import { useState } from "react";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import SERVER_CONFIG from "../utils/config";
import LABELS from "../utils/labels";
import { errorNotification, successNotification } from "../utils/notification";
import store from "../store/store";

const axiosInstance = axios.create({
  baseURL: SERVER_CONFIG.BASE_URL,
  // mode: 'cors',
  headers: {
    "Content-Type": "application/json",
  },
});
const useAxios = () => {
  const [response, setResponse] = useState<AxiosResponse>();
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState(false);
  const axiosFetch = async (request: AxiosRequestConfig) => {
    try {
      setLoading(true);
      const result = await axiosInstance.request(request);
      setResponse(result);
      return result;
    } catch (err: any) {
      setError(err);
      return { error: err };
    } finally {
      setLoading(false);
    }
  };
  return [response, loading, error, axiosFetch] as const;
};

// const axiosInstance = axios.create({
//   baseURL: SERVER_CONFIG.BASE_URL,
//   // mode: 'cors',
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const { token } = state.Auth;
  const obj = config;
  if (
    !obj?.headers?.Authorization &&
    token &&
    obj.headers &&
    !obj.url?.includes(SERVER_CONFIG.API_URL.COMMON_APIS.LOGIN)
  ) {
    const headers = `Bearer ${token}`;
    obj.headers.Authorization = headers;
  }
  return obj;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.message) {
      successNotification(response.data.message);
    }
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 500:
        errorNotification(LABELS.SOMETHING_WENT_WRONG);
        break;
      case 400: {
        const { message } = error.response.data;
        errorNotification(message);
        break;
      }
      case 409: {
        const { message } = error.response.data;
        errorNotification(message);
        break;
      }
      case 401: {
        const { message } = error.response.data;
        errorNotification(message);
        break;
      }
      default:
        errorNotification(LABELS.SOMETHING_WENT_WRONG);
        break;
    }
    return Promise.reject(error);
  }
);

export default useAxios;
