/* eslint-disable react/jsx-no-useless-fragment */
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";

import "./participants.scss";

type ResetModalType = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const DeleteParticipantModal = ({
  open,
  onClose,
  onSubmit,
}: ResetModalType) => {
  const { t } = useTranslation();
  return (
    <Modal open={open} onCancel={onClose} footer={null} closeIcon={<></>}>
      <h5 className="reset-modal-heading">
        {t<string>("participants.deleteParticipant")}
      </h5>
      <div className="reset-modal-button-container">
        <Button className="reset-modal-button retain" onClick={onClose}>
          {t<string>("globalSettings.profile.deleteAllToDefaultModal.cancel")}
        </Button>
        <Button className="reset-modal-button reset" onClick={onSubmit}>
          {t<string>("globalSettings.profile.deleteAllToDefaultModal.delete")}
        </Button>
      </div>
    </Modal>
  );
};
