import { PanelsData } from "../../../../interfaces/user-detail-interface";
// import { outlookMailBody } from '../../../../prop-types/prop-type';

const panelData: PanelsData = {};
export type Actions = {
  type: string;
  payload: any;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const panelDataReducer = (state = panelData, action: Actions) => {
  switch (action.type) {
    case "updatePanelData":
      return { ...state, [action.payload.panelId]: action.payload.data };
    case "fetchPanelsData":
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default panelDataReducer;
