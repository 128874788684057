/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { clickWidgetBtn } from "../../../../store/reducers/todo/action/todo";
import Page from "./page";
import "./main-layout.scss";
import { StateInterface } from "../types";
import { RootReducer } from "../../../../store/reducers/root-reducer";
import TodoBoard from "./todo-board/todo-board";
import AddEditWidget from "../widgets";

const { Content } = Layout;

const ToDoPageComponent = () => {
  const todoRedux: StateInterface = useSelector(
    (state: RootReducer) => state.todo
  );
  return (
    <>
      {todoRedux?.todoboard_show && <TodoBoard />}
      {todoRedux.widget_dialog_show && <AddEditWidget />}
    </>
  );
};

const Todo = ({ todoType }: { todoType: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className="small-layout">
      <Content className="main-layout-content">
        <Page>
          <ToDoPageComponent />
        </Page>
      </Content>
    </div>
  );
};
export default Todo;
