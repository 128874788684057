import { Dispatch } from "redux";
import { PreviousPanelInfoType } from "../reducer/prev-panel-reducer";

export const SET_PREV_PANEL_INFO = "setPrevPanelInfo";
export const RESET_PREV_PANEL_INFO = "resetPrevPanelInfo";
export const setPreviousPanelInfoAction = (data: PreviousPanelInfoType) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_PREV_PANEL_INFO,
      payload: data,
    });
  };
};
export const resetPreviousPanelInfoAction = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: RESET_PREV_PANEL_INFO,
    });
  };
};
