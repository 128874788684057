import URLS from "./urls";

export const STRIPE_CONFIG = {
  CREATE_PORTAL_URL: "https://api.stripe.com/v1/billing_portal/sessions",
  SUBSCRIPTION_URL: "https://api.stripe.com/v1/subscriptions",
  CUSTOMERS_URl: "https://api.stripe.com/v1/customers",
  SECRET_KEY: process.env.REACT_APP_STRIPE_SECRET_KEY,
};

const SERVER_CONFIG = {
  SERVER_SOCKET_URL: `${process.env.REACT_APP_API_BASE_URL}/NotificationHub`,
  UI_MAIN_URL: process.env.REACT_APP_UI_MAIN_URL || "http://localhost:3000",
  // SERVER_SOCKET_URL: `process.env.REACT_APP_API_BASE_URL|${'/NotificationHub'}`,
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  ARCHIVED_STATUS_ID: process.env.REACT_APP_ARCHIVED_STATUS_ID,
  ACTIVE_STATUS_ID: process.env.REACT_APP_ACTIVE_STATUS_ID,
  // Slack config / Methods
  SLACK: {
    SIGNIN: "SignInSlack",
    SLACK_WORKSPACE_DATA: "GetSlackWorkspacesData",
    SLACK_CHANNEL_DATA: "GetSlackChannelsData",
    SLACK_USER_DATA: "GetSlackUsersData",
  },
  // Outlook config / methods
  OUTLOOK: {
    SIGNIN: "SignIn",
  },
  // OneDrive config / methods
  ONEDRIVE: {
    SIGNIN: "SignInGoogle",
  },
  // EMAIL config / methods
  GMAIL: {
    SIGNIN: "SignInGoogle",
  },
  HTTP_METHOD: {
    GET: "get",
    POST: "post",
    DELETE: "delete",
    PATCH: "patch",
    PUT: "put",
  },
  API_URL: {
    ...URLS,
  },
};
export const Constants = {
  REPLACE: "replace",
  NAME_PATH: "/name",
  ARCHIVE_PATH: "panelStatusId",
  CHANGE_ARCHIVE_STATUS_PATH: "/active",
  COLOR_PATH: "/color",
  MOVE_PATH: "/pid",
  PANEL_ACTION_CHANGE_COLOR: "CHANGE_COLOR",
  PANEL_ACTION_ARCHIVE: "ARCHIVE",
  PANEL_ACTION_CHANGE_PARENT: "MOVE",
  PANEL_ACTION_DELETE: "DELETE",
  PANEL_ACTION_RENAME: "RENAME",
};
export default SERVER_CONFIG;
