/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useMemo, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { HolderOutlined } from "@ant-design/icons";
import { ChromePicker } from "react-color";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Col, Dropdown, Input, Modal, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Child,
  Panel,
  PartialUpdate,
} from "../../interfaces/user-detail-interface";
import { RootReducer } from "../../store/reducers/root-reducer";
import {
  MenuItem,
  switchItem,
  addToParentGroup,
  renamePanelInCache,
  updatePanelsColorInCache,
  updatePanelsInCache,
  deletePanelInCache,
} from "./active-panel-util";
import { Marker } from "../reusable-components/marker/marker";
import {
  updateAfterPanelDrag,
  updateArchivedPanelsDetails,
  updatePanelsDetails,
} from "../../store/reducers/panels/actions/panel-actions";
import "./logged-in-sidebar.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG, { Constants } from "../../utils/config";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import SocketResponseHandler from "../../utils/socket/socket-response-handler";
import { setPanelsData } from "../../store/reducers/panel-data/actions/panels-data-actions";
import { StrictModeDroppable } from "../reusable-components/droppable-dnd/strict-mode-droppable";

type ActivePanelProps = {
  isDraggable: boolean;
};

function ActivePanels(props: ActivePanelProps) {
  const navigate = useNavigate();
  const { isDraggable } = props;
  const params = useParams();
  const { activePanels, archivedPanels } = useSelector(
    (state: RootReducer) => state.panels
  );

  const { activeTodos, archiveTodos } = useSelector(
    (state: RootReducer) => state.todo
  );

  const [editName, setEditName] = useState("");
  const [newName, setNewName] = useState("");
  const [editColor, setEditColor] = useState("");
  const [color, setColor] = useState("#fff");
  const [dropdown, setDropdown] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [delPanelName, setDelPanelName] = useState({
    id: "",
    pId: "",
    panelName: "",
    childrenLength: 0,
  });
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const [
    updatePanelResponse,
    updatePanelLoading,
    updatePanelLoadingError,
    updatePanel,
  ] = useAxios();
  const [
    panelsDataResponse,
    panelsDataLoading,
    panelsDataLoadingError,
    panelsData,
  ] = useAxios();
  const [action, setAction] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const chromPickerRef = useRef<HTMLInputElement>(null);

  const handleOk = () => {
    const { id } = delPanelName;
    // eslint-disable-next-line
    // @ts-ignore
    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.PANELS_DELETE(id),
    });
    setIsModalVisible(false);
    setAction(Constants.PANEL_ACTION_DELETE);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const activeSidebarElement = (id: string, pId: string) => {
    const { panelId, subpanelId }: any = params;
    if (subpanelId) {
      if (id === subpanelId && pId === panelId) {
        return true;
      }
      return false;
    }
    if (panelId) {
      if (id && !pId) {
        if (id === panelId) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  };

  const [
    reorderPanelsResponse,
    reorderPanelsLoading,
    reorderPanelsError,
    reorderPanels,
  ] = useAxios();

  const updatePanelsOrder = async (
    payload: { id: string; order: number }[]
  ) => {
    await reorderPanels({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.PANELS_REORDERING,
      data: payload,
    });
  };

  // Flatting Active Panels array for List UI; Instead of using nested map statement in code flating it before hand
  const activePanelsList: Array<Panel | Child> = useMemo(() => {
    return [...activePanels]
      .sort((panelA: Panel, panelB: Panel) => panelA.order - panelB.order)
      .map((parent: Panel) => {
        if (parent.children) {
          const { children = [] } = parent;
          return [
            parent,
            ...children.sort(
              (childA: Child, childB: Child) => childA.order - childB.order
            ),
          ];
        }
        return [parent];
      })
      .flat();
  }, [activePanels]);

  const changeParentOnReorder = (newPid: string, id: string) => {
    const partialUpdateParams = {} as PartialUpdate;
    partialUpdateParams.op = Constants.REPLACE;
    partialUpdateParams.path = Constants.MOVE_PATH;
    partialUpdateParams.value = newPid;
    // eslint-disable-next-line
      // @ts-ignore
    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });

    if (params.subpanelId === id) {
      navigate(`/panels/${newPid}/${id}`);
    }
  };
  const onDragEnd = (result: any) => {
    // If element is dropped outside droppable area
    if (!result.destination) {
      return;
    }
    const droppedItem = activePanelsList[result.source.index];

    const destinationIndex: number = result.destination.index;
    const sourceIndex = result.source.index;

    const isDroppedUpper = destinationIndex < sourceIndex;
    const isChild = "pId" in droppedItem;
    const isChildPanel = "pId" in activePanelsList[destinationIndex];

    const isLastChild = isDroppedUpper && isChild && !isChildPanel;

    const isLastParent =
      !isChild && activePanelsList.length - 1 === destinationIndex;

    const getDroppedInItem = () => {
      if (isLastParent) {
        let lastIndex: number = sourceIndex;

        activePanelsList.forEach((panel: Panel | Child, i) => {
          const isPanelChild = "pId" in panel;
          if (!isPanelChild) {
            lastIndex = i;
          }
        });

        return activePanelsList[lastIndex];
      }
      if (isLastChild) {
        return activePanelsList[destinationIndex - 1];
      }

      return activePanelsList[destinationIndex];
    };

    const droppedInItem = getDroppedInItem();

    switchItem(
      droppedItem,
      droppedInItem,
      activePanels,
      dispatch,
      updatePanelsDetails,
      updateAfterPanelDrag,
      updatePanelsOrder,
      changeParentOnReorder,
      isDroppedUpper,
      isLastChild,
      isLastParent
    );
  };
  const deletePanel = (
    id: string,
    pId: string,
    panelName: string,
    childrenLength: number
  ) => {
    setIsModalVisible(true);
    setDelPanelName({ id, pId, panelName, childrenLength });
  };
  const updatePanelColor = (id: string, color: any) => {
    const partialUpdateParams = {} as PartialUpdate;
    partialUpdateParams.op = Constants.REPLACE;
    partialUpdateParams.path = Constants.COLOR_PATH;
    partialUpdateParams.value = color;
    // eslint-disable-next-line
    // @ts-ignore
    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
    setAction(Constants.PANEL_ACTION_CHANGE_COLOR);
  };
  const renamePanel = (event: any, id: string) => {
    if (!event.target.value) {
      setNewName("");
      setEditName("");
      return;
    }
    const partialUpdateParams = {} as PartialUpdate;
    partialUpdateParams.op = Constants.REPLACE;
    partialUpdateParams.path = Constants.NAME_PATH;
    partialUpdateParams.value = event.target.value;
    // eslint-disable-next-line
    // @ts-ignore
    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
    setAction(Constants.PANEL_ACTION_RENAME);
  };
  const archivePanel = (id: string) => {
    const partialUpdateParams = {
      op: Constants.REPLACE,
      path: Constants.CHANGE_ARCHIVE_STATUS_PATH,
      value: "false",
    };

    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
    setAction(Constants.PANEL_ACTION_ARCHIVE);
  };
  const changePanelParent = (newPid: string, id: string) => {
    const partialUpdateParams = {} as PartialUpdate;
    partialUpdateParams.op = Constants.REPLACE;
    partialUpdateParams.path = Constants.MOVE_PATH;
    partialUpdateParams.value = newPid;
    // eslint-disable-next-line
    // @ts-ignore
    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
    setAction(Constants.PANEL_ACTION_CHANGE_PARENT);
  };
  const redirectToParent = (delPanelName: any) => {
    if (
      delPanelName.pId === params.panelId &&
      delPanelName.id === params.subpanelId &&
      activePanels.length > 0
    ) {
      navigate(`/panels/${delPanelName.pId}`);
    }
  };
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(updatePanelResponse);
    if (updatePanelResponse) {
      const res = updatePanelResponse as any;
      if (res && res.data.success) {
        switch (action) {
          case Constants.PANEL_ACTION_CHANGE_COLOR:
            updatePanelsColorInCache(
              res.data.data,
              activePanels,
              dispatch,
              updatePanelsDetails
            );
            // If Location contains same panel pI or child ID the update root color
            if (
              window.location.href.includes(
                res.data.data.id || res.data.data.pId
              )
            ) {
              document.documentElement.style.setProperty(
                "--primary-theme-color",
                color
              );
            }
            setEditColor("");
            setColor("");
            break;
          case Constants.PANEL_ACTION_ARCHIVE:
            updatePanelsInCache(
              res.data.data,
              activePanels,
              archivedPanels,
              dispatch,
              updateArchivedPanelsDetails,
              updatePanelsDetails
            );
            redirectToParent(res.data.data);
            break;
          case Constants.PANEL_ACTION_RENAME:
            renamePanelInCache(
              res.data.data,
              activePanels,
              dispatch,
              updatePanelsDetails
            );
            setEditName("");
            break;
          case Constants.PANEL_ACTION_DELETE:
            deletePanelInCache(
              delPanelName,
              activePanels,
              dispatch,
              updatePanelsDetails,
              activeTodos,
              archiveTodos
            );
            redirectToParent(delPanelName);
            break;
          case Constants.PANEL_ACTION_CHANGE_PARENT:
            addToParentGroup(
              res.data.data,
              params.panelId as string,
              activePanelsList,
              activePanels,
              dispatch,
              updatePanelsDetails
            );
            navigate(`/panels/${res.data.data.pId}/${res.data.data.id}`);
            break;
          default:
            break;
        }
      }
    }
  }, [updatePanelResponse]);
  // const redirectToParent = (delPanelName: any) => {
  //   if (
  //     delPanelName.pId === params.panelId &&
  //     delPanelName.id === params.subpanelId &&
  //     activePanels.length > 0
  //   ) {
  //     navigate(`/panels/${delPanelName.pId}`);
  //   }
  // };
  const updateName = (e: any) => {
    setEditName(e.id);
    setNewName(e.name);
    setTimeout(
      (inputRef) => {
        inputRef.current?.focus();
      },
      0,
      inputRef
    );
  };
  // const testfn1 = () => {
  //   inputRef.current?.focus();
  // };

  const updateColor = (e: any) => {
    setEditColor(e.id);
    setColor(e.color);
    setTimeout(
      (chromPickerRef) => {
        chromPickerRef.current?.focus();
      },
      0,
      chromPickerRef
    );
  };
  // const testfn3 = () => {
  //   chromPickerRef.current?.focus();
  // };
  // const onContextMenu = (e:any) => {
  //   setDropdown(e.id);
  //   if (e.pId) {
  //     navigate(`/panels/${e.pId}/${e.id}`);
  //   } else {
  //     navigate(`/panels/${e.id}`);
  //   }
  // };

  const receiveMessage = useSelector(
    (state: RootReducer) => state.signalRReceiveMessage
  );
  const { panelData } = useSelector((state: RootReducer) => state);

  useEffect(() => {
    /// analyzing message from server and processing frontend data based on that
    if (receiveMessage && receiveMessage.message) {
      // notification.open({
      //   message: 'New Notification',
      //   description: 'hello',
      // });
      SocketResponseHandler(
        activePanels,
        panelData,
        receiveMessage.message,
        params,
        dispatch
      );
    }
  }, [receiveMessage]);
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    panelsData({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.PANELS_DATA,
    });
  }, []);

  useEffect(() => {
    if (panelsDataResponse) {
      const res = panelsDataResponse as any;
      if (res && res.data.success) {
        const panelsData = {} as any;
        res.data.panelsData.forEach((item: any) => {
          // const id  = item.email.gmail[0];
          // const emails  = item.email.gmail[0];
          // const { [item.panelId]: {email :{ gmail: {id: {emails} ={}} = {} } = {} } = {}} = panelsData;
          //   = item.email.gmail[0];
          // panelsData[item.panelId]['email']['gmail'][item.email.gmail[0]] = item.email.gmail[1];
          // panelsData[item.panelId] = { email: { gmail:} };
          // panelsData[item.panelId]['email'] = ['gmail'];
          // panelsData[item.panelId]['email']['gmail'] = item.email.gmail[0];
          // panelsData[item.panelId]['email']['gmail'][item.email.gmail[0]] = item.email.gmail[1];
          panelsData[item.panelId] = {
            email: { gmail: {}, outlook: {} },
            slack: {},
          };
          item.email?.gmail.forEach((gmailDetail: any) => {
            if (gmailDetail) {
              const gmailId = gmailDetail.emailId;
              const gmailMails = { data: gmailDetail.data };
              panelsData[item.panelId].email.gmail[gmailId] = gmailMails;
            }
          });
          item.email?.outlook.forEach((outlookDetail: any) => {
            if (outlookDetail) {
              const outlookId = outlookDetail.emailId;
              const outlookMails = { data: outlookDetail.data };
              panelsData[item.panelId].email.outlook[outlookId] = outlookMails;
            }
          });
          const slackDetails = item.slack;
          if (slackDetails && slackDetails.length > 0) {
            const slackObj = {} as any;
            slackDetails.forEach((slack: any) => {
              if (!slackObj[slack.emailId]) {
                slackObj[slack.emailId] = {};
              }
              slackObj[slack.emailId][slack.slackId.workSpaceId] = {};
              slackObj[slack.emailId][slack.slackId.workSpaceId].channels = {};
              slackObj[slack.emailId][
                slack.slackId.workSpaceId
              ].directMessages = {};
              slack.slackId.data.directMessage.forEach((message: any) => {
                if (
                  !slackObj[slack.emailId][slack.slackId.workSpaceId]
                    .directMessages[message.channelId]
                ) {
                  slackObj[slack.emailId][
                    slack.slackId.workSpaceId
                  ].directMessages[message.channelId] = {};
                  slackObj[slack.emailId][
                    slack.slackId.workSpaceId
                  ].directMessages[message.channelId].messages = [];
                }
                slackObj[slack.emailId][
                  slack.slackId.workSpaceId
                ].directMessages[message.channelId].messages.push(message);
              });
              slack.slackId.data.channels.forEach((channel: any) => {
                if (
                  !slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ]
                ) {
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ] = {};
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ].messages = [];
                }
                slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ].messages.push(channel);
              });
            });
            panelsData[item.panelId].slack = slackObj;
          }
          panelsData[item.panelId].storage = {};
          if (item.storage?.oneDrive?.length > 0) {
            panelsData[item.panelId].storage.oneDrive = {} as any;
            item.storage?.oneDrive.forEach((oneDrive: any) => {
              if (
                oneDrive &&
                oneDrive?.oneDriveData &&
                oneDrive?.storageEmailId
              ) {
                panelsData[item.panelId].storage.oneDrive[
                  oneDrive.storageEmailId
                ] = oneDrive.oneDriveData;
              }
            });
          }

          if (item.storage?.googleDrive?.length > 0) {
            panelsData[item.panelId].storage.googleDrive = {} as any;
            item.storage?.googleDrive.forEach((googleDrive: any) => {
              if (
                googleDrive &&
                googleDrive.googleDriveData &&
                googleDrive.storageEmailId
              ) {
                panelsData[item.panelId].storage.googleDrive[
                  googleDrive.storageEmailId
                ] = googleDrive.googleDriveData;
              }
            });
          }

          if (item.storage?.dropBox?.length > 0) {
            panelsData[item.panelId].storage.dropBox = {} as any;
            item.storage?.dropBox.forEach((dropBox: any) => {
              if (dropBox && dropBox.dropBoxData && dropBox.storageEmailId) {
                panelsData[item.panelId].storage.dropBox[
                  dropBox.storageEmailId
                ] = dropBox.dropBoxData;
              }
            });
          }
        });
        dispatch(setPanelsData(panelsData));
      }
    }
  }, [panelsDataResponse]);
  return (
    <Spin
      size="large"
      spinning={updatePanelLoading as boolean}
      wrapperClassName="spin-full-width"
    >
      <div style={{ width: "100%" }}>
        <Modal
          visible={isModalVisible}
          centered
          footer={false}
          closable={false}
          width="385px"
        >
          <div className="delete-modal-container">
            <p>
              {t<string>("deleteConfirm")}
              <span className="cus-panel-name">{delPanelName.panelName}</span>
              {delPanelName.id &&
                !delPanelName.pId &&
                delPanelName.childrenLength > 0 && (
                  <span> and all its children</span>
                )}
              {t<string>("undoneText")}
            </p>
            <div className="delete-modal-footer">
              <button
                type="button"
                className="modal-cancel-button"
                onClick={handleCancel}
              >
                {t<string>("globalSettings.cancel")}
              </button>
              <button
                type="button"
                className="modal-ok-button"
                onClick={handleOk}
              >
                {t<string>("globalSettings.permanentlyDelete")}
              </button>
            </div>
          </div>
        </Modal>
        <DragDropContext onDragEnd={onDragEnd}>
          <StrictModeDroppable droppableId="characters">
            {(provided) => (
              <ul
                style={{ padding: "0px" }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {activePanelsList.map((e: any, index: number) => {
                  const apath = e.pId
                    ? `/panels/${e.pId}/${e.id}`
                    : `/panels/${e.id}`;
                  const path = editName ? "#" : apath;
                  return (
                    <Draggable
                      key={e.id}
                      draggableId={e.id}
                      index={index}
                      isDragDisabled={!isDraggable}
                    >
                      {(provided) => (
                        <li
                          className={classNames("sidebar-list-item", {
                            highlight: activeSidebarElement(e.id, e.pId),
                          })}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Dropdown
                            overlay={
                              <MenuItem // passing menu item for each sidebar element
                                data={e}
                                editName={editName}
                                setEditName={() => {
                                  updateName(e);
                                }}
                                setDropdown={setDropdown}
                                addToParentGroup={(pId: string) =>
                                  changePanelParent(pId, e.id)
                                }
                                setEditColor={() => {
                                  updateColor(e);
                                }}
                                archivePanel={(id: string) => archivePanel(id)}
                                deletePanel={(
                                  id: string,
                                  pId: string,
                                  name: string,
                                  childrenLength: number
                                ) => deletePanel(id, pId, name, childrenLength)}
                              />
                            }
                            trigger={["contextMenu"]}
                            arrow
                            visible={dropdown === e.id}
                            onVisibleChange={() => {
                              if (dropdown) {
                                setDropdown("");
                              }
                            }}
                          >
                            <Link
                              className={
                                !e.pId
                                  ? "sidebar-items"
                                  : "sidebar-items sidebar-items-child"
                              }
                              key={e.id}
                              to={path}
                            >
                              <div
                                className="sidebar-item-contents"
                                onContextMenu={() => setDropdown(e.id)} // capturing right click events on each active panel list item
                              >
                                <div
                                  className={classNames(
                                    "sidebar-header-button-items-drag",
                                    {
                                      hide: !isDraggable,
                                    }
                                  )}
                                  {...provided.dragHandleProps}
                                >
                                  <HolderOutlined role="button" />
                                </div>
                                <Marker
                                  color={e.color}
                                  fill={e.pId || e.isStandalone === true}
                                />
                                {editName !== e.id ? (
                                  <span className="panel-name">{e.name}</span>
                                ) : (
                                  <Input
                                    ref={inputRef as any}
                                    value={newName}
                                    placeholder="Panel Name"
                                    onChange={(el) => {
                                      if (el.target.value.length < 32) {
                                        setNewName(el.target.value);
                                      }
                                    }}
                                    onBlur={(el) => renamePanel(el, e.id)}
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        renamePanel(event, e.id);
                                      }
                                    }}
                                  />
                                )}
                              </div>

                              <div>
                                <button
                                  type="button"
                                  className="sidebar-element-option-button"
                                  onClick={() => setDropdown(e.id)}
                                >
                                  ...
                                </button>
                              </div>
                            </Link>
                          </Dropdown>
                          {editColor === e.id ? (
                            <div
                              role="button"
                              ref={chromPickerRef as any}
                              tabIndex={0}
                              className="color-picker-container"
                            >
                              <ChromePicker
                                className="chrome-picker-custom"
                                color={color}
                                onChange={(updatedColor) => {
                                  setColor(updatedColor.hex);
                                }}
                              />
                              {/* <button type="button" className="color-picker-button" onClick={() => updatePanelColor(e.id, color)}>{t<string>('select')}</button> */}
                              <Row>
                                <Col xs={12}>
                                  <Button onClick={() => setEditColor("")}>
                                    Cancel
                                  </Button>
                                </Col>
                                <Col xs={12}>
                                  <Button
                                    onClick={() =>
                                      updatePanelColor(e.id, color)
                                    }
                                  >
                                    select
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </Spin>
  );
}

export default ActivePanels;
