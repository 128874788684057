import outlookIcon from "./Icons/outlook365.svg";
import gmailIcon from "./Icons/logosDS_Gmail.png";
import oneDriveIcon from "./Icons/oneDrive-new.svg";
import googleDriveIcon from "./Icons/gDrive-new.svg";
import slackIcon from "./Icons/slack-new.svg";
import dropboxIcon from "./Icons/dropbox-new.svg";
import jiraIcon from "./Icons/GroupDS_Jira.png";
import outlookLarge from "./Icons/outlook1.png";
import gmailLarge from "./Icons/google-new.svg";
import slackLarge from "./Icons/slack.png";
import slackavatar from "./Icons/slackavatar.png";
import folderIcon from "./Icons/folderIcon.png";
import fileIcons from "./Icons/onedriveIconFolder.svg";
import gDriveIcon from "./Icons/g_drive.png";
import dropBoxFolderIcon from "./Icons/folderDropboxIconNew.svg";
import dropBoxFolderIconMain from "./Icons/dropboxFolderIcons.svg";
import trashBinIcon from "./Icons/trash-bin.svg";
import smallArrowIcon from "./Icons/small-arrow.svg";
import editPencilIcon from "./Icons/edit-pencil.svg";
import panelLogicMainLogo from "./Icons/PanelLogicMainLogo.svg";
import errorCheck from "./Icons/error-check.svg";
import successCheck from "./Icons/success-check.svg";
import GreyCircle from "./Icons/Grey_Circle.png";
import wpArrowRight from "./Icons/wpArrowRight.svg";

export { ReactComponent as AddCollaboratorIcon } from "./Icons/addCollaborator.svg";

const icons = {
  outlookIcon,
  gmailIcon,
  oneDriveIcon,
  slackIcon,
  googleDriveIcon,
  dropboxIcon,
  jiraIcon,
  outlookLarge,
  gmailLarge,
  slackLarge,
  slackavatar,
  folderIcon,
  fileIcons,
  gDriveIcon,
  dropBoxFolderIcon,
  dropBoxFolderIconMain,
  trashBinIcon,
  smallArrowIcon,
  editPencilIcon,
  panelLogicMainLogo,
  errorCheck,
  successCheck,
  GreyCircle,
  wpArrowRight,
};

export default icons;
