/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isExpired } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { RootReducer } from "./store/reducers/root-reducer";
import { authAction } from "./store/reducers/auth/action/auth-action";
import SERVER_CONFIG from "./utils/config";
import useAxios from "./services/axios";
import { LoginParams } from "./interfaces/user-detail-interface";
import { setUserInfoAction } from "./store/reducers/user/action/user-action";

import UpdateConnection from "./store/reducers/signalR/connection/actions/connection-actions";

const useAuthProvider = () => {
  const dispatch = useDispatch<any>();
  const { token } = useSelector((state: RootReducer) => state.Auth);
  const { connection } = useSelector(
    (state: RootReducer) => state.signalRConnection
  );
  const navigate = useNavigate();

  const [loginResponse, loading, error, axiosFetch] = useAxios();
  const [
    loginLoginPageResponse,
    loadingLoginPage,
    errorLoginPage,
    axiosLoginPageFetch,
  ] = useAxios();
  const logIn = (smsValue: string, token: string) => {
    const payload = {
      smsCode: smsValue,
    };
    // eslint-disable-next-line
    // @ts-ignore
    axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: `${SERVER_CONFIG.API_URL.COMMON_APIS.VALIDATE_SEND_SMS}/${token}`,
      data: payload,
    });
  };

  const loginInLoginPage = (payload: LoginParams) => {
    // eslint-disable-next-line
    // @ts-ignore
    axiosLoginPageFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.COMMON_APIS.LOGIN_DOUBLE,
      data: payload,
    });
  };

  const logOut = () => {
    const logout = null;
    connection?.stop().then(() => {
      console.log("socket connection stopped");
    });
    dispatch(authAction(logout));
  };
  const isLoggedIn = () => {
    return !isExpired(token);
  };

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    const res = loginResponse?.data;
    const resDouble = loginLoginPageResponse?.data;
    if (res && res.success) {
      navigate("/panels");
      dispatch(authAction(res.data.token));
      dispatch(setUserInfoAction(res.data));
    }
    if (
      resDouble &&
      resDouble.success &&
      resDouble.data.twoFactorAuthRequired
    ) {
      const { verificationCode, subscriptionStatus } = resDouble.data;

      if (verificationCode && subscriptionStatus === "Active") {
        navigate(`/enter-confirm/${verificationCode}`);
      }
    }
    if (
      resDouble &&
      resDouble.success &&
      !resDouble.data.twoFactorAuthRequired
    ) {
      navigate("/panels");
      dispatch(authAction(resDouble.data.token));
      dispatch(setUserInfoAction(resDouble.data));
    }
  }, [loginResponse, loginLoginPageResponse]);

  return {
    logIn,
    logOut,
    isLoggedIn,
    loginInLoginPage,
    loading,
    loadingLoginPage,
    loginLoginPageResponse: loginLoginPageResponse?.data?.data,
    error,
  };
};

export default useAuthProvider;
