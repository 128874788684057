/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import Actions from "../../../action";

export type PathnameLocation = {
  pathname: string;
};

const pathnameLocationStorage: PathnameLocation = {
  pathname: "",
};

const AddNewPathnameLocationReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = pathnameLocationStorage,
  action: Actions
) => {
  switch (action.type) {
    case "setPathnameLocation":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default AddNewPathnameLocationReducer;
