/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditEmailModal from "./profile-edit-email-modal";
import "./profile.scss";
import icons from "../../assets/icon-export-assets";
import useAxios from "../../services/axios";
import { RootReducer } from "../../store/reducers/root-reducer";
import SERVER_CONFIG, { STRIPE_CONFIG } from "../../utils/config";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import Input from "../reusable-components/input/input";
import { Loader } from "../reusable-components/loader/loader";
import useAuthProvider from "../../authProvider";
import { ResetAllDataModal } from "./reset-all-data-modal";

const { editPencilIcon } = icons;

const createPasswordValidationSchema = (
  t: TFunction<"translation", undefined>
) =>
  Yup.object({
    oldPassword: Yup.string().required(
      t<string>("globalSettings.profile.validations.passwordRequired")
    ),
    newPassword: Yup.string()
      .required(
        t<string>("globalSettings.profile.validations.passwordRequired")
      )
      .min(
        10,
        t<string>("globalSettings.profile.validations.moreThanCharacters", {
          count: 10,
        })
      )
      .matches(
        /[A-Z]/,
        t<string>("globalSettings.profile.validations.includeUppercase")
      )
      .matches(
        /[a-z]/,
        t<string>("globalSettings.profile.validations.includeLowercase")
      )
      .matches(
        /[0-9]/,
        t<string>("globalSettings.profile.validations.includeNumber")
      )
      .matches(
        /[!@#%&]/,
        t<string>("globalSettings.profile.validations.includeCharacter")
      ),
    confirmPassword: Yup.string()
      .required(
        t<string>("globalSettings.profile.validations.passwordsShouldMatch")
      )
      .oneOf(
        [Yup.ref("newPassword"), null],
        t<string>("globalSettings.profile.validations.passwordsShouldMatch")
      ),
  });

const Profile: FC = () => {
  const [
    resetUserData,
    resetUserDataLoading,
    resetUserDataError,
    fetchResetUserData,
  ] = useAxios();

  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const { logOut } = useAuthProvider();
  const navigate = useNavigate();

  const handleResetAllData = () => {
    setIsResetModalOpen(false);
    fetchResetUserData({
      method: SERVER_CONFIG.HTTP_METHOD.DELETE,
      url: SERVER_CONFIG.API_URL.COMMON_APIS.RESET_ALL_DATA,
    });
  };

  useEffect(() => {
    if (resetUserData && resetUserData.data.success) {
      navigate("/login");
      logOut();
    }
  }, [resetUserData]);

  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const user = useSelector((state: RootReducer) => state.user);
  const [loginResponse, loading, error, axiosFetch] = useAxios();

  const passwordValidationSchema = createPasswordValidationSchema(t);
  const [isEditEmailModalOpened, setIsEditEmailModalOpened] = useState(false);
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [customerInfo, setCustomerInfo] = useState<Record<string, any>>({});

  const handleSubmitForm = async (values: Record<string, unknown>) => {
    const response = (await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.USER.UPDATE,
      data: values,
    })) as any;

    dispatch(setUserInfoAction(response));
  };
  const formik = useFormik({
    initialValues: {
      changeType: "password",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const headers = {
    Authorization: `Bearer ${STRIPE_CONFIG.SECRET_KEY}`,
  };

  const getBillingLink = async () => {
    // @ts-expect-error We have type errors in axiosFetch
    const { data } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: STRIPE_CONFIG.CREATE_PORTAL_URL,
      params: {
        customer: user.customerId,
        return_url: `${SERVER_CONFIG.UI_MAIN_URL}/globalsettings/profile`,
      },
      headers,
    });

    if (data?.url) setDashboardUrl(data?.url);
  };

  const getCustomerInfo = async () => {
    // @ts-expect-error We have type errors in axiosFetch
    const { data } = await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: `${STRIPE_CONFIG.CUSTOMERS_URl}/${user?.customerId}`,
      headers,
    });
    setCustomerInfo(data);
  };

  useEffect(() => {
    getBillingLink();
    getCustomerInfo();
  }, []);

  const openModal = () => {
    setIsEditEmailModalOpened(true);
  };

  const closeModal = () => {
    setIsEditEmailModalOpened(false);
  };

  const handleSubmitEditEmail = async (values: Record<string, any>) => {
    const response = (await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.USER.UPDATE,
      data: values,
    })) as any;

    dispatch(setUserInfoAction(response));

    closeModal();
  };

  const isDataLoading = loading || resetUserDataLoading;

  return isDataLoading ? (
    <Loader loading={isDataLoading} />
  ) : (
    <div className="profile-container">
      <EditEmailModal
        open={isEditEmailModalOpened}
        onCancel={closeModal}
        onSubmit={handleSubmitEditEmail}
      />

      <p className="profile-header-text">
        {t<string>("globalSettings.profile.headerText")}
      </p>
      <div className="contact-information-container">
        <div>
          <p className="contact-information-heading">
            {t<string>("globalSettings.profile.contactInformation")}
          </p>
          <p className="contact-information-item">{customerInfo?.name}</p>
          {Object.values<string>(customerInfo?.address || {})?.map(
            (item, i) => (
              <p key={i} className="contact-information-item">
                {item}
              </p>
            )
          )}
        </div>
        <div>
          <a href={dashboardUrl} className="edit-at-stripe-link">
            <img
              src={editPencilIcon}
              alt="edit pencil"
              className="edit-at-stripe-icon"
            />
            {t<string>("globalSettings.profile.editAtStripe")}
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="contact-information-container">
        <div>
          <p className="contact-information-heading">
            {t<string>("globalSettings.profile.panelsEmail")}
          </p>
          <p className="contact-information-item">{customerInfo?.email}</p>
        </div>
        <div>
          <span className="edit-at-stripe-link" onClick={openModal}>
            <img
              src={editPencilIcon}
              alt="edit pencil"
              className="edit-at-stripe-icon"
            />
            {t<string>("globalSettings.profile.edit")}
          </span>
        </div>
      </div>
      <div className="divider" />
      <p className="contact-information-heading">
        {t<string>("globalSettings.profile.password")}
      </p>
      <p className="contact-information-item">
        {t<string>("globalSettings.profile.notRemember")}{" "}
        <a href="/" className="reset-password-by-email">
          {t<string>("globalSettings.profile.resetEmail")}
        </a>
      </p>
      <div className="passwords-container">
        <Input
          label={t<string>("globalSettings.profile.oldPasswordLabel")}
          type="password"
          showEye
          id="oldPassword"
          name="oldPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.oldPassword}
          error={formik.touched.oldPassword && formik.errors.oldPassword}
        />
        <Input
          label={t<string>("globalSettings.profile.newPasswordLabel")}
          className="new-password-input"
          type="password"
          showEye
          id="newPassword"
          name="newPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          error={formik.touched.newPassword && formik.errors.newPassword}
        />
        <Input
          label={t<string>("globalSettings.profile.confirmNewPasswordLabel")}
          className="confirm-new-password-input"
          type="password"
          showEye
          id="confirmPassword"
          name="confirmPassword"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmPassword}
          error={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
        />
      </div>
      <div className="button-container">
        <Button
          danger
          className="save-button"
          disabled={!formik.isValid || !formik.dirty}
          // @ts-ignore
          onClick={formik.handleSubmit}
        >
          {t<string>("globalSettings.profile.saveButton")}
        </Button>
      </div>
      <div className="divider" />

      <Button
        className="reset-all-button"
        onClick={() => setIsResetModalOpen(true)}
      >
        {t<string>("globalSettings.profile.deleteAllToDefaultModal.delete")}
      </Button>

      <ResetAllDataModal
        open={isResetModalOpen}
        onClose={() => setIsResetModalOpen(false)}
        onSubmit={handleResetAllData}
      />
    </div>
  );
};

export default Profile;
