// import { getAuthToken } from '../../../../utils';
import Actions from "../../../action";
import { LOGIN, LOGOUT } from "../action/auth-action";

export const intialState: any = {
  token: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const AuthReducer = (state = intialState, action: Actions) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, token: action.payload };
    case LOGOUT:
      return { ...state, token: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
