export const WIDGET_DIALOG_OPEN = "WIDGET_DIALOG_OPEN";
export const TODOBOARD_SHOW = "TODOBOARD_SHOW";
export const UPDATE_TODOS = "UPDATE_TODOS";
export const UPDATE_COLUMNS = "UPDATE_COLUMNS";
export const UPDATE_TODO_WIDGETID = "UPDATE_TODO_WIDGETID";
export const UPDATE_ACTIVE_TODOS = "UPDATE_ACTIVE_TODOS";
export const UPDATE_ARCHIVE_TODOS = "UPDATE_ARCHIVE_TODOS";
export const TODO_PRIORITY_LIST = "TODO_PRIORITY_LIST";
export const TODO_MASTER_STATUS_DATA = "TODO_MASTER_STATUS_DATA";
export const TODO_PANEL_LIST = "TODO_PANEL_LIST";
export const TODO_LIST = "TODO_LIST";
export const WIDGETS_UPDATED = "WIDGETS_UPDATED";
