/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
/* eslint-disable no-var */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable default-param-last */
const panelStorageIntialData = [] as any;
// const panelStorageIntialData = [{
//   children: [{
//     createdDateTime: '5/17/2022 4:44:10 PM',
//     driveId: 'b!3QN-41fiWUeiz0-96F_I-UEUcZRDEfVEu1m4t48BGv6FhaP9O7SOQYDXysv6EBWo',
//     drivePath: '/drive/root:/Intel',
//     driveType: 'business',
//     fileSystemCreatedDate: null,
//     fileSystemLastModifiedDate: null,
//     folder: 'Intel',
//     folderChildCount: null,
//     id: '012PH7AC7Z2G2IVRB6ZFBY3Z5DLAIVOPS3',
//     isChecked: true,
//     isSelected: true,
//     itemType: 'file',
//     lastModifiedDateTime: '5/17/2022 4:44:45 PM',
//     name: 'abc.docx',
//     packageType: null,
//     parentRefId: '012PH7AC7PTNUXHIJ6WNAZFSQTWXQ2TDC7',
//     record_Id: 4,
//     specialFolderName: null,
//     webUrl: 'https://prabhaatinc-my.sharepoint.com/personal/amans_prabhaat_com/_layouts/15/Doc.aspx?sourcedoc=%7B8AB4D1F9-3EC4-43C9-8DE7-A35811573E5B%7D&file=abc.docx&action=default&mobileredirect=true',
//   }],
//   folder: 'Intel',
//   id: '012PH7AC7PTNUXHIJ6WNAZFSQTWXQ2TDC7',
//   parentRefId: '012PH7AC7PTNUXHIJ6WNAZFSQTWXQ2TDC7',
//   itemType: 'Folder',
// }];
// type panelStorages = [
//   {
//     children: object[];
//     folder: string;
//     id: string;
//     parentRefId: string;
//   }
// ];

export type Actions = {
  type: string;
  payload: any;
};

const PanelStorageDataReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = panelStorageIntialData,
  action: Actions
) => {
  switch (action.type) {
    case "updateStoragePanelData":
      state = action.payload.data;
      return state;
    default:
      return state;
  }
};

export default PanelStorageDataReducer;
