import { FC } from "react";
import PanelsMainLogo from "../../assets/Icons/PanelsMainLogo.svg";
import PanelsLogo01 from "../../assets/Icons/PanelsLogo01.svg";
import images from "../../assets/image-export-assets";
import "./footer.scss";

const socialLinks = [
  {
    title: "linkedIn",
    logoSrc: images.linkedInIconFooter,
    linkPath: "https://www.linkedin.com/company/panellogic",
  },
  {
    title: "twitter",
    logoSrc: images.twitterIconFooter,
    linkPath: "https://twitter.com/PanelLogic",
  },
  {
    title: "facebook",
    logoSrc: images.facebookIconFooter,
    linkPath:
      "https://www.facebook.com/profile.php?id=100094275431450&mibextid=LQQJ4d",
  },
  {
    title: "instagram",
    logoSrc: images.instagramIconFooter,
    linkPath: "https://instagram.com/panellogic?igshid=MTIzZWMxMTBkOA==",
  },
];

export const Footer: FC = () => {
  return (
    <footer className="footer">
      <div className="footer__main-content">
        <div className="footer__main-block">
          <div>
            <div className="footer__block">
              <div className="footer__main-left">
                <div className="footer__imgs">
                  <img src={PanelsLogo01} alt="logo" className="footer__img1" />
                  <img
                    src={PanelsMainLogo}
                    alt="logo"
                    className="footer__img2"
                  />
                </div>
              </div>
              <p className="footer__finally-text">
                Finally, a way to organize email and messages by project – just
                the way it works best for you!
              </p>
            </div>
          </div>
          <div className="footer__columns">
            <p className="footer__block-name">Privacy</p>

            <a
              href="https://panellogic.com/privacy-policy/"
              className="footer__link"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://panellogic.com/privacy-policy/#cookie"
              className="footer__link"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
            <a
              href="https://panellogic.com/data-security/"
              className="footer__link"
              target="_blank"
              rel="noreferrer"
            >
              Data Security
            </a>
          </div>
          <div className="footer__columns">
            <p className="footer__block-name">Terms</p>
            <a
              href="https://panellogic.com/terms-of-use/"
              className="footer__link"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>
            <a
              href="https://panellogic.com/acceptable-use-policy/"
              className="footer__link"
              target="_blank"
              rel="noreferrer"
            >
              Acceptable Use Policy
            </a>
          </div>
        </div>

        <div className="footer__follow-container">
          <div className="footer__follow">
            <p className="footer__block-name">Follow Us</p>
            <div className="footer__socials-block">
              {socialLinks.map((social, i) => (
                <div key={i} className="footer__socials">
                  <a href={social.linkPath} target="_blank" rel="noreferrer">
                    <img
                      src={social.logoSrc}
                      alt={`${social.title} icon`}
                      className="footer__socials"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="footer__divider" />

      <div className="footer__down-part">
        <div className="footer__first">
          ©{new Date().getFullYear()} PanelLogic, LLC. All rights reserved.
          Various trademarks held by their respective owners.
        </div>

        <div className="footer__second">
          Made by hand with care in ten cities around the world.
        </div>
      </div>
    </footer>
  );
};
