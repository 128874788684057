import { FC } from "react";
import { Spin } from "antd";
import "./loader.scss";

export type LoaderProps = {
  loading: boolean;
};

export const Loader: FC<LoaderProps> = ({ loading }) => {
  return loading ? (
    <div className="loader">
      <Spin size="large" />
    </div>
  ) : null;
};
