import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./participants.scss";
import { FC } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { UpgradePlan } from "./UpgradePlan";
import { InviteParticipants } from "./InviteParticipants";
import { RootReducer } from "../../store/reducers/root-reducer";
import { User } from "../../interfaces/user-detail-interface";
import { StripeProduct } from "../../prop-types/prop-type";

export const Participants: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const { subscription }: User = useSelector(
    (state: RootReducer) => state.user
  );

  const panelHeaderContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <span className="message-header-name">{t<string>("Participants")}</span>
      </div>
      <div className="PanelHeaderSubContainer2" />
    </div>
  );

  const goBack = () => {
    navigate("/panels");
  };

  const isUpgradePage = location.pathname.includes("upgrade");
  const isStripeStatusPage = location.pathname.includes("success");

  return (
    <div className="messages-container collab-panel">
      {panelHeaderContent}
      <div className="message-main-container">
        <div className="message-close">
          <button
            type="button"
            className="collab-back-button"
            title={t<string>("backToPanelsButton")}
            onClick={goBack}
          >
            <ArrowLeftOutlined />
            <span style={{ paddingLeft: "4px" }}>
              {t<string>("backToPanelsButton")}
            </span>
          </button>
        </div>

        {isStripeStatusPage ? (
          <div>
            <p>
              Your current subscription is <span>{`${subscription.type}`}</span>
            </p>
            {subscription.type === StripeProduct.Standard ? (
              <div>
                <Link to="/participants/upgrade">
                  Click here to upgrade your subscription
                </Link>
              </div>
            ) : (
              <div>
                <Link to="/participants/invite">
                  Click here to invite participants
                </Link>
              </div>
            )}
          </div>
        ) : (
          <>
            <h1 className="collab-title collab-18px">
              {`${t<string>("participants.invitePeopleTo")} ${
                isUpgradePage
                  ? t<string>("participants.view")
                  : t<string>("participants.join")
              } ${t<string>("participants.yourPanels")}`}
            </h1>

            <div className="collab-text">
              {isUpgradePage
                ? t<string>("participants.upgradePageInfo")
                : t<string>("participants.invitePageInfo")}
            </div>

            <div className="collab-divider" />

            {isUpgradePage ? <UpgradePlan /> : <InviteParticipants />}
          </>
        )}
      </div>
    </div>
  );
};
