/* eslint-disable no-console */
/* eslint-disable max-len */
import { useEffect, useMemo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  QuestionCircleOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import "./parent.scss";
import { Spin } from "antd";
import { ParentInLayoutProp } from "../../prop-types/prop-type";
import { RootReducer } from "../../store/reducers/root-reducer";
import Panel from "../panel/panel";
import {
  Child,
  Panel as PanelType,
} from "../../interfaces/user-detail-interface";
import {
  updateArchivedPanelsDetails,
  updatePanelsDetails,
} from "../../store/reducers/panels/actions/panel-actions";
import { panelIdToSetupAction } from "../../store/reducers/panel-to-set/actions/panel-to-set-action";
import images from "../../assets/image-export-assets";
import icons from "../../assets/icon-export-assets";
import SERVER_CONFIG, { Constants } from "../../utils/config";
import useAxios from "../../services/axios";
import { setZoomPanelInfoAction } from "../../store/reducers/zoomPanels/actions/zoom-panels-actions";
import { ZOOM_CALL_LINK } from "../../utils/constants";

function Parent({ panelId, archived }: ParentInLayoutProp) {
  const { t } = useTranslation();
  const { innerWidth: width } = window;
  let MAX_COL_SIZE = 1;
  const MIN_COL_SIZE = 1;
  if (width > 1700) {
    MAX_COL_SIZE = 4;
  }
  if (width > 1024 && width < 1700) {
    MAX_COL_SIZE = 3;
  }
  if (width > 768 && width < 1024) {
    MAX_COL_SIZE = 2;
  }
  if (width < 768) {
    MAX_COL_SIZE = 1;
  }

  const panels = useSelector((state: RootReducer) => state.panels.activePanels);
  const apanels = useSelector(
    (state: RootReducer) => state.panels.archivedPanels
  );
  const zoomInfo = useSelector((state: RootReducer) => state.zoom);

  const parentRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { panelIdToSetup } = useSelector(
    (state: RootReducer) => state.panelIdToSetup
  );

  const selectedElement: any = useMemo(() => {
    if (archived) {
      const parent = apanels?.filter((ele: PanelType) => {
        return ele.id === panelId;
      });
      return parent[0];
    }
    const parent = panels?.filter((ele: PanelType) => {
      return ele.id === panelId;
    });
    return parent[0];
  }, [panelId, panels, apanels]);

  const selectedElementId = useMemo(() => {
    return selectedElement?.id || "";
  }, [selectedElement]);

  const panelZoomInfo = useMemo(() => {
    return selectedElement && zoomInfo[selectedElementId]
      ? zoomInfo[selectedElementId]
      : {
          columns: MAX_COL_SIZE,
          rows: MAX_COL_SIZE,
        };
  }, [zoomInfo, selectedElement]);

  const childrenLength = useMemo(() => {
    if (selectedElement?.children?.length) {
      return selectedElement.children.length;
    }

    return 0;
  }, [selectedElement]);

  useEffect(() => {
    if (childrenLength && !zoomInfo[selectedElementId]) {
      const { children = [] } = selectedElement;

      if (children.length < panelZoomInfo.columns) {
        panelZoomInfo.columns = children.length;
      } else if (children.length > panelZoomInfo.columns) {
        panelZoomInfo.columns = MAX_COL_SIZE;
      } else {
        panelZoomInfo.columns = children.length;
      }

      const newNoOfRows = Math.ceil(children.length / panelZoomInfo.columns);
      panelZoomInfo.rows = newNoOfRows;

      dispatch(
        setZoomPanelInfoAction({
          [selectedElementId]: panelZoomInfo,
        })
      );
    }
  }, [childrenLength, selectedElement]);

  useEffect(() => {
    if (childrenLength && zoomInfo[selectedElementId]) {
      const { children = [] } = selectedElement;

      const newNoOfRows = Math.ceil(children.length / panelZoomInfo.columns);

      if (newNoOfRows < panelZoomInfo.rows) {
        panelZoomInfo.rows = newNoOfRows;

        dispatch(
          setZoomPanelInfoAction({
            [selectedElementId]: panelZoomInfo,
          })
        );
      }
    }
  }, [childrenLength, selectedElement]);

  const params = useParams();

  const gridTemplateRows = useMemo(() => {
    if (params.source) {
      return "99%";
    }
    if (!selectedElement?.children?.length) return "none";
    const { children } = selectedElement;
    const proportion = Math.round(10000 / panelZoomInfo.rows) / 100;
    const noOfRows = Math.ceil(children.length / panelZoomInfo.columns);
    return `${proportion - 0.33}% `.repeat(noOfRows);
  }, [zoomInfo, selectedElement, params]);

  const PanelList = useMemo(() => {
    if (!selectedElement?.children) return [];
    const { children } = selectedElement;

    if (!children || !children?.length) {
      return [<Panel item={selectedElement} key={0} archived={archived} />];
    }

    const noOfLastColCells =
      children.length % panelZoomInfo.columns || panelZoomInfo.columns;
    const noOfRows = Math.ceil(children.length / panelZoomInfo.columns);

    const panelsRowGrid = Number(gridTemplateRows.split("%")[0]);

    const position = (index: number) => {
      const row = Math.ceil(index / panelZoomInfo.columns);
      const col = index % panelZoomInfo.columns || panelZoomInfo.columns;
      const height = row === noOfRows - 1 && col > noOfLastColCells ? 2 : 1;
      return `${row} / ${col} / ${
        panelsRowGrid > 90 ? row + 1 : row + height
      } / ${col + 1}`;
    };

    if (panelIdToSetup) {
      const filtered = children.filter(
        (panel: any) => panel.id === panelIdToSetup
      );
      if (filtered.length === 0) {
        return dispatch(panelIdToSetupAction({ panelIdToSetup: "" }));
      }
      return <Panel item={filtered[0]} />;
    }

    return [...children]
      .sort((panelA: Child, panelB: Child) => panelA.order - panelB.order)
      .map((panel: Child, index: number) => (
        <Panel
          key={panel.id}
          item={panel}
          position={position(index + 1)}
          archived={archived}
        />
      ));
  }, [
    selectedElement?.children,
    zoomInfo,
    panelIdToSetup,
    panels,
    gridTemplateRows,
  ]);

  const zoomIn = useCallback(() => {
    if (
      panelZoomInfo.columns > MIN_COL_SIZE &&
      panelZoomInfo.rows === MIN_COL_SIZE
    ) {
      dispatch(
        setZoomPanelInfoAction({
          [selectedElementId]: {
            columns: panelZoomInfo.columns - 1,
            rows: panelZoomInfo.rows,
          },
        })
      );
    } else if (panelZoomInfo.columns > MIN_COL_SIZE)
      dispatch(
        setZoomPanelInfoAction({
          [selectedElementId]: {
            columns: panelZoomInfo.columns,
            rows: panelZoomInfo.rows - 1,
          },
        })
      );
  }, [zoomInfo, selectedElement]);

  const zoomOut = useCallback(() => {
    if (panelZoomInfo.columns < MAX_COL_SIZE) {
      dispatch(
        setZoomPanelInfoAction({
          [selectedElementId]: {
            columns: panelZoomInfo.columns + 1,
            rows: panelZoomInfo.rows,
          },
        })
      );
    }
    if (
      panelZoomInfo.rows < MAX_COL_SIZE &&
      panelZoomInfo.columns === MAX_COL_SIZE
    ) {
      dispatch(
        setZoomPanelInfoAction({
          [selectedElementId]: {
            columns: panelZoomInfo.columns,
            rows: panelZoomInfo.rows + 1,
          },
        })
      );
    }
  }, [zoomInfo, selectedElement]);

  const canZoomIn = useMemo(() => {
    if (!selectedElement?.children?.length) return false;
    if (panelZoomInfo.columns === 1 && panelZoomInfo.rows === 1) return false;
    return true;
  }, [zoomInfo, selectedElement]);

  const canZoomOut = useMemo(() => {
    if (!selectedElement?.children?.length) return false;
    const { children = [] } = selectedElement;
    if (children.length === panelZoomInfo.columns) return false;
    const noOfRows = Math.ceil(children.length / panelZoomInfo.columns);
    if (noOfRows === panelZoomInfo.rows) return false;
    if (
      panelZoomInfo.rows === MAX_COL_SIZE &&
      panelZoomInfo.columns === MAX_COL_SIZE
    ) {
      return false;
    }
    return true;
  }, [zoomInfo, selectedElement]);

  const [
    updatePanelResponse,
    updatePanelLoading,
    updatePanelLoadingError,
    updatePanel,
  ] = useAxios();

  useEffect(() => {
    if (updatePanelResponse?.data.success) {
      const { id } = selectedElement;
      const tempActivePanels = [...panels];
      const tempArchivedPanels = [...apanels];
      const indexOfArchived = tempArchivedPanels.findIndex(
        (e: PanelType) => e.id === id
      );
      const archivedParent = tempArchivedPanels.filter((e: PanelType) => {
        return e.id === id;
      });
      let activeParentDetails: PanelType = {} as PanelType;
      activeParentDetails = {
        ...archivedParent[0],
        active: true,
        order: tempActivePanels.length,
        children: archivedParent[0].children.map((e: Child) => {
          return { ...e, active: true };
        }),
      };
      tempArchivedPanels.splice(indexOfArchived, 1);
      tempActivePanels.splice(tempActivePanels.length, 0, activeParentDetails);
      dispatch(updatePanelsDetails([...tempActivePanels]));
      dispatch(updateArchivedPanelsDetails([...tempArchivedPanels]));
      navigate(`/panels/${id}`);
    }
  }, [updatePanelResponse]);

  const makeActive = () => {
    const { id } = selectedElement;
    const partialUpdateParams = {
      op: Constants.REPLACE,
      path: Constants.CHANGE_ARCHIVE_STATUS_PATH,
      value: "true",
    };

    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
  };

  const emptyPageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (parentRef && parentRef.current) {
      parentRef.current.scrollTop = 0;
    }
  }, [parentRef, childrenLength, selectedElementId]);

  return (
    <>
      {archived && (
        <Spin spinning={updatePanelLoading}>
          <div className="archivedHeader">
            <div>
              <span>{t<string>("archivedParentText")}</span>
              {updatePanelLoadingError && (
                <p className="error-update-msg">
                  {t<string>("sendFeedbackError")}
                </p>
              )}
            </div>
            <div>
              <button
                type="button"
                className="make-active-button"
                onClick={makeActive}
              >
                {t<string>("makeActiveButton")}
              </button>
            </div>
          </div>
        </Spin>
      )}
      {!selectedElement && (
        <div className="empty-page" ref={emptyPageRef}>
          <div className="EmptyPanelContainer">
            <div className="empty-page__logo">
              <img src={images.emptyPageLogo} alt="logo" />
            </div>
            <h1 className="empty-page__title">
              {t<string>("welcomePanelText")}
            </h1>
            <div className="empty-page__welcome-text-container">
              <div className="empty-page__welcome-text">
                {t<string>("welcomePanelTextFirstPart")}{" "}
                <span className="empty-page__welcome-text-span">
                  {t<string>("welcomePanelTextAddPanel")}
                </span>{" "}
                {t<string>("welcomePanelTextSecondPart")}
              </div>
              <div className="empty-page__welcome-text">
                {t<string>("welcomePanelTextThirdPart")}{" "}
                <QuestionCircleOutlined />.
              </div>
            </div>
            <div className="empty-page__card-container">
              <div className="empty-page__card" id="starttour001">
                <div className="empty-page__card-img">
                  <img
                    src={images.wpStartCreatingSmall}
                    alt="Start Creating Small"
                    className="empty-page__card"
                  />
                </div>
                <div className="empty-page__card-info">
                  <div className="empty-page__card-title">
                    {t<string>("welcomePanelCard1Text")}{" "}
                    <img src={icons.wpArrowRight} alt="arrow" />
                  </div>
                  <div className="empty-page__card-est">Est. 5 min</div>
                </div>
              </div>
              <div className="empty-page__card">
                <div
                  className="empty-page__card-img"
                  style={{
                    border: "3px solid #226fa9",
                    borderRadius: "6px",
                    padding: "10px",
                    height: "193px",
                  }}
                >
                  <img
                    src={images.ScheduleCall}
                    alt="Schedule a Zoom Call"
                    style={{
                      height: "100%",
                    }}
                  />
                </div>
                <div className="empty-page__card-info">
                  <div className="empty-page__card-title">
                    <a
                      href={ZOOM_CALL_LINK}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "rgba(0, 0, 0, .85)",
                      }}
                    >
                      {t<string>("welcomePanelCard2Text")}{" "}
                      <img src={icons.wpArrowRight} alt="arrow" />
                    </a>
                  </div>
                  <div className="empty-page__card-est">Est. 30 min</div>
                </div>
              </div>
              <div className="empty-page__card">
                <div className="empty-page__card-img">
                  <img
                    src={images.wpQuickOverview}
                    alt="Quick Overview"
                    className="empty-page__card"
                  />
                </div>
                <div className="empty-page__card-info">
                  <div className="empty-page__card-title">
                    {t<string>("welcomePanelCard3Text")}{" "}
                    <div className="empty-page__card-title-soon">
                      {t<string>("welcomePanelComingSoon")}
                    </div>
                  </div>
                  <div className="empty-page__card-est">Est. 3 min</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedElement && (
        <div
          ref={parentRef}
          className="ParentPanelContainer"
          style={{ gridTemplateRows }}
        >
          {PanelList}
          {selectedElement &&
          (selectedElement.children.length === 0 ||
            selectedElement.children.length === 1) ? (
            ""
          ) : (
            <div className="zoomButtonContainer">
              <button
                type="button"
                className="buttonItems"
                title="zoom In"
                disabled={!canZoomIn}
                onClick={zoomIn}
              >
                <ZoomInOutlined />
              </button>
              <button
                type="button"
                className="buttonItems"
                title="zoom Out"
                disabled={!canZoomOut}
                onClick={zoomOut}
              >
                <ZoomOutOutlined />
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Parent;
