import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AddCollaboratorIcon } from "../../assets/icon-export-assets";

import "./participants.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG, { STRIPE_CONFIG } from "../../utils/config";
import { RootReducer } from "../../store/reducers/root-reducer";
import { User } from "../../interfaces/user-detail-interface";

export const UpgradePlan: FC = () => {
  const { t } = useTranslation();
  const { customerId }: User = useSelector((state: RootReducer) => state.user);

  const [dashboardUrl, setDashboardUrl] = useState("");

  const [response, loading, error, axiosFetch] = useAxios();

  const getBillingLink = async () => {
    await axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: STRIPE_CONFIG.CREATE_PORTAL_URL,
      params: {
        customer: customerId,
        return_url: `${SERVER_CONFIG.UI_MAIN_URL}/participants/success`,
      },
      headers: {
        Authorization: `Bearer ${STRIPE_CONFIG.SECRET_KEY}`,
      },
    });
  };

  useEffect(() => {
    getBillingLink();
  }, []);

  useEffect(() => {
    if (response?.data?.url) {
      setDashboardUrl(response.data.url);
    }
  }, [response]);

  return (
    <>
      <h2 className="collab-title collab-16px">
        {t<string>("participants.benefits")}
      </h2>

      <div className="upgrade-container">
        <div>
          <AddCollaboratorIcon className="collaboration-icon" />
        </div>
        <div>
          <h3 className="collab-14px collab-title">
            {t<string>("Participants")}
          </h3>

          <p className="collab-text">
            {t<string>("participants.invitePeopleToSeeSharedExperience")}
          </p>
          <p className="collab-text">
            {t<string>("participants.keepPeopleUpToDate")}
          </p>
          <p className="collab-text">
            {t<string>("participants.participantsWillBeAble")}
          </p>
        </div>
      </div>

      <div className="collab-divider" />

      <h3 className="collab-14px collab-title">
        {t<string>("participants.subscriptionStatus")}
      </h3>

      <p className="collab-text-subscribe">
        {t<string>("participants.currentlySubscribed")}
      </p>

      {!loading && (
        <a href={dashboardUrl}>
          <button className="collab-send-button" type="button">
            {t<string>("participants.upgradeToPremium")}
          </button>
        </a>
      )}

      {error && (
        <div className="collab-send-button">
          {t<string>("sendFeedbackError")}
        </div>
      )}
    </>
  );
};
