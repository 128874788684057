import {
  Child,
  Panel,
  Panels,
  PanelSettings,
} from "../../../../interfaces/user-detail-interface";
import Actions from "../../../action";
import PanelActionTypes from "../action-types/panel-ation-types";

export const defaultPanelSettings: PanelSettings = {
  maxLinesPerEmail: 3,
  maxEmailViewedPerFilter: 5,
  maxEmailCapacityPerFilter: 60,
  expandedViewNumberofCharacters: 400,
  fontSize: 14,
  showMailFields: {
    date: true,
    subject: true,
    attachments: true,
    from: true,
  },
  isNotification: true,
  maxLinesPerSlackMessageViewd: 3,
  maxMessagePerSlackChannelViewd: 3,
  maxNumberofMessagesPerchannel: 21,
  maxFoldersPerChannel: 3,
  maxFilesPerChannel: 20,
  sortFoldersBy: "Date Modified",
  autoSize: false,
};

const panels: Panels = {
  activePanels: [],
  archivedPanels: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const panelReducer = (state = panels, action: Actions) => {
  switch (action.type) {
    case PanelActionTypes.DELETE_PANEL: {
      const itemIndex = state.activePanels.findIndex(
        (panel) => panel.id === action.payload.pId
      );
      const panelItem = state.activePanels[itemIndex];
      const resolvedPanelItem = {
        ...panelItem,
        children: panelItem.children.filter(
          (child) => child.id !== action.payload.id
        ),
      };

      const updatedPanels = [...state.activePanels];
      updatedPanels.splice(itemIndex, 1, resolvedPanelItem);
      return {
        ...state,
        activePanels: updatedPanels,
      };
    }

    case PanelActionTypes.UPDATE_PANEL_CHILDREN_SETTINGS_BY_ID: {
      const { id, pId } = action.payload.activePanel;
      const { data } = action.payload;
      const foundChild = state.activePanels.find(
        (panel: Panel) => panel.id === pId
      );
      const indexChild = state.activePanels.findIndex(
        (panel: Panel) => panel.id === pId
      );
      const updatedActivePanels = [...state.activePanels];
      const foundChildToUpdate = foundChild?.children.map((child: Child) => {
        if (child.id === id) {
          return {
            ...child,
            panelSettings: {
              ...child.panelSettings,
              ...data,
            },
          };
        }
        return child;
      });
      updatedActivePanels[indexChild].children = foundChildToUpdate || [];

      return {
        ...state,
        activePanels: updatedActivePanels,
      };
    }

    case PanelActionTypes.UPDATE_PANEL_SETTINGS_BY_ID: {
      const { id } = action.payload.activePanel;
      const { data } = action.payload;
      const foundPanel = state.activePanels.find(
        (panel: Panel) => panel.id === id
      );
      const indexPanel = state.activePanels.findIndex(
        (panel: Panel) => panel.id === id
      );
      const updatedActivePanels = [...state.activePanels];

      if (foundPanel) {
        const foundPanelToUpdate = {
          ...foundPanel,
          panelSettings: {
            ...foundPanel?.panelSettings,
            ...data,
          },
        };
        updatedActivePanels[indexPanel] = foundPanelToUpdate;
      }

      return {
        ...state,
        activePanels: updatedActivePanels,
      };
    }
    case PanelActionTypes.UPDATE_ALL_PANEL_SETTINGS: {
      const { data } = action.payload;

      const foundPanelsToUpdate = state.activePanels.map((panel) => {
        if (!panel.isStandalone) {
          return {
            ...panel,
            children: panel.children.map((child) => ({
              ...child,
              panelSettings: {
                ...child.panelSettings,
                ...data,
              },
            })),
          };
        }
        return {
          ...panel,
          panelSettings: {
            ...panel.panelSettings,
            ...data,
          },
        };
      });

      return {
        ...state,
        activePanels: foundPanelsToUpdate,
      };
    }

    case PanelActionTypes.UPDATE_POSITION_AFTER_DRAG: {
      const updatedPanels = [...state.activePanels];
      updatedPanels.splice(action.payload.index, 1, action.payload.item);
      return {
        ...state,
        activePanels: updatedPanels,
      };
    }
    case PanelActionTypes.UPDATE_PANEL_DETAILS: {
      return {
        ...state,
        activePanels: action.payload.item,
      };
    }
    case PanelActionTypes.UPDATE_ARCHIVED_PANEL_DETAILS: {
      return {
        ...state,
        archivedPanels: action.payload.item,
      };
    }
    case PanelActionTypes.UPDATE_PANEL: {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < action.payload.activeItem.length; index++) {
        if (!action.payload.activeItem[index].panelSettings) {
          action.payload.activeItem[index].panelSettings = defaultPanelSettings;
        }
        if (!action.payload.activeItem[index].sources) {
          action.payload.activeItem[index].sources = [];
        }
      }
      const conActivePanels = [
        ...panels.activePanels,
        ...action.payload.activeItem,
      ];
      return {
        ...state,
        archivedPanels: action.payload.archiveItem,
        activePanels: conActivePanels,
      };
    }
    default:
      return state;
  }
};

export default panelReducer;
