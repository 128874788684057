import { Dispatch } from "redux";

const UpdateConnection = (item: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateConnection",
      payload: item,
    });
  };
};

export default UpdateConnection;
