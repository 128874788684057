/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spin } from "antd";

import { HeaderSubscription } from "./header-subscription";
import "./subscription-page.scss";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";

const SubscriptionEnterConfirm = () => {
  const [mailValue, setMailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [checked, setChecked] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const { token } = params;

  const [
    verificationResponse,
    loadingVerification,
    errorVerification,
    axiosVerificationFetch,
  ] = useAxios();

  const [authSmsResponse, loadingAuthSms, errorAuthSms, axiosAuthSmsFetch] =
    useAxios();

  const selectVerificationMethod = (token: string) => {
    // eslint-disable-next-line
    // @ts-ignore
    axiosVerificationFetch({
      url: `${SERVER_CONFIG.API_URL.COMMON_APIS.SELECT_VERIFICATION_METHOD}/${token}`,
    });
  };

  const authSendSmsMethod = () => {
    try {
      const method = checked === `Send to ${mailValue}` ? "email" : "phone";
      const payload = {
        methodType: method,
      };
      // eslint-disable-next-line
      // @ts-ignore
      axiosAuthSmsFetch({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: `${SERVER_CONFIG.API_URL.COMMON_APIS.AUTH_SEND_SMS}/${token}`,
        data: payload,
      });
      navigate(`/subscription/sms-confirm/${token}`);
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    if (token) {
      selectVerificationMethod(token);
    }
  }, []);
  useEffect(() => {
    setMailValue(verificationResponse?.data.data.email || "");
    setPhoneValue(verificationResponse?.data.data.phoneNumber || "");
  }, [verificationResponse]);

  const choicesToVerify = [];
  if (mailValue) {
    choicesToVerify.push(`Send to ${mailValue}`);
  }
  if (phoneValue) {
    choicesToVerify.push(`Send SMS to mobile number ending in ${phoneValue}`);
  }

  return (
    <Spin size="large" spinning={loadingAuthSms}>
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">Select Method of Verification</h1>
        <p>
          To complete your secure sign in, we need to send you an access code.
          Please select where you’d like to receive this six digit code:
        </p>

        {choicesToVerify.map((choice, i) => (
          <div key={i} className="text-left">
            <label
              htmlFor={choice}
              className="radio-input__radio text-xs md:text-base font-light"
            >
              <input
                type="radio"
                id={choice}
                value={choice}
                className="radio-input__radio-input"
                checked={checked === choice}
                onChange={(e) => {
                  setChecked(e.target.value);
                }}
              />
              {choice}
              <span className="radio-input__radio-span" />
            </label>
          </div>
        ))}

        <p>
          If you select to receive the access code via your phone, you authorize
          PanelLogic to deliver the code via SMS. Message and carrier data rates
          may apply.
        </p>
        <div className="max-w-[581px] mx-auto mb-[47px] flex flex-col md:flex-row md:gap-5 gap-0 items-center sm:items-start">
          <div className="form-button-width">
            <button
              type="button"
              className="form-button"
              disabled={!checked || loadingAuthSms || loadingVerification}
              onClick={authSendSmsMethod}
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SubscriptionEnterConfirm;
