/* eslint-disable react/jsx-no-useless-fragment */
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./reset-to-default-modal.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootReducer } from "../../store/reducers/root-reducer";
import { pathnameLocationAction } from "../../store/reducers/pathname-location/actions/pathname-location-action";

type ConfirmLeavePageModalProps = {
  isActive: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  saveData: () => void;
};

function ConfirmLeavePageModal({
  isActive,
  onConfirm,
  onCancel,
  saveData,
}: ConfirmLeavePageModalProps) {
  const { t } = useTranslation();
  const { pathname } = useSelector(
    (state: RootReducer) => state.pathnameLocation
  );

  const navigate = useNavigate();

  const dispatch = useDispatch<any>();
  const nextPath = pathname;

  const handleLeaveClick = () => {
    onCancel();
    dispatch(
      pathnameLocationAction({
        pathname: "",
      })
    );
    navigate(nextPath);
  };

  const handleSaveClick = () => {
    saveData();
    onConfirm();
  };

  return (
    <Modal open={isActive} onCancel={onCancel} footer={null} closeIcon={<></>}>
      <h5 className="reset-modal-heading">
        {t<string>("globalSettings.confirmLeavePageModal.areYouSure")}
      </h5>
      <div className="reset-modal-button-container">
        <Button
          className="reset-modal-button retain"
          onClick={handleLeaveClick}
        >
          {t<string>("globalSettings.confirmLeavePageModal.leave")}
        </Button>
        <Button className="reset-modal-button reset" onClick={handleSaveClick}>
          {t<string>("globalSettings.confirmLeavePageModal.save")}
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmLeavePageModal;
