import { useState } from "react";
import icons from "../../assets/icon-export-assets";
import {
  Source,
  SlackData,
  Child,
} from "../../interfaces/user-detail-interface";
import { DataSourceId } from "../../prop-types/prop-type";
import Email from "./email-sources/email";
import Slack from "./slack-sources/slack";
import StorageDrive from "./storage/OneDrive/storage";

const { oneDriveIcon, gDriveIcon, dropboxIcon } = icons;
function DataSources({ item }: { item: Child }) {
  const [isShowAllClicked, setIsShowAllClicked] = useState(false);
  const changeIsShowAll = () => setIsShowAllClicked((prev) => !prev);
  const slackSources = () => {
    const slackSrc = {
      sourceId: 3,
      type: "slack",
      order: 3,
      data: {
        slack: {} as SlackData,
      },
    } as Source;
    if (!item.active) {
      return slackSrc;
    }

    item.sources.forEach((source: any) => {
      if (source.sourceId === 3 && slackSrc.data.slack) {
        if (!slackSrc.data.slack[source.slackData.email]) {
          slackSrc.data.slack[source.slackData.email] = {};
        }
        if (slackSrc.data.slack[source.slackData.email]) {
          slackSrc.data.slack[source.slackData.email][source.workspaceId] =
            source?.data?.slack[source.slackData.email][source.workspaceId];
        }
      }
    });
    return slackSrc;
  };
  const emailSources = (itm: Child) => {
    const emailSource: Source[] = [];
    itm.sources.forEach((source: Source) => {
      if (
        source.sourceId === DataSourceId.OUTLOOK ||
        source.sourceId === DataSourceId.GMAIL
      ) {
        emailSource.push(source);
      }
    });
    return emailSource;
  };

  const driveSources = (itm: Child, id: number) => {
    const driveSource: Source[] = [];
    itm.sources.forEach((source: Source) => {
      if (source.sourceId === id) {
        driveSource.push(source);
      }
    });
    return driveSource;
  };

  const mailSourcesList = emailSources(item);

  return (
    <div>
      {mailSourcesList.length > 0 && (
        <Email
          item={item}
          sources={mailSourcesList}
          changeIsShowAll={changeIsShowAll}
        />
      )}
      {!isShowAllClicked &&
        Object.keys(slackSources()?.data?.slack as SlackData).length > 0 &&
        slackSources().sourceId === DataSourceId.SLACK && (
          <Slack item={item} slackSource={slackSources()} />
        )}
      {!isShowAllClicked &&
        driveSources(item, DataSourceId.ONEDRIVE).map((source: Source) => {
          return source.storageData ? (
            <StorageDrive
              key={source.id}
              item={item}
              driveSource={source}
              sourceType="onedrive"
              storageIcon={oneDriveIcon}
            />
          ) : (
            ""
          );
        })}
      {!isShowAllClicked &&
        driveSources(item, DataSourceId.GOOGLEDRIVE).map((source: Source) => {
          return source.storageData ? (
            <StorageDrive
              key={source.id}
              item={item}
              driveSource={source}
              sourceType="googledrive"
              storageIcon={gDriveIcon}
            />
          ) : (
            ""
          );
        })}
      {!isShowAllClicked &&
        driveSources(item, DataSourceId.DROPBOX).map((source: Source) => {
          return source.storageData ? (
            <StorageDrive
              key={source.id}
              item={item}
              driveSource={source}
              sourceType="dropbox"
              storageIcon={dropboxIcon}
            />
          ) : (
            ""
          );
        })}
    </div>
  );
}

export default DataSources;
