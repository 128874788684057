/* eslint-disable react/jsx-no-useless-fragment */
import { Modal } from "antd";
import "./help-page.scss";

type ImgPageModalProps = {
  isActive: boolean;
  source: string;
  onCancel: () => void;
};

function ImgPageModal({ isActive, onCancel, source }: ImgPageModalProps) {
  return (
    <Modal
      open={isActive}
      onCancel={onCancel}
      footer={null}
      className="modal-img"
      width={600}
    >
      <div className="big-modal-img">
        <img src={source} alt="faq-img" />
      </div>
    </Modal>
  );
}

export default ImgPageModal;
