import { useTranslation } from "react-i18next";

export default function ParentWithoutChild() {
  const { t } = useTranslation();

  return (
    <div>
      {t<string>("parentWithoutChildCongrets")}
      <br /> ({t<string>("parentWithoutChildMoreDetailed")})
    </div>
  );
}
