/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { memo, MouseEventHandler, useMemo, useState } from "react";
import {
  CaretRightOutlined,
  CaretDownOutlined,
  FileOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classNames from "classnames";
import { Dropdown, Menu } from "antd";
import { useDispatch } from "react-redux";
import icons from "../../../../assets/icon-export-assets";
import {
  updateStoragePanelsData,
  updateAfterStorageDrag,
  updateGdiveStoragePanelsData,
  updateDropBoxStoragePanelsData,
  updateStorageBeforeDrag,
} from "../../../../store/reducers/panel-data/actions/panels-data-actions";
import { ChildDriveUtilsType } from "../../../../prop-types/prop-type";
import "./one-drive.scss";

// import { useTranslation } from 'react-i18next';
// import { switchItem } from '../../../Sidebar/LoggedInSidebar/ActivePanelsUtils';

type SelectedFolderValues = {
  children: object[];
  name: string;
  itemId: string;
  parentId: string;
};
function DriveUtils(props: any) {
  const [editName, setEditName] = useState("");
  const [expandPanelFolders, setExpandPanelFolders] = useState<string[]>([]);
  const [dropdown, setDropdown] = useState<string>("");
  const dispatch = useDispatch();
  const {
    panelStorage,
    sourceType,
    isDraggable,
    setIsDraggable,
    setFileBeforeDrag,
    saveReorderData,
    maxFolders,
    sortBy,
  } = props;
  const { folderIcon, dropBoxFolderIcon } = icons;
  const isOpenPanelFolder = (ids: string) => {
    const selected = expandPanelFolders.includes(ids);
    return selected;
  };

  const removePanelOpenFolderId = (ids: string) => {
    const index = expandPanelFolders.indexOf(ids);
    const temp = [...expandPanelFolders];
    if (index !== -1) {
      temp.splice(index, 1);
      setExpandPanelFolders(temp);
    }
  };
  const addPanelOpenId = (itemId: string) => {
    const temp = [...expandPanelFolders];
    if (!temp.includes(itemId)) {
      temp.push(itemId);
    }
    setExpandPanelFolders(temp);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const MenuItem = () => {
    const menuOnClick = (e: any) => {
      const { key, keyPath } = e;
      if (keyPath.length === 1) {
        if (key === "2") {
          setIsDraggable(!isDraggable);
        }
        setDropdown("");
      }
    };
    return (
      <Menu
        onClick={(e: any) => menuOnClick(e)}
        style={{ background: "#f9f9f9" }}
      >
        {/* <Menu.Item key="1">default</Menu.Item> */}
        <Menu.Item key="2">Reorder</Menu.Item>
        {/* <Menu.Item key="3">Permanently Delete</Menu.Item> */}
      </Menu>
    );
  };

  const switchItem = (
    dropped: any,
    droppedIn: any,
    activePanels: any,
    dispatch: any,
    updatePanelsDetails: any,
    gupdateStorageBeforeDrag: any
  ) => {
    if (!dropped.pId && !droppedIn.pId) {
      const temp = [...panelStorage];
      // dispatch(updateStorageBeforeDrag(temp));
      // Both are parent or stand alone just swap
      const tempActivePanels = activePanels;
      const droppedParentIndex = tempActivePanels.findIndex(
        (e: any) => e.parentId === dropped.parentId
      );
      const droppedInParentIndex = tempActivePanels.findIndex(
        (e: any) => e.parentId === droppedIn.parentId
      );
      tempActivePanels.splice(droppedParentIndex, 1);
      tempActivePanels.splice(droppedInParentIndex, 0, dropped);
      const updatedActivePanels = tempActivePanels.map(
        (e: any, index: number) => {
          return { ...e, order: index };
        }
      );
      saveReorderData(updatedActivePanels);
      // dispatch(updatePanelsDetails(updatedActivePanels));
      // setFileBeforeDrag(updatedActivePanels);
      return updatedActivePanels;
    }
  };

  const onDragEnd = (result: any) => {
    // If element is dropped outside droppable area
    if (!result.destination) {
      return;
    }
    const droppedItem = panelStorage[result.source.index];
    const droppedInItem = panelStorage[result.destination.index];
    if (sourceType === "GetGoogleDrive") {
      // dispatch(updateGdiveStoragePanelsData(selectedFiles, item.itemId));
      switchItem(
        droppedItem,
        droppedInItem,
        panelStorage,
        dispatch,
        updateGdiveStoragePanelsData,
        updateAfterStorageDrag
      );
    }
    // GetOneDrive
    if (sourceType === "GetOneDrive") {
      // dispatch(updatePanelsDetails(updatedActivePanels));
      const updatedSwitchData = switchItem(
        droppedItem,
        droppedInItem,
        panelStorage,
        dispatch,
        updateStoragePanelsData,
        updateAfterStorageDrag
      );
      // setFileBeforeDrag(updatedSwitchData);
      console.log("updatedSwitchData", updatedSwitchData);
    }
    if (sourceType === "GetDropBoxDrive") {
      // dispatch(updatePanelsDetails(updatedActivePanels));
      switchItem(
        droppedItem,
        droppedInItem,
        panelStorage,
        dispatch,
        updateDropBoxStoragePanelsData,
        updateAfterStorageDrag
      );
    }
  };

  const dragableLayout = (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="characters">
        {(provided) => (
          <ul
            style={{ padding: "0px" }}
            className="cursor-default"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {panelStorage
              .slice(0, maxFolders || 10)
              .map((e: any, index: number) => {
                return (
                  <Draggable
                    key={e.itemId}
                    draggableId={e.itemId}
                    index={index}
                    isDragDisabled={!isDraggable}
                  >
                    {(provided) => (
                      <li
                        className={classNames(
                          "sidebar-list-item cursor-default"
                        )}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Dropdown
                          overlay={<MenuItem />}
                          trigger={["contextMenu"]}
                          arrow
                        >
                          <Link
                            className={
                              !e.pId
                                ? "sidebar-items cursor-default"
                                : "sidebar-items cursor-default sidebar-items-child"
                            }
                            key={e.itemId}
                            to="#"
                          >
                            <div
                              className={
                                isDraggable
                                  ? "sidebar-item-contents cursor-default"
                                  : "cursor-default"
                              }
                              onContextMenu={() => setDropdown(e.itemId)}
                            >
                              <div
                                style={{ marginTop: "4px" }}
                                className={classNames(
                                  "sidebar-header-button-items-drag",
                                  {
                                    hide: !isDraggable,
                                  }
                                )}
                              >
                                <HolderOutlined role="button" />
                              </div>

                              <div className="cursor-default">
                                {!isDraggable && (
                                  <span className="cursor-default">
                                    {isOpenPanelFolder(e.itemId) ? (
                                      <CaretDownOutlined
                                        style={{ marginRight: "12px" }}
                                        onClick={() =>
                                          removePanelOpenFolderId(e.itemId)
                                        }
                                        className="cursor-default"
                                      />
                                    ) : (
                                      <CaretRightOutlined
                                        style={{ marginRight: "12px" }}
                                        onClick={() => addPanelOpenId(e.itemId)}
                                        className="cursor-default"
                                      />
                                    )}
                                  </span>
                                )}

                                <img
                                  alt="gmail"
                                  width={20}
                                  height={14}
                                  src={
                                    sourceType === "dropbox"
                                      ? dropBoxFolderIcon
                                      : folderIcon
                                  }
                                  style={{
                                    marginLeft: "13px",
                                    marginRight: "13px",
                                  }}
                                />
                                <span className="breadcrumb-item">
                                  {e.name}
                                </span>
                              </div>
                              {isOpenPanelFolder(e.itemId) &&
                                e.children.length > 0 &&
                                [...e.children]
                                  .sort((a, b) => {
                                    if (
                                      sortBy === "Date Modified" ||
                                      sortBy === "modified"
                                    ) {
                                      return (
                                        // @ts-expect-error TODO
                                        new Date(b.lastModifiedDateTime) -
                                        // @ts-expect-error TODO
                                        new Date(a.lastModifiedDateTime)
                                      );
                                    }

                                    if (sortBy === "asc") {
                                      return a.name.localeCompare(b.name);
                                    }

                                    return b.name.localeCompare(a.name);
                                  })
                                  .map(
                                    (
                                      childs: ChildDriveUtilsType,
                                      inde: number
                                    ) => {
                                      const handleDoubleClick = () =>
                                        window.open(childs.webUrl, "_blank");

                                      const handleClickEvent: MouseEventHandler<
                                        HTMLDivElement
                                      > = (event) => {
                                        switch (event.detail) {
                                          case 2: {
                                            handleDoubleClick();
                                            break;
                                          }

                                          default: {
                                            break;
                                          }
                                        }
                                      };

                                      return (
                                        <div
                                          className="cursor-default"
                                          key={inde}
                                          style={{
                                            marginLeft: "25px",
                                            marginBottom: "5px",
                                            marginTop: "5px",
                                            minHeight: "22px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          <FileOutlined
                                            style={{
                                              marginLeft: "15px",
                                              marginRight: "13px",
                                              width: 14,
                                              height: 18,
                                            }}
                                          />
                                          <span
                                            style={{ marginLeft: "6px" }}
                                            onClick={handleClickEvent}
                                          >
                                            {childs.name}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                            </div>

                            <div>
                              <button
                                type="button"
                                className="sidebar-element-option-button"
                                onClick={() => setDropdown(e.itemId)}
                              >
                                ...
                              </button>
                            </div>
                          </Link>
                        </Dropdown>
                      </li>
                    )}
                  </Draggable>
                );
              })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
  return dragableLayout;
}
export default memo(DriveUtils);
