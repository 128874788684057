/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  CaretRightOutlined,
  ArrowLeftOutlined,
  ArrowUpOutlined,
  CaretDownOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { Checkbox, Breadcrumb, Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./one-drive.scss";
import icons from "../../../../assets/icon-export-assets";
import AddEditFilesHeader from "../../../reusable-components/add-edit-files-header";
import { RootReducer } from "../../../../store/reducers/root-reducer";
import { updateDriveList } from "../../../../store/reducers/loading-states/actions/loading-actions";
import { updatePanelsData } from "../../../../store/reducers/panel-data/actions/panels-data-actions";
import DriveUtil from "./drive-utils";
import useAxios from "../../../../services/axios";
import SERVER_CONFIG from "../../../../utils/config";
import { PanelsData } from "../../../../interfaces/user-detail-interface";

type BreadCrumb = {
  name: string;
  itemId: string;
  path_lower?: string;
  path_display?: string;
};
type File = {
  createdDateTime: string;
  driveId: string;
  drivePath: string;
  driveType: string;
  fileSystemCreatedDate: null;
  fileSystemLastModifiedDate: null;
  folder: string;
  folderChildCount: null;
  itemId: string;
  isChecked: boolean;
  isSelected: boolean;
  itemType: string;
  lastModifiedDateTime: string;
  name: string;
  packageType: null;
  parentId: string;
  record_Id: number;
  specialFolderName: null;
  webUrl: string;
  children: object[];
  createdDate?: string;
  deleted?: boolean;
  id?: string;
  modifiedDate?: string;
  orderBy?: number;
  panelId?: string;
  panelStorageId?: string;
  panelUserId?: string;
};

type Folder = {
  name?: string;
  itemId: string;
  children: File[];
  parentId: string;
  itemType?: string;
  selectAll?: boolean;
  isSelected?: true;
  path_lower?: string;
  path_display?: string;
  id?: string | null;
  orderBy?: number;
  panelId?: string;
  panelStorageId?: string;
  panelUserId?: string;
};
const { folderIcon, dropBoxFolderIcon } = icons;

const StorageDrive = ({
  item,
  driveSource,
  sourceType,
  storageIcon,
}: {
  item: any;
  driveSource: any;
  sourceType: string;
  storageIcon: any;
}) => {
  const dispatch = useDispatch<any>();
  const [expandFolders, setExpandFolders] = useState<string[]>([]);
  const [addEditFolder, setAddEditFolder] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([
    { name: "My Files", itemId: "myfile" },
  ]);
  const { addRemoveStorageFolders } = useSelector(
    (state: RootReducer) => state.loading
  );

  const panelData: PanelsData = useSelector(
    (state: RootReducer) => state.panelData
  );
  const [isDraggable, setIsDraggable] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Folder[]>([]);

  const [fileBeforeDrag, setFileBeforeDrag] = useState<any>([]);
  const [isSelectAll, setSelectAll] = useState(false);
  const [dragedFile, setdragedFile] = useState([]);
  const [
    driveDataResponse,
    driveDataLoading,
    driveDataLoadingError,
    fetchDriveData,
  ] = useAxios();
  const [
    selectedDriveDataApiResponse,
    selectedDriveDataApiResponseLoading,
    saveSelectedDriveDataLoadingError,
    saveSelectedDriveData,
  ] = useAxios();

  const { t } = useTranslation();
  const getMessage = () => {
    // eslint-disable-next-line
    // @ts-ignore
    fetchDriveData({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.SOURCE_DRIVE_GET(sourceType),
      data: {
        panelId: item.id,
        panelEmail: driveSource.storageData.email,
      },
    });
  };

  const storageKeys = {
    onedrive: { drive: "oneDrive", key: "oneDriveList" },
    googledrive: { drive: "googleDrive", key: "googleDriveList" },
    dropbox: { drive: "dropBox", key: "dropBoxList" },
  } as any;
  const populateSavedFiles = () => {
    const tempPanelsData = { ...panelData[item.id] } || {};
    const storage = tempPanelsData.storage
      ? { ...tempPanelsData.storage }
      : ({} as any);

    const driveSourceData = storage[storageKeys[sourceType].drive] || {};

    const finalpanelStorage = driveSourceData[driveSource?.storageData?.email]
      ? [...driveSourceData[driveSource.storageData.email]]
      : [];

    const finalPanelStorage = finalpanelStorage.map((parent: Folder) => ({
      ...parent,
      children: parent.children.map((file: File) => ({
        ...file,
        isSelected: !!file.id,
      })),
    }));

    const items: string[] = [];
    if (finalPanelStorage.length > 0) {
      selectedFiles.forEach((folder: Folder) => {
        if (folder.itemId) {
          items.push(folder.itemId);
        }
      });
    }
    setSelectedFiles([...finalPanelStorage]);
    setExpandFolders(items);
  };
  const addFolderHandler = () => {
    setAddEditFolder(!addEditFolder);
    getMessage();
    setBreadCrumbs([{ name: "My Files", itemId: "myfile" }]);
    setSelectAll(false);
    populateSavedFiles();
  };
  useEffect(() => {
    setBreadCrumbs([{ name: "My Files", itemId: "myfile" }]);
    setSelectAll(false);
    setExpandFolders([]);
  }, []);
  useEffect(() => {
    populateSavedFiles();
  }, [panelData, item]);

  const selectedFolderList = useMemo(() => {
    return selectedFiles
      .map((parent: Folder) => {
        const { children = [] } = parent;
        return [parent, ...children];
      })
      .flat();
  }, [selectedFiles, item]);
  const checkAllSelected = useMemo(() => {
    const tempSelectedFolderSource =
      { ...addRemoveStorageFolders[storageKeys[sourceType].key] } || {};
    const tempSelectedFolder = tempSelectedFolderSource[item.id] || [];
    tempSelectedFolder.forEach((element: any, index: number) => {
      selectedFolderList.map((val: any) => {
        if (element.itemId === val.itemId) {
          tempSelectedFolder[index].isSelected = true;
        }
      });
    });
    return tempSelectedFolder;
  }, [
    addRemoveStorageFolders[storageKeys[sourceType].key][item.id],
    selectedFiles,
    item,
  ]);
  useEffect(() => {
    const isAllSelected = checkAllSelected.every(
      (x: File) => x.itemType.toLowerCase() === "file" && x.isSelected
    );
    setSelectAll(isAllSelected);
  }, [checkAllSelected]);

  const selectedBreadCrumb = useMemo(() => {
    const temp = [...breadCrumbs];
    if (temp.length > 3) {
      temp.splice(1, temp.length - 3, { name: "...", itemId: "" });
    }
    return temp;
  }, [breadCrumbs]);

  useEffect(() => {
    if (driveDataResponse) {
      const res = driveDataResponse as any;
      if (res && res.data.success) {
        dispatch(
          updateDriveList(res.data.data, storageKeys[sourceType].key, item.id)
        );
      }
    }
  }, [driveDataResponse, item]);

  const storageDataList = useCallback(() => {
    return addRemoveStorageFolders[storageKeys[sourceType].key][item.id] || [];
  }, [addRemoveStorageFolders, sourceType, item]);

  const deleteFile = (parentId: string, childId: string) => {
    const tempSavedFoldersandFiles = [...selectedFiles];
    const selectedFolderIndex = tempSavedFoldersandFiles.findIndex(
      (i: any) => i.parentId === parentId
    );
    const temp = { ...tempSavedFoldersandFiles[selectedFolderIndex] };
    const remainingFiles = temp.children.filter(
      (item: any) => item.itemId !== childId
    );
    if (remainingFiles.length) {
      temp.children = [...remainingFiles];
      tempSavedFoldersandFiles.splice(selectedFolderIndex, 1, temp);
    } else {
      tempSavedFoldersandFiles.splice(selectedFolderIndex, 1);
    }
    const store = addRemoveStorageFolders[storageKeys[sourceType].key][item.id]
      .map((parent: any) => {
        const { children = [] } = parent;
        return [parent, ...children];
      })
      .flat();
    store.forEach((element: any, index: number) => {
      if (element.itemId === childId) {
        store[index].isSelected = false;
      }
    });
    setSelectedFiles([...tempSavedFoldersandFiles]);
    setSelectAll(false);
  };

  const checkFileExist = () => {
    if (
      addRemoveStorageFolders[storageKeys[sourceType].key][item.id].length > 0
    ) {
      const isFileExist = !addRemoveStorageFolders[storageKeys[sourceType].key][
        item.id
      ].some((x: any) => x.itemType === "file" || x.itemType === "File");
      return isFileExist;
    }
  };
  const addIds = (itemId: string) => {
    const temp = [...expandFolders];
    if (!temp.includes(itemId)) {
      temp.push(itemId);
    }
    setExpandFolders(temp);
  };
  const onChange = (e: any) => {
    const { checked } = e.target;
    const selectedfile: File = e.target.value;
    selectedfile.isSelected = checked;
    const tempSavedFoldersandFiles = [...selectedFiles];
    const selectedFolderIndex = tempSavedFoldersandFiles.findIndex(
      (i: Folder) => i.itemId === breadCrumbs[breadCrumbs.length - 1].itemId
    );
    let tempSelectedFolder: Folder = {} as Folder;
    if (selectedFolderIndex !== -1) {
      tempSelectedFolder = tempSavedFoldersandFiles[selectedFolderIndex];
    } else {
      tempSelectedFolder = {
        name: breadCrumbs[breadCrumbs.length - 1].name,
        itemId: breadCrumbs[breadCrumbs.length - 1].itemId,
        children: [],
        parentId: selectedfile.parentId,
        itemType: "Folder",
        selectAll: false,
        path_lower: breadCrumbs[breadCrumbs.length - 1].path_lower || "",
        path_display: breadCrumbs[breadCrumbs.length - 1].path_display || "",
      };
    }
    if (checked) {
      selectedfile.folder = breadCrumbs[breadCrumbs.length - 1].name;
      const isSelectAll = !addRemoveStorageFolders[storageKeys[sourceType].key][
        item.id
      ].some(
        (x: any) =>
          (x.itemType === "file" || x.itemType === "File") &&
          (x.isSelected === false || x.isSelected === undefined)
      );
      tempSelectedFolder.selectAll = isSelectAll;
      if (selectedFolderIndex === -1) {
        tempSelectedFolder.children = [selectedfile];
        addIds(tempSelectedFolder.itemId as unknown as string);
        tempSavedFoldersandFiles.push(tempSelectedFolder);
      } else {
        tempSelectedFolder.children = [
          ...tempSelectedFolder.children,
          selectedfile,
        ];
        tempSavedFoldersandFiles.splice(
          selectedFolderIndex,
          1,
          tempSelectedFolder
        );
      }
      setSelectAll(isSelectAll);
    } else {
      const remainingFiles = tempSelectedFolder.children.filter(
        (item: File) => item.itemId !== selectedfile.itemId
      );
      if (remainingFiles.length) {
        tempSelectedFolder.children = [...remainingFiles];
        tempSelectedFolder.selectAll = false;
        tempSavedFoldersandFiles.splice(
          selectedFolderIndex,
          1,
          tempSelectedFolder
        );
      } else {
        tempSavedFoldersandFiles.splice(selectedFolderIndex, 1);
      }
      setSelectAll(false);
    }
    setSelectedFiles([...tempSavedFoldersandFiles]);
  };

  const onSelectAllhange = (e: any) => {
    const { checked } = e.target;
    setSelectAll(checked);

    const tempSelectedFoldersandFiles = [
      ...addRemoveStorageFolders[storageKeys[sourceType].key][item.id],
    ];
    const tempSelectedFiles = tempSelectedFoldersandFiles.filter(
      (x) => x.itemType === "file" || x.itemType === "File"
    );

    const tempSavedFoldersandFiles = [...selectedFiles];
    const selectedFolderIndex = tempSavedFoldersandFiles.findIndex(
      (x) => x.itemId === breadCrumbs[breadCrumbs.length - 1].itemId
    );
    let tempSelectedFolder: Folder = {} as Folder;
    if (selectedFolderIndex !== -1) {
      tempSelectedFolder = tempSavedFoldersandFiles[selectedFolderIndex];
    } else {
      tempSelectedFolder = {
        name: breadCrumbs[breadCrumbs.length - 1].name,
        itemId: breadCrumbs[breadCrumbs.length - 1].itemId,
        children: [],
        parentId: tempSelectedFiles[0].parentId,
        itemType: "Folder",
        path_lower: breadCrumbs[breadCrumbs.length - 1].path_lower || "",
        path_display: breadCrumbs[breadCrumbs.length - 1].path_display || "",
      };
    }
    tempSelectedFiles.forEach((file) => {
      file.folder = breadCrumbs[breadCrumbs.length - 1].name;
      file.isSelected = checked;
    });
    if (checked) {
      tempSelectedFolder.children = [...tempSelectedFiles];
      tempSelectedFolder.selectAll = checked;
      if (selectedFolderIndex !== -1) {
        tempSavedFoldersandFiles.splice(
          selectedFolderIndex,
          1,
          tempSelectedFolder
        );
      } else {
        tempSavedFoldersandFiles.push(tempSelectedFolder);
      }
      setSelectedFiles([...tempSavedFoldersandFiles]);
    } else {
      tempSavedFoldersandFiles.splice(selectedFolderIndex, 1);
    }
    setSelectedFiles([...tempSavedFoldersandFiles]);
  };
  const getFolderDetails = (val: any) => {
    fetchDriveData({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.SOURCE_DRIVE_GET_FOLDER_ITEMS(
        `${sourceType}item`
      ),
      data: {
        itemId: val.itemId,
        panelId: item.id,
        panelEmail: driveSource.storageData.email,
      },
    });
    if (!breadCrumbs.some((e) => e.itemId === val.itemId)) {
      setBreadCrumbs([
        ...breadCrumbs,
        {
          name: val.name,
          itemId: val.itemId,
          path_lower: val.path_lower,
          path_display: val.path_display,
        },
      ]);
    }
    populateSavedFiles();
  };

  const handleBreadCrumbClick = (value: any) => {
    if (value.itemId === "myfile") {
      getMessage();
      const firstIndex = breadCrumbs.indexOf(value);
      const updatedData = breadCrumbs.slice(0, firstIndex + 1);
      setBreadCrumbs(updatedData);
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      fetchStorageData({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.SOURCE_DRIVE_GET_FOLDER_ITEMS(
          `${sourceType}item`
        ),
        params: {
          Item_Id: selectedBreadCrumb[selectedBreadCrumb.length - 2].itemId,
        },
      });
      const firstIndex = breadCrumbs.indexOf(value);
      const updatedData = breadCrumbs.slice(0, firstIndex + 1);
      setBreadCrumbs(updatedData);
      populateSavedFiles();
    }
  };

  const handleBack = () => {
    if (selectedBreadCrumb[selectedBreadCrumb.length - 2].itemId === "myfile") {
      getMessage();
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      fetchStorageData({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.SOURCE_DRIVE_GET_FOLDER_ITEMS(
          `${sourceType}item`
        ),
        params: {
          Item_Id: selectedBreadCrumb[selectedBreadCrumb.length - 2].itemId,
        },
      });
    }
    const firstIndex = breadCrumbs.indexOf(
      selectedBreadCrumb[selectedBreadCrumb.length - 2]
    );
    const updatedData = breadCrumbs.slice(0, firstIndex + 1);
    setBreadCrumbs(updatedData);
  };

  const saveStorageData = () => {
    saveSelectedDriveData({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.SOURCE_DRIVE_SAVE(sourceType),
      data: {
        panelId: item.id,
        panelEmail: driveSource.storageData.email,
        data: [...selectedFiles],
      },
    });
    setAddEditFolder(!addEditFolder);
  };

  useEffect(() => {
    console.log(selectedDriveDataApiResponse);
    if (selectedDriveDataApiResponse) {
      const res = selectedDriveDataApiResponse as any;
      if (res && res.data.success) {
        const mailData = res.data.data.panelEmail;

        const tempPanelsData = { ...panelData[item.id] } || {};
        const storage = tempPanelsData.storage as any;
        if (!storage[storageKeys[sourceType].drive]) {
          storage[storageKeys[sourceType].drive] = {};
        }
        const driveSourceData = storage[storageKeys[sourceType].drive];
        driveSourceData[mailData] = res.data.data.data;
        dispatch(updatePanelsData(tempPanelsData, item.id));
      }
    }
  }, [selectedDriveDataApiResponse]);

  const onCancel = () => {
    setAddEditFolder(!addEditFolder);
    populateSavedFiles();
  };
  const isFileOpen = (ids: string) => {
    const selected = expandFolders.includes(ids);
    return selected;
  };
  const removeFileId = (ids: string) => {
    const index = expandFolders.indexOf(ids);
    const temp = [...expandFolders];
    if (index !== -1) {
      temp.splice(index, 1);
      setExpandFolders(temp);
    }
  };

  const reorderedData = (isSave: boolean) => {
    setIsDraggable(false);

    if (isSave) {
      setSelectedFiles([...dragedFile]);
      // eslint-disable-next-line
      // @ts-ignore
      saveSelectedDriveData({
        method: SERVER_CONFIG.HTTP_METHOD.POST,
        url: SERVER_CONFIG.API_URL.SOURCE_DRIVE_SAVE(sourceType),
        data: {
          panelId: item.id,
          panelEmail: driveSource.storageData.email,
          data: [...selectedFiles],
        },
      });
    } else {
      populateSavedFiles();
    }
    // dispatch(updatePanelsDetails(dragedFile));
  };
  const saveReorderData = (data: any) => {
    setdragedFile(data);
  };

  const areSettingsInItem =
    item.panelSettings[sourceType] &&
    item.panelSettings[sourceType].desktopFolderSortBy;

  const sortByFromItem = areSettingsInItem
    ? isMobile
      ? item.panelSettings[sourceType].mobileFolderSortBy
      : item.panelSettings[sourceType].desktopFolderSortBy
    : "Date Modified";

  const saveCancelButtonsRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const executeScroll = () =>
      saveCancelButtonsRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    executeScroll();
  }, [addEditFolder]);

  const isDataLoading = driveDataLoading || selectedDriveDataApiResponseLoading;

  return (
    <Spin size="large" spinning={isDataLoading}>
      <div>
        {!addEditFolder ? (
          <div className="ondriveContainer">
            {" "}
            <AddEditFilesHeader
              addFolder={t<string>("addEditFolderButton")}
              addFolderHandler={addFolderHandler}
              imageIcon={storageIcon}
              isDraggable={isDraggable}
              reorderedData={reorderedData}
              workSpaceName={driveSource?.storageData?.email || ""}
            />
            <div>
              <DriveUtil
                panelStorage={[...selectedFiles]}
                sourceType={sourceType}
                isDraggable={isDraggable}
                setIsDraggable={setIsDraggable}
                setFileBeforeDrag={setFileBeforeDrag}
                saveReorderData={saveReorderData}
                sortBy={sortByFromItem}
              />
            </div>
            {/* )} */}
          </div>
        ) : (
          <>
            {addEditFolder && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 10px 0px 10px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}
              >
                <div onClick={onCancel} style={{ cursor: "pointer" }}>
                  <ArrowLeftOutlined style={{ color: "#676567" }} />
                  <span className="back-to-panel">Back to Panel</span>
                </div>
                <img
                  alt="icon"
                  width={20}
                  height={20}
                  src={storageIcon}
                  style={{ marginRight: "4px" }}
                />
              </div>
            )}

            {addEditFolder && breadCrumbs.length === (1 || 0) && (
              <div className="storage-text-container">
                Select Folders OR Files to add to your panel
              </div>
            )}
            {addEditFolder && breadCrumbs.length > 1 && (
              <div className="storage-text-container">
                Click to open folders and select using the checkbox to add
                folders or files to the panel.
              </div>
            )}
            {addEditFolder && (
              <div className="myFile">
                <Breadcrumb separator=">">
                  {breadCrumbs.length === 0 && (
                    <Breadcrumb.Item>
                      <span className="myFile">My Files</span>
                      <span>{">"}</span>
                    </Breadcrumb.Item>
                  )}
                  {selectedBreadCrumb.length > 0 &&
                    selectedBreadCrumb.map((bcrumb, index) => {
                      const last =
                        breadCrumbs.indexOf(bcrumb) === breadCrumbs.length - 1;
                      return last ? (
                        <Breadcrumb.Item
                          key={index}
                          className={
                            last
                              ? "last-breadcrumb-element"
                              : "ant-breadcrumb-link"
                          }
                        >
                          <span>{bcrumb.name}</span>
                        </Breadcrumb.Item>
                      ) : bcrumb.name === "..." ? (
                        <Breadcrumb.Item
                          key={index}
                          className="breadcrumb-style"
                        >
                          <u className="breadcrumb-style">{bcrumb.name}</u>
                        </Breadcrumb.Item>
                      ) : (
                        <Breadcrumb.Item
                          key={index}
                          className={
                            last
                              ? "last-breadcrumb-element"
                              : "ant-breadcrumb-link"
                          }
                        >
                          <Link
                            onClick={() => handleBreadCrumbClick(bcrumb)}
                            style={{ color: "#206FA8" }}
                            to="#"
                          >
                            {bcrumb.name}
                          </Link>
                        </Breadcrumb.Item>
                      );
                    })}
                </Breadcrumb>
              </div>
            )}
            {addEditFolder && (
              <div style={{ marginBottom: "6px" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "auto",
                    marginBottom: "5px",
                  }}
                >
                  <div className="name-top-container">
                    {/* <ArrowLeftOutlined onClick={handleBack} style={{ color: '#206FA8' }} /> */}
                    <span className="name-text">Name</span>
                    <span className="top-arrow">
                      <ArrowUpOutlined onClick={handleBack} />
                    </span>
                  </div>
                  <div className="select-all-container">
                    <span className="select-all">Select All:</span>
                    <span style={{ marginLeft: "7px" }}>
                      <Checkbox
                        // style={{ border: '1px solid #817E81' }}
                        name="from"
                        onChange={onSelectAllhange}
                        checked={isSelectAll}
                        disabled={
                          storageDataList().length === 0 || checkFileExist()
                        }
                      />
                    </span>
                  </div>
                </div>
                <div className="folder-listing-box">
                  <div style={{ textAlign: "left", marginLeft: "10px" }}>
                    {storageDataList().map(
                      (
                        val: {
                          name:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | React.ReactPortal
                            | null
                            | undefined;
                          isSelected: boolean | undefined;
                          itemType: string;
                          itemId: number;
                        },
                        index: React.Key | null | undefined
                      ) => {
                        return (
                          <div
                            className={
                              val.itemType === "Folder"
                                ? "showFilderDetails"
                                : ""
                            }
                            key={index}
                          >
                            {val.itemType === "file" ||
                            val.itemType === "File" ? (
                              <>
                                <FileOutlined
                                  style={{
                                    width: 14,
                                    height: 18,
                                    marginLeft: "5px",
                                    marginRight: "0px",
                                  }}
                                />
                                <span
                                  style={{ marginLeft: "10px" }}
                                  className={
                                    val.isSelected ? "fileSelected" : ""
                                  }
                                >
                                  {val.name}
                                </span>
                              </>
                            ) : (
                              <div onClick={() => getFolderDetails(val)}>
                                {val.isSelected && (
                                  <span
                                    style={{
                                      fontSize: "25px",
                                      paddingRight: "5px",
                                      color: "grey",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    &#x2022;
                                  </span>
                                )}
                                <img
                                  alt="foldericons"
                                  width={20}
                                  height={14}
                                  src={
                                    sourceType === "dropbox"
                                      ? dropBoxFolderIcon
                                      : folderIcon
                                  }
                                />
                                <span
                                  className={
                                    val.isSelected ? "fileSelected" : ""
                                  }
                                  style={{ marginLeft: "10px" }}
                                >
                                  {val.name}
                                </span>
                              </div>
                            )}
                            <span
                              style={{ float: "right", marginRight: "8px" }}
                            >
                              {(val.itemType === "file" ||
                                val.itemType === "File") && (
                                <Checkbox
                                  name="from"
                                  value={val}
                                  onChange={onChange}
                                  checked={val.isSelected}
                                />
                              )}
                            </span>
                            <hr style={{ border: "0.8px solid #F2F2F2" }} />
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "15px",
                    marginTop: "10px",
                  }}
                >
                  <div
                    className="save-cancel-button-container"
                    ref={saveCancelButtonsRef}
                  >
                    <button
                      onClick={onCancel}
                      type="button"
                      style={{ color: "rgb(103, 103, 103)" }}
                      className="setting-cancle"
                    >
                      {t<string>("globalSettings.cancel")}
                    </button>
                    <button
                      onClick={saveStorageData}
                      type="button"
                      style={{ color: "#BA3A29", fontWeight: 500 }}
                      className="sidebar-button-items"
                    >
                      {t<string>("globalSettings.save")}
                    </button>
                  </div>
                </div>
                <Divider
                  style={{ border: "1px solid #CACACA", paddingBottom: "0px" }}
                />
                {/* <hr style={{ border: '.8px solid #CACACA' }} /> */}
                <div
                  style={{
                    marginTop: "5px",
                    height: "100px",
                    overflowY: "scroll",
                  }}
                >
                  {selectedFiles.length > 0 && (
                    <div>
                      {selectedFiles.map((value: Folder, ind: number) => {
                        const ids: string = value.itemId;
                        return (
                          <div
                            key={ind}
                            style={{
                              textAlign: "left",
                              marginLeft: "8px",
                              minHeight: "30px",
                            }}
                          >
                            {isFileOpen(ids) ? (
                              <CaretDownOutlined
                                style={{ marginRight: "12px" }}
                                onClick={() => removeFileId(ids)}
                              />
                            ) : (
                              <CaretRightOutlined
                                style={{ marginRight: "12px" }}
                                onClick={() => addIds(ids)}
                              />
                            )}
                            <img
                              alt="gmail"
                              width={20}
                              height={14}
                              src={
                                sourceType === "dropbox"
                                  ? dropBoxFolderIcon
                                  : folderIcon
                              }
                              style={{
                                marginLeft: "13px",
                                marginRight: "13px",
                              }}
                            />
                            <span className="breadcrumb-item">
                              {value.name}
                            </span>
                            {isFileOpen(ids) &&
                              value.children.length > 0 &&
                              value.children.map(
                                (childs: any, inde: number) => {
                                  return (
                                    <div
                                      key={inde}
                                      style={{
                                        marginLeft: "25px",
                                        marginBottom: "5px",
                                        marginTop: "5px",
                                        minHeight: "22px",
                                      }}
                                    >
                                      <FileOutlined
                                        style={{
                                          marginLeft: "15px",
                                          marginRight: "13px",
                                          width: 14,
                                          height: 18,
                                        }}
                                      />
                                      <span
                                        className="breadcrumb-item"
                                        style={{ marginLeft: "6px" }}
                                      >
                                        {childs.name}
                                      </span>
                                      <span
                                        onClick={() =>
                                          deleteFile(
                                            value.itemId === "myfile"
                                              ? value.parentId
                                              : value.itemId,
                                            childs.itemId
                                          )
                                        }
                                        style={{
                                          marginLeft: "20px",
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      >
                                        X
                                      </span>
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default StorageDrive;
