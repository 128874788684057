/* eslint-disable import/no-cycle */
import React, { Dispatch, SetStateAction } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Child } from "../interfaces/user-detail-interface";

export enum DataSourceId {
  OUTLOOK = 1,
  GMAIL = 2,
  SLACK = 3,
  ONEDRIVE = 4,
  GOOGLEDRIVE = 5,
  DROPBOX = 6,
  // JIRA = 7,
}

export enum StripePriceNickName {
  Standard = "standard monthly payment",
  Premium = "premium monthly payment",
  PremiumSpecial = "special premium monthly payment",
}

export enum StripePriceLookupKey {
  Standard = "standard_monthly",
  Premium = "premium_monthly",
  PremiumSpecial = "premium_monthly_special",
}

export enum StripeProduct {
  Standard = "Standard",
  Premium = "Premium",
  Participant = "Participant",
}

export type GlobalSettingsParams = {
  id: string;
};

export type LoggedInLayoutProp = {
  panelId?: string;
  subpanelId?: string;
};

export type ParentInLayoutProp = {
  panelId?: string;
  subpanelId?: string;
  archived?: boolean;
};

export type LoggedInNavbarProp = {
  setVisibleAddWidget?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  visible?: boolean;
  showDrawer?: any;
};

export type PanelPropType = {
  item: Child;
  position?: string;
  archived?: boolean;
};

export type AddPanelPropType = {
  showAddPanel: boolean;
  setShowAddPanel: React.Dispatch<React.SetStateAction<boolean>>;
};

export type OutlookMailBody = {
  id: string;
  isRead: string;
  emailBody: string;
  fromName: string;
  fromAddress: string;
  sendorName: string;
  sendorAddress: string;
  attachments: string;
  importance: string;
  subject: string;
  emailDate: Date;
  createdDate: string;
  webLink: string;
  emailSourceType: string;
  panelId: string;
};

export type ChanelsTempSlackType = {
  id: string;
  panelUserId: string;
  sourceId: string;
  panelDetailsourceId: string;
  panelId: string;
  slackAccessTokenId: string;
  slackEmailAddress: string;
  workspaceId: string;
  recordType: string;
  channelId: string;
  channelName: string;
  channelType: string;
  directMessageId: string;
  directMessageName: string;
  createdDate: string;
  modifiedDate: string;
};

export type TempDirrectMsgSlackType = {
  id: string;
  panelUserId: string;
  sourceId: string;
  panelDetailsourceId: string;
  panelId: string;
  slackAccessTokenId: string;
  slackEmailAddress: string;
  workspaceId: string;
  recordType: string;
  channelId: string;
  channelName: string;
  channelType: string;
  directMessageId: string;
  directMessageName: string;
  createdDate: string;
  modifiedDate: string;
};

export type TempDataSlackIdType = {
  slackName: string;
  channels: ChanelsTempSlackType[];
  directMessage: TempDirrectMsgSlackType[];
};

export type TempSlackIDDataType = {
  workSpaceId: string;
  data: TempDataSlackIdType;
};

export type TempSlackData = {
  email: string;
  slackId: TempSlackIDDataType;
};

export type TempSourceType = {
  id: string;
  source_Id: string;
  sourceId: number;
  type: string;
  panelId: string;
  pId: string;
  panelAccessTokenId: string;
  workspaceId: string;
  panelUserId: string;
  createdDate: string;
  modifiedDate: string;
  data: {
    email: string;
    filters: [
      {
        id: string;
        panelUserId: string;
        panelSourceId: string;
        email: string;
        panelId: string;
        from: string;
        hasTheWords: string;
        subject: string;
        date: string;
        hasAttachment: boolean;
        mailbox: number;
        createdDate: string;
        modifiedDate: string;
      }
    ];
  };
  slackData: TempSlackData;
  storageData: {
    email: string;
  };
};

export type TempActivePanelChildType = {
  id: string;
  pId: string;
  name: string;
  isStandalone: boolean;
  color: string;
  order: number;
  panelStatusId: string;
  panelUserId: string;
  active: boolean;
  createdDate: string;
  modifiedDate: string;
  panelSettings: {
    outlook: {
      id: string;
      sourceType: string;
      desktopStdViewLinesPerEmail: number;
      desktopExpViewLinesPerEmail: number;
      desktopMaxEmailViewedPerPanel: number;
      desktopOrganisedBy: string;
      mobileStdViewLinesPerEmail: number;
      mobileExpViewLinesPerEmail: number;
      mobileMaxEmailViewedPerPanel: number;
      mobileOrganisedBy: string;
      autoDeleteOlderEmails: string;
      maxEmailCapacityPerPanel: number;
      showEmailFrom: boolean;
      showEmailDate: boolean;
      showEmailSubject: boolean;
      showEmailAttachments: boolean;
      fontSize: number;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    gmail: {
      id: string;
      sourceType: string;
      desktopStdViewLinesPerEmail: number;
      desktopExpViewLinesPerEmail: number;
      desktopMaxEmailViewedPerPanel: number;
      desktopOrganisedBy: string;
      mobileStdViewLinesPerEmail: number;
      mobileExpViewLinesPerEmail: number;
      mobileMaxEmailViewedPerPanel: number;
      mobileOrganisedBy: string;
      autoDeleteOlderEmails: string;
      maxEmailCapacityPerPanel: number;
      showEmailFrom: boolean;
      showEmailDate: boolean;
      showEmailSubject: boolean;
      showEmailAttachments: boolean;
      fontSize: number;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    slack: {
      id: string;
      sourceType: string;
      desktopMaxLinesViewPerSlackMessage: number;
      desktopMaxMessagesViewPerSlackChannel: number;
      mobileMaxLinesViewPerSlackMessage: number;
      mobileMaxMessagesViewPerSlackChannel: number;
      autoDeleteOlderMessages: string;
      maxRetentionSlackMessagePerChannel: number;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    onedrive: {
      id: string;
      sourceType: string;
      desktopMaxFoldersDisplayed: number;
      desktopFolderSortBy: string;
      mobileMaxFoldersDisplayed: number;
      mobileFolderSortBy: string;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    googledrive: {
      id: string;
      sourceType: string;
      desktopMaxFoldersDisplayed: number;
      desktopFolderSortBy: string;
      mobileMaxFoldersDisplayed: number;
      mobileFolderSortBy: string;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    dropbox: {
      id: string;
      sourceType: string;
      desktopMaxFoldersDisplayed: number;
      desktopFolderSortBy: string;
      mobileMaxFoldersDisplayed: number;
      mobileFolderSortBy: string;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
  };
  sources: TempSourceType[];
};

export type TempActivePanelsType = {
  id: string;
  name: string;
  isStandalone: boolean;
  color: string;
  order: number;
  panelStatusId: string;
  panelUserId: string;
  active: boolean;
  createdDate: string;
  modifiedDate: string;
  panelSettings: {
    outlook: {
      id: string;
      sourceType: string;
      desktopStdViewLinesPerEmail: number;
      desktopExpViewLinesPerEmail: number;
      desktopMaxEmailViewedPerPanel: number;
      desktopOrganisedBy: string;
      mobileStdViewLinesPerEmail: number;
      mobileExpViewLinesPerEmail: number;
      mobileMaxEmailViewedPerPanel: number;
      mobileOrganisedBy: string;
      autoDeleteOlderEmails: string;
      maxEmailCapacityPerPanel: number;
      showEmailFrom: boolean;
      showEmailDate: boolean;
      showEmailSubject: boolean;
      showEmailAttachments: boolean;
      fontSize: number;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    gmail: {
      id: string;
      sourceType: string;
      desktopStdViewLinesPerEmail: number;
      desktopExpViewLinesPerEmail: number;
      desktopMaxEmailViewedPerPanel: number;
      desktopOrganisedBy: string;
      mobileStdViewLinesPerEmail: number;
      mobileExpViewLinesPerEmail: number;
      mobileMaxEmailViewedPerPanel: number;
      mobileOrganisedBy: string;
      autoDeleteOlderEmails: string;
      maxEmailCapacityPerPanel: number;
      showEmailFrom: boolean;
      showEmailDate: boolean;
      showEmailSubject: boolean;
      showEmailAttachments: boolean;
      fontSize: number;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    slack: {
      id: string;
      sourceType: string;
      desktopMaxLinesViewPerSlackMessage: number;
      desktopMaxMessagesViewPerSlackChannel: number;
      mobileMaxLinesViewPerSlackMessage: number;
      mobileMaxMessagesViewPerSlackChannel: number;
      autoDeleteOlderMessages: string;
      maxRetentionSlackMessagePerChannel: number;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    onedrive: {
      id: string;
      sourceType: string;
      desktopMaxFoldersDisplayed: number;
      desktopFolderSortBy: string;
      mobileMaxFoldersDisplayed: number;
      mobileFolderSortBy: string;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    googledrive: {
      id: string;
      sourceType: string;
      desktopMaxFoldersDisplayed: number;
      desktopFolderSortBy: string;
      mobileMaxFoldersDisplayed: number;
      mobileFolderSortBy: string;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
    dropbox: {
      id: string;
      sourceType: string;
      desktopMaxFoldersDisplayed: number;
      desktopFolderSortBy: string;
      mobileMaxFoldersDisplayed: number;
      mobileFolderSortBy: string;
      panelUserId: string;
      pId: string;
      panelId: string;
      status: string;
    };
  };
  children: TempActivePanelChildType[];
  sources: [
    {
      id: string;
      source_Id: string;
      sourceId: number;
      type: string;
      panelId: string;
      pId: string;
      panelAccessTokenId: string;
      workspaceId: string;
      panelUserId: string;
      createdDate: string;
      modifiedDate: string;
      data: {
        email: string;
        filters: [
          {
            id: string;
            panelUserId: string;
            panelSourceId: string;
            email: string;
            panelId: string;
            from: string;
            hasTheWords: string;
            subject: string;
            date: string;
            hasAttachment: boolean;
            mailbox: number;
            createdDate: string;
            modifiedDate: string;
          }
        ];
      };
      slackData: {
        email: string;
        slackId: {
          workSpaceId: string;
          data: {
            slackName: string;
            channels: [
              {
                id: string;
                panelUserId: string;
                sourceId: string;
                panelDetailsourceId: string;
                panelId: string;
                slackAccessTokenId: string;
                slackEmailAddress: string;
                workspaceId: string;
                recordType: string;
                channelId: string;
                channelName: string;
                channelType: string;
                directMessageId: string;
                directMessageName: string;
                createdDate: string;
                modifiedDate: string;
              }
            ];
            directMessage: [
              {
                id: string;
                panelUserId: string;
                sourceId: string;
                panelDetailsourceId: string;
                panelId: string;
                slackAccessTokenId: string;
                slackEmailAddress: string;
                workspaceId: string;
                recordType: string;
                channelId: string;
                channelName: string;
                channelType: string;
                directMessageId: string;
                directMessageName: string;
                createdDate: string;
                modifiedDate: string;
              }
            ];
          };
        };
      };
      storageData: {
        email: string;
      };
    }
  ];
};

export type ChildDriveUtilsType = {
  createdDate: string;
  createdDateTime: string;
  deleted: boolean;
  driveId: string;
  drivePath: string;
  driveType: string;
  fileSystemCreatedDate: null;
  fileSystemLastModifiedDate: null;
  folderChildCount: null;
  id: string;
  isSelected: boolean;
  itemId: string;
  itemType: string;
  lastModifiedDateTime: string;
  modifiedDate: string;
  name: string;
  orderBy: number;
  packageType: null;
  panelId: string;
  panelStorageId: string;
  panelUserId: string;
  parentId: string;
  specialFolderName: null;
  webUrl: string;
};
export type TodoMsgProps = {};

export type Card = {
  id: string;
  name?: string;
  notes?: string;
  toDoMId?: string;
  panelId?: string;
  panelName?: string;
  statusId: string;
  statusName: string;
  priorityId: string;
  priorityName?: string;
  panelUserId?: string;
  createdDate?: Date;
  modifiedDate?: Date;
  description: string;
  panel?: string;
  priority: string;
  title: string;
  status: string;
  order: number;
};
export type TodoListProps = {
  title: string;
  id: string;
  cards: Card[];
  status: any;
  order: number;
  createdDate?: Date;
};

export type TaskPriority = {
  id: string;
  priorityName: string;
  isActive: boolean;
};

export enum TodoStatusIDItems {
  completed = "624eed84f6041cc524b9603a",
  inComplete = "624eed93f6041cc524b9603b",
}

export enum TodoStatusIDMasters {
  archived = "624eed67f6041cc524b96039",
  active = "624eec6df6041cc524b96038",
  inActive = "62c48a8b2547f627c6439294",
}

export type TodoStatusType = "Item" | "Master";
export enum TodoStatusNameItems {
  completed = "Completed",
  inComplete = "InComplete",
}

export type TodoStatusNameMasters = "Archived" | "Active" | "In Active";

export type TaskStatus = {
  id: TodoStatusIDItems | TodoStatusIDMasters;
  statusName: TodoStatusNameItems | TodoStatusNameMasters;
  statusType: TodoStatusType;
};
export type TaskStatusList = {
  items: TaskStatus[];
  masters: TaskStatus[];
};

export type TodoList = {
  id: string;
  name: string;
  statusId: string;
  statusName: string;
  panelUserId: string;
  createdDate: Date;
  modifiedDate: Date;
  children: Card[];
  order: number;
};
export interface StateInterface {
  widget_dialog_show: boolean;
  todoboard_show: boolean;
  widgets_updated: boolean;
  columnsToShow: number;
  todoWidgetId: string;
  todos: any[];
  activeTodos: TodoList[];
  archiveTodos: any[];
  priorityData: TaskPriority[];
  todoPanelList: any[];
  activeStatusList: TaskStatus[];
  todoMasterStatusList: any[];
}

export type PriorityKeys = {
  [priority: string]: string;
};

export type StatusKeys = {
  [status: string]: string;
};
export type CardProps = {
  card: Card;
  index: number;
  listId: string;
  measureHeights: () => void;
  priorityList: TaskPriority[];
  statusList: TaskStatusList;
  priorityKeys: PriorityKeys;
  statusKeys: StatusKeys;
  loadingCard: boolean;
};

export type AddTaskProps = {
  listId: string;
  measureHeights: () => void;
  isTitle: boolean;
  isNewTask: boolean;
};

export type ListTitleProps = {
  title: string;
  status: string;
  listId: string;
  slideWidth: number | undefined;
  sortMenu: string[];
  filterBy: string;
  sortByHandler: (val: string) => void;
  seeBy: string;
  list: TodoListProps;
  setSeeBy: Dispatch<SetStateAction<string>>;
  measureHeights: () => void;
  onSubmitMainTodoTitle: (val: string) => void;
  loading: boolean;
  provided?: DraggableProvided;
};

export type CardSizeProps = {
  id: string;
  val: string;
};

export type FTodoList = {
  value: string;
  label: JSX.Element;
  children: {
    value: string;
    label: JSX.Element;
  }[];
};

export type TodoLoadingStatus = {};
