import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./notifications.scss";

const today = new Date();
const yesterday = new Date().setDate(today.getDate() - 1);

const AllNotifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const panelHeaderContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <span className="message-header-name">
          {t<string>("notifications.notifications")}
        </span>
      </div>
      <div className="PanelHeaderSubContainer2">
        <button
          type="button"
          className="headerButtonItems"
          title="Help Center"
          onClick={() => navigate("/help/faq")}
        >
          <QuestionCircleOutlined role="button" />
        </button>
      </div>
    </div>
  );

  const goBack = () => {
    navigate("/panels");
  };

  return (
    <div className="messages-container">
      {panelHeaderContent}
      <div className="message-main-container">
        <div className="message-close">
          <button
            type="button"
            title={t<string>("backToPanelsButton")}
            className="message-close-button"
            onClick={() => goBack()}
          >
            {t<string>("notifications.close")}
          </button>
        </div>
        <div>
          <div className="message-date">{t<string>("notifications.today")}</div>
          <div className="message-info">
            <div className="task-circle" />
            <div className="task-info">
              <div className="task-title">Need to reconnect Data Source.</div>
              Warning: a data source has become disconnected Click here to
              reconnect your data source
            </div>
          </div>
          <div className="message-info">
            <div className="task-circle" />
            <div className="task-info">
              <div className="task-title">Subscription Update</div>
              Your subscription will update on May 1. Manage Subscription.
            </div>
          </div>
        </div>
        <div>
          <div className="message-date">
            {t<string>("notifications.yesterday")}
          </div>
          <div className="message-info">
            <div className="task-circle" />
            <div className="task-info">
              <div className="task-title">Need to reconnect Data Source.</div>
              Warning: a data source has become disconnected Click here to
              reconnect your data source
            </div>
          </div>
          <div className="message-info">
            <div className="task-circle task-circle__urgent" />
            <div className="task-info">
              <div className="task-title">Subscription Update</div>
              Your subscription will update on May 1. Manage Subscription.
            </div>
          </div>
        </div>
        <div>
          <div className="message-date">{t<string>("notifications.older")}</div>
          <div className="message-info">
            <div className="task-circle task-circle__urgent-read" />
            <div className="task-info">
              <div className="task-title">Need to reconnect Data Source.</div>
              Warning: a data source has become disconnected Click here to
              reconnect your data source
            </div>
          </div>
          <div className="message-info">
            <div className="task-circle task-circle__read" />
            <div className="task-info">
              <div className="task-title">Subscription Update</div>
              Your subscription will update on May 1. Manage Subscription.
            </div>
          </div>
        </div>
      </div>
      <div className="message-footer">
        <div className="message-footer-text">
          {t<string>("notifications.markAll")}
        </div>
      </div>
    </div>
  );
};

export default AllNotifications;
