/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import c from "classnames";
import { FC, PropsWithChildren } from "react";
import "./subscription-page.scss";

type CardInputBlockProps = {
  className?: string;
};
const CardInputBlock: FC<PropsWithChildren<CardInputBlockProps>> = ({
  className,
}) => {
  const basicStyles = c("basic-card-styles", className);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    showIcon: true,
  };

  return (
    <div>
      <div className={c("flex-f-col-100 pb15px", className)}>
        <label htmlFor="card-number" className="font-14-pb-5px">
          Card Number
        </label>
        <CardNumberElement
          id="card-number"
          className={basicStyles}
          options={CARD_ELEMENT_OPTIONS}
        />
      </div>

      <div className="flex pb15px">
        <div className={c("flex-f-col-100 pr10px", className)}>
          <label htmlFor="card-expiration" className="font-14-pb-5px">
            Expiration
          </label>
          <CardExpiryElement
            id="card-expiration"
            className={basicStyles}
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
        <div className={c("flex-f-col-100", className)}>
          <label htmlFor="card-cvc" className="font-14-pb-5px">
            CVC
          </label>
          <CardCvcElement
            id="card-cvc"
            className={basicStyles}
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
      </div>
    </div>
  );
};

export { CardInputBlock };
