import { Dispatch } from "redux";
import {
  Panel,
  SourceName,
} from "../../../../interfaces/user-detail-interface";
import PanelActionTypes from "../action-types/panel-ation-types";

export const updateAfterPanelDrag = (item: Panel, index: number) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_POSITION_AFTER_DRAG,
      payload: { item, index },
    });
  };
};

export const updatePanelsDetails = (item: Panel[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_PANEL_DETAILS,
      payload: { item },
    });
  };
};

export const updatePanelChildrenById = (
  activePanel: { id: string; pId: string },
  data: SourceName
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_PANEL_CHILDREN_SETTINGS_BY_ID,
      payload: { activePanel, data },
    });
  };
};

export const updatePanelSettingsById = (
  activePanel: { id: string },
  data: SourceName
) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_PANEL_SETTINGS_BY_ID,
      payload: { activePanel, data },
    });
  };
};

export const updateAllPanelSettings = (data: SourceName) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_ALL_PANEL_SETTINGS,
      payload: { data },
    });
  };
};

export const updateArchivedPanelsDetails = (item: Panel[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_ARCHIVED_PANEL_DETAILS,
      payload: { item },
    });
  };
};

export const updatePanel = (activeItem: Panel[], archiveItem: Panel[]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.UPDATE_PANEL,
      payload: { activeItem, archiveItem },
    });
  };
};

export const deletePanel = (panelId: Panel["id"], pId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: PanelActionTypes.DELETE_PANEL,
      payload: { id: panelId, pId },
    });
  };
};

export const updateAfterPanelDragSidebar = (item: Panel, index: number) => {
  return {
    type: PanelActionTypes.UPDATE_POSITION_AFTER_DRAG_SIDEBAR,
    payload: { item, index },
  };
};
