import { HeaderSubscription } from "./header-subscription";
import "./subscription-page.scss";

const SubscriptionResetCheck = () => {
  return (
    <>
      <HeaderSubscription />
      <div className="confirm-container">
        <h1 className="text-left">Reset Password</h1>
        <p className="text-left">
          Please check your email and click the included link to reset your
          password.
        </p>
      </div>
    </>
  );
};

export default SubscriptionResetCheck;
