/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Divider,
  Dropdown,
  Input,
  InputRef,
  Menu,
  MenuProps,
  Spin,
  Typography,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import storeApi from "./StoreAPI";
import { ReactComponent as GrabIcon } from "../../../../../assets/Icons/smallGrab.svg";
import { ReactComponent as SortIcon } from "../../../../../assets/Icons/sort.svg";
import { ReactComponent as OptionsIcon } from "../../../../../assets/Icons/options.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/Icons/check-icon.svg";
import { RootReducer } from "../../../../../store/reducers/root-reducer";
import {
  ListTitleProps,
  TodoList,
  TodoListProps,
} from "../../../../../prop-types/prop-type";
import useAxios from "../../../../../services/axios";
import SERVER_CONFIG from "../../../../../utils/config";
import { updateActiveTodos } from "../../../../../store/reducers/todo/action/todo";
import "./todo-board.scss";

const { Title } = Typography;

const ListTitle = ({
  title,
  status,
  listId,
  slideWidth,
  sortMenu,
  filterBy,
  sortByHandler,
  seeBy,
  list,
  setSeeBy,
  measureHeights,
  onSubmitMainTodoTitle,
  loading,
  provided,
}: ListTitleProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [newTitle, setNewTitle] = useState<string>(title);
  const { updateListTitle, deleteList, updateListStatus } =
    useContext(storeApi); // deleteList

  const [responseUpdate, loadingUpdate, errorUpdate, axiosUpdateFetch] =
    useAxios();

  const { activeTodos } = useSelector((state: RootReducer) => state.todo);

  const updateTodoTitle = () => {
    axiosUpdateFetch({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.TODO_LIST_UPDATE(listId),
      data: [
        {
          value: newTitle,
          path: "/name",
          op: "replace",
        },
      ],
    });
  };

  // UPDATE TODO LIST TITLE HANDLER
  const handleOnBlur = () => {
    if (listId.startsWith("new-id") && newTitle) {
      onSubmitMainTodoTitle(newTitle);
    } else if (title !== newTitle) {
      updateTodoTitle();
    }
    setOpen(false);
    measureHeights();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (responseUpdate && responseUpdate.data.success) {
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === listId
      );
      const tepmTodo = tempTodos[indexModifiedToDo];

      tepmTodo.name = newTitle;
      tempTodos.splice(indexModifiedToDo, 1, tepmTodo);
      dispatch(updateActiveTodos(tempTodos));
    }
  }, [responseUpdate]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button
          type="text"
          onClick={() => {
            deleteList(listId);
            measureHeights();
          }}
          className="button-context-menu"
        >
          Delete
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          type="text"
          onClick={() => {
            setSeeBy("View All");
            measureHeights();
          }}
          className="button-context-menu"
        >
          View All
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          type="text"
          onClick={() => {
            setSeeBy("Complete");
            measureHeights();
          }}
          className="button-context-menu"
        >
          View Only Complete
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          type="text"
          onClick={() => {
            setSeeBy("InComplete");
            measureHeights();
          }}
          className="button-context-menu"
        >
          View Only InComplete
        </Button>
      ),
    },
  ];

  const inputRef = useRef<InputRef | null>(null);

  useEffect(() => {
    if (inputRef && inputRef.current && !title) {
      inputRef.current.focus({
        cursor: "start",
      });
    }
  }, [title]);

  return (
    <Spin size="large" spinning={loading || loadingUpdate}>
      <div className="todo-header">
        <div className="todo-title">
          <Button
            type="text"
            className="todo-gb-btn"
            icon={<GrabIcon />}
            {...provided?.dragHandleProps}
          />
          {title !== "" && !open ? (
            <Title
              level={5}
              onClick={() => {
                setOpen(true);
                measureHeights();
              }}
              // style={{ width: `${Number(slideWidth) / 2.7}px` }}
            >
              {title}
            </Title>
          ) : (
            <Input
              value={newTitle}
              onChange={(e) => {
                setNewTitle(e.target.value);
              }}
              onBlur={handleOnBlur}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleOnBlur();
                  measureHeights();
                }
              }}
              placeholder="Enter a ToDo Title..."
              bordered={false}
              ref={inputRef}
            />
          )}
        </div>

        <div className="header-actions">
          {/* <div className="sortBy-todo">
          <Dropdown
            disabled={title === ""}
            overlay={
              <Menu className="todo-menu" selectable>
                {sortMenu.map((x: any, index: number) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Menu.Item key={index}>
                      <Button type="text" onClick={() => sortByHandler(x)}>
                        <SortIcon style={{ marginRight: 6 }} />
                        by <span className="light-txt">{x}</span>
                      </Button>
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            trigger={["click"]}
            arrow
          >
            <Button
              className={`fby-btn ${title === "" ? "disable-filter" : ""}`}
              type="text"
              onClick={() => sortByHandler}
            >
              <SortIcon style={{ marginRight: 5 }} />
              by <span className="light-txt">{filterBy}</span>
            </Button>
          </Dropdown>
        </div> */}
          <Dropdown
            menu={{ items, selectable: true }}
            trigger={["click"]}
            arrow
            placement="bottomRight"
          >
            <Button type="text" icon={<OptionsIcon />} />
          </Dropdown>
        </div>
      </div>
    </Spin>
  );
};

export default ListTitle;
