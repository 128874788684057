import { combineReducers } from "redux";
import AuthReducer from "./auth/reducer/auth-reducer";
import loadingStateReducer from "./loading-states/reducer/loading-states-reducer";
import panelDataReducer from "./panel-data/reducer/panel-data";
import panelReducer from "./panels/reducer/panels-reducer";
import UpdateDropBoxStoragePanelsData from "./panel-storage/reducer/drop-box-panel-storage";
import UpdateGdriveStoragePanelsData from "./panel-storage/reducer/gdrive-panel-storage";
import UpdateStorageBeforeDrag from "./panel-storage/reducer/panel-default-data-drag";
import PanelStorageDataReducer from "./panel-storage/reducer/panel-storage-reducer";
import AddNewPanelReducer from "./save-panel-storage/add-new-panel-reducer";
import paramsReducer from "./save-params/params-reducer";
import SocketConnectionReducer from "./signalR/connection/reducer/connection-reducer";
import UserAccountReducer from "./user/recducer/user-reducer";
import AddSourceReducer from "./addSource/reducer/addDataSource-reducer";
import ReceiveMessageReducer from "./signalR/receive-message/reducer/receive-message-reducer";
import { LOGOUT } from "./auth/action/auth-action";
import AddNewPathnameLocationReducer from "./pathname-location/reducer/pathname-location-reducer";
import AddPreviousPanelInfoReducer from "./prev-panel/reducer/prev-panel-reducer";
import AddPanelIdToSetupReducer from "./panel-to-set/reducer/panel-to-set-reducer";
import { subscriptionApi } from "../api/subscription";
import { usersApi } from "../api/users";
import todoReducer from "./todo/reducer/todo-reducer";
import panelZoomInfoReducer from "./zoomPanels/reducer/zoom-panels-reducer";
import EmailDeleteReducer from "./email-delete/reducer/email-delete-reducer";

const Reducers = combineReducers({
  user: UserAccountReducer,
  panels: panelReducer,
  signalRConnection: SocketConnectionReducer,
  signalRReceiveMessage: ReceiveMessageReducer,
  loading: loadingStateReducer,
  panelData: panelDataReducer,
  panelStorage: PanelStorageDataReducer,
  gDriveStorage: UpdateGdriveStoragePanelsData,
  dropboxStorage: UpdateDropBoxStoragePanelsData,
  saveNewPanel: AddNewPanelReducer,
  paramsRoute: paramsReducer,
  updateStorageBeforeDrags: UpdateStorageBeforeDrag,
  Auth: AuthReducer,
  addSource: AddSourceReducer,
  pathnameLocation: AddNewPathnameLocationReducer,
  previousPanelInfo: AddPreviousPanelInfoReducer,
  panelIdToSetup: AddPanelIdToSetupReducer,
  todo: todoReducer,
  zoom: panelZoomInfoReducer,
  emailDelete: EmailDeleteReducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    return Reducers(undefined, action);
  }
  return Reducers(state, action);
};

export default rootReducer;

export type RootReducer = ReturnType<typeof rootReducer>;
