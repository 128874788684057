/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import { Input, InputRef } from "antd";
import { useSelector, useDispatch } from "react-redux";
import storeApi from "./StoreAPI";
import { ReactComponent as AddIcon } from "../../../../../assets/Icons/add-icon.svg";
import {
  AddTaskProps,
  Card,
  TodoList,
} from "../../../../../prop-types/prop-type";
import useAxios from "../../../../../services/axios";
import SERVER_CONFIG from "../../../../../utils/config";
import { RootReducer } from "../../../../../store/reducers/root-reducer";
import { updateActiveTodos } from "../../../../../store/reducers/todo/action/todo";

const AddTask = ({
  listId,
  measureHeights,
  isTitle,
  isNewTask,
}: AddTaskProps) => {
  const [title, setTitle] = useState("");

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    measureHeights();
  };
  const { activeTodos } = useSelector((state: RootReducer) => state.todo);
  const [response, loading, error, axiosFetch] = useAxios();

  const handleBtnConfirm = () => {
    axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.TODO_LIST_TASK_SAVE,
      data: {
        toDoMId: listId,
        name: title,
        notes: "",
      },
    });
    setTitle("");
    measureHeights();
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (response && response.data?.success) {
      const newTodo: Card = response.data?.data;
      const tempTodos: TodoList[] = [...activeTodos];
      const indexModifiedToDo: number = tempTodos.findIndex(
        (todoList: TodoList) => todoList.id === newTodo.toDoMId
      );
      const tepmTodo = tempTodos[indexModifiedToDo];

      tepmTodo.children = [...tepmTodo.children, newTodo];
      tempTodos.splice(indexModifiedToDo, 1, tepmTodo);
      dispatch(updateActiveTodos(tempTodos));
    }
  }, [response]);

  const inputRef = useRef<InputRef | null>(null);

  useEffect(() => {
    if (inputRef && inputRef.current && isNewTask) {
      inputRef.current.focus({
        cursor: "end",
      });
    }
  }, [title, isTitle]);

  return (
    <div className="add-to-do-textfield">
      <AddIcon className="add-icon" />
      <Input
        onChange={handleOnChange}
        value={title}
        className="input-text"
        placeholder="Add To Do"
        onKeyPress={(e) => {
          if (title !== "" && e.key === "Enter") {
            handleBtnConfirm();
          }
        }}
        onBlur={() => {
          if (title) {
            handleBtnConfirm();
          }
        }}
        ref={isTitle ? inputRef : null}
        bordered={false}
      />
    </div>
  );
};

export default AddTask;
