import { useEffect, useState } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import icons from "../../assets/icon-export-assets";
import {
  Child,
  Panel,
  PanelsData,
  TempChannel,
  TempChildType,
  TempItemPanelData,
  TempParentType,
  TempPreparedChennelsType,
  TempPreparedDMType,
  TempSlackChannelDMMessage,
  TempSlackChannels,
  TempSlackData,
  TempSlackDirectMsg,
  TempSlackIdData,
  TempSlackMsg,
  TempSlackWorkspaceId,
  TempSourceType,
} from "../../interfaces/user-detail-interface";

import "../global-settings/settings.scss";
import DataSourcesDeleteModal from "../settings/data-sources-delete-modal";
import { RootReducer } from "../../store/reducers/root-reducer";
import useAxios from "../../services/axios";
import SERVER_CONFIG from "../../utils/config";
import { updatePanel } from "../../store/reducers/panels/actions/panel-actions";
import { setPanelsData } from "../../store/reducers/panel-data/actions/panels-data-actions";
import { DataSourceId } from "../../prop-types/prop-type";

const cutLongName = (name: string) => {
  if (name.length <= 29) {
    return name;
  }
  const cuttedName = `${name.slice(0, 27)}...`;
  return cuttedName;
};

const {
  outlookIcon,
  gmailLarge,
  oneDriveIcon,
  slackIcon,
  googleDriveIcon,
  dropboxIcon,
  // jiraIcon,
} = icons;

const CheckIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6L5 9L10 3"
      stroke="#676567"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DelIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0837 3.73913L10.2612 2.91663L7.00033 6.17746L3.73949 2.91663L2.91699 3.73913L6.17783 6.99996L2.91699 10.2608L3.73949 11.0833L7.00033 7.82246L10.2612 11.0833L11.0837 10.2608L7.82283 6.99996L11.0837 3.73913Z"
      fill="#BA3A29"
    />
  </svg>
);

function DatasourcesList({
  addSource,
  item,
}: {
  addSource: (type: string) => void;
  item: Child;
}) {
  const { t } = useTranslation();

  type ModifyAccount = {
    id: string;
    email: string;
    sourceType: string;
    workspaceId?: string;
    workspaceName?: string;
    retainOlderData?: boolean;
  };

  const [accountToModify, setAccountToModify] = useState({} as ModifyAccount);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteSourse = ({
    id,
    email,
    sourceType,
    workspaceId,
    workspaceName,
  }: Record<string, string>) => {
    if (workspaceId && workspaceName) {
      setAccountToModify({ id, email, sourceType, workspaceId, workspaceName });
    } else {
      setAccountToModify({ id, email, sourceType });
    }
    setIsDeleteModalOpen(true);
  };

  const handleModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const [panelsResponse, panelsLoading, panelsError, axiosFetchPanels] =
    useAxios();
  const [deleteResponse, deleteLoading, deleteError, axiosDeleteFetch] =
    useAxios();
  const [addResponse, addLoading, addError, axiosAddFetch] = useAxios();

  const handleAddSource = async ({
    id,
    email,
    sourceType,
    workspaceId,
  }: Record<string, string>) => {
    const dataSourcePayload: ModifyAccount = {
      id,
      email,
      sourceType,
    };
    if (sourceType.toLowerCase() === "slack") {
      dataSourcePayload.workspaceId = workspaceId;
    }
    const {
      // @ts-ignore
      data: { data },
    } = await axiosAddFetch({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: item.pId
        ? SERVER_CONFIG.API_URL.SOURCE_SUBPANEL_ADD(item.pId, item.id)
        : SERVER_CONFIG.API_URL.SOURCE_PANEL_ADD(item.id),
      data: dataSourcePayload,
    });
    if (data && dataSourcePayload.workspaceId) {
      setAccountToModify({ id, email, sourceType, workspaceId });
    } else if (data) {
      setAccountToModify({ id, email, sourceType });
    }
  };

  const handleModalSubmit = async () => {
    try {
      const payload: ModifyAccount = {
        id: accountToModify.id,
        email: accountToModify.email,
        sourceType: accountToModify.sourceType,
        retainOlderData: false,
      };
      if (accountToModify.workspaceId) {
        payload.workspaceId = accountToModify.workspaceId;
      }
      await axiosDeleteFetch({
        method: SERVER_CONFIG.HTTP_METHOD.DELETE,
        url: item.pId
          ? SERVER_CONFIG.API_URL.SOURCE_SUBPANEL_DELETE(item.pId, item.id)
          : SERVER_CONFIG.API_URL.SOURCE_PANEL_DELETE(item.id),
        data: payload,
      });
    } finally {
      setIsDeleteModalOpen(false);
    }
  };
  const panelData: PanelsData = useSelector(
    (state: RootReducer) => state.panelData
  );
  const { activePanels } = useSelector((state: RootReducer) => state.panels);
  const params = useParams();
  const parentMode = !params.subpanelId;
  const parentPanel: Panel = activePanels.find(
    (panel: Panel) => panel.id === params.panelId
  );
  const isNerrowWindow =
    (parentPanel.children?.length > 2 && parentMode) || isMobile;

  const dispatch = useDispatch<any>();
  const [panelsAllResponse, panelsAllLoading, errorAll, axiosFetch] =
    useAxios();

  const [panelsDataResponse, panelsDataLoading, errorData, axiosDataFetch] =
    useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    if (panelsAllResponse) {
      axiosDataFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.PANELS_GET,
      });
    }
  }, [panelsAllResponse]);
  useEffect(() => {
    if (panelsDataResponse) {
      const { data } = panelsDataResponse;
      if (data && data.success) {
        const tempPanels = [...data.panels.activePanels];
        tempPanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempPanels[itemIndex].children[childIndex].sources.splice(
                    sourceIndex,
                    1,
                    updatedSourceDetails
                  );
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempPanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        const tempArchivePanels = [...data.panels.archivedPanels];
        tempArchivePanels.forEach((item: TempParentType, itemIndex) => {
          if (!item.isStandalone) {
            const tempChildren = [...item.children];
            tempChildren.forEach((child: TempChildType, childIndex) => {
              const tempSources: TempSourceType[] = [...child.sources];
              tempSources.forEach((source: TempSourceType, sourceIndex) => {
                if (source.sourceId === DataSourceId.SLACK) {
                  const { slackData } = source;
                  const { slackId } = slackData;
                  const { data: dataObj } = slackId;
                  const slack = {
                    [slackData.email]: {
                      [slackId.workSpaceId]: {
                        slackName: dataObj.slackName,
                        channels: {} as TempPreparedChennelsType,
                        directMessages: {} as TempPreparedDMType,
                      },
                    },
                  };
                  dataObj.channels.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].channels[
                      e.channelId
                    ] = { channelName: e.channelName };
                  });
                  dataObj.directMessage.forEach((e) => {
                    slack[slackData.email][slackId.workSpaceId].directMessages[
                      e.channelId
                    ] = {
                      directMessagesName: e.directMessageName,
                      channelId: e.channelId,
                    };
                  });
                  const updatedSourceDetails = { ...source, data: { slack } };
                  tempArchivePanels[itemIndex].children[
                    childIndex
                  ].sources.splice(sourceIndex, 1, updatedSourceDetails);
                }
              });
            });
          } else {
            const tempSources = [...item.sources];
            tempSources.forEach((source: TempSourceType, sourceIndex) => {
              if (source.sourceId === DataSourceId.SLACK) {
                const { slackData } = source;
                const { slackId } = slackData;
                const { data: dataObj } = slackId;
                const slack = {
                  [slackData.email]: {
                    [slackId.workSpaceId]: {
                      slackName: dataObj.slackName,
                      channels: {} as TempPreparedChennelsType,
                      directMessages: {} as TempPreparedDMType,
                    },
                  },
                };
                dataObj.channels.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].channels[
                    e.channelId
                  ] = { channelName: e.channelName };
                });
                dataObj.directMessage.forEach((e) => {
                  slack[slackData.email][slackId.workSpaceId].directMessages[
                    e.channelId
                  ] = {
                    directMessagesName: e.directMessageName,
                    channelId: e.channelId,
                  };
                });
                const updatedSourceDetails = { ...source, data: { slack } };
                tempArchivePanels[itemIndex].sources.splice(
                  sourceIndex,
                  1,
                  updatedSourceDetails
                );
              }
            });
          }
        });
        dispatch(updatePanel(tempPanels, tempArchivePanels));
      }

      if (
        item.pId &&
        (accountToModify.sourceType?.toLowerCase() === "outlook" ||
          accountToModify.sourceType?.toLowerCase() === "gmail")
      ) {
        navigate(`/filters/${item.pId}/${item.id}`);
      } else if (
        accountToModify.sourceType?.toLowerCase() === "outlook" ||
        accountToModify.sourceType?.toLowerCase() === "gmail"
      ) {
        navigate(`/filters/${item.id}`);
      } else if (item.pId) {
        setAccountToModify({} as ModifyAccount);
        navigate(`/panels/${item.pId}/${item.id}`);
      } else {
        setAccountToModify({} as ModifyAccount);
        navigate(`/panels/${item.id}`);
      }
    }
  }, [panelsDataResponse]);

  const [
    panelsAllDataListResponse,
    panelsAllDataListLoading,
    panelsAllDataListError,
    axiosPanelsAllDataList,
  ] = useAxios();

  useEffect(() => {
    if (panelsAllDataListResponse) {
      const res = panelsAllDataListResponse;
      if (res && res.data.success) {
        const panelsData = {} as any;
        res.data.panelsData.forEach((item: TempItemPanelData) => {
          panelsData[item.panelId] = {
            email: { gmail: {}, outlook: {} },
            slack: {},
          };
          const gmailDetails = item.email?.gmail[0];
          if (gmailDetails) {
            const gmailId = gmailDetails.emailId;
            const gmailMails = { data: gmailDetails.data };
            panelsData[item.panelId].email.gmail[gmailId] = gmailMails;
          }
          const outlookDetails = item.email.outlook[0];
          if (outlookDetails) {
            const outlookId = outlookDetails.emailId;
            const outlookMails = { data: outlookDetails.data };
            panelsData[item.panelId].email.outlook[outlookId] = outlookMails;
          }
          const slackDetails = item.slack;
          if (slackDetails && slackDetails.length > 0) {
            const slackObj = {} as TempSlackData;
            slackDetails.forEach((slack: TempSlackMsg) => {
              if (!slackObj[slack.emailId]) {
                slackObj[slack.emailId] = {} as TempSlackIdData;
              }
              slackObj[slack.emailId][slack.slackId.workSpaceId] =
                {} as TempSlackWorkspaceId;
              slackObj[slack.emailId][slack.slackId.workSpaceId].channels =
                {} as TempSlackChannels;
              slackObj[slack.emailId][
                slack.slackId.workSpaceId
              ].directMessages = {} as TempSlackDirectMsg;
              slack.slackId.data.directMessage.forEach(
                (message: TempChannel) => {
                  if (
                    !slackObj[slack.emailId][slack.slackId.workSpaceId]
                      .directMessages[message.channelId]
                  ) {
                    slackObj[slack.emailId][
                      slack.slackId.workSpaceId
                    ].directMessages[message.channelId] =
                      {} as TempSlackChannelDMMessage;
                    slackObj[slack.emailId][
                      slack.slackId.workSpaceId
                    ].directMessages[message.channelId].messages = [];
                  }
                  slackObj[slack.emailId][
                    slack.slackId.workSpaceId
                  ].directMessages[message.channelId].messages.push(message);
                }
              );
              slack.slackId.data.channels.forEach((channel: TempChannel) => {
                if (
                  !slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ]
                ) {
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ] = {} as TempSlackChannelDMMessage;
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ].messages = [];
                }
                slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ].messages.push(channel);
              });
            });
            panelsData[item.panelId].slack = slackObj;
          }
          panelsData[item.panelId].storage = {};
          if (item.storage?.oneDrive?.length > 0) {
            panelsData[item.panelId].storage.oneDrive = {} as any;
            item.storage?.oneDrive.forEach((oneDrive: any) => {
              if (
                oneDrive &&
                oneDrive?.oneDriveData &&
                oneDrive?.storageEmailId
              ) {
                panelsData[item.panelId].storage.oneDrive[
                  oneDrive.storageEmailId
                ] = oneDrive.oneDriveData;
              }
            });
          }

          if (item.storage?.googleDrive?.length > 0) {
            panelsData[item.panelId].storage.googleDrive = {} as any;
            item.storage?.googleDrive.forEach((googleDrive: any) => {
              if (
                googleDrive &&
                googleDrive.googleDriveData &&
                googleDrive.storageEmailId
              ) {
                panelsData[item.panelId].storage.googleDrive[
                  googleDrive.storageEmailId
                ] = googleDrive.googleDriveData;
              }
            });
          }

          if (item.storage?.dropBox?.length > 0) {
            panelsData[item.panelId].storage.dropBox = {} as any;
            item.storage?.dropBox.forEach((dropBox: any) => {
              if (dropBox && dropBox.dropBoxData && dropBox.storageEmailId) {
                panelsData[item.panelId].storage.dropBox[
                  dropBox.storageEmailId
                ] = dropBox.dropBoxData;
              }
            });
          }
        });
        dispatch(setPanelsData(panelsData));
      }
    }
  }, [panelsAllDataListResponse]);

  useEffect(() => {
    if (addResponse?.data.success) {
      axiosFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.PANELS_GET,
      });
      axiosPanelsAllDataList({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.PANELS_DATA,
      });
    }
  }, [addResponse]);
  useEffect(() => {
    if (deleteResponse?.data.success) {
      axiosFetch({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.PANELS_GET,
      });
      axiosPanelsAllDataList({
        method: SERVER_CONFIG.HTTP_METHOD.GET,
        url: SERVER_CONFIG.API_URL.PANELS_DATA,
      });
      setAccountToModify({} as ModifyAccount);
    }
  }, [deleteResponse]);

  useEffect(() => {
    axiosFetchPanels({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: item.pId
        ? SERVER_CONFIG.API_URL.SOURCE_SUBPANEL_ALL(item.pId, item.id)
        : SERVER_CONFIG.API_URL.SOURCE_PANELS_ALL(item.id),
    });
  }, [panelData]);

  type SourceResponse = {
    email: string;
    id: string;
    isAssigned: boolean;
    sourceType: string;
    workspaceName?: string;
    workspaceId?: string;
  };

  const isDataLoading =
    panelsLoading ||
    addLoading ||
    deleteLoading ||
    panelsAllLoading ||
    panelsDataLoading ||
    panelsAllDataListLoading;

  return (
    <Spin size="large" spinning={isDataLoading}>
      <div className="datasource-list">
        {" "}
        <div className="settings-datasource-container">
          <img src={outlookIcon} height={50} width={50} alt="outlook" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>{t<string>("globalSettings.datasources.outlookTitle")}</h4>

              {panelsResponse?.data.data.outlook.map((source: SourceResponse) =>
                !source.isAssigned ? (
                  <div
                    key={source.id}
                    onClick={() =>
                      handleAddSource({
                        id: source.id,
                        email: source.email,
                        sourceType: source.sourceType,
                      })
                    }
                  >
                    <span className="datasource-text-plus grab">+</span>{" "}
                    <span className="datasource-text-mail">
                      {isNerrowWindow
                        ? cutLongName(source.email || "")
                        : source.email}
                    </span>
                  </div>
                ) : (
                  <p key={source.id} className="datasource-list-sources">
                    <span>
                      <CheckIcon />
                    </span>{" "}
                    {isNerrowWindow
                      ? cutLongName(source.email || "")
                      : source.email}{" "}
                    <span
                      onClick={() => {
                        handleDeleteSourse({
                          id: source.id,
                          email: source.email,
                          sourceType: source.sourceType,
                        } as Record<string, string>);
                      }}
                      className="grab"
                    >
                      <DelIcon />
                    </span>
                  </p>
                )
              )}

              <p>{t<string>("globalSettings.datasources.outlookText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("outlook")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div>
        <div className="settings-datasource-container">
          <img src={gmailLarge} height={50} width={50} alt="gmail" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>{t<string>("globalSettings.datasources.gmailTitle")}</h4>

              {panelsResponse?.data.data.gmail.map((source: SourceResponse) =>
                !source.isAssigned ? (
                  <div
                    key={source.id}
                    onClick={() =>
                      handleAddSource({
                        id: source.id,
                        email: source.email,
                        sourceType: source.sourceType,
                      })
                    }
                  >
                    <span className="datasource-text-plus grab">+</span>{" "}
                    <span className="datasource-text-mail">
                      {isNerrowWindow
                        ? cutLongName(source.email || "")
                        : source.email}
                    </span>
                  </div>
                ) : (
                  <p key={source.id} className="datasource-list-sources">
                    <span>
                      <CheckIcon />
                    </span>{" "}
                    {isNerrowWindow
                      ? cutLongName(source.email || "")
                      : source.email}{" "}
                    <span
                      onClick={() => {
                        handleDeleteSourse({
                          id: source.id,
                          email: source.email,
                          sourceType: source.sourceType,
                        } as Record<string, string>);
                      }}
                      className="grab"
                    >
                      <DelIcon />
                    </span>
                  </p>
                )
              )}

              <p>{t<string>("globalSettings.datasources.gmailText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("gmail")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div>
        <div className="settings-datasource-container">
          <img src={slackIcon} height={50} width={50} alt="slack" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>{t<string>("globalSettings.datasources.slackTitle")}</h4>

              {panelsResponse?.data.data.slack.map((source: SourceResponse) =>
                !source.isAssigned ? (
                  <div
                    key={source.id}
                    onClick={() =>
                      handleAddSource({
                        id: source.id,
                        email: source.email,
                        sourceType: source.sourceType,
                        workspaceId: source.workspaceId || "",
                      })
                    }
                  >
                    <span className="datasource-text-plus grab">+</span>{" "}
                    <span className="datasource-text-mail">
                      {isNerrowWindow
                        ? cutLongName(source.email || "")
                        : source.email}
                    </span>
                    <span className="datasource-text-mail">
                      {" "}
                      - {source.workspaceName}
                    </span>
                  </div>
                ) : (
                  <p key={source.id} className="datasource-list-sources">
                    <span>
                      <CheckIcon />
                    </span>{" "}
                    {isNerrowWindow
                      ? cutLongName(source.email || "")
                      : source.email}{" "}
                    <span>- {source.workspaceName} </span>
                    <span
                      onClick={() => {
                        handleDeleteSourse({
                          id: source.id,
                          email: source.email,
                          sourceType: source.sourceType,
                          workspaceId: source.workspaceId,
                          workspaceName: source.workspaceName,
                        } as Record<string, string>);
                      }}
                      className="grab"
                    >
                      <DelIcon />
                    </span>
                  </p>
                )
              )}

              <p>{t<string>("globalSettings.datasources.slackText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("slack")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div>
        <div className="settings-datasource-container">
          <img src={dropboxIcon} height={50} width={50} alt="dropbox" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>{t<string>("globalSettings.datasources.dropboxTitle")}</h4>

              {panelsResponse?.data.data.dropbox.map((source: SourceResponse) =>
                !source.isAssigned ? (
                  <div
                    key={source.id}
                    onClick={() =>
                      handleAddSource({
                        id: source.id,
                        email: source.email,
                        sourceType: source.sourceType,
                      })
                    }
                  >
                    <span className="datasource-text-plus grab">+</span>{" "}
                    <span className="datasource-text-mail">
                      {isNerrowWindow
                        ? cutLongName(source.email || "")
                        : source.email}
                    </span>
                  </div>
                ) : (
                  <p key={source.id} className="datasource-list-sources">
                    <span>
                      <CheckIcon />
                    </span>{" "}
                    {isNerrowWindow
                      ? cutLongName(source.email || "")
                      : source.email}{" "}
                    <span
                      onClick={() => {
                        handleDeleteSourse({
                          id: source.id,
                          email: source.email,
                          sourceType: source.sourceType,
                        } as Record<string, string>);
                      }}
                      className="grab"
                    >
                      <DelIcon />
                    </span>
                  </p>
                )
              )}

              <p>{t<string>("globalSettings.datasources.dropboxText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("dropbox")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div>
        <div className="settings-datasource-container">
          <img src={oneDriveIcon} height={50} width={50} alt="oneDrive" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>{t<string>("globalSettings.datasources.oneDriveTitle")}</h4>

              {panelsResponse?.data.data.onedrive.map(
                (source: SourceResponse) =>
                  !source.isAssigned ? (
                    <div
                      key={source.id}
                      onClick={() =>
                        handleAddSource({
                          id: source.id,
                          email: source.email,
                          sourceType: source.sourceType,
                        })
                      }
                    >
                      <span className="datasource-text-plus grab">+</span>{" "}
                      <span className="datasource-text-mail">
                        {isNerrowWindow
                          ? cutLongName(source.email || "")
                          : source.email}
                      </span>
                    </div>
                  ) : (
                    <p key={source.id} className="datasource-list-sources">
                      <span>
                        <CheckIcon />
                      </span>{" "}
                      {isNerrowWindow
                        ? cutLongName(source.email || "")
                        : source.email}{" "}
                      <span
                        onClick={() => {
                          handleDeleteSourse({
                            id: source.id,
                            email: source.email,
                            sourceType: source.sourceType,
                          } as Record<string, string>);
                        }}
                        className="grab"
                      >
                        <DelIcon />
                      </span>
                    </p>
                  )
              )}

              <p>{t<string>("globalSettings.datasources.oneDriveText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("onedrive")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div>
        <div className="settings-datasource-container">
          <img src={googleDriveIcon} height={50} width={50} alt="googleDrive" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>
                {t<string>("globalSettings.datasources.googleDriveTitle")}
              </h4>

              {panelsResponse?.data.data.googledrive.map(
                (source: SourceResponse) =>
                  !source.isAssigned ? (
                    <div
                      key={source.id}
                      onClick={() =>
                        handleAddSource({
                          id: source.id,
                          email: source.email,
                          sourceType: source.sourceType,
                        })
                      }
                    >
                      <span className="datasource-text-plus grab">+</span>{" "}
                      <span className="datasource-text-mail">
                        {isNerrowWindow
                          ? cutLongName(source.email || "")
                          : source.email}
                      </span>
                    </div>
                  ) : (
                    <p key={source.id} className="datasource-list-sources">
                      <span>
                        <CheckIcon />
                      </span>{" "}
                      {isNerrowWindow
                        ? cutLongName(source.email || "")
                        : source.email}{" "}
                      <span
                        onClick={() => {
                          handleDeleteSourse({
                            id: source.id,
                            email: source.email,
                            sourceType: source.sourceType,
                          } as Record<string, string>);
                        }}
                        className="grab"
                      >
                        <DelIcon />
                      </span>
                    </p>
                  )
              )}
              <p>{t<string>("globalSettings.datasources.googleDriveText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("googledrive")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div>
        {/* will uncomment this block when Jira be ready to connect */}
        {/* <div className="settings-datasource-container">
          <img src={jiraIcon} height={50} width={50} alt="jira" />
          <div
            className={`${
              isNerrowWindow
                ? "datasource-list-flex-col"
                : "datasource-list-flex-row"
            }`}
          >
            <div className="datasource-text-container">
              <h4>{t<string>("globalSettings.datasources.jiraTitle")}</h4>
              <p>{t<string>("globalSettings.datasources.jiraText")}</p>
            </div>
            <div className={`${isNerrowWindow ? "text-left" : "self-center"}`}>
              <button
                className="datasource-list-button"
                onClick={() => addSource("jira")}
                type="button"
              >
                {t<string>("globalSettings.datasources.addButton")}
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <DataSourcesDeleteModal
        open={isDeleteModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        mail={accountToModify.email}
        name={item.name}
        buttonsDisable={deleteLoading}
        workspaceName={accountToModify.workspaceName}
        sourceType={accountToModify.sourceType}
      />
    </Spin>
  );
}

export default DatasourcesList;
