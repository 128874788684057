/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tag, Input } from "antd";
import { v4 as uuid } from "uuid";
import "./email-input.scss";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const EditableTags = ({
  index,
  value,
  onChange,
  onRemove,
}: {
  index: number;
  value: string;
  onChange: (i: number, v: string) => void;
  onRemove: (i: number) => void;
}) => {
  const ref = useRef(null);
  const [isEditable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const editToggle = useCallback(() => {
    if (!isEditable) {
      setInputValue(value);
    } else {
      onChange(index, inputValue);
    }
    setEditable(!isEditable);
  }, [isEditable, index, value, inputValue]);

  const onValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e?.target?.value);
    },
    [setInputValue]
  );

  const onClose = useCallback(() => {
    onRemove(index);
  }, [index, onRemove]);

  useEffect(() => {
    const { current } = ref;
    if (isEditable && current) {
      const { focus }: { focus: any } = current || {};
      if (focus) focus({ cursor: "end" });
    }
  }, [isEditable]);

  if (isEditable) {
    return (
      <Input
        ref={ref}
        key={value}
        size="small"
        className="tag-input"
        value={inputValue}
        onChange={onValueChange}
        onBlur={editToggle}
        onPressEnter={editToggle}
      />
    );
  }
  return (
    <Tag closable onDoubleClick={editToggle} onClose={onClose}>
      {value}
    </Tag>
  );
};

const EmailsInput = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (v: string) => void;
}) => {
  const [newEmail, setNewEmail] = useState("");
  const [inputStatus, setInputStatus] = useState("");
  const tags = useMemo(
    () =>
      value
        ?.replace(/ /g, "")
        .split(",")
        .filter((email) => !!email),
    [value]
  );

  const onNewEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewEmail(e.target.value);
      if (inputStatus) {
        setInputStatus("");
      }
    },
    [inputStatus, setInputStatus]
  );

  const onNewEmailEnter = useCallback(() => {
    // if (validateEmail(newEmail)) {
    const newValue = [...tags, newEmail];
    onChange(newValue.join());
    setNewEmail("");
    // } else {
    //   setInputStatus('error');
    // }
  }, [tags, newEmail, onChange, setNewEmail]);

  const onEditEmail = useCallback(
    (index: number, newTagValue: string) => {
      const newTags = [...tags];
      newTags.splice(index, 1, newTagValue);
      onChange(newTags.join());
    },
    [tags, onChange]
  );

  const onRemoveEmail = (index: number) => {
    // console.log(index, tags);
    const newTags = [...tags];
    // console.log(newTags);
    newTags.splice(index, 1);
    console.log(newTags);
    onChange(newTags.join());
  };

  return (
    <div className="emails-input-container">
      {tags?.map((name, index) => {
        const key = uuid();
        return (
          <EditableTags
            key={key}
            index={index}
            value={name}
            onChange={onEditEmail}
            onRemove={onRemoveEmail}
          />
        );
      })}
      <Input
        placeholder="Enter From Email Address or Domain"
        bordered={!!inputStatus}
        value={newEmail}
        onChange={onNewEmailChange}
        onPressEnter={onNewEmailEnter}
        onBlur={onNewEmailEnter}
        // status={inputStatus ? 'error' : ''}
      />
    </div>
  );
};

export default EmailsInput;
