/* eslint-disable no-console */
import { Dispatch } from "redux";
import { Panel } from "../../../../interfaces/user-detail-interface";

export const updatePanelsData = (data: any, panelId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updatePanelData",
      payload: { data, panelId },
    });
  };
};

export const setPanelsData = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "fetchPanelsData",
      payload: { data },
    });
  };
};

export const updateAfterPanelDrag1 = (item: Panel) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updatePanelData",
      payload: { item },
    });
  };
};

export const updateStoragePanelsData = (data: any, panelId: string) => {
  console.log("Actionitem", data);
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateStoragePanelData",
      payload: { data, panelId },
    });
  };
};
export const updateAfterStorageDrag = (item: Panel, index: number) => {
  console.log("Actionitem2", item);
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateAfterStorageDrag",
      payload: { item, index },
    });
  };
};
export const updateGdiveStoragePanelsData = (data: any, panelId: string) => {
  console.log("Actionitem", data);
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateGdiveStoragePanelsData",
      payload: { data, panelId },
    });
  };
};

export const updateDropBoxStoragePanelsData = (data: any, panelId: string) => {
  console.log("Actionitem", data);
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateDropBoxStoragePanelsData",
      payload: { data, panelId },
    });
  };
};
export const updateStorageBeforeDrag = (data: any) => {
  console.log("Actionitem", data);
  return (dispatch: Dispatch) => {
    dispatch({
      type: "updateStorageBeforeDrag",
      payload: data,
    });
  };
};
