/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { useSelector } from "react-redux";
import icons from "../../assets/icon-export-assets";
import { Child, Panel, Source } from "../../interfaces/user-detail-interface";
import {
  updateChannelList,
  updateDMList,
  updateGDriveList,
  updateLoading,
  updateOneDriveList,
  updateWorkspaceList,
} from "../../store/reducers/loading-states/actions/loading-actions";
import { updatePanelsData as update } from "../../store/reducers/panel-data/actions/panels-data-actions";
import {
  updatePanel,
  updatePanelsDetails,
} from "../../store/reducers/panels/actions/panel-actions";
import { RootReducer } from "../../store/reducers/root-reducer";
import { savePanelStorage } from "../../store/reducers/save-panel-storage/add-new-panel-action";
import newTabHTML from "../new-tab-html";
// Dispa/

let myWindow: any;
const { outlookLarge, gmailLarge, slackLarge, dropBoxFolderIcon } = icons;
const SocketResponseHandler = (
  activePanels: any,
  panelsData: any,
  message: any,
  paramsRoute1: any,
  dispatch: (action: any) => void
) => {
  // const { username } = useSelector((state: RootReducer) => state.user);
  console.log("paramsRoute1 main", paramsRoute1);
  console.log("activePanels", activePanels);
  console.log("message1", message);
  if (message.messageType === "SignInUrl") {
    if (message.messageData !== "") {
      myWindow = window.open("", "Panels Market Place", "width=800,height=600");
      myWindow.document.title = "Panels Market Place";
      myWindow.document.body.style.margin = "0";
      if (message.messageData.includes("microsoft")) {
        myWindow.document.body.innerHTML = newTabHTML(
          message.messageData,
          "Outlook",
          outlookLarge
        );
      }
      if (message.messageData.includes("gmail")) {
        myWindow.document.body.innerHTML = newTabHTML(
          message.messageData,
          "Gmail",
          gmailLarge
        );
      }
      if (message.messageData.includes("slack")) {
        myWindow.document.body.innerHTML = newTabHTML(
          message.messageData,
          "Slack",
          slackLarge
        );
      }
      if (message.messageData.includes("www.dropbox.com")) {
        myWindow.document.body.innerHTML = newTabHTML(
          message.messageData,
          "Dropbox",
          dropBoxFolderIcon
        );
      }
    }
  } else if (message.messageType === "SignInStatus") {
    const { panelId, subpanelId } = paramsRoute1;
    const panel = { id: panelId, pId: subpanelId };
    if (message.messageData !== "") {
      if (myWindow) {
        myWindow.close();
        console.log("mainsocket", paramsRoute1);
        const getType = () => {
          if (message.signInSourceId === 1 || message.signInSourceId === 2) {
            return "email";
          }
          if (
            message.signInSourceId === 4 ||
            message.signInSourceId === 5 ||
            message.signInSourceId === 6
          ) {
            return "storage";
          }
          if (message.signInSourceId === 3) {
            return "slack";
          }
          return "invalid";
        };
        let tempSource: any = {
          sourceId: message.signInSourceId,
          type: getType(),
        };
        const temPanels = [...activePanels];
        if (!panel.pId) {
          // console.log('called me', temPanels);
          const parentIndex = temPanels.findIndex(
            (e: Panel) => e.id === panel.id
          );
          if (parentIndex > -1) {
            const sourceDetails = temPanels[parentIndex].sources;
            console.log(
              "temPanels[parentIndex].sources;",
              temPanels[parentIndex].sources
            );
            const updatedSource = [...sourceDetails];
            console.log("updatedSource", updatedSource);
            const sourceIndex = sourceDetails.findIndex(
              (e: Source) => e.sourceId === tempSource.sourceId
            );
            console.log("sourceIndex", sourceIndex);
            if (sourceIndex > -1) {
              tempSource = {
                ...tempSource,
                order: sourceDetails.length,
                data: {
                  [tempSource.type]: {
                    ...sourceDetails[sourceIndex].data[tempSource.type],
                    [message.signInEmailAddress]: {},
                  },
                },
              };
              console.log("tempSource", tempSource);
              updatedSource.splice(sourceIndex, 1, tempSource);
            } else {
              tempSource = {
                ...tempSource,
                order: sourceDetails.length,
                data: {
                  [tempSource.type]: { [message.signInEmailAddress]: {} },
                },
              };
              console.log("tempSource else", tempSource);
              updatedSource.push(tempSource);
            }
            const updatedParent = {
              ...temPanels[parentIndex],
              sources: JSON.parse(JSON.stringify(updatedSource)),
            };
            console.log("updatedParent", updatedParent);
            temPanels.splice(parentIndex, 1, updatedParent);
          }
          console.log("dispatch1 ", temPanels);
          dispatch(updatePanelsDetails([...temPanels]));
        } else if (panel.id && panel.pId) {
          console.log("inside panel id");
          const parentIndex = temPanels.findIndex(
            (e: Panel) => e.id === panel.pId
          );
          const childIndex = temPanels[parentIndex].children.findIndex(
            (e: Child) => e.id === panel.id
          );
          if (childIndex > -1) {
            const sourceDetails =
              temPanels[parentIndex].children[childIndex].sources;
            const updatedSource = [...sourceDetails];
            const sourceIndex = sourceDetails.findIndex(
              (e: Source) => e.sourceId === tempSource.sourceId
            );
            if (sourceIndex > -1) {
              tempSource = {
                ...tempSource,
                order: sourceDetails.length,
                data: {
                  [tempSource.type]: {
                    ...sourceDetails[sourceIndex].data[tempSource.type],
                    [message.signInEmailAddress]: {},
                  },
                },
              };
              updatedSource.splice(sourceIndex, 1, tempSource);
            } else {
              tempSource = {
                ...tempSource,
                order: sourceDetails.length,
                data: {
                  [tempSource.type]: { [message.signInEmailAddress]: {} },
                },
              };
              updatedSource.push(tempSource);
            }
            const updatedChild = {
              ...temPanels[parentIndex].children[childIndex],
              sources: updatedSource,
            };
            const updatedParent = { ...temPanels[parentIndex] };
            updatedParent.children.splice(childIndex, 1, updatedChild);
            const tempParent = { ...JSON.parse(JSON.stringify(updatedParent)) };
            temPanels.splice(parentIndex, 1, tempParent);
          }
          dispatch(updatePanelsDetails([...temPanels]));
        }
        dispatch(updateLoading("connectDatasourcePopupId", ""));
        dispatch(updateLoading("addDatasourceId", ""));
      }
    }
    // } else if (message.messageType === 'GmailEmailNotification') {
    //   const { panelId, subpanelId } = paramsRoute1;
    //   const panel = { id: panelId, pId: subpanelId };
    //   const panelIds = panel.pId;
    //   console.log('mainsocket2 ');
    //   const tempPanelsData = panelsData[panelIds] || {};
    //   const tempMessageData = message.lstEmails[0];
    //   const emailId = 'dyadav@radicleinc.com'; // 'asingh@gmail.com'
    //   if (
    //     !(
    //       tempPanelsData?.email?.gmail
    //       && tempPanelsData?.email?.gmail[emailId]?.data
    //     )
    //   ) {
    //     const dummy = { email: { gmail: { [emailId]: { data: [] } } } };
    //     if (!tempPanelsData?.email) tempPanelsData.email = dummy.email;
    //     if (!tempPanelsData?.email?.gmail) {
    //       tempPanelsData.email.gmail = dummy.email.gmail;
    //     }
    //     if (!tempPanelsData?.email?.gmail[emailId]) {
    //       tempPanelsData.email.gmail[emailId] = dummy.email.gmail[emailId];
    //     }
    //     if (!tempPanelsData?.email?.gmail[emailId].data) {
    //       tempPanelsData.email.gmail[emailId].data = [];
    //     }
    //   }
    //   tempPanelsData.email.gmail[emailId].data.unshift(tempMessageData);
    //   dispatch(update({ ...tempPanelsData }, panelIds));
  } else if (message.messageType === "EmailNotification") {
    console.log("mainsocket3 ");
    const tempData = {} as any;
    message.lstEmails.forEach((tempMessageData: any) => {
      // const tempMessageData = message.lstEmails[0];
      const panelIds = tempMessageData.panelId;
      const tempPanelsData = tempData[panelIds]
        ? tempData[panelIds]
        : panelsData[tempMessageData.panelId] || {};
      const emailId = tempMessageData.emailUserName;
      let emailObj = tempPanelsData?.email;
      const emailProvider = emailObj
        ? tempMessageData.emailSourceType === "Gmail"
          ? emailObj?.gmail
          : emailObj?.outlook
        : null;
      const emailType =
        tempMessageData.emailSourceType === "Gmail" ? "gmail" : "outlook";
      if (!(emailProvider && emailProvider[emailId]?.data)) {
        const dummy =
          tempMessageData.emailSourceType === "Gmail"
            ? ({ email: { gmail: { [emailId]: { data: [] } } } } as any)
            : ({ email: { outlook: { [emailId]: { data: [] } } } } as any);
        if (!tempPanelsData?.email) {
          emailObj = dummy.email;
          emailObj[emailType] = dummy.email[emailType];
          emailObj[emailType][emailId] = dummy.email[emailType][emailId];
          emailObj[emailType][emailId].data = [];
        } else if (!tempPanelsData?.email?.[emailType]) {
          emailObj[emailType] = dummy.email[emailType];
          emailObj[emailType][emailId] = dummy.email[emailType][emailId];
          emailObj[emailType][emailId].data = [];
        } else if (!tempPanelsData?.email[emailType][emailId]) {
          emailObj[emailType][emailId] = dummy.email[emailType][emailId];
          emailObj[emailType][emailId].data = [];
        } else if (!tempPanelsData?.email[emailType][emailId].data) {
          emailObj[emailType][emailId].data = [];
        }
      }
      emailObj[emailType][emailId].data.unshift(tempMessageData);
      tempPanelsData.email = emailObj;
      tempData[panelIds] = tempPanelsData;
    });
    Object.keys(tempData).forEach((key) => {
      dispatch(update({ ...tempData[key] }, key));
    });
    // } else if (message.messageType === 'WorkspacesData') {
    //   dispatch(updateWorkspaceList(message.lstWorkspaces));
    // } else if (message.messageType === 'SlackUsersData') {
    //   dispatch(updateDMList(message.lstSlackUsers));
    // } else if (message.messageType === 'SlackChannelsData') {
    //   dispatch(updateChannelList(message.lstSlackChannels));
  } else if (message.messageType === "SlackMessageNotification") {
    message.lstMessages.forEach((tempMessageData: any) => {
      const emailId = tempMessageData.email;
      const panelIds = tempMessageData.panelId;
      console.log("mainsocket4 ");
      const tempPanelsData = { ...panelsData[panelIds] } || {};
      const messageType =
        tempMessageData.channelId[0] === "C" ? "channels" : "directMessages";
      const dummy = {
        slack: {
          [emailId]: {
            [tempMessageData.teamId]: {
              [messageType]: { [tempMessageData.channelId]: { messages: [] } },
            },
          },
        },
      };
      if (!tempPanelsData?.slack) tempPanelsData.slack = dummy.slack;
      if (!tempPanelsData?.slack[emailId]) {
        tempPanelsData.slack[emailId] = dummy.slack[emailId];
      }
      if (!tempPanelsData?.slack[emailId][tempMessageData.teamId]) {
        tempPanelsData.slack[emailId][tempMessageData.teamId] =
          dummy.slack[emailId][tempMessageData.teamId];
      }
      if (
        !tempPanelsData?.slack[emailId][tempMessageData.teamId][messageType]
      ) {
        tempPanelsData.slack[emailId][tempMessageData.teamId][messageType] =
          dummy.slack[emailId][tempMessageData.teamId][messageType];
      }
      const id = tempMessageData.channelId;
      if (
        !tempPanelsData?.slack[emailId][tempMessageData.teamId][messageType][id]
      ) {
        tempPanelsData.slack[emailId][tempMessageData.teamId][messageType][id] =
          {};
        tempPanelsData.slack[emailId][tempMessageData.teamId][messageType][id] =
          dummy.slack[emailId][tempMessageData.teamId][messageType][
            tempMessageData.channelId
          ];
      }
      console.log("channelId------------------->", id ? id : null);
      console.log("pannelId------------------->", panelIds);
      console.log(
        "panelDataforPanelId------------------->",
        tempPanelsData.slack[emailId][tempMessageData.teamId][messageType][id]
      );
      tempPanelsData?.slack[emailId][tempMessageData.teamId][messageType][
        id
      ].messages.push(tempMessageData);
      dispatch(update(JSON.parse(JSON.stringify(tempPanelsData)), panelIds));
    });
  } else if (
    message.messageType === "OneDriveData" ||
    message.messageType === "GoogleDriveData" ||
    message.messageType === "DropBoxDriveData"
  ) {
    const storageKeys = {
      OneDriveData: { drive: "oneDrive", key: "lstOneDrivesData" },
      GoogleDriveData: { drive: "googleDrive", key: "lstGoogleDrivesData" },
      DropBoxDriveData: { drive: "dropBox", key: "lstDropBoxData" },
    } as any;
    // const file = message.lstOneDrivesData[0];
    const fileArray = message[storageKeys[message.messageType].key] || [];
    const { panelId } = fileArray[0];
    const tempPanelsData = { ...panelsData[panelId] } || {};
    const storage = tempPanelsData?.storage || ({} as any);
    const tempSavedFoldersandFiles =
      storage[storageKeys[message.messageType].drive] || [];
    fileArray.forEach((file: any) => {
      const selectedFolderIndex = tempSavedFoldersandFiles.findIndex(
        (i: any) => i.parentId === file.parentId
      );
      if (selectedFolderIndex !== -1) {
        const tempSelectedFolder =
          tempSavedFoldersandFiles[selectedFolderIndex];
        if (!file.deleted) {
          tempSelectedFolder.children = [...tempSelectedFolder.children, file];
          tempSavedFoldersandFiles.splice(
            selectedFolderIndex,
            1,
            tempSelectedFolder
          );
        } else {
          const remainingFiles = tempSelectedFolder.children.filter(
            (item: any) => item.itemId !== file.itemId
          );
          if (remainingFiles.length) {
            tempSelectedFolder.children = [...remainingFiles];
            tempSavedFoldersandFiles.splice(
              selectedFolderIndex,
              1,
              tempSelectedFolder
            );
          } else {
            tempSavedFoldersandFiles.splice(selectedFolderIndex, 1);
          }
        }
        tempPanelsData.storage = {
          [storageKeys[message.messageType].drive]: tempSavedFoldersandFiles,
        };
      }
    });
    dispatch(update(tempPanelsData, panelId));
  }
  // else if (message.messageType === 'OneDriveItemData') {
  //   dispatch(updateOneDriveList(message.lstOneDrivesData));
  // } else if (message.messageType === 'GoogleDriveData') {
  //   dispatch(updateOneDriveList(message.lstGoogleDrivesData));
  // } else if (message.messageType === 'GoogleDriveItemData') {
  //   dispatch(updateOneDriveList(message.lstOneDrivesData));
  // } else if (message.messageType === 'DropBoxData') {
  //   dispatch(updateOneDriveList(message.lstDropBoxData));
  // } else if (message.messageType === 'DropBoxItemData') {
  //   dispatch(updateOneDriveList(message.lstOneDrivesData));
  // }
  // TODO:: Need to review
  // else if (message.messageType === 'savePanelData') {
  //   dispatch(savePanelStorage(message.saveData));
  // }
  // TODO:: Need to review
  // else if (message.messageType === 'Panels') {
  //   if (message.messageData === 'Y') {
  //     dispatch(
  //       updatePanel(
  //         message.viewPanels.activePanels,
  //         message.viewPanels.archivedPanels,
  //       ),
  //     );
  //   }
  // }
};

export default SocketResponseHandler;
