import React from "react";

interface APIContextInterface {
  updateListTitle: (arg0: string, arg1: string) => void;
  deleteList: (arg0: string) => void;
  addMoreCard: (arg0: string, arg1: string) => void;
  addMoreList: (arg0: string, arg1: string) => void;
  removeCard: (arg0: number, arg1: string) => void;
  updateCardTitle: (arg0: string, arg1: number, arg2: string) => void;
  updateCardDescription: (arg0: string, arg1: number, arg2: string) => void;
  updateCardPriority: (arg0: string, arg1: number, arg2: string) => void;
  updateCardPanel: (arg0: any, arg1: number, arg2: string) => void;
  updateCardStatus: (arg0: string, arg1: number, arg2: string) => void;
  updateListStatus: (arg0: any, arg1: string) => void;
}
export default React.createContext<APIContextInterface>({
  updateListTitle: () => ({}),
  deleteList: () => ({}),
  addMoreCard: () => ({}),
  addMoreList: () => ({}),
  removeCard: () => ({}),
  updateCardTitle: () => ({}),
  updateCardDescription: () => ({}),
  updateCardPriority: () => ({}),
  updateCardPanel: () => ({}),
  updateCardStatus: () => ({}),
  updateListStatus: () => ({}),
});
