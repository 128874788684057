/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { Button, Spin, Switch, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  clickWidgetBtn,
  todoBoardShow,
  updateColumns,
  widgetsUpdated,
} from "../../../store/reducers/todo/action/todo";
import { ReactComponent as CalenderIcon } from "../../../assets/Icons/calender.svg";
import { ReactComponent as TodoIcon } from "../../../assets/Icons/todo.svg";
import "./widgets.scss";
import { RootReducer } from "../../../store/reducers/root-reducer";
import useAxios from "../../../services/axios";
import SERVER_CONFIG from "../../../utils/config";

const { Title, Text } = Typography;
type UserWidgets = {
  widgetId: string;
  show: boolean;
  name: string;
  columns: number;
};
const AddEditWidget = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [userWidgets, setUserWidgets] = useState<UserWidgets[]>([]);
  const [
    widgetsResponse,
    widgetsResponseLoading,
    widgetsResponseError,
    widgets,
  ] = useAxios();
  const [
    saveWidgetsResponse,
    saveWidgetsLoading,
    saveWidgetsError,
    saveWidgets,
  ] = useAxios();
  const todoSwitchHandler = (id: string, value: boolean) => {
    const temp = [...userWidgets];
    temp.forEach((widget: UserWidgets, index: number) => {
      if (widget.widgetId === id) {
        temp[index].show = value;
      }
    });
    setUserWidgets(temp);
  };
  useEffect(() => {
    widgets({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url: SERVER_CONFIG.API_URL.WIDGETS_LIST_USER_GET,
    });
  }, []);

  useEffect(() => {
    setUserWidgets(widgetsResponse?.data.data.widgetDetails);
  }, [widgetsResponse]);

  const saveHandler = () => {
    saveWidgets({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.WIDGETS_LIST_USER_SAVE,
      data: userWidgets,
    });
  };

  useEffect(() => {
    if (saveWidgetsResponse) {
      dispatch(widgetsUpdated(true));
      dispatch(clickWidgetBtn(false));
      dispatch(todoBoardShow(userWidgets[0].show));
      dispatch(updateColumns(userWidgets[0].columns));
    }
  }, [saveWidgetsResponse]);

  return (
    <div className="add-edit-widget">
      <Spin
        size="large"
        spinning={widgetsResponseLoading || saveWidgetsLoading}
      >
        <div className="widget-head">
          <Title className="h-txt">{t<string>("widgets.title")}</Title>
          <div className="actions">
            <Link to="/help/faq">
              <button
                title="Help Center"
                type="button"
                className="headerButtonItems space-right"
              >
                <QuestionCircleOutlined />
              </button>
            </Link>
          </div>
        </div>

        <div className="body">
          <div className="sub-header">
            <Title className="sh-txt">{t<string>("widgets.subDesc")}</Title>
            <Text className="sub-desc">{t<string>("widgets.subTxt")}</Text>
          </div>

          <div className="content">
            <Title level={4} className="cnt-title">
              {t<string>("widgets.widgetCate")}
            </Title>

            {userWidgets?.map((widget: UserWidgets) => {
              return (
                <div key={widget.widgetId} className="widget-opt">
                  <div className="opt-icon">
                    <TodoIcon width={52} height={52} />
                  </div>
                  <div className="opt-desc">
                    <Title className="opt-h">
                      {t<string>("widgets.toDoList")}
                    </Title>
                    <Text className="opt-txt">
                      {t<string>("widgets.todoDesc")}
                    </Text>
                  </div>
                  <div className="act">
                    <Switch
                      size="small"
                      checked={widget.show}
                      onClick={(e: boolean) => {
                        todoSwitchHandler(widget.widgetId, e);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div className="widget-opt">
              <div className="opt-icon">
                <CalenderIcon height={40} />
              </div>
              <div className="opt-desc">
                <Title className="opt-h">{t<string>("widgets.calender")}</Title>
                <Text className="opt-txt">
                  {t<string>("widgets.calenderDesc")}
                </Text>
              </div>
              <div className="act" />
            </div>

            {/* WIDGET ACTIONS */}
            <div className="widget-actions">
              <Button
                type="text"
                className="save-btn act-btn"
                onClick={saveHandler}
              >
                {t<string>("widgets.save")}
              </Button>
              <Button
                type="text"
                className="act-btn cancel-btn"
                onClick={() => dispatch(clickWidgetBtn(false))}
              >
                {t<string>("widgets.cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default AddEditWidget;
