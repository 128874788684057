import { useState } from "react";
import { useTranslation } from "react-i18next";

export type Option = {
  value: string | number;
  label: string;
};

const maxFoldersAtTimeDesktop: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxFoldersAtTimeDesktopDefaultValue = 3;

const maxFoldersAtTimeMobile: Option[] = new Array(10)
  .fill(0)
  .map((_, i) => ({ label: `${i + 1}`, value: i + 1 }));
const maxFoldersAtTimeMobileDefaultView = 1;

const useStorageSettings = () => {
  const { t } = useTranslation();
  const [maxFoldersDesktop, setMaxFoldersDesktop] = useState<Option["value"]>(
    maxFoldersAtTimeDesktopDefaultValue
  );

  const [maxFoldersMobile, setMaxFoldersMobile] = useState<Option["value"]>(
    maxFoldersAtTimeMobileDefaultView
  );

  const sortByOptions = [
    { value: "asc", label: t("globalSettings.gdrive.asc") },
    { value: "desc", label: t("globalSettings.gdrive.desc") },
    { value: "modified", label: t("globalSettings.gdrive.modified") },
  ];

  const defaultSortBy = sortByOptions[2].value;

  const [sortByDesktop, setSortByDesktop] =
    useState<Option["value"]>(defaultSortBy);

  const [sortByMobile, setSortByMobile] =
    useState<Option["value"]>(defaultSortBy);

  return {
    maxFoldersDesktop: {
      options: maxFoldersAtTimeDesktop,
      value: maxFoldersDesktop,
      defaultValue: maxFoldersAtTimeDesktopDefaultValue,
      onChange: (newValue: Option["value"]) => {
        setMaxFoldersDesktop(newValue);
      },
    },
    maxFoldersMobile: {
      options: maxFoldersAtTimeMobile,
      value: maxFoldersMobile,
      defaultValue: maxFoldersAtTimeMobileDefaultView,
      onChange: (newValue: Option["value"]) => {
        setMaxFoldersMobile(newValue);
      },
    },
    sortByDesktop: {
      options: sortByOptions,
      value: sortByDesktop,
      defaultValue: defaultSortBy,
      onChange: (newValue: Option["value"]) => {
        setSortByDesktop(newValue);
      },
      className: "dropdown-preview-small",
    },
    sortByMobile: {
      options: sortByOptions,
      value: sortByMobile,
      defaultValue: defaultSortBy,
      onChange: (newValue: Option["value"]) => {
        setSortByMobile(newValue);
      },
      className: "dropdown-preview-small",
    },
  };
};

export default useStorageSettings;
