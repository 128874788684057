/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/require-default-props */
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { Modal, Checkbox, Button } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import Input from "../reusable-components/input/input";

type ProfileEditEmailModalProps = {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (values: Record<string, string | boolean>) => void;
};

const createChangeEmailValidationSchema = (
  t: TFunction<"translation", undefined>
) =>
  Yup.object({
    newEmailAddress: Yup.string()
      .required(t<string>("globalSettings.profile.validations.emailRequired"))
      .email(t<string>("globalSettings.profile.validations.emailNotValid")),
    currentPassword: Yup.string().required(
      t<string>("globalSettings.profile.validations.passwordRequired")
    ),
  });

function ProfileEditEmailModal({
  open,
  onCancel,
  onSubmit,
}: ProfileEditEmailModalProps) {
  const { t } = useTranslation();
  const emailValidationSchema = createChangeEmailValidationSchema(t);

  const formik = useFormik({
    initialValues: {
      changeType: "email",
      newEmailAddress: "",
      currentPassword: "",
      updateStripeEmail: true,
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
  });

  return (
    <Modal
      className="edit-email-modal"
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <form onSubmit={formik.handleSubmit}>
        <h6 className="heading">
          {t<string>("globalSettings.profile.editEmailModal.heading")}
        </h6>
        <div className="input-container">
          <Input
            label={t<string>(
              "globalSettings.profile.editEmailModal.currentPassword"
            )}
            type="password"
            required
            id="currentPassword"
            name="currentPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.currentPassword}
            error={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
          />
          <Input
            label={t<string>("globalSettings.profile.editEmailModal.newEmail")}
            required
            id="newEmailAddress"
            name="newEmailAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newEmailAddress}
            error={
              formik.touched.newEmailAddress && formik.errors.newEmailAddress
            }
          />
          <div className="checkbox-container">
            <Checkbox
              checked={formik.values.updateStripeEmail}
              onChange={formik.handleChange}
              id="updateStripeEmail"
              name="updateStripeEmail"
            />
            <span className="checkbox-span">
              {t<string>(
                "globalSettings.profile.editEmailModal.updateEmailAtStripe"
              )}
            </span>
          </div>
        </div>
        <div className="button-container">
          <Button key="back" onClick={onCancel} className="modal-footer-button">
            {t<string>("globalSettings.profile.editEmailModal.cancel")}
          </Button>
          <Button
            key="submit"
            type="primary"
            disabled={!formik.isValid || !formik.dirty}
            className="modal-footer-button submit-button"
          >
            {t<string>("globalSettings.profile.editEmailModal.saveChanges")}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ProfileEditEmailModal;
