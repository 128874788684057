/* eslint-disable react/no-danger */
import { useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import ImgPageModal from "./madal-img";
import "./help-page.scss";

type Faq = {
  title: string;
  content: string[];
  imgs: string[];
};

type Props = {
  answer: Faq;
};

const Answer = ({ answer }: Props) => {
  const [isFullAnswerView, setIsFullAnswerView] = useState(false);
  const [source, setSource] = useState("");

  const cancelModal = () => setSource("");

  return (
    <div className="answer-container">
      <div
        className="answer-arrow"
        onClick={() => setIsFullAnswerView(!isFullAnswerView)}
      >
        <CaretRightOutlined
          rotate={isFullAnswerView ? 90 : 0}
          style={{
            color: "#BA3A29",
            cursor: "pointer",
          }}
        />
      </div>
      <div className="answer-block">
        <div
          className="answer-title"
          onClick={() => setIsFullAnswerView(!isFullAnswerView)}
        >
          {answer.title}
        </div>
        <div className={isFullAnswerView ? "answer-content" : "answer-hidden"}>
          {answer.content.map((paragraph, i) => (
            <p
              key={i}
              style={{ lineHeight: "1.5715" }}
              dangerouslySetInnerHTML={{
                __html: `${paragraph}`,
              }}
            />
          ))}

          <div className="answer-steps">
            {answer.imgs.map((img, i) => (
              <div className="img-step" key={i} onClick={() => setSource(img)}>
                <img src={img} alt={`step-${i + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ImgPageModal
        isActive={!!source}
        onCancel={cancelModal}
        source={source}
      />
    </div>
  );
};

export default Answer;
