export type Actions = {
  type: string;
  payload: any;
};
interface ReceiveMessageInterface {
  message: any;
}

const receiveMessage: ReceiveMessageInterface = {
  message: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const ReceiveMessageReducer = (state = receiveMessage, action: Actions) => {
  switch (action.type) {
    case "receiveMessage":
      return { ...state, message: action.payload };
    default:
      return state;
  }
};

export default ReceiveMessageReducer;
