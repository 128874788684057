import { Button, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../services/axios";
import SERVER_CONFIG from "../../../utils/config";
import LogoDataSourceModal from "../../../assets/Icons/LogoDataSourceModal.svg";
import "./add-panel-source.scss";
import { Child } from "../../../interfaces/user-detail-interface";

function AddPanelSourcePopup({
  imgSrc,
  type,
  item,
  closeModal,
}: {
  imgSrc: string;
  type: string;
  item: Child;
  closeModal: () => void;
}) {
  const [newUrl, setNewUrl] = useState("");

  const { t } = useTranslation();

  const [
    addPanelDataSourceResponse,
    addPanelDataSourceLoading,
    error,
    axiosFetch,
  ] = useAxios();
  const fetchAppAuthURL = () => {
    const url = item.pId
      ? SERVER_CONFIG.API_URL.SOURCE_SUBPANELS_ADD_STEP_1(
          item.pId,
          item.id,
          type
        )
      : SERVER_CONFIG.API_URL.SOURCE_PANELS_ADD_STEP_1(item.id, type);

    axiosFetch({
      method: SERVER_CONFIG.HTTP_METHOD.GET,
      url,
    });
  };
  useEffect(() => {
    if (addPanelDataSourceResponse && addPanelDataSourceResponse.data.success) {
      setNewUrl(addPanelDataSourceResponse.data.data);
    }
  }, [addPanelDataSourceResponse]);

  const invokeSignIn = () => {
    switch (type) {
      case "outlook":
        fetchAppAuthURL();
        break;
      case "gmail":
        fetchAppAuthURL();
        break;
      case "slack":
        fetchAppAuthURL();
        break;
      case "onedrive":
        fetchAppAuthURL();
        break;
      case "googledrive":
        fetchAppAuthURL();
        break;
      case "dropbox":
        fetchAppAuthURL();
        break;
      // case "jira":
      //   console.log("call jira");
      //   break;
      default:
        console.log("Invalid Data Source");
    }
  };

  useEffect(() => {
    invokeSignIn();
  }, [error]);
  return (
    <Spin size="large" spinning={addPanelDataSourceLoading}>
      <Row>
        <Col span={4}>
          <img src={LogoDataSourceModal} alt="logo" className="modal-img" />
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <div style={{ textAlign: "center", paddingTop: 10 }}>
            <img src={imgSrc} alt={type} width="100px" />
            <div className="modal-btn">
              <a href={newUrl} target="_blank" rel="noreferrer">
                <Button type="primary" onClick={closeModal} block>
                  {t<string>("addSourceToPanel.installButton")}
                </Button>
              </a>
            </div>
          </div>
        </Col>
        <Col span={16}>
          <h2 className="modal-title">
            {t<string>("addSourceToPanel.titleFirstPart")}&nbsp;
            {t<string>(`addSourceToPanel.${type}.title`)}
          </h2>
          <div>
            <p>{t<string>(`addSourceToPanel.${type}.firstLine`)}</p>
            <p>{t<string>(`addSourceToPanel.${type}.secondLine`)}</p>
            <p>{t<string>(`addSourceToPanel.${type}.thirdLine`)}</p>
            <p>{t<string>(`addSourceToPanel.${type}.fourthLine`)}</p>
            <p>
              {t<string>("addSourceToPanel.footerTextFirstPart")}{" "}
              <a href="mailto:support@panellogic.com">support@panellogic.com</a>{" "}
              {t<string>("addSourceToPanel.footerTextSecondPart")}{" "}
              <a
                href="https://www.panellogic.com"
                target="_blank"
                rel="noreferrer"
              >
                www.panellogic.com{" "}
              </a>{" "}
              {t<string>("addSourceToPanel.footerTextThirdPart")}
            </p>
          </div>
        </Col>
      </Row>
    </Spin>
  );
}

export default AddPanelSourcePopup;
