/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  HolderOutlined,
  SettingOutlined,
  QuestionCircleOutlined,
  ArrowLeftOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import { Input, Modal, Dropdown, Spin } from "antd";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PanelPropType } from "../../prop-types/prop-type";
import "./panel.scss";
import DataSources from "../data-sources/data-sources-main";
import { RootReducer } from "../../store/reducers/root-reducer";
import {
  Child,
  Panel as PanelType,
  PanelsData,
  TempChannel,
  TempItemPanelData,
  TempSlackChannelDMMessage,
  TempSlackChannels,
  TempSlackData,
  TempSlackDirectMsg,
  TempSlackIdData,
  TempSlackMsg,
  TempSlackWorkspaceId,
} from "../../interfaces/user-detail-interface";
import {
  updatePanelsDetails,
  updateAfterPanelDrag,
  updateArchivedPanelsDetails,
  deletePanel,
} from "../../store/reducers/panels/actions/panel-actions";
import DatasourcesList from "../reusable-components/data-source-list";
import icons from "../../assets/icon-export-assets";
import { updateLoading } from "../../store/reducers/loading-states/actions/loading-actions";
import ParentWithoutChild from "../parent-panel/parent-without-child";
import AddPanelSourcePopup from "./add-panel-source/add-panel-source";
import SERVER_CONFIG, { Constants } from "../../utils/config";
import useAxios from "../../services/axios";
import { setPanelsData } from "../../store/reducers/panel-data/actions/panels-data-actions";
import Settings from "../settings/settings";
import { panelIdToSetupAction } from "../../store/reducers/panel-to-set/actions/panel-to-set-action";
import { PreviousPanelInfoType } from "../../store/reducers/prev-panel/reducer/prev-panel-reducer";
import { setPreviousPanelInfoAction } from "../../store/reducers/prev-panel/actions/prev-panel-action";

const {
  outlookIcon,
  gmailLarge,
  oneDriveIcon,
  slackIcon,
  googleDriveIcon,
  dropboxIcon,
  outlookLarge,
  trashBinIcon,
} = icons;
// type closeSetting: function
function Panel({ item, position, archived }: PanelPropType) {
  const { panelIdToSetup } = useSelector(
    (state: RootReducer) => state.panelIdToSetup
  );
  const [
    updatePanelResponse,
    updatePanelLoading,
    updatePanelLoadingError,
    updatePanel,
  ] = useAxios();

  const params = useParams();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (!params.source) {
      dispatch(panelIdToSetupAction({ panelIdToSetup: "" }));
    }
  }, [params]);

  const activePanelList = useSelector(
    (state: RootReducer) => state.panels.activePanels
  );
  const archivedPanelList = useSelector(
    (state: RootReducer) => state.panels.archivedPanels
  );
  const location = useLocation();
  const isApannel = location.pathname
    .split("/")
    .find((path) => path === "apanels");

  const panels = isApannel ? archivedPanelList : activePanelList;

  const currectParentPanel = panels.find(
    (panel: PanelType) => panel.id === params.panelId
  );
  const currectPanel = currectParentPanel.isStandalone
    ? currectParentPanel
    : currectParentPanel.children.find(
        (c: Child) => c.id === params.subpanelId
      );
  const propItem = item || currectPanel;

  const ref: any = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { connectDatasourcePopupId, addDatasourceId } = useSelector(
    (state: RootReducer) => state.loading
  );
  const currentActivePanels = panels.filter((e: PanelType) =>
    item.pId ? e.id === item.pId : e.id === item.id
  );
  const [name, setName] = useState("");
  const [addSourceId, setAddSourceId] = useState("");
  const [sourceType, setSourceType] = useState("");

  const [addSourceStep2, addSourceStep2Loading, error, axiosFetch] = useAxios();
  const [
    deletePanelResponse,
    deletePanelLoading,
    deletePanelError,
    deletePanelFetch,
  ] = useAxios();
  const panelData: PanelsData = useSelector(
    (state: RootReducer) => state.panelData
  );

  const [showAddedSources, setShowAddedSources] = useState<boolean>(
    item.sources && item.sources.length > 0
  );

  const [{ opacity }, drag, preview] = useDrag(() => ({
    type: "panel",
    item,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 1 : 1,
    }),
  }));

  const [
    reorderPanelsResponse,
    reorderPanelsLoading,
    reorderPanelsError,
    reorderPanels,
  ] = useAxios();

  const updeatePanelsOrder = async (
    payload: { id: string; order: number }[]
  ) => {
    await reorderPanels({
      method: SERVER_CONFIG.HTTP_METHOD.POST,
      url: SERVER_CONFIG.API_URL.PANELS_REORDERING,
      data: payload,
    });
  };

  const shiftPanels = (droppedItem: Child, droppedInItem: Child) => {
    const droppedInIndex = currentActivePanels[0].children.findIndex(
      (e: Child) => e.id === droppedInItem.id
    );
    const droppedIndex = currentActivePanels[0].children.findIndex(
      (e: Child) => e.id === droppedItem.id
    );
    currentActivePanels[0].children.splice(droppedIndex, 1);
    currentActivePanels[0].children.splice(droppedInIndex, 0, droppedItem);
    let updatedChildrenList: Child[] = [];
    currentActivePanels[0].children.forEach((ele: Child, index: number) => {
      updatedChildrenList = [...updatedChildrenList, { ...ele, order: index }];
    });
    return updatedChildrenList;
  };
  const [, drop] = useDrop(() => ({
    accept: "panel",
    drop: (droppedItem: Child) => {
      if (droppedItem.id === item.id || !item.pId) return null;
      const activeParentindex = activePanelList.findIndex(
        (e: PanelType) => e.id === item.pId
      );
      const updatedChildrenList = shiftPanels(droppedItem, item);
      const updatedParent = {
        ...activePanelList[activeParentindex],
        children: updatedChildrenList,
      };
      dispatch(updateAfterPanelDrag(updatedParent, activeParentindex));
      const payload = updatedChildrenList.map((child) => ({
        id: child.id,
        order: child.order,
      }));
      updeatePanelsOrder(payload);
      return droppedItem;
    },
  }));
  const changePanelName = (event: any, id: string, pId: string) => {
    if (!name) {
      setName(item.name);
      return;
    }
    if (pId && id) {
      const updatedPanel = activePanelList;
      const parentDetails = updatedPanel.filter((e: any) => e.id === pId);
      const parentIndex = updatedPanel.findIndex((e: any) => e.id === pId);
      const updateParent = {
        ...parentDetails[0],
        children: parentDetails[0].children.map((e: any) => {
          if (e.id === id) {
            return { ...e, name };
          }
          return e;
        }),
      };
      updatedPanel.splice(parentIndex, 1, updateParent);
      dispatch(updatePanelsDetails([...updatedPanel]));
    }
    if (id && !pId) {
      const updatedPanel = [...activePanelList];
      const parentDetails = updatedPanel.filter((e: PanelType) => e.id === id);
      const parentIndex = updatedPanel.findIndex((e: PanelType) => e.id === id);
      const updateParent = { ...parentDetails[0], name };
      updatedPanel.splice(parentIndex, 1, updateParent);
      dispatch(updatePanelsDetails(updatedPanel));
    }
    const partialUpdateParams = {
      op: Constants.REPLACE,
      path: Constants.NAME_PATH,
      value: name,
    };

    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
  };

  const makeActive = () => {
    // If parent
    const { id, pId } = item;
    const partialUpdateParams = {
      op: Constants.REPLACE,
      path: Constants.CHANGE_ARCHIVE_STATUS_PATH,
      value: "true",
    };

    updatePanel({
      method: SERVER_CONFIG.HTTP_METHOD.PATCH,
      url: SERVER_CONFIG.API_URL.PANELS_PARTIAL_UPDATE(id),
      data: [partialUpdateParams],
    });
    const tempActivePanels = [...activePanelList];
    const tempArchivedPanels = archivedPanelList;
    if (!pId) {
      const indexOfArchived = tempArchivedPanels.findIndex(
        (e: PanelType) => e.id === id
      );
      const archivedParent = tempArchivedPanels.filter((e: PanelType) => {
        return e.id === id;
      });
      const updatedPanel = {
        ...archivedParent[0],
        active: true,
        order: tempActivePanels.length,
      };
      tempArchivedPanels.splice(indexOfArchived, 1);
      const updatedArchivedPanelList = tempArchivedPanels.map(
        (e: PanelType, index: number) => {
          return { ...e, order: index };
        }
      );
      const updatedActivePanelList = [...tempActivePanels, updatedPanel];
      dispatch(updatePanelsDetails(updatedActivePanelList));
      navigate(`/panels/${id}`);
      dispatch(updateArchivedPanelsDetails(updatedArchivedPanelList));
    } else {
      // If Child
      const indexofParentInArchive = tempArchivedPanels.findIndex(
        (e: PanelType) => e.id === pId
      );
      const indexOfParentInActive = tempActivePanels.findIndex(
        (e: PanelType) => e.id === pId
      );
      const activeElementDetails = {
        ...tempArchivedPanels[indexofParentInArchive].children.filter(
          (e: Child) => e.id === id
        )[0],
        active: true,
      };
      const activeParentDetails = {
        ...tempArchivedPanels[indexofParentInArchive],
        active: true,
      };

      if (tempArchivedPanels[indexofParentInArchive].children.length > 1) {
        const updatedArchiveParent = {
          ...tempArchivedPanels[indexofParentInArchive],
          children: tempArchivedPanels[indexofParentInArchive].children
            .filter((e: Child) => e.id !== id)
            .map((e: Child, index: number) => {
              return { ...e, order: index };
            }),
          active: true,
        };
        tempArchivedPanels.splice(
          indexofParentInArchive,
          1,
          updatedArchiveParent
        );
      } else {
        tempArchivedPanels.splice(indexofParentInArchive, 1);
      }
      if (indexOfParentInActive >= 0) {
        const currentActiveParent = tempActivePanels[indexOfParentInActive];
        const updatedActiveParent = {
          ...currentActiveParent,
          children: [
            ...currentActiveParent.children,
            {
              ...activeElementDetails,
              order: currentActiveParent.children.length,
            },
          ],
        };
        tempActivePanels.splice(indexOfParentInActive, 1, updatedActiveParent);
      } else {
        tempActivePanels.splice(tempActivePanels.length, 0, {
          ...activeParentDetails,
          children: [{ ...activeElementDetails, order: 0 }],
        });
      }

      dispatch(updatePanelsDetails([...tempActivePanels]));
      dispatch(updateArchivedPanelsDetails([...tempArchivedPanels]));
      navigate(`/panels/${pId}/${id}`);
    }
    // If child
  };

  const isSourceClicked = location.pathname.includes("add-source");
  useEffect(() => {
    setShowAddedSources(item.sources && item.sources.length > 0);
  }, [item]);

  useEffect(() => {
    if (deletePanelResponse) {
      dispatch(deletePanel(item.id, item.pId));

      if (params.panelId && params.subpanelId) {
        navigate(`/panels/${params.panelId}`);
      }
    }
  }, [deletePanelResponse]);

  const deleteCurrentPanel = () => {
    if (deletePanelFetch) {
      deletePanelFetch({
        method: SERVER_CONFIG.HTTP_METHOD.DELETE,
        url: SERVER_CONFIG.API_URL.PANELS_DELETE(item.id),
      });
    }
  };

  const panelHeaderContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <div
          ref={drag}
          className="headerButtonItems"
          style={{
            paddingTop: "2px",
          }}
        >
          <HolderOutlined role="button" />
        </div>
        <Input
          className="PanelName"
          placeholder={t<string>("panelNamePlaceholder")}
          bordered={false}
          value={name || item.name}
          onChange={(el) => {
            if (el.target.value.length < 32) {
              setName(el.target.value || item.name);
            }
          }}
          onBlur={(e: any) => changePanelName(e, item.id, item.pId)}
        />
      </div>
      <div className="PanelHeaderSubContainer2">
        {!isSourceClicked && !archived && (
          <button
            type="button"
            className="headerButtonItems headerButtonIconRed"
            onClick={() => {
              if (item.pId) {
                navigate(`/panels/${item.pId}/${item.id}/add-source`);
              } else {
                navigate(`/panels/${item.id}/add-source`);
              }
            }}
          >
            <CloudSyncOutlined
              title={t<string>(
                window.innerWidth >= 600
                  ? "addEditPanelHasSourceButton"
                  : "addEditMobileSourceButton"
              )}
            />
          </button>
        )}
        <button
          type="button"
          className="headerButtonItems"
          title="Help Center"
          onClick={() => navigate("/help/faq")}
        >
          <QuestionCircleOutlined role="button" />
        </button>
        <Dropdown
          trigger={["click"]}
          placement="bottomLeft"
          className="actions-icon"
          overlayClassName="actions-dropdown"
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <button
                    type="button"
                    className="dropdown-menu-action-option"
                    disabled={!item.sources?.length || archived}
                    onClick={() => {
                      if (!panelIdToSetup) {
                        if (item) {
                          dispatch(
                            panelIdToSetupAction({ panelIdToSetup: item.id })
                          );
                        } else if (params.subpanelId) {
                          dispatch(
                            panelIdToSetupAction({
                              panelIdToSetup: params.subpanelId,
                            })
                          );
                        }
                      } else {
                        dispatch(panelIdToSetupAction({ panelIdToSetup: "" }));
                      }
                      if (params.subpanelId && !panelIdToSetup) {
                        navigate(
                          `/panels/${params.panelId}/${params.subpanelId}/settings/o365`
                        );
                      } else if (
                        !panelIdToSetup &&
                        params.panelId &&
                        item.pId
                      ) {
                        navigate(
                          `/panels/${params.panelId}/${item.id}/settings/o365`
                        );
                      } else if (
                        !panelIdToSetup &&
                        params.panelId &&
                        !item.pId
                      ) {
                        navigate(`/panels/${params.panelId}/settings/o365`);
                      } else if (panelIdToSetup && params.subpanelId) {
                        navigate(
                          `/panels/${params.panelId}/${params.subpanelId}`
                        );
                      } else if (panelIdToSetup && params.panelId) {
                        navigate(`/panels/${params.panelId}`);
                      }
                    }}
                  >
                    <SettingOutlined
                      role="button"
                      className="dropdown-menu-action-option-icon"
                    />
                    {t<string>("panelSettings")}
                  </button>
                ),
              },
              {
                key: "2",
                label: (
                  <button
                    type="button"
                    onClick={() => {
                      const prevPanelInfo: PreviousPanelInfoType = {
                        panelId: params.panelId || "",
                      };
                      if (params.subpanelId) {
                        prevPanelInfo.subpanelId = params.subpanelId;
                      }
                      dispatch(setPreviousPanelInfoAction(prevPanelInfo));
                      navigate("/globalsettings/profile");
                    }}
                    className="dropdown-menu-action-option"
                  >
                    <SettingOutlined
                      role="button"
                      className="dropdown-menu-action-option-icon"
                    />
                    {t<string>("globalSettingsLink")}
                  </button>
                ),
              },
              {
                key: "3",
                label: (
                  <button
                    type="button"
                    className="dropdown-menu-action-option dropdown-menu-action-option-delete"
                    onClick={deleteCurrentPanel}
                  >
                    <img src={trashBinIcon} alt="delete-icon" />
                    {t<string>("deletePanel")}
                  </button>
                ),
              },
            ],
          }}
        >
          <button
            className="ant-dropdown-button-email-list"
            type="submit"
            onClick={(e) => e.preventDefault()}
          >
            <SettingOutlined role="button" />
          </button>
        </Dropdown>
      </div>
    </div>
  );
  const panelHeaderWithoutChildContent = (
    <div className="PanelHeaderContainer">
      <div className="PanelHeaderSubContainer1">
        <div ref={drag} className="headerButtonItems">
          <HolderOutlined role="button" />
        </div>
        <Input
          className="PanelName"
          placeholder={t<string>("panelNamePlaceholder")}
          bordered={false}
          value={name || item.name}
          onChange={(el) => {
            if (el.target.value.length < 32) {
              setName(el.target.value || item.name);
            }
          }}
          onBlur={(e) => changePanelName(e, item.id, item.pId)}
        />
      </div>
      <div className="PanelHeaderSubContainer2">
        <button
          type="button"
          className="headerButtonItems"
          title="Help Center"
          onClick={() => navigate("/help/faq")}
        >
          <QuestionCircleOutlined role="button" />
        </button>
        {/* <button type="button" className="headerButtonItems" title="A" onClick={() => setShowSettingsView(!showSettingsView)}>
          <SettingOutlined role="button" />
        </button> */}
      </div>
    </div>
  );
  const addSource = (type: string) => {
    const temp: { [type: string]: string } = {
      outlook: outlookIcon,
      gmail: gmailLarge,
      slack: slackIcon,
      onedrive: oneDriveIcon,
      googledrive: googleDriveIcon,
      dropbox: dropboxIcon,
      // jira: outlookLarge,
    };
    dispatch(updateLoading("connectDatasourcePopupId", item.id));
    setSourceType(type);
    setAddSourceId(temp[type]);
  };

  const closeModal = () => {
    dispatch(updateLoading("connectDatasourcePopupId", ""));
    setAddSourceId("");
  };

  drop(preview(ref));
  useEffect(() => {
    const addPanelSource = () => {
      const srcState = window.localStorage.getItem("source_state") as string;
      if (srcState) {
        // eslint-disable-next-line
        // @ts-ignore
        axiosFetch({
          method: SERVER_CONFIG.HTTP_METHOD.POST,
          url: SERVER_CONFIG.API_URL.SOURCE_SAVE_ADD_SOURCE_RESPONSE,
          data: JSON.parse(srcState),
        });
        window.localStorage.removeItem("source_state");
        // setSourceState(JSON.parse(srcState));
      }
    };
    window.addEventListener("storage", addPanelSource);
    return () => {
      window.removeEventListener("storage", addPanelSource);
    };
  }, []);
  useEffect(() => {
    setShowAddedSources(item.sources.length > 0);
  }, [panelData]);

  const [
    panelsAllDataListResponse,
    panelsAllDataListLoading,
    panelsAllDataListError,
    axiosPanelsAllDataList,
  ] = useAxios();

  useEffect(() => {
    if (panelsAllDataListResponse) {
      const res = panelsAllDataListResponse;
      if (res && res.data.success) {
        const panelsData = {} as any;
        res.data.panelsData.forEach((item: TempItemPanelData) => {
          panelsData[item.panelId] = {
            email: { gmail: {}, outlook: {} },
            slack: {},
          };
          const gmailDetails = item.email?.gmail[0];
          if (gmailDetails) {
            const gmailId = gmailDetails.emailId;
            const gmailMails = { data: gmailDetails.data };
            panelsData[item.panelId].email.gmail[gmailId] = gmailMails;
          }
          const outlookDetails = item.email.outlook[0];
          if (outlookDetails) {
            const outlookId = outlookDetails.emailId;
            const outlookMails = { data: outlookDetails.data };
            panelsData[item.panelId].email.outlook[outlookId] = outlookMails;
          }
          const slackDetails = item.slack;
          if (slackDetails && slackDetails.length > 0) {
            const slackObj = {} as TempSlackData;
            slackDetails.forEach((slack: TempSlackMsg) => {
              if (!slackObj[slack.emailId]) {
                slackObj[slack.emailId] = {} as TempSlackIdData;
              }
              slackObj[slack.emailId][slack.slackId.workSpaceId] =
                {} as TempSlackWorkspaceId;
              slackObj[slack.emailId][slack.slackId.workSpaceId].channels =
                {} as TempSlackChannels;
              slackObj[slack.emailId][
                slack.slackId.workSpaceId
              ].directMessages = {} as TempSlackDirectMsg;
              slack.slackId.data.directMessage.forEach(
                (message: TempChannel) => {
                  if (
                    !slackObj[slack.emailId][slack.slackId.workSpaceId]
                      .directMessages[message.channelId]
                  ) {
                    slackObj[slack.emailId][
                      slack.slackId.workSpaceId
                    ].directMessages[message.channelId] =
                      {} as TempSlackChannelDMMessage;
                    slackObj[slack.emailId][
                      slack.slackId.workSpaceId
                    ].directMessages[message.channelId].messages = [];
                  }
                  slackObj[slack.emailId][
                    slack.slackId.workSpaceId
                  ].directMessages[message.channelId].messages.push(message);
                }
              );
              slack.slackId.data.channels.forEach((channel: TempChannel) => {
                if (
                  !slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ]
                ) {
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ] = {} as TempSlackChannelDMMessage;
                  slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                    channel.channelId
                  ].messages = [];
                }
                slackObj[slack.emailId][slack.slackId.workSpaceId].channels[
                  channel.channelId
                ].messages.push(channel);
              });
            });
            panelsData[item.panelId].slack = slackObj;
          }
          panelsData[item.panelId].storage = {};
          if (item.storage?.oneDrive?.length > 0) {
            panelsData[item.panelId].storage.oneDrive = {} as any;
            item.storage?.oneDrive.forEach((oneDrive: any) => {
              if (
                oneDrive &&
                oneDrive?.oneDriveData &&
                oneDrive?.storageEmailId
              ) {
                panelsData[item.panelId].storage.oneDrive[
                  oneDrive.storageEmailId
                ] = oneDrive.oneDriveData;
              }
            });
          }

          if (item.storage?.googleDrive?.length > 0) {
            panelsData[item.panelId].storage.googleDrive = {} as any;
            item.storage?.googleDrive.forEach((googleDrive: any) => {
              if (
                googleDrive &&
                googleDrive.googleDriveData &&
                googleDrive.storageEmailId
              ) {
                panelsData[item.panelId].storage.googleDrive[
                  googleDrive.storageEmailId
                ] = googleDrive.googleDriveData;
              }
            });
          }

          if (item.storage?.dropBox?.length > 0) {
            panelsData[item.panelId].storage.dropBox = {} as any;
            item.storage?.dropBox.forEach((dropBox: any) => {
              if (dropBox && dropBox.dropBoxData && dropBox.storageEmailId) {
                panelsData[item.panelId].storage.dropBox[
                  dropBox.storageEmailId
                ] = dropBox.dropBoxData;
              }
            });
          }
        });
        dispatch(setPanelsData(panelsData));

        if (
          addSourceStep2?.data?.data?.sourceId === 1 ||
          addSourceStep2?.data?.data?.sourceId === 2
        ) {
          if (item.pId) {
            navigate(`/filters/${item.pId}/${item.id}`);
          } else {
            navigate(`/filters/${item.id}`);
          }
        } else if (item.pId) {
          navigate(`/panels/${item.pId}/${item.id}`);
        } else {
          navigate(`/panels/${item.id}`);
        }
      }
    }
  }, [panelsAllDataListResponse]);

  useEffect(() => {
    if (addSourceStep2) {
      const res = addSourceStep2;
      if (res && res.data.success) {
        const parentPanel = item.pId
          ? activePanelList.find((parent: PanelType) => parent.id === item.pId)
          : activePanelList.find((parent: PanelType) => parent.id === item.id);
        const childPanel = item.pId
          ? parentPanel.children.find(
              (child: Child) => child.id === res.data.data.panelId
            )
          : parentPanel;
        const source = res.data.data;
        if (source.sourceId === 3) {
          const { slackData = {} } = source;
          const { slackId = {} } = slackData;
          const { data: dataObj = {} } = slackId;
          const slack = {
            [slackData.email]: {
              [slackId.workSpaceId]: {
                slackName: dataObj.slackName,
                channels: {} as any,
                directMessages: {} as any,
              },
            },
          };
          dataObj.channels.forEach((e: any) => {
            // eslint-disable-next-line max-len
            slack[slackData.email][slackId.workSpaceId].channels[e.channelId] =
              {
                channelName: e.channelName,
                channelId: e.channelId,
              };
          });
          dataObj.directMessage.forEach((e: any) => {
            // eslint-disable-next-line max-len
            slack[slackData.email][slackId.workSpaceId].directMessages[
              e.channelId
            ] = {
              directMessagesName: e.directMessageName,
              channelId: e.channelId,
            };
          });
          const updatedSourceDetails = { ...source, data: { slack } };
          childPanel.sources.push(updatedSourceDetails);
          dispatch(updatePanelsDetails(activePanelList));
        } else {
          childPanel.sources.push(source);
          dispatch(updatePanelsDetails(activePanelList));
        }

        axiosPanelsAllDataList({
          method: SERVER_CONFIG.HTTP_METHOD.GET,
          url: SERVER_CONFIG.API_URL.PANELS_DATA,
        });
      }
    }
  }, [addSourceStep2]);
  return (
    <div
      ref={ref}
      className="PanelContainer"
      style={{ gridArea: position, background: "#fff", opacity }}
    >
      {item.id &&
      !item.pId &&
      (!item.children || item.children?.length === 0) &&
      !item.isStandalone ? (
        <>
          {panelHeaderWithoutChildContent}
          {!archived && (
            <div className="sub-account-main-container">
              <ParentWithoutChild />
            </div>
          )}
        </>
      ) : (
        panelHeaderContent
      )}
      {!params.source ? (
        <>
          {archived && (
            <div className="archivedHeader-child">
              <div>{t<string>("archivedParentText")}</div>
              <div>
                <button
                  type="button"
                  className="make-active-button"
                  onClick={makeActive}
                >
                  {t<string>("makeActiveButton")}
                </button>
              </div>
            </div>
          )}
          {!(
            item?.id &&
            !item?.pId &&
            !item?.isStandalone &&
            (!item?.children || item?.children?.length === 0)
          ) &&
            isSourceClicked && (
              <div className="sub-account-main-container">
                <button
                  type="button"
                  className="global-settings-back-button"
                  title={t<string>("backToPanelButton")}
                  onClick={() => {
                    // showAddSourceList();
                    if (params.subpanelId) {
                      navigate(`/panels/${item.pId}/${item.id}`);
                    } else {
                      navigate(`/panels/${item.id}`);
                    }
                  }}
                >
                  <ArrowLeftOutlined />
                  <span style={{ paddingLeft: "4px" }}>
                    {t<string>("backToPanelButton")}
                  </span>
                </button>
                <div className="settings-datasource-container">
                  <div className="datasource-text-container">
                    <h4>{t<string>("addSourceSubHeader")}</h4>
                    <p>{t<string>("addSourceSubText")}</p>
                  </div>
                </div>
                <Spin
                  size="large"
                  spinning={
                    panelsAllDataListLoading ||
                    addSourceStep2Loading ||
                    deletePanelLoading
                  }
                >
                  <DatasourcesList
                    addSource={(type: string) => addSource(type)}
                    item={item}
                  />
                </Spin>
              </div>
            )}
          {!(
            item?.id &&
            !item?.pId &&
            !item?.isStandalone &&
            (!item?.children || item?.children?.length === 0)
          ) &&
            !isSourceClicked &&
            !showAddedSources &&
            !archived && (
              <div className="sub-account-main-container">
                <Spin size="large" spinning={deletePanelLoading}>
                  <div className="empty-panel-text">
                    {window.innerWidth >= 600 ? (
                      <>
                        {t<string>("clickSourceToAddDataToPanel")} <br />
                        {t<string>("clickSourceToAddDataToPanel2")}
                      </>
                    ) : (
                      <>{t<string>("clickSourceToAddDataToPanelMobile")}</>
                    )}
                  </div>
                </Spin>
              </div>
            )}
          {showAddedSources && !isSourceClicked && !archived && (
            <Spin size="large" spinning={deletePanelLoading}>
              <DataSources item={item} />
            </Spin>
          )}
        </>
      ) : (
        <Settings item={propItem} />
      )}
      {connectDatasourcePopupId && (
        // <Modal title="Basic Modal" open={connectDatasourcePopupId === item.id} onOk={handleCancel} onCancel={handleCancel} />
        <Modal
          open={connectDatasourcePopupId === item.id}
          closable={false}
          centered
          footer={false}
          onCancel={closeModal}
          onOk={closeModal}
          width={780}
        >
          <AddPanelSourcePopup
            imgSrc={addSourceId}
            type={sourceType}
            item={item}
            closeModal={closeModal}
          />
        </Modal>
      )}
    </div>
  );
}

export default Panel;
