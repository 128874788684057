/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/return-await */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { FocusEventHandler, useMemo, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { StripeAddressElementOptions } from "@stripe/stripe-js";
import { usersApi } from "../../store/api/users";
import { subscriptionApi, Subscription } from "../../store/api/subscription";
import PPanels from "../../assets/Images/P_Panels.png";

import { SubscriptionInput } from "./subscription-input";
import "./subscription-page.scss";
import { setUserInfoAction } from "../../store/reducers/user/action/user-action";
import { CardInputBlock } from "./card-input-block";
import { AddressInputBlock } from "./address-input-block";
import { Footer } from "../footer/footer";
import {
  StripePriceLookupKey,
  StripeProduct,
} from "../../prop-types/prop-type";

const validationSchema = Yup.object({
  email: Yup.string()
    .required("* Please enter e-mail")
    .email("* Please enter a valid email address")
    .trim(),
});

const SubscriptionPage = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      console.log("values", values);
    },
    validationSchema,
  });

  const dispatch = useDispatch<any>();

  const stripe = useStripe();

  const elements = useElements();

  const [address, setAddress] = useState<
    StripeAddressElementOptions["defaultValues"]
  >({});

  const {
    useSetupIntentQuery,
    useGetPricesQuery,
    useCreateCustomerMutation,
    useSubscribeCustomerMutation,
  } = subscriptionApi;
  const { useCreateUserMutation, useValidateEmailMutation } = usersApi;

  const { data: _intent } = useSetupIntentQuery({ paymentMethod: "card" });
  const { data: prices } = useGetPricesQuery({});
  const [createCustomer, _customer] = useCreateCustomerMutation();
  const [subscribeCustomer, _subscription] = useSubscribeCustomerMutation();
  const [createUser, _user] = useCreateUserMutation();
  const [validateEmail, _validateEmailResposne] = useValidateEmailMutation();

  const location = useLocation();

  const isPremiumPage = location.pathname.includes("/premium");
  const defaultPrice = isPremiumPage ? 14 : 12;

  const currentPrice = useMemo(() => {
    if (!prices) {
      return;
    }

    const currentLookupKey = isPremiumPage
      ? StripePriceLookupKey.PremiumSpecial
      : StripePriceLookupKey.Standard;

    const standardPrice = prices.find(
      (price) => price.active && price.lookup_key === currentLookupKey
    );

    return standardPrice;
  }, [prices]);

  const priceId = currentPrice?.id;

  const priceAmount = currentPrice
    ? currentPrice.unit_amount / 100
    : defaultPrice;

  type SubscriptionHandler = () => Promise<Subscription | undefined>;

  const handleSubscriptionWithCard: SubscriptionHandler = async () => {
    if (!stripe || !elements) {
      toast.error("Something went wrong");
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card && address) {
      const { paymentMethod } = await stripe.createPaymentMethod({
        card,
        type: "card",
      });

      const customerData = await createCustomer({
        email: formik.values.email,
        defaultPaymentMethod: paymentMethod?.id,
        address,
        phone: address.phone || "",
      }).unwrap();
      const customerId = customerData?.id;

      if (customerId && priceId) {
        return await subscribeCustomer({
          customerId,
          priceId,
          trialPeriodDays: 0,
          defaultPaymentMethod: paymentMethod?.id,
        }).unwrap();
      }
    }
  };

  const navigate = useNavigate();
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    try {
      const subscriptionData = await handleSubscriptionWithCard();

      await createUser({
        firstName: address?.firstName || "",
        lastName: address?.lastName || "",
        email: formik.values.email,
        customerId: subscriptionData?.customer,
        subscriptionId: subscriptionData?.id,
        trialCode: `0`,
        phoneNumber: address?.phone || "",
        subscriptionType: isPremiumPage
          ? StripeProduct.Premium
          : StripeProduct.Standard,
        subscriptionStatus: "Active",
      }).unwrap();
      dispatch(
        setUserInfoAction({
          email: formik.values.email,
        })
      );
      toast("You have successfully subscribed.");
      navigate("/subscription/check-email");
    } catch (e) {
      toast.error(`${(e as { data: { message: string } })?.data?.message}`);
    }
  };

  const handleEmailOnBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    await validateEmail({ email: e.target.value });
  };

  const emailErrorHandler = () => {
    if (_validateEmailResposne?.data?.isTaken) {
      return "This email is already taken";
    }
    if (formik.touched.email && formik.errors.email) {
      return "Please enter a valid email address";
    }
    return "";
  };

  return (
    <>
      <div className="container">
        <form className="subscription-form" onSubmit={handleSubmit}>
          <div className="subscription-title">
            <div className="subscription-title__img">
              <img src={PPanels} alt="logo" className="logo-img" />
            </div>
            <div>
              <p className="subscription-title__text">
                {`We invite you to subscribe to a ${
                  isPremiumPage ? "Premium" : "Standard"
                } subscription${
                  isPremiumPage ? " at a special discount" : ""
                } for $${priceAmount}/mo.`}
                <br />
                <br />
                {`You may cancel, or ${
                  isPremiumPage ? "downgrade" : "upgrade"
                } to ${isPremiumPage ? "" : "or downgrade from "}a ${
                  isPremiumPage ? "Standard" : "Premium "
                }
                subscription at any time. Just go to Global Settings >
                Subscription in your Panels menu.`}
              </p>
            </div>
          </div>
          <div className="subscription-data">
            <div className="subscription-data__container">
              <div className="subscription-data__title">
                Account Information
              </div>
              <div className="subscription-data__subtitle">
                Please enter your account information below.
              </div>
              <div className="flex subscription-data__space">
                <SubscriptionInput
                  id="email"
                  label="Email"
                  value={formik.values.email}
                  placeholder="Email"
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                    handleEmailOnBlur(e);
                  }}
                  handleChange={formik.handleChange}
                  error={emailErrorHandler()}
                />
              </div>

              <CardInputBlock />

              <AddressInputBlock
                addressElement={{
                  onChange: (event) => {
                    setAddress(event.value);
                  },
                }}
              />

              <div className="button-area">
                <button
                  type="submit"
                  className="form-button"
                  disabled={
                    !formik.values.email ||
                    _customer.isLoading ||
                    _subscription.isLoading ||
                    _user.isLoading ||
                    !stripe
                  }
                >
                  <strong>Subscribe</strong>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <Footer />
    </>
  );
};

export default SubscriptionPage;
