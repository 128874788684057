/* eslint-disable react/jsx-no-useless-fragment */
import { useState } from "react";
import { Modal, Button, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import "./data-sources-delete-modal.scss";

type DataSourcesDeleteModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (retainOlderData: boolean) => void;
  mail?: string;
  name?: string;
  workspaceName?: string;
  sourceType?: string;
  buttonsDisable?: boolean;
};

function DataSourcesDeleteModal({
  open,
  onClose,
  onSubmit,
  mail,
  name,
  workspaceName,
  sourceType,
  buttonsDisable,
}: DataSourcesDeleteModalProps) {
  const { t } = useTranslation();
  const [retainOlderData, setRetainOlderData] = useState(false);

  const handleSubmit = () => {
    onSubmit(retainOlderData);
  };

  const isMail =
    sourceType?.toLocaleLowerCase() === "gmail" ||
    sourceType?.toLocaleLowerCase() === "outlook";

  const isStorage = !isMail && sourceType?.toLocaleLowerCase() !== "slack";

  const titlePhrase = isStorage
    ? `${t<string>(
        "globalSettings.dataSources.deleteModal.deleting-panel-data-source-storage"
      )} ${sourceType} ${t<string>(
        "globalSettings.dataSources.deleteModal.deleting-panel-source"
      )}`
    : t<string>(
        "globalSettings.dataSources.deleteModal.deleting-panel-data-source-one"
      );

  return (
    <Modal open={open} onCancel={onClose} footer={null} closeIcon={<></>}>
      <h5 className="data-sources-delete-modal-heading">
        {!mail ? (
          <>
            {t<string>(
              "globalSettings.dataSources.deleteModal.deleting-data-source"
            )}{" "}
            <br />
            {t<string>(
              "globalSettings.dataSources.deleteModal.deleting-data-source-proceed"
            )}
          </>
        ) : (
          titlePhrase
        )}
      </h5>
      {mail && (
        <div>
          <p className="data-sources-delete-modal-paragraph">{mail}</p>
          <h5 className="data-sources-delete-modal-heading">
            {t<string>(
              "globalSettings.dataSources.deleteModal.deleting-panel-data-source-two"
            )}{" "}
            {name}?
          </h5>
          {workspaceName && (
            <p className="data-sources-delete-modal-paragraph">
              {t<string>(
                "globalSettings.dataSources.deleteModal.deleting-panel-data-source-slack-note"
              )}
              {` ${workspaceName} `}
              {t<string>(
                "globalSettings.dataSources.deleteModal.deleting-panel-data-source-slack-note-end"
              )}
            </p>
          )}
          {isMail && (
            <p className="data-sources-delete-modal-paragraph">
              {t<string>(
                "globalSettings.dataSources.deleteModal.deleting-panel-data-source-note"
              )}{" "}
              {mail}
            </p>
          )}
        </div>
      )}
      {!mail && (
        <>
          <p className="data-sources-delete-modal-paragraph">
            {t<string>(
              "globalSettings.dataSources.deleteModal.if-you-wish-to-remove"
            )}{" "}
            <br />{" "}
            {t<string>(
              "globalSettings.dataSources.deleteModal.if-you-wish-to-remove-second"
            )}
          </p>
          <Checkbox
            value={retainOlderData}
            onChange={() => {
              setRetainOlderData((props) => !props);
            }}
          >
            {t<string>(
              "globalSettings.dataSources.deleteModal.do-you-wish-to-retain"
            )}
          </Checkbox>
        </>
      )}
      <div className="data-sources-delete-modal-button-container">
        <Button
          className="data-sources-delete-modal-button cancel"
          onClick={onClose}
          disabled={buttonsDisable}
        >
          {t<string>("globalSettings.dataSources.deleteModal.cancel")}
        </Button>
        <Button
          className="data-sources-delete-modal-button delete"
          onClick={handleSubmit}
          disabled={buttonsDisable}
        >
          {!mail
            ? t<string>("globalSettings.dataSources.deleteModal.delete")
            : t<string>("globalSettings.dataSources.deleteModal.remove")}
        </Button>
      </div>
    </Modal>
  );
}

export default DataSourcesDeleteModal;
